import { useAuth } from '@agentnet/auth';
import { Content, DataTable, pxToRem } from '@fluentsms/agentnet-web-components';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { ColDef } from 'ag-grid-enterprise';
import { getActivityRights, getUserDetailInfo, getUsersDetailList } from 'api/manageUsers/manage-users-api';
import { getTeamUsers } from 'api/orderManagement/order-management-api';
import clsx from 'clsx';
import FloatingFilterComponent from 'features/files/files-summary/files-list/FloatingFilterComponent';
import { defaultFscTeam, getTeamAssignmentIds, TeamAssignment } from 'features/orderManagement/enum';
import { UserDetailType, UserTeamType } from 'features/orderManagement/types';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import { useNavPermissions } from 'hooks/useNavPermissions';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import PageHeader from 'ui-kit/components/headers/PageHeader';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import { Notification } from 'ui-kit/components/notification/Notification';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';
import { hqUser, httpErrorCodes, userRoleAgent, userRoleStaff, userStatus, userType } from '../../constants';
import AddNewUser from './AddNewUser';
import LoginUserNameRenderer from './loginUserIdRenderer';
import UserActionsRenderer from './userActionsRenderer';
import './usersList.scss';

const ManageUsers: React.FC = () => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        marginTop: pxToRem(114),
      },
      paddingX: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        width: 'auto',
      },
      tableDefault: {
        height: `calc(100vh - 294px) !important`,
        fontFamily: theme.typography.fontFamily,
      },
      contentWrap: {
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: 'calc(100%)',
      },
      contentClass: {
        margin: '0 auto auto',
      },
      tableStyles: {
        marginTop: '0px !important',
        height: 'calc(100vh - 220px) !important',
        width: '100% !important',
      },
      mr1: {
        marginRight: theme.spacing(1),
      },
      mr2: {
        marginRight: theme.spacing(2),
      },
      noResultsType: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
      },
      titleButtonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      addNewUser: {
        lineHeight: 'normal',
        transition: 'none',
        marginRight: theme.spacing(3),
      },
      loadingSpinner: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
      },
    }),
  );
  const classes = useStyles();
  interface IGridFilterState {
    filters: { [key: string]: any };
    number: number;
  }

  const location = useLocation<{ userId: number }>();
  const historyUserId = location.state?.userId;

  const { hasPermission } = useNavPermissions();
  const useProfile: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm } = useProfile;
  const firmId = userFirm?.firmId ?? '';
  const userSearchInput =
    useProfile?.profile?.roleId === 3
      ? {
          firmId: null,
          loginUserId: null,
          fullName: null,
          office: null,
          email: null,
          userType: null,
          roles: null,
          hqUser: null,
          userStatus: null,
        }
      : {
          firmId: firmId,
          loginUserId: null,
          fullName: null,
          office: null,
          email: null,
          userType: null,
          roles: null,
          hqUser: null,
          userStatus: null,
        };

  const [searchPayload, setSearchPayload] = useState<any>(userSearchInput);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>('');
  const [gridReady, setGridReady] = useState<boolean>(false);

  const [addNewUser, setAddNewUser] = useState<boolean>(false);
  const [isAddNewUser, setIsAddNewUser] = useState<boolean>(false);
  const [rowData, setRowData] = useState<any>([]);

  const [isUserProfileLoaded, setIsUserProfileLoaded] = useState<boolean>(false);

  const [userId, setUserId] = useState<number>(0);
  const [activityRightsValue, setActivityRightsValue] = useState<boolean>(true);

  const defaultGridFilter = {};

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 2,
      floatingFilter: true,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: true,
      minWidth: 220,
      filterParams: { closeOnApply: true, suppressAndOrCondition: true },
    };
  }, []);

  const gridRef = useRef<any>(null);
  // const saveFilter = useRef<boolean>(true);
  const secondaryFilterOptions = useRef<{ [key: string]: any }>({ searchText: '', state: '' });
  const [gridFilterState, setGridFilterState] = useState<IGridFilterState>({
    filters: defaultGridFilter,
    number: 3,
  });

  const getUserInfo = async (): Promise<any> => {
    const token = await getAccessToken();
    const response = await getUserDetailInfo(token, userId);
    return response;
  };

  const { execute: executeGetUserInfo, value: getUserInfoValue } = useAsync<any>(getUserInfo, false);

  const getUsersListData = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    const result: any = null;
    if (useProfile?.profile?.roleTypeId === 101) {
      searchPayload.firmId = null;
    } else {
      searchPayload.firmId = firmId;
    }
    if (hasPermission?.orderManagementAdmin) {
      searchPayload.roles = defaultFscTeam.role;
    }
    if (isUserProfileLoaded) {
      const response = await getUsersDetailList(token, searchPayload);
      return response;
    }
    return result;
  };

  // Temporary calling 3 times in parallel to get the FSC Team Users by Role ID (Team)
  // until we create a new SP and API to return all FSC users in one call
  const getFscUserList = async (): Promise<Array<UserTeamType>> => {
    const token = await getAccessToken();
    const fscRoleIds = getTeamAssignmentIds();
    const result = await Promise.all(
      fscRoleIds.map(async (roleId) => {
        const response = await getTeamUsers(roleId, token);
        const fscTeamUsers: Array<UserTeamType> = [];
        response?.map((user: UserDetailType) => {
          fscTeamUsers.push({ userId: user.userId, fscRole: TeamAssignment[roleId] });
        });
        return fscTeamUsers;
      }),
    );
    return result.flat();
  };

  const {
    execute: executeGetUsersList,
    status: usersListStatus,
    value: usersListResults,
  } = useAsync<any>(getUsersListData, false);

  const {
    execute: executeGetFscUserList,
    status: executeGetFscUserListStatus,
    value: fscUserTeamList,
  } = useAsync<Array<UserTeamType>>(getFscUserList, false);

  const handelEditClick = (userId: number) => {
    executeGetUserInfo();
    setAddNewUser(true);
    setIsAddNewUser(true);
    setUserId(userId);
  };

  const resetGridInputFocus = () => {
    const focusElement = document.querySelectorAll('.ag-header-cell.ag-floating-filter.ag-focus-managed');
    focusElement.forEach((item) => {
      item.removeAttribute('tabindex');
    });
  };

  const isCompactGrid: boolean = useProfile?.profile?.roleId === 13 || hasPermission?.orderManagementAdmin;

  useEffect(() => {
    if (usersListResults?.result) {
      setDisplayError(false);

      if (!usersListResults?.result.total) gridRef.current?.api.showNoRowsOverlay();
      setRowData(usersListResults?.result);
    } else {
      if (usersListResults?.errorCode && gridRef.current) gridRef.current?.api.hideOverlay();
      if (
        usersListResults?.errorCode == httpErrorCodes.notFound ||
        usersListResults?.errorCode == httpErrorCodes.badRequest ||
        usersListResults?.errorCode == httpErrorCodes.forbidden ||
        usersListResults?.errorCode == httpErrorCodes.internalServerError
      ) {
        setErrMsg(usersListResults?.message ?? 'Error.  Failed to GET.');
        setDisplayError(true);
      }
    }
  }, [usersListResults]);

  useEffect(() => {
    if (userId && userId > 0 && !historyUserId) {
      executeGetUserInfo();
      setAddNewUser(true);
      setIsAddNewUser(true);
    }
  }, [userId]);

  useEffect(() => {
    if (historyUserId && historyUserId > 0) {
      if (location.state) {
        location.state.userId = 0;
      }
      setUserId(0);
      setAddNewUser(false);
      setIsAddNewUser(false);
      executeGetFscUserList();
    }
  }, [historyUserId]);

  const usersColumnsConfig: ColDef[] = [
    {
      headerName: 'User ID',
      field: 'LoginUserId',
      tooltipField: 'LoginUserId',
      filter: 'agTextColumnFilter',
      minWidth: 180,
      cellRenderer: (params: any) => {
        return (
          <LoginUserNameRenderer
            loginUserId={params?.data?.LoginUserId}
            handleEditClick={() => handelEditClick(params?.data?.UserId)}
          />
        );
      },
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      headerName: 'Full Name',
      field: 'FullName',
      tooltipField: 'FullName',
      filter: 'agTextColumnFilter',
      minWidth: 250,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      headerName: 'Office',
      field: 'FirmLocation',
      tooltipField: 'FirmLocation',
      filter: 'agTextColumnFilter',
      hide: isCompactGrid,
      minWidth: 320,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      headerName: 'Email',
      field: 'EmailAddress',
      tooltipField: 'EmailAddress',
      filter: 'agTextColumnFilter',
      minWidth: 280,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      headerName: 'Type',
      field: 'UserType',
      tooltipField: 'UserType',
      maxWidth: 120,
      hide: useProfile?.profile?.roleId === 3 ? false : true,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: userType.map((status) => status),
      },
    },
    {
      headerName: 'Role',
      field: 'Role',
      tooltipField: 'Role',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: hasPermission?.orderManagementAdmin
          ? [defaultFscTeam.role]
          : useProfile?.profile?.roleTypeId === 100
          ? userRoleAgent.map((status) => status)
          : useProfile?.profile?.roleId === 3
          ? [...userRoleAgent, ...userRoleStaff].map((role) => role)
          : userRoleStaff.map((status) => status),
      },
    },
    {
      headerName: 'Team',
      field: 'Team',
      tooltipField: 'Team',
      hide: !hasPermission?.orderManagementAdmin,
      filter: 'agSetColumnFilter',
    },
    {
      headerName: 'HQ User',
      field: 'HQUser',
      maxWidth: 120,
      hide: isCompactGrid,
      tooltipField: 'HQUser',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: hqUser.map((status) => status),
      },
    },
    {
      headerName: 'Status',
      field: 'UserStatus',
      filter: 'agSetColumnFilter',
      tooltipField: 'UserStatus',
      minWidth: 160,
      filterParams: {
        values: userStatus.map((status) => status),
      },
    },
    {
      headerName: '',
      field: '',
      cellRenderer: (params: any) => {
        return (
          <UserActionsRenderer
            userRoleType={params?.data?.UserType}
            userStatus={params?.data?.UserStatus}
            userId={params?.data?.UserId}
            userEmail={params?.data?.EmailAddress}
            handleEditClick={() => handelEditClick(params?.data?.UserId)}
          />
        );
      },
      maxWidth: 90,
      cellStyle: { display: 'flex', textAlign: 'right', justifyContent: 'center', alignItems: 'center' },
      pinned: 'right',
      filter: false,
    },
  ];

  interface IGridFilterState {
    filters: { [key: string]: any };
    number: number;
  }

  const { getAccessToken } = useAuth();

  useEffect(() => {
    if (useProfile?.profile) {
      setIsUserProfileLoaded(true);
    }
  }, [useProfile?.profile]);

  useEffect(() => {
    if (isUserProfileLoaded) {
      executeGetUsersList();
      if (hasPermission?.orderManagementAdmin) {
        executeGetFscUserList();
      }
    }
  }, [isUserProfileLoaded]);

  const GetActivityRights = async (): Promise<any> => {
    const token = await getAccessToken();
    const response = await getActivityRights(token);
    return response;
  };

  const { execute: executeGetActivityRights, value: getActivityRightsValue } = useAsync(GetActivityRights, false);

  useEffect(() => {
    executeGetActivityRights();

    if (!hasPermission?.orderManagementAdmin) {
      const hasOneCharValue = Object.entries(searchPayload).some(
        ([key, value]) => key !== 'firmId' && key !== 'state' && value && (value as string).length < 3,
      );

      !hasOneCharValue && executeGetUsersList();
    }

    try {
      if (gridRef.current) {
        gridRef.current?.api.hideOverlay();
      }
    } catch (error) {
      console.warn('AgGrid fail to hide Overlay');
    }
  }, [searchPayload]);

  useEffect(() => {
    if (getActivityRightsValue) setActivityRightsValue(false);
  }, [getActivityRightsValue]);

  useEffect(() => {
    if (usersListResults) {
      setDisplayError(false);
      if (usersListResults?.length === 0) gridRef.current?.api.showNoRowsOverlay();
      setRowData(usersListResults);
      setGridReady(false);
    } else {
      if (usersListResults && gridRef.current) gridRef.current?.api.hideOverlay();
      if (
        usersListResults == '404' ||
        usersListResults == '400' ||
        usersListResults == '403' ||
        usersListResults == '500'
      ) {
        setErrMsg(usersListResults?.message ?? 'Error.  Failed to GET.');
        setDisplayError(true);
      }
    }
  }, [usersListResults, gridReady]);

  useEffect(() => {
    if (executeGetFscUserListStatus === 'success' && usersListStatus === 'success') {
      // Add Team to rowData
      const updatedRowData = usersListResults.map((row: any) => {
        const team = fscUserTeamList?.find((team) => team.userId === row.UserId);
        return { ...row, Team: team?.fscRole };
      });
      setRowData(updatedRowData);
    }
  }, [executeGetFscUserListStatus, usersListStatus]);

  const onGridReady = (params: any) => {
    if (actionStatus == 'pending') {
      gridRef.current?.api.showLoadingOverlay();
    } else {
      gridRef.current?.api.hideOverlay();
    }
  };

  const hideOverlay = useCallback(() => {
    if (gridRef.current) {
      const rowCount = gridRef.current?.api.getDisplayedRowCount();
      if (rowCount) {
        gridRef.current?.api.hideOverlay();
      }
    }
    resetGridInputFocus();
  }, []);

  const savedFilterModel = useCallback(() => {
    const filterModel = gridRef.current?.api.getFilterModel() ?? {};
    const payload: any = {
      ...userSearchInput,
    };
    if (filterModel?.FirmLocation) {
      payload.office = filterModel?.FirmLocation.filter;
    }
    if (filterModel?.LoginUserId) {
      payload.loginUserId = filterModel?.LoginUserId.filter;
    }
    if (filterModel?.EmailAddress) {
      payload.email = filterModel?.EmailAddress.filter;
    }
    if (filterModel?.FullName) {
      payload.fullName = filterModel?.FullName.filter;
    }
    if (filterModel?.HQUser) {
      let hqRole = '';
      filterModel?.HQUser?.values?.map((item: any) => {
        if (item !== null) hqRole = hqRole + ',' + item;
      });
      hqRole = hqRole.slice(1);
      if (hqRole === 'No,Yes' || hqRole === 'Yes,No') payload.hqUser = null;
      else if (hqRole === 'Yes') payload.hqUser = 'Yes';
      else if (hqRole === 'No') payload.hqUser = 'No';
      else if (hqRole === 'Null') payload.hqUser = null;
      else payload.hqUser = null;
    }
    if (filterModel?.Role) {
      let roles = '';
      filterModel?.Role?.values?.map((item: any) => {
        if (item !== null) roles = roles + ',' + item;
      });
      roles = roles.slice(1);
      payload.roles = roles;
    }
    if (filterModel?.UserStatus) {
      let status = '';
      filterModel?.UserStatus?.values?.map((item: any) => {
        if (item !== null) status = status + ',' + item;
      });
      status = status.slice(1);
      payload.userStatus = status;
    }
    if (filterModel?.UserType) {
      let type = '';
      filterModel?.UserType?.values?.map((item: any) => {
        if (item !== null) type = type + ',' + item;
      });
      type = type.slice(1);
      payload.userType = type;
    }
    setSearchPayload(payload);
    setGridFilterState({ filters: filterModel, number: Object.keys(filterModel).length ?? 0 });
    hideOverlay();
  }, []);

  const actionStatus = usersListStatus === 'pending' ? 'pending' : 'idle';

  const CustomOverlayLoading = () => {
    return (
      <div className={classes.loadingSpinner}>
        <LoadingSpinner status="pending" className="files-container--spinner-root" />
      </div>
    );
  };

  const handleAddNewUser = () => {
    setAddNewUser(true);
    setIsAddNewUser(true);
  };

  if (!usersListResults && !gridRef.current) {
    return (
      <ContentContainer fullWidth>
        <Content>
          <CustomOverlayLoading />
        </Content>
      </ContentContainer>
    );
  }

  return (
    <ContentContainer fullWidth>
      {!isAddNewUser ? (
        <div className={classes.contentWrap}>
          {!addNewUser && (
            <PageHeader
              title="Manage Users"
              subtitle="Control and configure user access for your firm within AgentNet Services. Administrators can manage user accounts, assign access rights, set default offices, update contact information, view activity history, and distribute account credentials."
              contentRight={
                !isCompactGrid ? (
                  <AgentNetButton
                    variant="contained"
                    onClick={handleAddNewUser}
                    disabled={activityRightsValue}
                    plusIcon
                    color="primary"
                    size="small"
                  >
                    Add User
                  </AgentNetButton>
                ) : undefined
              }
            />
          )}
          <>
            {displayError && (
              <Notification
                msg={errMsg}
                severity="error"
                className="files-container--alert"
                action={
                  <Button color="inherit" variant="text" endIcon={<CloseIcon />} onClick={() => setDisplayError(false)}>
                    ACTION
                  </Button>
                }
              />
            )}
          </>
          <div className="grid-summary">
            <div className="files-list-grid">
              <div className={clsx('ag-theme-alpine table-grid', classes.tableDefault, classes.paddingX)}>
                <DataTable
                  rowData={rowData}
                  columnDefs={usersColumnsConfig}
                  onGridReady={onGridReady}
                  ref={gridRef}
                  defaultColDef={defaultColDef}
                  gridOptions={{ suppressRowClickSelection: true }}
                  components={{
                    customFloatingFilter: FloatingFilterComponent,
                  }}
                  onFilterChanged={savedFilterModel}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {addNewUser && userId === 0 ? (
            <AddNewUser
              activityRights={getActivityRightsValue}
              userId={0}
              userDetail={null}
              reLoadEdit={handelEditClick}
            />
          ) : addNewUser && userId > 0 && getUserInfoValue ? (
            <AddNewUser
              activityRights={getActivityRightsValue}
              userId={userId}
              userDetail={getUserInfoValue}
              reLoadEdit={handelEditClick}
            />
          ) : (
            CustomOverlayLoading()
          )}
        </>
      )}
    </ContentContainer>
  );
};
export default ManageUsers;
