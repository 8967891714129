import { getApiHost } from 'api/host-by-env';
import { openPdfResponse } from 'utilities/utilities';

export async function generateFileSummaryReport(fileId: string, fileNumber: string, token: string, addGlobalMsg: any) {
  try {
    const url = `${getApiHost()}pdfGeneration/generateFileSummaryReport`;
    //const url = `https://env2.dev.services.agentnetportal.firstam.net/api/v1.0/pdfGeneration/generateFileSummaryReport`;
    const response = await fetch(url, {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}`, Accept: 'application/pdf' },
      body: JSON.stringify({
        fileId: fileId,
        fileNumber: fileNumber,
      }),
    });

    if (!response.ok) {
      throw { message: 'Error Downloading File Summary Report.' };
    }

    openPdfResponse(response, `FileSummaryReport-${fileNumber}`);
  } catch (err: any) {
    console.error(err);
    addGlobalMsg({
      message: 'Error Downloading File Summary Report.',
      type: 'error',
    });
  }
}

export async function generateHelpArticle(article: string, token: string, addGlobalMsg: any) {
  try {
    const url = `${getApiHost()}pdfGeneration/generateHelpArticle`;
    //const url = 'http://localhost:3001/local/api/generateHelpArticle';
    const response = await fetch(url, {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}`, Accept: 'application/pdf' },
      body: JSON.stringify({
        article: article,
      }),
    });

    if (!response.ok) {
      throw { message: 'Error Downloading Help Article.' };
    }

    openPdfResponse(response, `HelpArticle-${article}`);
  } catch (err: any) {
    console.error(err);
    addGlobalMsg({
      message: 'Error Downloading Help Article.',
      type: 'error',
    });
  }
}

export async function checkPdfToken(token: string): Promise<boolean> {
  const url = `${getApiHost()}pdfGeneration/checkPdfToken/${token}`;
  //const url = `http://localhost:3001/local/api/checkPdfToken/${token}`;
  const response = await fetch(url, {
    method: 'GET',
  });
  const json = await response.json();
  return json.result;
}
