import { SvgIconProps } from '@material-ui/core';

const VerifiedOutlinedOff = (props: SvgIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      fill={props.htmlColor ? props.htmlColor : '#013A6F'}
      d="m9.42 4.03 1.78.77.8.34.79-.34 1.78-.77.99 1.68.44.74 2.74.62-.18 1.95-.08.85.56.65 1.29 1.47-1.28 1.48-.56.65.08.85.07.79 2.21 2.21-.29-3.18 2.44-2.8-2.44-2.79.34-3.69-3.61-.82L15.4 1.5 12 2.96 8.6 1.5 7.03 4.15l1.46 1.46zm-8.28-.15 2 2 .31 3.32-2.44 2.79 2.44 2.79-.34 3.7 3.61.82 1.89 3.2 3.4-1.47 3.4 1.46 1.62-2.73 2.51 2.51 1.4-1.4L2.54 2.48zm3.82 6.64.56-.65-.09-.86-.09-.92 5.2 5.2-.46.46-2.32-2.33-1.48 1.49 3.8 3.81 1.94-1.95 3.53 3.53-.98 1.66-1.78-.77-.8-.34-.79.34-1.78.77-.99-1.67-.44-.74-2.74-.62.18-1.96.08-.85-.56-.65L3.66 12l1.29-1.48z"
    ></path>
    <path fill={props.htmlColor ? props.htmlColor : '#013A6F'} d="m13.36 10.47 1.48 1.49 2.59-2.6-1.48-1.49z"></path>
  </svg>
);

export default VerifiedOutlinedOff;
