import { makeStyles, Theme, Typography } from '@material-ui/core';
import NoResultsIcon from 'ui-kit/icons/NoResultFound';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    marginBottom: theme.spacing(1),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

interface NoResultsPayFilesProps {
  handleOnClick?: () => void;
}

export function NoResultsPayFiles({ handleOnClick }: NoResultsPayFilesProps) {
  const classes = useStyles();

  return (
    <div>
      <NoResultsIcon className={classes.icon} />
      <Typography component="h4" variant="h4">
        No Results Found
      </Typography>
      <Typography component="p" variant="body1">
        Try adjusting your filters to find or use the{' '}
        <span
          data-testid="PayAdvSearchSearchLink "
          className={classes.link}
          style={{ pointerEvents: 'auto', cursor: 'pointer' }}
          onClick={() => {
            handleOnClick && handleOnClick();
          }}
        >
          Advanced Search
        </span>
      </Typography>
    </div>
  );
}
