import { useAuth } from '@agentnet/auth';
import { pxToRem } from '@fluentsms/agentnet-web-components';
import { Container, Divider, FormControlLabel, FormHelperText, Grid, Switch, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { getStateOptions } from 'api/profile/profile-utilities';
import { Firm } from 'api/profile/types';
import {
  agentClosingAttorneys,
  approvedAttorneys,
  getLetterTypes,
  getSecondPartyDetails,
  myClosingAttorneys,
} from 'api/underwriting-api';
import { partyRequirement, partySection } from 'features/cpl/types';
import { FileDataContext, FileDataContextInterface } from 'hooks/FileDataContext';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import AccordionContent from 'ui-kit/components/accordion/AccordionContent';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import { AgentNetDivider } from 'ui-kit/components/dividers/AgentNetDivider2';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import { Notification } from 'ui-kit/components/notification/Notification';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import RadioGroup, { RadioProps } from 'ui-kit/components/radios/RadioGroup';
import { AgentNetCheckBox, AgentNetTextInput } from 'ui-kit/inputs';
import AgentNetDropdownSelector, { SelectOption } from 'ui-kit/inputs/AgentNetDropdownSelector';
import CurrencyField from 'ui-kit/inputs/CurrencyField/CurrencyField';
import StateSelectField from 'ui-kit/inputs/StateSelectField';
import SuggestDropdown from 'ui-kit/inputs/SuggestDropdown/SuggestDropdown';
import {
  AdditionalAddressSchema,
  AgentNameAddressSchema,
  CplModSchema,
  InternationalPartiesSchema,
  RiskSchema,
  SecondPartySchema,
  VerbiageEditedRemovedSchema,
} from 'utilities/validation/schemas/underwriting-schema';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import UnderwritingCoveredParties from './UnderwritingCoveredParties';
import UnderwritingIncreaseCoveragelimitParties from './UnderwritingIncreaseCoveragelimitParties';
import {
  agentClosingAttorneyStates,
  approvedAttorneyStates,
  myClosingAttorneyStates,
  underwritingText,
} from './constants';
import {
  AdditionalAddress,
  AdditionalPartyFields,
  AgentNameAddressModification,
  ApprovedAttorney,
  ClosingAttorney,
  CoveredPartiesAmount,
  CplType,
  IncreaseCoverageLimit,
  InternationalParties,
  LetterType,
  MultipleProperties,
  SecondPartyDto,
  SecondPartyOnCoverageLetter,
  UnderwritingRequest,
  UnderwritingRisk,
  VerbiageUpdate,
} from './types';

interface UnderwritingProps {
  uwr?: UnderwritingRequest;
  updateUnderwriting: any;
  isUwrDisabled?: boolean;
  isDisabled?: boolean;
  updateUwrErrorStatus: any;
  submitAttempted: boolean;
  setSubmitAttempted: React.Dispatch<React.SetStateAction<boolean>>;
  isDuplicateRisk?: any;
  isCoveredpartiesSelected: (value: boolean) => void;
}
const UnderwritingRisks: React.FC<UnderwritingProps> = ({
  uwr,
  updateUnderwriting,
  isUwrDisabled,
  isDisabled,
  updateUwrErrorStatus,
  submitAttempted,
  setSubmitAttempted,
  isDuplicateRisk,
  isCoveredpartiesSelected,
}: UnderwritingProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        background: theme.palette.common.white,
      },
      UnderwritingWrap: {
        padding: pxToRem(0, 24, 88),
      },
      mb2: {
        marginBottom: theme.spacing(2),
      },
      riskDisclaimer: {
        paddingTop: pxToRem(12),
      },
      cplModLetterType: {
        paddingTop: `24px`,
      },
      cplModLenderName: {
        padding: '24px 0 0 0',
      },
      warningMsg: { marginTop: theme.spacing(3) },
      cplModRisk: {
        display: 'flex',
      },
      linePadding: {
        paddingTop: `18px`,
      },
      agentName: { padding: '12px 12px 12px 0' },
      fieldPadding: {
        marginTop: theme.spacing(1),
      },
    }),
  );

  const randomId = () => (Math.random() + 1).toString(36).substring(7);
  const { getAccessToken } = useAuth();
  const { addGlobalMsg } = useGlobalMessages();

  const [riskOptions, setRiskOptions] = useState<SelectOption[]>([]);

  const [isdeleteRisk, setIsDeleteRisk] = useState(false);
  const [deleteRiskIndex, setDeleteRiskIndex] = useState<any>('');

  const [duplicateRiskName, setDuplicateRiskName] = useState('');
  const [duplicateRiskId, setDuplicateRiskId] = useState<any>('');

  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const { fileData } = fileDataCtx;
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm } = profileCtx;

  // Form Validation State Vars
  const [showMandatoryField, setShowMandatoryField] = useState(false);
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[][]>([]);

  const [additionalPartiesFields, setAdditionalPartiesFields] = useState<AdditionalPartyFields>({});
  const [letterTypeValue, setLetterTypeValue] = useState<string>('');

  const [letterTypeOptions, setLetterTypeOptions] = useState<SelectOption[]>([]);
  const [coveredParties, setCoveredParties] = useState<string[]>([]);

  const [summaryRiskRequired, setSummaryRiskRequired] = useState<boolean>(false);
  const roleTypeOptions = [
    { label: 'Title', value: 'Title' },
    { label: 'Escrow', value: 'Escrow' },
  ];

  const radioOption = [
    { name: '2 Years', value: 'twoyears', dataTestId: 'twoyears' },
    { name: '60 Days', value: 'sixtydays', dataTestId: 'sixtydays' },
  ];
  const radioOptions = radioOption.map((x) => {
    return { label: x.name, value: x.value, dataTestId: x.dataTestId };
  });

  const selectedRadioOption = radioOptions.find(
    (option) => option.value === (uwr?.Risks?.find((risk) => risk.Name === 'CPL Modification')?.Period ?? 'twoyears'),
  );

  const [secondPartyFilter, setSecondPartyFilter] = useState<RadioProps>(roleTypeOptions[0]);

  const [partiesSection, setPartiesSection] = useState<partySection>({});
  const [CoverageMinAmount, setCoverageMinAmount] = useState<number>(0);
  const [isAdjustClaimsPeriod, setisAdjustClaimsPeriod] = useState<boolean>(false);
  const [isRemoveArbitrationParagraph, setisRemoveArbitrationParagraph] = useState<boolean>(false);
  const [coverageLimitError, setCoverageLimitError] = useState<string>('');
  const [touched, setTouched] = useState<boolean>(false);

  const handleRiskNameChange = (index: number, riskName: string) => {
    const risks = uwr?.Risks ?? ([] as UnderwritingRisk[]);
    if (riskName !== 'CPL Modification') {
      delete risks[index].AgentNameAddressModification;
      delete risks[index].AdditionalAddress;
      delete risks[index].VerbiageUpdate;
      delete risks[index].InternationalParties;
      delete risks[index].IncreaseCoverageLimit;
      delete risks[index].SecondPartyOnCoverageLetter;
      delete risks[index].MultipleProperties;

      updateUnderwriting('Risks', risks);
    }
  };

  const lenderRequirements: partyRequirement = {
    name: partiesSection?.lender?.default?.name?.require,
  };

  const buyerRequirements: partyRequirement = {
    name: partiesSection?.buyer?.default?.name?.require,
  };

  useEffect(() => {
    const riskOptionsMap =
      uwr?.TemplateMetaData.Risks.map((x, i) => {
        return { name: x.Name, value: x.Name };
      }) ?? [];

    setRiskOptions(riskOptionsMap);

    if (!uwr?.Risks?.length && uwr?.RequestInformation && uwr?.RequestInformation?.RequestId == 0) {
      handleNewRisk(true);
    } else if (
      !uwr?.Risks?.length &&
      uwr?.RequestInformation?.RequestId &&
      uwr?.RequestInformation?.RequestId > 0 &&
      uwr?.AdditionalRisks?.length > 0
    ) {
      handleNewRisk(false);
    } else if (
      !uwr?.Risks?.length &&
      uwr?.RequestInformation?.RequestId &&
      uwr?.RequestInformation?.RequestId > 0 &&
      uwr?.AdditionalRisks?.length == 0
    ) {
      handleNewRisk(false);
    }
  }, [uwr?.TemplateMetaData]);

  const classes = useStyles();

  function formatCurrency(value: number): string {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  }

  useEffect(() => {
    const officeDetails = userFirm?.offices?.find((office) => office.officeId == fileData?.officeId);
    const accountDetails = officeDetails?.accounts?.find((account) => account.accountId == fileData?.accountId);

    if (accountDetails?.stateCode == 'HI') {
      setCoverageMinAmount(15000000);
    } else {
      setCoverageMinAmount(5000000);
    }
  }, [userFirm]);

  const validateCoverageAmount = (value: string) => {
    let msg = '';
    if (parseInt(_.replace(value, /,/g, ''), 10) < CoverageMinAmount) {
      msg = 'The coverage limit entered must be greater than ' + formatCurrency(CoverageMinAmount);
    }
    return msg;
  };

  const handelecovergelimit = (index: number, checkBox: string, field: string, value: any) => {
    //  validateCoverageAmount(value, field);
    updateCplModRisk(index, checkBox, field, value);
  };

  const validateErrors = async () => {
    const riskErrs: FieldValidationError[][] = await Promise.all(
      (uwr?.Risks ?? []).map(async (risk) => {
        const perRiskErr = (await doValidate(risk, RiskSchema)) || [];
        return perRiskErr;
      }),
    );

    const cplRiskErrors: FieldValidationError[][] = await Promise.all(
      (uwr?.Risks ?? []).map(async (risk) => {
        if (risk.Name === 'CPL Modification') {
          risk.ApprovedAttorneyId = risk.ApprovedAttorneyId === null ? 0 : risk.ApprovedAttorneyId;
          const cplRiskErr =
            (await doValidate(
              {
                ApprovedAttorney: risk.ApprovedAttorneyId,
                AgentClosingAttorney: risk.AgentClosingAttorneyId,
                MyClosingAttorney: risk.MyClosingAttorneyId,
              },
              CplModSchema([
                additionalPartiesFields?.approvedAttorneys?.require,
                additionalPartiesFields?.agentClosingAttorneys?.require,
                additionalPartiesFields?.myClosingAttorneys?.require,
                additionalPartiesFields?.titleSecondParties?.require,
              ]),
            )) || [];
          const cplModAgentAddress = risk.AgentNameAddressModification
            ? (await doValidate(risk.AgentNameAddressModification, AgentNameAddressSchema)) ?? []
            : [];
          const cplModSecondParty = risk.SecondPartyOnCoverageLetter
            ? (await doValidate(risk.SecondPartyOnCoverageLetter, SecondPartySchema)) ?? []
            : [];
          const cplModAdditionalAddress = risk.AdditionalAddress
            ? (await doValidate(risk.AdditionalAddress, AdditionalAddressSchema)) ?? []
            : [];

          const cplModInternationalParties: [] = risk.InternationalParties
            ? (await doValidate(risk.InternationalParties, InternationalPartiesSchema)) ?? []
            : [];

          const VerbiageUpdate: [] = risk.VerbiageUpdate
            ? (await doValidate(risk, VerbiageEditedRemovedSchema)) ?? []
            : [];

          let increaseCoverLimitParties = [] as FieldValidationError[];
          if (risk.IncreaseCoverageLimit) {
            if (!risk.IncreaseCoverageLimit.CoveredParties) {
              const msg = validateCoverageAmount(risk.IncreaseCoverageLimit.CoverageLimit.toString());
              increaseCoverLimitParties = msg !== '' ? [{ field: 'CoveredParty', message: msg }] : [];
            }
            if (risk.IncreaseCoverageLimit.CoveredParties) {
              if (risk.IncreaseCoverageLimit.CoveredParties?.length > 0) {
                increaseCoverLimitParties = risk.IncreaseCoverageLimit.CoveredParties.reduce(
                  (error: FieldValidationError[], party: CoveredPartiesAmount) => {
                    party.CoverageAmount = party.CoverageAmount === '' ? '0' : party.CoverageAmount;
                    const msg = validateCoverageAmount(party.CoverageAmount);
                    if (msg !== '') {
                      error.push({
                        field: 'CoveredParty',
                        message: msg,
                      });
                    }
                    return error;
                  },
                  [],
                );
              }
            }
          }

          // const multipleProperties = risk.MultipleProperties
          //   ? (await doValidate(risk.MultipleProperties, MultiplePropertiesSchema)) ?? []
          //   : [];

          const cplAllErrs: any = [
            ...cplRiskErr,
            ...cplModAgentAddress,
            ...cplModSecondParty,
            ...cplModAdditionalAddress,
            ...cplModInternationalParties,
            ...(increaseCoverLimitParties ?? []),
            ...VerbiageUpdate,
          ];
          return cplAllErrs;
        } else {
          return [];
        }
      }),
    );
    // Deleting 1st index from error array for bypass the validation by assuming
    // no new risk would be added unless the exiting Risk is filled

    let errs = [];
    if (uwr?.AdditionalRisks && uwr?.AdditionalRisks?.length > 0 && uwr?.Risks?.length == 1) {
      errs = riskErrs.splice(1);
    } else {
      errs = riskErrs;
    }

    if (uwr?.Risks && uwr.Risks.some((risk) => risk.Name === 'CPL Modification')) {
      errs = [...errs, ...cplRiskErrors];
      Object.assign(errs, cplRiskErrors);
    }

    updateUwrErrorStatus('Risks', noErrorsObj(errs ?? []));
    setValidationErrors(errs);
  };

  const noErrorsObj = (obj: any) => {
    return Object.values(obj).every((val) => Array.isArray(val) && val.length === 0);
  };
  const handleBlur = (value: string) => {
    setTouched(true);
    const newError = validateCoverageAmount(value);
    setCoverageLimitError(newError);
  };

  // Risks Validation on Save/ Submit
  useEffect(() => {
    if (submitAttempted) {
      validateErrors();

      if (uwr?.Risks && uwr.Risks.some((risk) => risk.Name === 'CPL Modification')) {
        ValidateIncreaseCoveragelimit();
        validateCplModRisk();
      }

      setShowMandatoryField(true);
      setSubmitAttempted(false);
    }
  }, [submitAttempted]);

  const ValidateIncreaseCoveragelimit = () => {
    const risk = uwr?.Risks?.find((risk) => risk.Name === 'CPL Modification');
    let error = false;

    if (risk?.IncreaseCoverageLimit) {
      if (coveredParties.length > 1 && risk?.IncreaseCoverageLimit?.CoveredParties.length > 0) {
        risk?.IncreaseCoverageLimit?.CoveredParties.forEach((party) => {
          if (party.CoverageAmount === '') {
            error = true;
          } else if (
            party.CoverageAmount !== '' &&
            parseInt(_.replace(party.CoverageAmount, /,/g, ''), 10) < CoverageMinAmount
          ) {
            error = true;
          }
        });
      } else if (coveredParties.length == 0 && risk?.IncreaseCoverageLimit.CoverageLimit == 0) {
        error = true;
      }
    }

    if (error) {
      isCoveredpartiesSelected(false);
      addGlobalMsg({
        message: 'The coverage limit entered must be greater than $5,000,000.00',
        type: 'error',
      });
      error = false;
    }
  };

  const validateCplModRisk = () => {
    const risk = uwr?.Risks?.find((risk) => risk.Name === 'CPL Modification');

    let error = false;
    if (
      !risk?.AdditionalAddress &&
      !risk?.AgentNameAddressModification &&
      !risk?.IncreaseCoverageLimit &&
      !risk?.InternationalParties &&
      !risk?.SecondPartyOnCoverageLetter &&
      !risk?.VerbiageUpdate &&
      !risk?.MultipleProperties
    ) {
      isCoveredpartiesSelected(false);
      addGlobalMsg({
        message: 'Please select at least one CPL Modification type.',
        type: 'error',
      });
      error = true;
    }

    let coveredPartyError = false;
    if (risk?.AgentNameAddressModification) {
      if (coveredParties.length > 1 && risk?.AgentNameAddressModification?.CoveredParties.length == 0) {
        coveredPartyError = true;
      }
    }
    if (risk?.SecondPartyOnCoverageLetter) {
      if (coveredParties.length > 1 && risk?.SecondPartyOnCoverageLetter?.CoveredParties.length === 0) {
        coveredPartyError = true;
      }
    }
    if (risk?.IncreaseCoverageLimit) {
      if (coveredParties.length > 1 && risk?.IncreaseCoverageLimit?.CoveredParties.length === 0) {
        coveredPartyError = true;
      }
    }
    if (risk?.AdditionalAddress) {
      if (coveredParties.length > 1 && risk?.AdditionalAddress?.CoveredParties.length === 0) {
        coveredPartyError = true;
      }
    }
    if (risk?.InternationalParties) {
      if (coveredParties.length > 1 && risk?.InternationalParties?.CoveredParties.length === 0) {
        coveredPartyError = true;
      }
    }
    if (risk?.VerbiageUpdate) {
      if (coveredParties.length > 1 && risk?.VerbiageUpdate?.CoveredParties.length === 0) {
        coveredPartyError = true;
      }
    }
    if (risk?.MultipleProperties) {
      if (coveredParties.length > 1 && risk?.MultipleProperties?.CoveredParties.length === 0) {
        coveredPartyError = true;
      }
    }

    if (coveredPartyError) {
      isCoveredpartiesSelected(false);
      addGlobalMsg({
        message: 'Please select at least one Covered Party.',
        type: 'error',
      });
    }

    if (error == false) {
      updateUwrErrorStatus('Risks', true);
    } else {
      updateUwrErrorStatus('Risks', false);
    }
  };

  const handleNewRisk = (isHili: boolean) => {
    setShowMandatoryField(false);
    const risks = uwr?.Risks ?? ([] as UnderwritingRisk[]);

    risks.push({
      Name: isHili ? uwr?.TemplateMetaData.Risks[0].Name : '',
      Summary: '',
      IsExpanded: true,
      Id: randomId(),
    } as UnderwritingRisk);

    updateUnderwriting('Risks', risks);
    validateErrors();
  };

  const updateRiskValue = (index: number, field: string, value: any) => {
    const risks = uwr?.Risks ?? ([] as UnderwritingRisk[]);

    if (field === 'AdjustClaimsPeriod' && value === false) {
      delete risks[index].Period;
    }

    risks[index] = { ...risks[index], [field]: value };
    if (field === 'Name') {
      const riskMetadata = uwr?.TemplateMetaData.Risks.filter((risk) => {
        return risk.Name === value;
      })[0] ?? { Definition: '' };
      risks[index].Definition = riskMetadata.Definition;
      risks[index].Id = randomId();
      risks[index].Summary = '';
    }
    updateUnderwriting('Risks', risks);
    validateErrors();
  };

  useEffect(() => {
    const risk = uwr?.Risks?.find((risk) => risk.Name === 'CPL Modification');
    if (risk?.VerbiageUpdate) {
      setSummaryRiskRequired(true);
    } else {
      setSummaryRiskRequired(false);
    }
  }, [uwr?.Risks]);

  const deleteRisk = () => {
    const risks = uwr?.Risks ?? ([] as UnderwritingRisk[]);
    const deleteRiskName = risks[deleteRiskIndex].Name;
    risks.splice(deleteRiskIndex, 1);
    updateUnderwriting('Risks', risks);
    setIsDeleteRisk(false);
    const duplicateRisks = risks.filter((x) => x.Name == deleteRiskName);
    const duplicates = duplicateElements(risks);

    if (duplicateRisks.length > 1) {
      setDuplicateRiskName(duplicateRisks[1]?.Name);
      setDuplicateRiskId(duplicateRisks[0].Id);
      updateUwrErrorStatus('Risks', false);
      isDuplicateRisk(true);
    } else if (duplicates?.length > 0 ? true : false) {
      setDuplicateRiskName(duplicates[1]?.Name);
      setDuplicateRiskId(duplicates[0].Id);
      updateUwrErrorStatus('Risks', false);
      isDuplicateRisk(true);
    } else {
      setDuplicateRiskName('');
      setDuplicateRiskId('');
      updateUwrErrorStatus('Risks', true);
      isDuplicateRisk(false);
    }
    validateErrors();
    // if (deleteRiskName === duplicateRiskName) {
    //   setDuplicateRiskName('');
    // }
  };

  const cancelDeleteRisk = () => {
    setIsDeleteRisk(false);
    validateErrors();
  };

  const handleRiskExpandIndividual = (isExpand: boolean, id: string | number) => {
    const risks = uwr?.Risks ?? ([] as UnderwritingRisk[]);

    const updatedRisks = risks.map((risk) => {
      if (risk.Name === id) {
        return { ...risk, IsExpanded: isExpand };
      }
      return risk;
    });
    updateUnderwriting('Risks', updatedRisks);
  };

  const handleRiskExpand = (isExpand: boolean) => {
    const risks = uwr?.Risks ?? ([] as UnderwritingRisk[]);

    const updatedRisks = risks.map((risk) => {
      risk.IsExpanded = isExpand;
      return risk;
    });
    updateUnderwriting('Risks', updatedRisks);
    validateErrors();
  };

  function duplicateElements(array: UnderwritingRisk[]) {
    const map = new Map();
    array.forEach((a) => map.set(a.Name, (map.get(a.Name) || 0) + 1));
    return array.filter((a) => map.get(a.Name) > 1);
  }

  useEffect(() => {
    getLetterType().then();

    if (myClosingAttorneyStates.includes(fileData?.propertyState ?? '')) {
      getMyClosingAttorneys().then();
    }

    if (agentClosingAttorneyStates.includes(fileData?.propertyState ?? '')) {
      getAgentClosingAttorneys().then();
    }

    if (approvedAttorneyStates.includes(fileData?.propertyState ?? '')) {
      getApprovedAttorneys().then();
    }

    getSecondPartyListForTitle().then();
    getSecondPartyListForEscrow().then();
  }, []);

  const myClosingAttorney = async (): Promise<ClosingAttorney[]> => {
    const token = await getAccessToken();
    return myClosingAttorneys(userFirm?.firmId, token);
  };

  const {
    execute: getMyClosingAttorneys,
    value: myClosingAttorneyValue,
    status: myClosingAttorneyStatus,
    errors: myClosingAttorneyError,
  } = useAsync<ClosingAttorney[]>(myClosingAttorney, false);

  const secondPartyListForTitle = async (): Promise<SecondPartyDto[]> => {
    const token = await getAccessToken();
    return getSecondPartyDetails('title', token);
  };

  const secondPartyListForEscrow = async (): Promise<SecondPartyDto[]> => {
    const token = await getAccessToken();
    return getSecondPartyDetails('escrow', token);
  };

  const {
    execute: getSecondPartyListForTitle,
    value: secondPartyValueForTitle,
    status: secondPartyStatusForTitle,
    errors: secondPartyErrorForTitle,
  } = useAsync<SecondPartyDto[]>(secondPartyListForTitle, false);

  const {
    execute: getSecondPartyListForEscrow,
    value: secondPartyValueForEscrow,
    status: secondPartyStatusForEscrow,
    errors: secondPartyErrorForEscrow,
  } = useAsync<SecondPartyDto[]>(secondPartyListForEscrow, false);

  const agentClosingAttorney = async (): Promise<ClosingAttorney[]> => {
    const token = await getAccessToken();
    return agentClosingAttorneys(userFirm?.firmId, token);
  };

  const {
    execute: getAgentClosingAttorneys,
    value: agentClosingAttorneyValue,
    status: agentClosingAttorneyStatus,
    errors: agentClosingAttorneyError,
  } = useAsync<ClosingAttorney[]>(agentClosingAttorney, false);

  const approvedAttorney = async (): Promise<ApprovedAttorney[]> => {
    const token = await getAccessToken();
    return approvedAttorneys(fileData?.propertyState, token);
  };

  const {
    execute: getApprovedAttorneys,
    value: approvedAttorneyValue,
    status: approvedAttorneyStatus,
    errors: approvedAttorneyError,
  } = useAsync<ApprovedAttorney[]>(approvedAttorney, false);

  const letterType = async (): Promise<LetterType | undefined> => {
    const token = await getAccessToken();
    return getLetterTypes(fileData, token);
  };

  const {
    execute: getLetterType,
    value: letterTypesValue,
    status: letterTypesStatus,
    errors: letterTypesError,
  } = useAsync<LetterType | undefined>(letterType, false);

  useEffect(() => {
    if (letterTypesValue) {
      const letterTypeOptions = letterTypesValue?.CplTypes.map((letterType: CplType) => {
        return { name: letterType.LetterTypeName, value: letterType.LetterTypeId };
      });
      setLetterTypeOptions(letterTypeOptions);

      if (uwr?.RequestInformation?.RequestId !== 0 && uwr?.Risks.some((risk) => risk.Name === 'CPL Modification')) {
        const risk = uwr?.Risks.find((risk) => risk.Name === 'CPL Modification');

        letterTypesValue?.CplTypes?.map((cplLetterType) => {
          if (cplLetterType.LetterTypeId.toString() === risk?.LetterTypeId.toString()) {
            setAdditionalPartiesFields(cplLetterType.AdditionalParties);
            setPartiesSection(cplLetterType.Parties);
            setLetterTypeValue(cplLetterType.LetterTypeId.toString());

            //Assigning Covered Parties
            const coveredPartyOptions: string[] = [];
            if (cplLetterType.CoveredParties.length > 0) {
              cplLetterType.CoveredParties.map((coveredParty) => {
                if (coveredParty.DisplayName !== 'None') {
                  //const coveredPartys = { CoveredPartyName: coveredParty.DisplayName, Checked: false, Coverageamount: 0 };
                  coveredPartyOptions.push(coveredParty.DisplayName);
                }
              });
              setCoveredParties(coveredPartyOptions);
            }
            setisRemoveArbitrationParagraph(cplLetterType.RemoveArbitrationParagraph);
            setisAdjustClaimsPeriod(cplLetterType.AdjustClaimsPeriod);
          }
        });
      } else {
        setAdditionalPartiesFields(letterTypesValue?.CplTypes[0].AdditionalParties);
        setLetterTypeValue(letterTypesValue?.CplTypes[0].LetterTypeId.toString());
        setPartiesSection(letterTypesValue.CplTypes[0].Parties);

        //Assigning Covered Parties
        const coveredPartyOptions: string[] = [];
        if (letterTypesValue?.CplTypes[0].CoveredParties.length > 0) {
          letterTypesValue?.CplTypes[0].CoveredParties.map((coveredParty) => {
            if (coveredParty.DisplayName !== 'None') {
              coveredPartyOptions.push(coveredParty.DisplayName);
            }
          });
          setCoveredParties(coveredPartyOptions);
        }
        setisRemoveArbitrationParagraph(letterTypesValue?.CplTypes[0].RemoveArbitrationParagraph);
        setisAdjustClaimsPeriod(letterTypesValue?.CplTypes[0].AdjustClaimsPeriod);
      }
    }
  }, [letterTypesValue]);

  const initialMyClosingAttorneyOptions = letterTypesValue?.MyClosingAttorneys?.map((attorney) => {
    return {
      name: `${attorney.FullName ?? ''}${
        attorney.Address.Address1
          ? `, ${attorney.Address?.Address1 ?? ''}, ${attorney.Address?.City ?? ''}, ${
              attorney.Address?.State ?? ''
            }, ${attorney.Address?.PostalCode ?? ''}`
          : ''
      }`,
      value: attorney.ClosingAttorneyId,
    };
  });

  const fullListSecondParties =
    secondPartyFilter?.value === 'Title' ? secondPartyValueForTitle : secondPartyValueForEscrow;

  const secondPartyList = (list: SecondPartyDto[]) => {
    return list?.map((party) => {
      return {
        name: `${party.Name ?? ''}${
          party.Address1
            ? `, ${party.Address1 ?? ''}, ${party.City ?? ''}, ${party.State ?? ''}, ${party.Zip ?? ''}`
            : ''
        }`,
        value: party.OfficeId,
      };
    });
  };

  const fullMyClosingAttorneyOptions = myClosingAttorneyValue?.map((attorney) => {
    const { FirstName, MiddleName, LastName, Address } = attorney;
    if (MiddleName === undefined) {
      return {
        name: `${FirstName ?? ''} ${LastName ?? ''}${
          Address.Address1 ? `, ${Address?.Address1}, ${Address?.City}, ${Address?.State}, ${Address?.PostalCode}` : ''
        }`,
        value: attorney.ClosingAttorneyId,
      };
    } else {
      return {
        name: `${FirstName ?? ''} ${MiddleName ?? ''} ${LastName ?? ''}${
          Address.Address1 ? `, ${Address?.Address1}, ${Address?.City}, ${Address?.State}, ${Address?.PostalCode}` : ''
        }`,
        value: attorney.ClosingAttorneyId,
      };
    }
  });

  const combinedMyClosingAttorneyOptions = [
    ...(initialMyClosingAttorneyOptions ?? []),
    ...(fullMyClosingAttorneyOptions ?? []),
  ];

  const uniqueMyClosingAttorneyOptions = combinedMyClosingAttorneyOptions.filter(
    (option, index, self) => index === self.findIndex((t) => t.value === option.value),
  );

  const initialAgentClosingAttorneyOptions = letterTypesValue?.AgentClosingAttorneys?.map((attorney) => {
    if (attorney.MiddleName !== undefined) {
      return {
        name: `${attorney.FirstName ?? ''} ${attorney.MiddleName ?? ''} ${attorney.LastName ?? ''}${
          attorney.Address?.Address1
            ? `, ${attorney.Address?.Address1 ?? ''}, ${attorney.Address?.City ?? ''}, ${
                attorney.Address?.State ?? ''
              }, ${attorney.Address?.PostalCode ?? ''}`
            : ''
        }`,
        value: attorney.ClosingAttorneyId,
      };
    } else {
      return {
        name: `${attorney.FirstName ?? ''} ${attorney.LastName ?? ''}${
          attorney.Address?.Address1
            ? `, ${attorney.Address?.Address1 ?? ''}, ${attorney.Address?.City ?? ''}, ${
                attorney.Address?.State ?? ''
              }, ${attorney.Address?.PostalCode ?? ''}`
            : ''
        }`,
        value: attorney.ClosingAttorneyId,
      };
    }
  });

  const fullListAgentClosingAttorneys = agentClosingAttorneyValue?.map((attorney) => {
    if (attorney.MiddleName !== undefined) {
      return {
        name: `${attorney.FirstName ?? ''} ${attorney.MiddleName ?? ''} ${attorney.LastName ?? ''}${
          attorney.Address?.Address1
            ? `, ${attorney.Address?.Address1 ?? ''}, ${attorney.Address?.City ?? ''}, ${
                attorney.Address?.State ?? ''
              }, ${attorney.Address?.PostalCode ?? ''}`
            : ''
        }`,
        value: attorney.ClosingAttorneyId,
      };
    } else {
      return {
        name: `${attorney.FirstName ?? ''} ${attorney.LastName ?? ''}${
          attorney.Address?.Address1
            ? `, ${attorney.Address?.Address1 ?? ''}, ${attorney.Address?.City ?? ''}, ${
                attorney.Address?.State ?? ''
              }, ${attorney.Address?.PostalCode ?? ''}`
            : ''
        }`,
        value: attorney.ClosingAttorneyId,
      };
    }
  });

  const combinedAgentClosingAttorneyOptions = [
    ...(initialAgentClosingAttorneyOptions ?? []),
    ...(fullListAgentClosingAttorneys ?? []),
  ];

  const uniqueAgentClosingAttorneyOptions = combinedAgentClosingAttorneyOptions.filter(
    (option, index, self) => index === self.findIndex((t) => t.value === option.value),
  );

  const initialApprovedAttorneyOptions = letterTypesValue?.ApprovedAttorneys?.map((attorney) => {
    return {
      name: `${attorney.DbaName ?? ''}${
        attorney.Address1 ? `, ${attorney.Address1}, ${attorney.City}, ${attorney.State}, ${attorney.Zip}` : ''
      }`,
      value: attorney.ApprovedAttorneyId,
    };
  });

  const fullListApprovedAttorneyOptions = approvedAttorneyValue?.map((attorney) => {
    return {
      name: `${attorney.DbaName ?? ''}${
        attorney.Address1 ? `, ${attorney.Address1}, ${attorney.City}, ${attorney.State}, ${attorney.Zip}` : ''
      }`,
      value: attorney.ApprovedAttorneyId,
    };
  });

  const combinedApprovedAttorneyOptions = [
    ...(initialApprovedAttorneyOptions ?? []),
    ...(fullListApprovedAttorneyOptions ?? []),
  ];

  const uniqueApprovedAttorneyOptions = combinedApprovedAttorneyOptions.filter(
    (option, index, self) => index === self.findIndex((t) => t.value === option.value),
  );

  const handleLetterTypeChange = (letterType: string, index: number) => {
    const risks = uwr?.Risks ?? ([] as UnderwritingRisk[]);

    letterTypesValue?.CplTypes?.map((cplLetterType) => {
      if (cplLetterType.LetterTypeId.toString() === letterType.toString()) {
        setAdditionalPartiesFields(cplLetterType.AdditionalParties);
        if (cplLetterType.AdditionalParties.approvedAttorneys?.default) {
          updateRiskValue(index, 'ApprovedAttorneyId', cplLetterType.AdditionalParties.approvedAttorneys?.default);
        } else if (cplLetterType.AdditionalParties.approvedAttorneys?.display == false) {
          updateRiskValue(index, 'ApprovedAttorneyId', undefined);
        }

        //Assigning Covered Parties
        const coveredPartyOptions: string[] = [];
        if (cplLetterType.CoveredParties.length > 0) {
          cplLetterType.CoveredParties.map((coveredParty) => {
            if (coveredParty.DisplayName !== 'None') {
              //const coveredPartys = { CoveredPartyName: coveredParty.DisplayName, Checked: false, Coverageamount: 0 };
              coveredPartyOptions.push(coveredParty.DisplayName);
            }
          });
          setCoveredParties(coveredPartyOptions);
        }

        setisRemoveArbitrationParagraph(cplLetterType?.RemoveArbitrationParagraph);
        setisAdjustClaimsPeriod(cplLetterType?.AdjustClaimsPeriod);

        if (cplLetterType.RemoveArbitrationParagraph) {
          updateRiskValue(index, 'RemoveArbitrationParagraph', false);
        }
        if (cplLetterType.AdjustClaimsPeriod) {
          updateRiskValue(index, 'AdjustClaimsPeriod', false);
        }
        delete risks[index].Period;

        delete risks[index].AgentNameAddressModification;
        delete risks[index].AdditionalAddress;
        delete risks[index].VerbiageUpdate;
        delete risks[index].InternationalParties;
        delete risks[index].IncreaseCoverageLimit;
        delete risks[index].SecondPartyOnCoverageLetter;
        delete risks[index].MultipleProperties;

        const risk = risks.some((risk) => risk.Name === 'CPL Modification');
        if (risk) {
          if (risks[index].AgentNameAddressModification) {
            delete risks[index].AgentNameAddressModification;
            updateUnderwriting('Risks', risks);
          }
          if (risks[index].SecondPartyOnCoverageLetter) {
            delete risks[index].SecondPartyOnCoverageLetter;
            updateUnderwriting('Risks', risks);
          }
          if (risks[index].IncreaseCoverageLimit) {
            delete risks[index].IncreaseCoverageLimit;
            updateUnderwriting('Risks', risks);
          }
          if (risks[index].AdditionalAddress) {
            delete risks[index].AdditionalAddress;
            updateUnderwriting('Risks', risks);
          }
          if (risks[index].VerbiageUpdate) {
            delete risks[index].VerbiageUpdate;
            updateUnderwriting('Risks', risks);
          }
          if (risks[index].InternationalParties) {
            delete risks[index].InternationalParties;
            updateUnderwriting('Risks', risks);
          }
          if (risks[index].MultipleProperties) {
            delete risks[index].MultipleProperties;
            updateUnderwriting('Risks', risks);
          }
        }

        setPartiesSection(cplLetterType.Parties);
      }
    });
  };

  const updateCplModRiskOnSelect = (index: number) => {
    updateRiskValue(index, 'LetterTypeId', letterTypeOptions[0]?.value);
    if (fileData?.lenders && fileData?.lenders[0]?.name) {
      updateRiskValue(index, 'LenderName', fileData?.lenders[0].name);
    }

    if (letterTypesValue?.ApprovedAttorneys && letterTypesValue?.ApprovedAttorneys.length > 0) {
      updateRiskValue(
        index,
        'ApprovedAttorneyId',
        letterTypesValue?.CplTypes[0].AdditionalParties.approvedAttorneys?.default,
      );
    }
    if (letterTypesValue?.CplTypes[0].RemoveArbitrationParagraph) {
      updateRiskValue(index, 'RemoveArbitrationParagraph', false);
    }
    if (letterTypesValue?.CplTypes[0].AdjustClaimsPeriod) {
      updateRiskValue(index, 'AdjustClaimsPeriod', false);
    }
  };

  const updateCplModRisk = (index: number, checkBox: string, field: string, value: any) => {
    const risks = uwr?.Risks ?? ([] as UnderwritingRisk[]);
    let values: any;

    if (checkBox === 'AgentNameAddressModification') {
      risks[index].AgentNameAddressModification = {
        ...risks[index].AgentNameAddressModification,
        [field]: value,
      } as AgentNameAddressModification;
      values = risks[index].AgentNameAddressModification;
    }

    if (checkBox === 'SecondPartyOnCoverageLetter') {
      risks[index].SecondPartyOnCoverageLetter = {
        ...risks[index].SecondPartyOnCoverageLetter,
        [field]: value,
      } as SecondPartyOnCoverageLetter;
      values = risks[index].SecondPartyOnCoverageLetter;
    }

    if (checkBox === 'IncreaseCoverageLimit') {
      risks[index].IncreaseCoverageLimit = {
        ...risks[index].IncreaseCoverageLimit,
        [field]: value,
      } as IncreaseCoverageLimit;
      values = risks[index].IncreaseCoverageLimit;
    }

    if (checkBox === 'AdditionalAddress') {
      risks[index].AdditionalAddress = {
        ...risks[index].AdditionalAddress,
        [field]: value,
      } as AdditionalAddress;
      values = risks[index].AdditionalAddress;
    }

    if (checkBox === 'VerbiageUpdate') {
      risks[index].VerbiageUpdate = {
        ...risks[index].VerbiageUpdate,
        [field]: value,
      } as VerbiageUpdate;
      values = risks[index].VerbiageUpdate;
      setSummaryRiskRequired(true);
    }

    if (checkBox === 'InternationalParties') {
      risks[index].InternationalParties = {
        ...risks[index].InternationalParties,
        [field]: value,
      } as InternationalParties;
      values = risks[index].InternationalParties;
    }

    if (checkBox === 'MultipleProperties') {
      risks[index].MultipleProperties = {
        ...risks[index].MultipleProperties,
        [field]: value,
      } as MultipleProperties;
      values = risks[index].MultipleProperties;
    }

    updateRiskValue(index, checkBox, values);
  };

  const handleCplModCheckBox = (index: number, field: string, value: boolean) => {
    const risks = uwr?.Risks ?? ([] as UnderwritingRisk[]);

    if (field == 'AgentNameAddressModification') {
      if (value == true) {
        if (userFirm) {
          const officeDetails = userFirm?.offices?.find((office) => office.officeId == fileData?.officeId);

          const AgentNameAddressModification: any = {
            AgentName: letterTypesValue?.CplOffices.find((office) => office.OfficeId == fileData?.officeId)?.DbaName,
            Address1: officeDetails?.address.address1,
            Address2: officeDetails?.address.address2,
            City: officeDetails?.address.city,
            State: officeDetails?.address.state,
            Zip: officeDetails?.address.postalCode,
          };
          if (coveredParties.length > 1) {
            AgentNameAddressModification.CoveredParties = [];
          }
          updateRiskValue(index, 'AgentNameAddressModification', AgentNameAddressModification);
        }
      } else {
        delete risks[index].AgentNameAddressModification;
        updateUnderwriting('Risks', risks);
      }
    }

    if (field == 'SecondPartyOnCoverageLetter') {
      if (value) {
        const SecondParty: any = {
          Role: secondPartyFilter.value,
          SecondPartyId: undefined,
        };
        if (coveredParties.length > 1) {
          SecondParty.CoveredParties = [];
        }
        updateRiskValue(index, 'SecondPartyOnCoverageLetter', SecondParty);
      } else {
        delete risks[index].SecondPartyOnCoverageLetter;
        updateUnderwriting('Risks', risks);
      }
    }

    if (field == 'IncreaseCoverageLimit') {
      if (value == true) {
        const IncreaseCoverageLimit: any = {};
        if (coveredParties.length <= 1) {
          IncreaseCoverageLimit.CoverageLimit = 0;
        }
        if (coveredParties.length > 1) {
          IncreaseCoverageLimit.CoveredParties = [];
        }
        updateRiskValue(index, 'IncreaseCoverageLimit', IncreaseCoverageLimit);
      } else {
        delete risks[index].IncreaseCoverageLimit;
        updateUnderwriting('Risks', risks);
      }
    }

    if (field == 'AdditionalAddress') {
      if (value) {
        const AdditionalAddress: any = {
          Address: '',
        };
        if (coveredParties.length > 1) {
          AdditionalAddress.CoveredParties = [];
        }
        updateRiskValue(index, 'AdditionalAddress', AdditionalAddress);
      } else {
        delete risks[index].AdditionalAddress;
        updateUnderwriting('Risks', risks);
      }
    }

    if (field == 'VerbiageUpdate') {
      if (value) {
        const Verbiage: any = {};
        if (coveredParties.length > 1) {
          Verbiage.CoveredParties = [];
        }
        setSummaryRiskRequired(true);
        updateRiskValue(index, 'VerbiageUpdate', Verbiage);
      } else {
        delete risks[index].VerbiageUpdate;
        setSummaryRiskRequired(false);
        updateUnderwriting('Risks', risks);
      }
    }

    if (field == 'MultipleProperties') {
      if (value) {
        const Multiple: any = {};
        if (coveredParties.length > 1) {
          Multiple.CoveredParties = [];
        }

        updateRiskValue(index, 'MultipleProperties', Multiple);
      } else {
        delete risks[index].MultipleProperties;
        updateUnderwriting('Risks', risks);
      }
    }

    if (field == 'InternationalParties') {
      if (value) {
        const InternationalParties: any = {};
        if (coveredParties.length > 1) {
          InternationalParties.CoveredParties = [];
        }
        updateRiskValue(index, 'InternationalParties', InternationalParties);
      } else {
        delete risks[index].InternationalParties;
        updateUnderwriting('Risks', risks);
      }
    }
  };

  const handleCoveredParties = (
    index: number,
    cplModCheckBox: string,
    coveredParty: string,
    value: any,
    currency?: string,
  ) => {
    const risks = uwr?.Risks ?? ([] as UnderwritingRisk[]);
    let coveredParties: string[] = [];
    let coveredPartiesAmount: CoveredPartiesAmount[] = [];

    const checkCoveredPartyResults = checkCoveredParty(cplModCheckBox, coveredParty);

    if (!checkCoveredPartyResults) {
      if (cplModCheckBox === 'AgentNameAddressModification') {
        const individualCoveredParties = risks[index].AgentNameAddressModification?.CoveredParties;
        if (value === true) {
          coveredParties = individualCoveredParties?.concat(coveredParty) as string[];
        } else {
          coveredParties = individualCoveredParties?.filter((party) => party !== coveredParty) as string[];
        }
      }

      if (cplModCheckBox === 'SecondPartyOnCoverageLetter') {
        const individualCoveredParties = risks[index].SecondPartyOnCoverageLetter?.CoveredParties;
        if (value === true) {
          coveredParties = individualCoveredParties?.concat(coveredParty) as string[];
        } else {
          coveredParties = individualCoveredParties?.filter((party) => party !== coveredParty) as string[];
        }
      }

      if (cplModCheckBox === 'AdditionalAddress') {
        const individualCoveredParties = risks[index].AdditionalAddress?.CoveredParties;
        if (value === true) {
          coveredParties = individualCoveredParties?.concat(coveredParty) as string[];
        } else {
          coveredParties = individualCoveredParties?.filter((party) => party !== coveredParty) as string[];
        }
      }

      if (cplModCheckBox === 'IncreaseCoverageLimit') {
        const individualCoveredParties = risks[index].IncreaseCoverageLimit?.CoveredParties;
        const individualCoveredPartiesAmount = individualCoveredParties?.filter(
          (party) => party.CoveredPartyName === coveredParty,
        );

        if (value === true) {
          if (!individualCoveredParties?.find((party) => party.CoveredPartyName === coveredParty)) {
            coveredPartiesAmount = individualCoveredParties?.concat({
              CoveredPartyName: coveredParty,
              CoverageAmount: '',
            }) as CoveredPartiesAmount[];
          }
          if (
            individualCoveredParties &&
            individualCoveredParties?.length > 0 &&
            individualCoveredParties.some((party) => party.CoveredPartyName === coveredParty)
          ) {
            coveredPartiesAmount = individualCoveredParties.map((party) => {
              if (party.CoveredPartyName === coveredParty) {
                party.CoverageAmount = currency as string;
              }
              return party;
            });
          }
        } else {
          coveredPartiesAmount = individualCoveredParties?.filter(
            (party) => party.CoveredPartyName !== coveredParty,
          ) as CoveredPartiesAmount[];
        }
      }

      if (cplModCheckBox === 'VerbiageUpdate') {
        const individualCoveredParties = risks[index].VerbiageUpdate?.CoveredParties;
        setSummaryRiskRequired(true);
        if (value === true) {
          coveredParties = individualCoveredParties?.concat(coveredParty) as string[];
        } else {
          coveredParties = individualCoveredParties?.filter((party) => party !== coveredParty) as string[];
        }
      }

      if (cplModCheckBox === 'InternationalParties') {
        const individualCoveredParties = risks[index].InternationalParties?.CoveredParties;
        if (value === true) {
          coveredParties = individualCoveredParties?.concat(coveredParty) as string[];
        } else {
          coveredParties = individualCoveredParties?.filter((party) => party !== coveredParty) as string[];
        }
      }

      if (cplModCheckBox === 'MultipleProperties') {
        const individualCoveredParties = risks[index].MultipleProperties?.CoveredParties;
        if (value === true) {
          coveredParties = individualCoveredParties?.concat(coveredParty) as string[];
        } else {
          coveredParties = individualCoveredParties?.filter((party) => party !== coveredParty) as string[];
        }
      }

      if (cplModCheckBox === 'IncreaseCoverageLimit') {
        updateCplModRisk(index, cplModCheckBox, 'CoveredParties', coveredPartiesAmount);
      } else {
        updateCplModRisk(index, cplModCheckBox, 'CoveredParties', coveredParties);
      }
    }
  };

  const handleRoleType = (index: number, cplModCheckBox: string, role: string) => {
    const risks = uwr?.Risks ?? ([] as UnderwritingRisk[]);
    let roleType: any;

    if (cplModCheckBox === 'SecondPartyOnCoverageLetter') {
      const selectedRoleType = risks[index].SecondPartyOnCoverageLetter?.Role;
      roleType = selectedRoleType == role ? selectedRoleType : role;
    }
    updateCplModRisk(index, cplModCheckBox, 'Role', roleType);
    updateCplModRisk(index, 'SecondPartyOnCoverageLetter', 'SecondPartyId', undefined);
  };

  const checkCoveredParty = (cplModCheckBox: string, coveredParty: string) => {
    let isInvalid = false;

    switch (coveredParty) {
      case 'Lender':
      case 'Lender / Borrower(s)':
        if (IsLenderInvalid().isLenderInvalid) {
          addGlobalMsg({
            message: IsLenderInvalid().lenderValidationMessage,
            type: 'error',
          });
          isInvalid = true;
        }
        break;
      case 'Borrower(s) / Buyer(s)':
      case 'Borrower(s)':
      case 'Buyer(s)':
        if (isBuyerInvalid().isBuyerInvalid) {
          addGlobalMsg({
            message: isBuyerInvalid().buyerValidationMessage,
            type: 'error',
          });
          isInvalid = true;
        }
        break;
      case 'Seller(s)':
        if (isSellerInvalid().isSellerInvalid) {
          addGlobalMsg({
            message: isSellerInvalid().sellerValidationMessage,
            type: 'error',
          });
          isInvalid = true;
        }
        break;

      default:
        break;
    }

    // if (isInvalid == false) {
    //   updateUwrErrorStatus('Risks', true);
    // } else {
    //   updateUwrErrorStatus('Risks', false);
    // }
    return isInvalid;
  };

  const IsLenderInvalid = () => {
    let isLenderInvalid = false;
    let lenderFaultCount = 0;
    let lenderValidationMessage = '';
    if (fileData?.lenders) {
      if (fileData?.lenders?.length == 0) {
        isLenderInvalid = true;
        lenderValidationMessage =
          'LENDER party is required to add this CPL Modification Risk.  Please add a LENDER to proceed.';
      }
    }
    if (!fileData?.lenders) {
      isLenderInvalid = true;
      lenderValidationMessage =
        'LENDER party is required to add this CPL Modification Risk.  Please add a LENDER to proceed.';
    } else {
      if (fileData?.lenders.length > 0) {
        lenderValidationMessage = 'Lender ';
        if (!fileData.lenders[0].address?.address1) {
          isLenderInvalid = true;
          lenderFaultCount++;
          lenderValidationMessage += 'Address1';
        }
        if (!fileData.lenders[0].address?.city) {
          isLenderInvalid = true;
          lenderFaultCount++;
          lenderValidationMessage += lenderFaultCount > 1 ? ', City' : 'City';
        }
        if (!fileData.lenders[0].address?.state) {
          isLenderInvalid = true;
          lenderFaultCount++;
          lenderValidationMessage += lenderFaultCount > 1 ? ', State' : 'State';
        }
        if (!fileData.lenders[0].address?.postalCode) {
          isLenderInvalid = true;
          lenderFaultCount++;
          lenderValidationMessage += lenderFaultCount > 1 ? ', Zip' : 'Zip';
        }
      }
      if (lenderFaultCount == 1) {
        lenderValidationMessage += ' is required to add this CPL Modification Risk.';
      }
      if (lenderFaultCount > 1) {
        lenderValidationMessage += ' are required to add this CPL Modification Risk.';
      }
    }

    return { isLenderInvalid, lenderValidationMessage };
  };

  const buyerMandatoryStates = () => {
    let mandatoryState = false;
    const officeDetails = userFirm?.offices?.find((office) => office.officeId == fileData?.officeId);
    const accountDetails = officeDetails?.accounts?.find((account) => account.accountId == fileData?.accountId);

    if (
      accountDetails?.stateCode == 'AR' ||
      accountDetails?.stateCode == 'AZ' ||
      accountDetails?.stateCode == 'FL' ||
      accountDetails?.stateCode == 'GA' ||
      accountDetails?.stateCode == 'HI' ||
      accountDetails?.stateCode == 'IL' ||
      accountDetails?.stateCode == 'IN' ||
      accountDetails?.stateCode == 'MO' ||
      accountDetails?.stateCode == 'NV' ||
      accountDetails?.stateCode == 'NM' ||
      accountDetails?.stateCode == 'SC' ||
      accountDetails?.stateCode == 'TX' ||
      accountDetails?.stateCode == 'UT' ||
      accountDetails?.stateCode == 'MI' ||
      officeDetails?.address.state == 'MO' ||
      (accountDetails?.stateCode == 'KY' && fileData?.underwriterCode == 'FATIC')
    ) {
      mandatoryState = true;
    }
    return mandatoryState;
  };

  const isBuyerInvalid = () => {
    let isBuyerInvalid = false;
    let buyerFaultCount = 0;
    let buyerValidationMessage = '';
    if (fileData?.buyers) {
      if (fileData?.buyers.length == 0) {
        isBuyerInvalid = true;
        buyerValidationMessage =
          'Buyer/Borrower party is required to add this CPL Modification Risk.  Please add a Buyer/Borrower to proceed.';
      }
    }
    if (!fileData?.buyers) {
      isBuyerInvalid = true;
      buyerValidationMessage =
        'Buyer/Borrower party is required to add this CPL Modification Risk.  Please add a Buyer/Borrower to proceed.';
    }

    if (buyerMandatoryStates() && fileData?.buyers) {
      if (fileData?.buyers.length > 0) {
        if (!fileData.buyers[0].address?.isForeignAddress) {
          buyerValidationMessage = 'Buyer/Borrower ';
          if (!fileData.buyers[0].address?.address1) {
            isBuyerInvalid = true;
            buyerFaultCount++;
            buyerValidationMessage += 'Address1';
          }
          if (!fileData.buyers[0].address?.city) {
            isBuyerInvalid = true;
            buyerFaultCount++;
            buyerValidationMessage += buyerFaultCount > 1 ? ', City' : 'City';
          }
          if (!fileData.buyers[0].address?.state) {
            isBuyerInvalid = true;
            buyerFaultCount++;
            buyerValidationMessage += buyerFaultCount > 1 ? ', State' : 'State';
          }
          if (!fileData.buyers[0].address?.postalCode) {
            isBuyerInvalid = true;
            buyerFaultCount++;
            buyerValidationMessage += buyerFaultCount > 1 ? ', Zip' : 'Zip';
          }
        }
        if (buyerFaultCount == 1) {
          buyerValidationMessage += ' is required to add this CPL Modification Risk.';
        }
        if (buyerFaultCount > 1) {
          buyerValidationMessage += ' are required to add this CPL Modification Risk.';
        }
      } else {
        buyerValidationMessage =
          'A foreign address has been entered for the Borrower(s) on this file. Please contact Underwriting to add or update the CPL Modification Risk.';
        isBuyerInvalid = true;
      }
    }

    return { isBuyerInvalid, buyerValidationMessage };
  };

  const sellerMandatoryStates = () => {
    let mandatoryState = false;
    const officeDetails = userFirm?.offices?.find((office) => office.officeId == fileData?.officeId);
    const accountDetails = officeDetails?.accounts?.find((account) => account.accountId == fileData?.accountId);

    if (
      accountDetails?.stateCode == 'AR' ||
      accountDetails?.stateCode == 'AZ' ||
      accountDetails?.stateCode == 'GA' ||
      accountDetails?.stateCode == 'IL' ||
      accountDetails?.stateCode == 'IN' ||
      accountDetails?.stateCode == 'MO' ||
      officeDetails?.address.state == 'MO' ||
      accountDetails?.stateCode == 'MI' ||
      accountDetails?.stateCode == 'TX'
    ) {
      mandatoryState = true;
    }
    return mandatoryState;
  };

  const isSellerInvalid = () => {
    let isSellerInvalid = false;
    let sellerFaultCount = 0;
    let sellerValidationMessage = '';
    if (fileData?.sellers) {
      if (fileData?.sellers.length == 0) {
        isSellerInvalid = true;
        sellerValidationMessage =
          'Seller party is required to add this CPL Modification Risk.  Please add a Seller to proceed.';
      }
    }
    if (!fileData?.sellers) {
      isSellerInvalid = true;
      sellerValidationMessage =
        'Seller party is required to add this CPL Modification Risk.  Please add a Seller to proceed.';
    }

    if (sellerMandatoryStates() && fileData?.sellers) {
      if (fileData?.sellers.length > 0) {
        if (!fileData.sellers[0].address?.isForeignAddress) {
          sellerValidationMessage = 'Seller ';
          if (!fileData.sellers[0].address?.address1) {
            isSellerInvalid = true;
            sellerFaultCount++;
            sellerValidationMessage += 'Address1';
          }
          if (!fileData.sellers[0].address?.city) {
            isSellerInvalid = true;
            sellerFaultCount++;
            sellerValidationMessage += sellerFaultCount > 1 ? ', City' : 'City';
          }
          if (!fileData.sellers[0].address?.state) {
            isSellerInvalid = true;
            sellerFaultCount++;
            sellerValidationMessage += sellerFaultCount > 1 ? ', State' : 'State';
          }
          if (!fileData.sellers[0].address?.postalCode) {
            isSellerInvalid = true;
            sellerFaultCount++;
            sellerValidationMessage += sellerFaultCount > 1 ? ', Zip' : 'Zip';
          }
        }
        if (sellerFaultCount == 1) {
          sellerValidationMessage += ' is required to add this CPL Modification Risk.';
        }
        if (sellerFaultCount > 1) {
          sellerValidationMessage += ' are required to add this CPL Modification Risk.';
        }
      } else {
        sellerValidationMessage =
          'A foreign address has been entered for the Seller(s) on this file. Please contact Underwriting to add or update the CPL Modification Risk.';
        isSellerInvalid = true;
      }
    }

    return { isSellerInvalid, sellerValidationMessage };
  };

  const validateCplModCheckBox = (index: number, checkBox: string, value: any) => {
    let errorMessage = '';
    if (buyerRequirements.name && !(fileData?.buyers && fileData?.buyers?.length > 0)) {
      errorMessage = 'Buyer/Borrower ';
    }
    if (lenderRequirements.name && !(fileData?.lenders && fileData?.lenders?.length > 0)) {
      errorMessage += 'Lender ';
    }

    if (errorMessage.length > 0) {
      errorMessage += ' parties required to add a CPL Modification Risk.  Please add a party to proceed.';
      addGlobalMsg({
        message: errorMessage,
        type: 'error',
      });
      // updateUwrErrorStatus('Risks', false);
    }
    if (errorMessage.length == 0) {
      if (checkBox === 'AgentNameAddressModification') {
        handleCplModCheckBox(index, 'AgentNameAddressModification', value);
      }
      if (checkBox === 'SecondPartyOnCoverageLetter') {
        handleCplModCheckBox(index, 'SecondPartyOnCoverageLetter', value);
      }
      if (checkBox === 'IncreaseCoverageLimit') {
        handleCplModCheckBox(index, 'IncreaseCoverageLimit', value);
      }
      if (checkBox === 'AdditionalAddress') {
        handleCplModCheckBox(index, 'AdditionalAddress', value);
      }

      if (checkBox === 'VerbiageUpdate') {
        handleCplModCheckBox(index, 'VerbiageUpdate', value);
      }

      if (checkBox === 'MultipleProperties') {
        handleCplModCheckBox(index, 'MultipleProperties', value);
      }

      if (checkBox === 'InternationalParties') {
        handleCplModCheckBox(index, 'InternationalParties', value);
      }
    }
  };

  const renderRisks = () => {
    return uwr?.Risks?.map((risk, i) => (
      <Grid container spacing={3} key={'UnderwritngRiskContriner' + [i + 1]}>
        <Grid item sm={12}>
          <AccordionContent
            accordianQAAttribute={'UnderwritingRiskAccordionContent' + [i + 1]}
            icon={'list'}
            type={'underwriting'}
            title={(risk?.Name ?? '') === '' ? 'Select a Risk' : risk.Name}
            hideSubtitle={true}
            key={`underwritingRisk_${risk.Id}`}
            id={risk.Name}
            //closeAll={riskActionsState.closeAll}
            expanded={risk.IsExpanded}
            onExpandChange={handleRiskExpandIndividual}
            status={''}
            className={classes.mb2}
            hideStatus={true}
            onDelete={
              i > 0
                ? () => {
                    setIsDeleteRisk(true);
                    setDeleteRiskIndex(i);
                    updateUwrErrorStatus('Risks', true);
                  }
                : undefined
            }
            isDeleteDisabled={isUwrDisabled || isDisabled}
            removeAsterisk
          >
            <Grid container spacing={0}>
              <Grid container spacing={3}>
                <Grid item sm={6}>
                  <AgentNetDropdownSelector
                    label={'Risk'}
                    name={'Name'}
                    qaAttribute={'UnderwritingRisk'}
                    options={riskOptions}
                    value={risk.Name}
                    menuOption={(val: string, name: any) => {
                      updateRiskValue(i, 'Name', val);
                      const sameRisks = uwr.Risks.filter((e) => e.Name === val);
                      const duplicates = duplicateElements(uwr.Risks);
                      if (sameRisks.length > 1) {
                        setDuplicateRiskName(sameRisks[1]?.Name);
                        setDuplicateRiskId(sameRisks[0].Id);
                        updateUwrErrorStatus('Risks', false);
                        isDuplicateRisk(true);
                      } else if (duplicates?.length > 0 ? true : false) {
                        setDuplicateRiskName(duplicates[1]?.Name);
                        setDuplicateRiskId(duplicates[0].Id);
                        updateUwrErrorStatus('Risks', false);
                        isDuplicateRisk(true);
                      } else {
                        setDuplicateRiskName('');
                        setDuplicateRiskId('');
                        updateUwrErrorStatus('Risks', true);
                        isDuplicateRisk(false);
                      }
                      if (val === 'CPL Modification') {
                        updateCplModRiskOnSelect(i);
                      }
                      if (val != 'CPL Modification') {
                        handleRiskNameChange(i, val);
                      }
                    }}
                    disabled={isUwrDisabled || isDisabled}
                    required
                    dropdowntype="outlined"
                    showValidation={showMandatoryField}
                    errs={validationErrors[i]}
                  />
                </Grid>
              </Grid>
              <Grid>
                {duplicateRiskName && risk.Id !== duplicateRiskId && risk.Name === duplicateRiskName && (
                  <>
                    <Notification inline severity="warning" className={classes.warningMsg}>
                      {duplicateRiskName} is already selected for the Underwriting Request. Please select a different
                      Risk.
                    </Notification>
                  </>
                )}
              </Grid>
              {risk?.Name && risk?.Name !== 'CPL Modification' && (
                <Grid container spacing={3}>
                  <Grid item sm={12}>
                    <Typography variant="body1" className={classes.riskDisclaimer}>
                      {' '}
                      {risk.Definition}
                    </Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <AgentNetTextInput
                      multiline
                      fullWidth
                      variant="outlined"
                      label="Summary of Risk"
                      name="Description"
                      data-testid={'UnderwritingDescription'}
                      value={risk.Summary ?? ''}
                      disabled={isUwrDisabled || isDisabled}
                      onChange={(event: any) => {
                        updateRiskValue(i, 'Summary', event.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              {risk?.Name && risk?.Name == 'CPL Modification' && (
                <Grid container spacing={3} style={{ paddingTop: '24px' }}>
                  <Grid item sm={6} className={classes.cplModLenderName}>
                    <AgentNetDropdownSelector
                      name="LetterType"
                      label="Letter Type"
                      options={letterTypeOptions ?? []}
                      value={risk.LetterTypeId ?? letterTypeValue}
                      disabled={isUwrDisabled || isDisabled}
                      menuOption={(value: string) => {
                        updateRiskValue(i, 'LetterTypeId', value);
                        setLetterTypeValue(value);
                        handleLetterTypeChange(value, i);
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} className={classes.cplModLenderName}>
                    <AgentNetTextInput
                      fullWidth
                      name="LenderName"
                      label="Lender Name"
                      value={risk.LenderName ?? ''}
                      disabled={isUwrDisabled || isDisabled}
                      onChange={(event: any) => {
                        updateRiskValue(i, 'LenderName', event.target.value);
                      }}
                    />
                  </Grid>
                  {additionalPartiesFields?.approvedAttorneys?.display ? (
                    <Grid item sm={6}>
                      <SuggestDropdown
                        name="ApprovedAttorney"
                        label="Approved Attorney"
                        value={risk?.ApprovedAttorneyId ?? ''}
                        recentOptions={initialApprovedAttorneyOptions ?? []}
                        fullOptions={uniqueApprovedAttorneyOptions}
                        required={additionalPartiesFields?.approvedAttorneys?.require}
                        onChange={(ele: any) => {
                          updateRiskValue(i, 'ApprovedAttorneyId', ele.value);
                        }}
                        disabled={!additionalPartiesFields?.approvedAttorneys?.editable || isUwrDisabled || isDisabled}
                        showValidation={showMandatoryField}
                        errs={validationErrors[i]}
                      />
                    </Grid>
                  ) : null}

                  {additionalPartiesFields?.myClosingAttorneys?.display ? (
                    <Grid item sm={6}>
                      <SuggestDropdown
                        name="MyClosingAttorney"
                        label="My Closing Attorney"
                        value={risk?.MyClosingAttorneyId ?? ''}
                        recentOptions={initialMyClosingAttorneyOptions ?? []}
                        fullOptions={uniqueMyClosingAttorneyOptions}
                        required={additionalPartiesFields?.myClosingAttorneys?.require}
                        onChange={(ele) => {
                          updateRiskValue(i, 'MyClosingAttorneyId', ele?.value);
                        }}
                        disabled={isUwrDisabled || isDisabled}
                        showValidation={showMandatoryField}
                        errs={validationErrors[i]}
                      />
                    </Grid>
                  ) : null}
                  {additionalPartiesFields?.agentClosingAttorneys?.display ? (
                    <Grid item sm={6}>
                      <SuggestDropdown
                        name="AgentClosingAttorney"
                        label="Agent Closing Attorney"
                        value={risk?.AgentClosingAttorneyId ?? ''}
                        recentOptions={initialAgentClosingAttorneyOptions ?? []}
                        fullOptions={uniqueAgentClosingAttorneyOptions}
                        required={additionalPartiesFields?.agentClosingAttorneys?.require}
                        onChange={(ele) => {
                          updateRiskValue(i, 'AgentClosingAttorneyId', ele?.value);
                        }}
                        disabled={isUwrDisabled || isDisabled}
                        showValidation={showMandatoryField}
                        errs={validationErrors[i]}
                      />
                    </Grid>
                  ) : null}

                  <Grid item sm={12}>
                    <AgentNetTextInput
                      multiline
                      fullWidth
                      variant="outlined"
                      label="Summary of Risk"
                      name="Summary"
                      data-testid={'UnderwritingDescription'}
                      required={summaryRiskRequired}
                      value={risk.Summary ?? ''}
                      disabled={isUwrDisabled || isDisabled}
                      onChange={(event: any) => {
                        updateRiskValue(i, 'Summary', event.target.value);
                      }}
                      showValidation={showMandatoryField}
                      errs={validationErrors[i]}
                    />
                  </Grid>

                  {isRemoveArbitrationParagraph ? (
                    <Grid item sm={12} style={{ paddingLeft: '24px' }}>
                      <AgentNetCheckBox
                        label="Remove Arbitration Paragraph"
                        name="RemoveArbitrationParagraph"
                        value={risk.RemoveArbitrationParagraph}
                        checkHandler={(value: any) => {
                          updateRiskValue(i, 'RemoveArbitrationParagraph', value);
                        }}
                        disabled={isUwrDisabled || isDisabled}
                      />
                    </Grid>
                  ) : null}
                  {isAdjustClaimsPeriod ? (
                    <Grid item sm={12} style={{ paddingLeft: '14px' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={risk.AdjustClaimsPeriod}
                            disabled={isUwrDisabled || isDisabled}
                            name="AdjustClaimsPeriod"
                            onChange={(e) => {
                              updateRiskValue(i, 'AdjustClaimsPeriod', e.target.checked);
                              if (e.target.checked) {
                                updateRiskValue(i, 'Period', 'twoyears');
                              }
                            }}
                          />
                        }
                        label="Adjust Claims Period"
                        value={risk.AdjustClaimsPeriod}
                      />
                      <RadioGroup
                        options={radioOptions}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          updateRiskValue(i, 'Period', e.target.value);
                        }}
                        selected={selectedRadioOption}
                        disabled={isUwrDisabled || isDisabled || !risk.AdjustClaimsPeriod}
                      />
                    </Grid>
                  ) : null}
                  <Grid item sm={12}>
                    <Typography variant="h2">Select Modification Type</Typography>
                  </Grid>
                  <Grid item sm={12} style={{ paddingLeft: '24px' }}>
                    <AgentNetCheckBox
                      label="Increase Coverage Limit"
                      name="IncreaseCoverageLimit"
                      value={risk.IncreaseCoverageLimit ? true : false}
                      checkHandler={(value: any) => {
                        validateCplModCheckBox(i, 'IncreaseCoverageLimit', value);
                      }}
                      disabled={isUwrDisabled || isDisabled}
                    />
                  </Grid>
                  {risk.IncreaseCoverageLimit && (
                    <Grid container spacing={3} style={{ paddingLeft: '24px' }}>
                      <Grid item sm={12} style={{ paddingBottom: coveredParties?.length > 1 ? 0 : '' }}>
                        <Typography variant="body2">
                          If an increase in the funding amount limit in the standard letter needs to be increased.
                        </Typography>
                      </Grid>
                      {/* coverage limit */}
                      {coveredParties.length <= 1 && (
                        <Grid item sm={3} style={{ paddingLeft: '20px' }}>
                          <CurrencyField
                            required
                            variant="outlined"
                            fullWidth
                            label="Coverage Limit"
                            id="coverageLimit"
                            name="coverageLimit"
                            max={999999999999.99}
                            allowNegative={false}
                            value={risk.IncreaseCoverageLimit.CoverageLimit}
                            onChange={(e) => {
                              handelecovergelimit(i, 'IncreaseCoverageLimit', 'CoverageLimit', e.target.value);
                            }}
                            onBlur={(e) => {
                              handleBlur(e.target.value);
                            }}
                            error={touched && !!coverageLimitError} // Display error if submitAttempted is true
                            helperText={
                              <FormHelperText style={{ marginLeft: '-15px', marginRight: '-2px', color: '#B3150A' }}>
                                {touched && coverageLimitError}
                              </FormHelperText>
                            }
                            disabled={isUwrDisabled || isDisabled}
                          />
                        </Grid>
                      )}
                      {/* -------------------------------------------------- */}
                      {/* Covered Parties */}
                      {coveredParties.length > 1 && (
                        <Grid item sm={12}>
                          <UnderwritingIncreaseCoveragelimitParties
                            index={i}
                            coveredParties={coveredParties}
                            cplModCoveredParties={risk.IncreaseCoverageLimit.CoveredParties}
                            uwr={uwr}
                            cplModCheckBox="IncreaseCoverageLimit"
                            handleCoveredParties={handleCoveredParties}
                            validateCoverageAmount={validateCoverageAmount}
                            isUwrDisabled={isUwrDisabled}
                            isDisabled={isDisabled}
                          />
                        </Grid>
                      )}
                      <Grid item sm={12} className={classes.linePadding}>
                        <Divider />
                      </Grid>
                    </Grid>
                  )}

                  <Grid item sm={12} style={{ paddingLeft: '24px' }}>
                    <AgentNetCheckBox
                      label="2nd Party on Coverage Letter"
                      name="SecondPartyOnCoverageLetter"
                      value={risk.SecondPartyOnCoverageLetter ? true : false}
                      checkHandler={(value: any) => {
                        validateCplModCheckBox(i, 'SecondPartyOnCoverageLetter', value);
                      }}
                      disabled={isUwrDisabled || isDisabled}
                    />
                    {risk.SecondPartyOnCoverageLetter && (
                      <Grid item sm={12}>
                        <Grid item sm={12} style={{ paddingTop: '12px', paddingBottom: '12px' }}>
                          <Typography variant="body2">{underwritingText.UWR_SECONDPARTY}</Typography>
                        </Grid>
                        {coveredParties?.length > 1 && (
                          <Grid>
                            <UnderwritingCoveredParties
                              index={i}
                              coveredParties={coveredParties}
                              cplModCoveredParties={risk.SecondPartyOnCoverageLetter.CoveredParties}
                              cplModCheckBox="SecondPartyOnCoverageLetter"
                              handleCoveredParties={handleCoveredParties}
                              isUwrDisabled={isUwrDisabled}
                              isDisabled={isDisabled}
                            />
                            <br />
                          </Grid>
                        )}
                        <AgentNetDivider
                          typoVariant="h2"
                          subtitle="Role"
                          subtitleMuted
                          disablePadding
                          customClass={classes.mb2}
                        />
                        <Grid item sm={12}>
                          <RadioGroup
                            selected={secondPartyFilter}
                            options={roleTypeOptions}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setSecondPartyFilter({
                                ...secondPartyFilter,
                                value: e.target.value,
                                label: e.target.value,
                              });
                              handleRoleType(i, 'SecondPartyOnCoverageLetter', e.target.value);
                            }}
                            disabled={isUwrDisabled || isDisabled}
                          />
                        </Grid>
                        <br />
                        <Grid item sm={6} style={{ paddingTop: '12px' }}>
                          <SuggestDropdown
                            dataTestId={'UnderwritingSecondParty'}
                            label="Search Second Party"
                            disabled={isUwrDisabled || isDisabled}
                            recentOptions={secondPartyList([])}
                            fullOptions={secondPartyList(fullListSecondParties ?? [])}
                            onChange={(party: any) => {
                              updateCplModRisk(i, 'SecondPartyOnCoverageLetter', 'SecondPartyId', party.value);
                            }}
                            value={risk.SecondPartyOnCoverageLetter.SecondPartyId ?? ''}
                            required={true}
                            name="SecondPartyId"
                            showValidation={showMandatoryField}
                            errs={validationErrors[i]}
                          />
                        </Grid>
                        <Grid item sm={12} className={classes.linePadding}>
                          <Divider />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Grid item sm={12} style={{ paddingLeft: '24px' }}>
                    <AgentNetCheckBox
                      label="Agent Name / Address Modification"
                      name="AgentNameAddressModification"
                      value={risk.AgentNameAddressModification ? true : false}
                      checkHandler={(value: any) => {
                        validateCplModCheckBox(i, 'AgentNameAddressModification', value);
                      }}
                      disabled={isUwrDisabled || isDisabled}
                    />
                  </Grid>
                  {risk.AgentNameAddressModification && (
                    <Grid container spacing={0}>
                      <Grid container spacing={3} style={{ paddingLeft: '24px' }}>
                        <Grid item sm={12} style={{ paddingBottom: coveredParties?.length > 1 ? 0 : '' }}>
                          <Typography variant="body2">{underwritingText.UWR_AGENTNAME_ADDRESS}</Typography>
                        </Grid>
                        {coveredParties?.length > 1 && (
                          <Grid item sm={12} style={{ paddingLeft: '12px' }}>
                            <UnderwritingCoveredParties
                              index={i}
                              coveredParties={coveredParties}
                              cplModCoveredParties={risk.AgentNameAddressModification.CoveredParties}
                              cplModCheckBox="AgentNameAddressModification"
                              handleCoveredParties={handleCoveredParties}
                              isUwrDisabled={isUwrDisabled}
                              isDisabled={isDisabled}
                            />
                          </Grid>
                        )}
                        <Grid item sm={6} className={classes.agentName}>
                          <AgentNetTextInput
                            fullWidth
                            variant="outlined"
                            label="Agent Name"
                            name="AgentName"
                            required
                            data-testid={'UnderwritingAgentName'}
                            disabled={isUwrDisabled || isDisabled}
                            value={risk.AgentNameAddressModification.AgentName ?? ''}
                            onChange={(event: any) => {
                              updateCplModRisk(i, 'AgentNameAddressModification', 'AgentName', event.target.value);
                            }}
                            showValidation={showMandatoryField}
                            errs={validationErrors[i]}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={3} style={{ paddingLeft: '24px' }}>
                        <Grid item sm={6} style={{ paddingTop: '36px' }}>
                          <AgentNetTextInput
                            fullWidth
                            variant="outlined"
                            label="Address 1"
                            name="Address1"
                            required
                            data-testid={'UnderwritingAddress1'}
                            disabled={isUwrDisabled || isDisabled}
                            value={risk.AgentNameAddressModification.Address1 ?? ''}
                            onChange={(event: any) => {
                              updateCplModRisk(i, 'AgentNameAddressModification', 'Address1', event.target.value);
                            }}
                            showValidation={showMandatoryField}
                            errs={validationErrors[i]}
                          />
                        </Grid>
                        <Grid item sm={6} style={{ paddingTop: '36px', paddingRight: '24px' }}>
                          <AgentNetTextInput
                            fullWidth
                            variant="outlined"
                            label="Address 2"
                            name="Address2"
                            data-testid={'UnderwritingAddress2'}
                            disabled={isUwrDisabled || isDisabled}
                            value={risk.AgentNameAddressModification.Address2 ?? ''}
                            onChange={(event: any) => {
                              updateCplModRisk(i, 'AgentNameAddressModification', 'Address2', event.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} style={{ paddingLeft: '24px' }}>
                        <Grid item sm={3} style={{ paddingTop: '36px' }}>
                          <AgentNetTextInput
                            fullWidth
                            variant="outlined"
                            label="City"
                            name="City"
                            required
                            data-testid={'UnderwritingCity'}
                            disabled={isUwrDisabled || isDisabled}
                            value={risk.AgentNameAddressModification.City ?? ''}
                            onChange={(event: any) => {
                              updateCplModRisk(i, 'AgentNameAddressModification', 'City', event.target.value);
                            }}
                            showValidation={showMandatoryField}
                            errs={validationErrors[i]}
                          />
                        </Grid>
                        <Grid item sm={3} style={{ paddingTop: '36px' }}>
                          <StateSelectField
                            label="State"
                            //data-testid="domestic-address-state-select"
                            name="State"
                            id="state"
                            required
                            disabled={isUwrDisabled || isDisabled}
                            stateOptions={getStateOptions(userFirm ?? ({} as Firm)).map((e: any) => {
                              return { name: e.name, abbreviation: e.value };
                            })}
                            value={risk.AgentNameAddressModification.State ?? ''}
                            onChange={(event: any) => {
                              updateCplModRisk(i, 'AgentNameAddressModification', 'State', event);
                            }}
                            showValidation={showMandatoryField}
                            errs={validationErrors[i]}
                          />
                        </Grid>
                        <Grid item sm={3} style={{ paddingTop: '36px' }}>
                          <AgentNetTextInput
                            fullWidth
                            variant="outlined"
                            label="Zip"
                            name="Zip"
                            required
                            data-testid={'UnderwritingCity'}
                            disabled={isUwrDisabled || isDisabled}
                            value={risk.AgentNameAddressModification.Zip ?? ''}
                            onChange={(event: any) => {
                              updateCplModRisk(i, 'AgentNameAddressModification', 'Zip', event.target.value);
                            }}
                            showValidation={showMandatoryField}
                            errs={validationErrors[i]}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item sm={12} style={{ paddingLeft: '24px' }}>
                    <AgentNetCheckBox
                      label="Additional Lender(s) / Addressee(s)"
                      name="AdditionalAddress"
                      value={risk.AdditionalAddress ? true : false}
                      checkHandler={(value: any) => {
                        validateCplModCheckBox(i, 'AdditionalAddress', value);
                      }}
                      disabled={isUwrDisabled || isDisabled}
                    />
                    {risk.AdditionalAddress && (
                      <Grid item sm={12}>
                        <Grid item sm={12} style={{ paddingTop: '12px', paddingBottom: '12px' }}>
                          <Typography variant="body2">{underwritingText.UWR_ADDITIONAL_ADDRESS_Note1}</Typography>
                        </Grid>
                        <Grid item sm={12} style={{ paddingTop: '12px', paddingBottom: '12px' }}>
                          <Typography variant="body2">{underwritingText.UWR_ADDITIONAL_ADDRESS_Note2}</Typography>
                        </Grid>
                        {coveredParties.length > 1 && (
                          <Grid>
                            <UnderwritingCoveredParties
                              index={i}
                              coveredParties={coveredParties}
                              cplModCoveredParties={risk.AdditionalAddress.CoveredParties}
                              cplModCheckBox="AdditionalAddress"
                              handleCoveredParties={handleCoveredParties}
                              isUwrDisabled={isUwrDisabled}
                              isDisabled={isDisabled}
                            />
                            <br />
                          </Grid>
                        )}
                        <br />
                        <Grid item sm={12} style={{ paddingBottom: '12px' }}>
                          <Typography variant="body2">{underwritingText.UWR_ADDITIONAL_ADDRESS_TEXTBOX}</Typography>
                        </Grid>
                        <Grid item sm={6} style={{ paddingTop: '12px' }}>
                          <AgentNetTextInput
                            fullWidth
                            variant="outlined"
                            label="Address"
                            name="Address"
                            required
                            data-testid={'UnderwritingAdditionalAddress'}
                            disabled={isUwrDisabled || isDisabled}
                            value={risk.AdditionalAddress.Address ?? ''}
                            onChange={(event: any) => {
                              updateCplModRisk(i, 'AdditionalAddress', 'Address', event.target.value);
                            }}
                            showValidation={showMandatoryField}
                            errs={validationErrors[i]}
                          />
                        </Grid>
                        <Grid item sm={12} className={classes.linePadding}>
                          <Divider />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Grid item sm={12} style={{ paddingLeft: '24px' }}>
                    <AgentNetCheckBox
                      label="Verbiage Edited or Removed"
                      name="VerbiageUpdate"
                      value={risk.VerbiageUpdate ? true : false}
                      checkHandler={(value: any) => {
                        validateCplModCheckBox(i, 'VerbiageUpdate', value);
                      }}
                      disabled={isUwrDisabled || isDisabled}
                    />
                    {risk.VerbiageUpdate && (
                      <Grid item sm={12}>
                        <Grid item sm={12} style={{ paddingTop: '12px', paddingBottom: '12px' }}>
                          <Typography variant="body2">
                            Specific approval from Corporate Underwriting is required for any change to the standard
                            letter used for any state.
                          </Typography>
                        </Grid>
                        {coveredParties.length > 1 && (
                          <UnderwritingCoveredParties
                            index={i}
                            coveredParties={coveredParties}
                            cplModCoveredParties={risk.VerbiageUpdate.CoveredParties}
                            cplModCheckBox="VerbiageUpdate"
                            handleCoveredParties={handleCoveredParties}
                            isUwrDisabled={isUwrDisabled}
                            isDisabled={isDisabled}
                          />
                        )}
                        <Grid item sm={12} className={classes.linePadding}>
                          <Divider />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Grid item sm={12} style={{ paddingLeft: '24px' }}>
                    <AgentNetCheckBox
                      label="International Parties"
                      name="InternationalParties"
                      value={risk.InternationalParties ? true : false}
                      checkHandler={(value: any) => {
                        validateCplModCheckBox(i, 'InternationalParties', value);
                      }}
                      disabled={isUwrDisabled || isDisabled}
                    />
                    {risk.InternationalParties && (
                      <Grid container spacing={0}>
                        <Grid item sm={12} style={{ paddingTop: '12px', paddingBottom: '12px' }}>
                          <Typography variant="body2">Addressee is located outside the United States.</Typography>
                        </Grid>
                        {coveredParties.length > 1 && (
                          <UnderwritingCoveredParties
                            index={i}
                            coveredParties={coveredParties}
                            cplModCoveredParties={risk.InternationalParties.CoveredParties}
                            cplModCheckBox="InternationalParties"
                            handleCoveredParties={handleCoveredParties}
                            isUwrDisabled={isUwrDisabled}
                            isDisabled={isDisabled}
                          />
                        )}
                        <Grid container spacing={3} style={{ paddingBottom: '3px' }}>
                          <Grid item sm={6} style={{ paddingTop: '36px' }}>
                            <AgentNetTextInput
                              fullWidth
                              variant="outlined"
                              label="Attention"
                              name="Attention"
                              data-testid={'UnderwritingAttention'}
                              disabled={isUwrDisabled || isDisabled}
                              value={risk.InternationalParties.Attention ?? ''}
                              onChange={(event: any) => {
                                updateCplModRisk(i, 'InternationalParties', 'Attention', event.target.value);
                              }}
                              showValidation={showMandatoryField}
                              errs={validationErrors[i]}
                            />
                          </Grid>
                          <Grid item sm={6} style={{ paddingTop: '36px', paddingRight: '24px' }}>
                            <AgentNetTextInput
                              fullWidth
                              variant="outlined"
                              label="Name"
                              name="Name"
                              required
                              data-testid={'UnderwritingName'}
                              disabled={isUwrDisabled || isDisabled}
                              value={risk.InternationalParties.Name ?? ''}
                              onChange={(event: any) => {
                                updateCplModRisk(i, 'InternationalParties', 'Name', event.target.value);
                              }}
                              showValidation={showMandatoryField}
                              errs={validationErrors[i]}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{ paddingBottom: '8px' }}>
                          <Grid item sm={6} style={{ paddingTop: '24px' }}>
                            <AgentNetTextInput
                              fullWidth
                              variant="outlined"
                              label="Care of / DBA"
                              name="CareOfDba"
                              data-testid={'UnderwritingCareOfDba'}
                              disabled={isUwrDisabled || isDisabled}
                              value={risk.InternationalParties.CareOfDBA ?? ''}
                              onChange={(event: any) => {
                                updateCplModRisk(i, 'InternationalParties', 'CareOfDBA', event.target.value);
                              }}
                              showValidation={showMandatoryField}
                              errs={validationErrors[i]}
                            />
                          </Grid>
                          <Grid item sm={6} style={{ paddingTop: '24px', paddingRight: '24px' }}>
                            <AgentNetTextInput
                              fullWidth
                              variant="outlined"
                              label="Address"
                              name="Address"
                              required
                              data-testid={'UnderwritingAddress'}
                              disabled={isUwrDisabled || isDisabled}
                              value={risk.InternationalParties.Address ?? ''}
                              onChange={(event: any) => {
                                updateCplModRisk(i, 'InternationalParties', 'Address', event.target.value);
                              }}
                              showValidation={showMandatoryField}
                              errs={validationErrors[i]}
                            />
                          </Grid>
                        </Grid>
                        <Grid item sm={12} className={classes.linePadding}>
                          <Divider />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Grid item sm={12} style={{ paddingLeft: '24px' }}>
                    <AgentNetCheckBox
                      label="Multiple Properties"
                      name="MultipleProperties"
                      value={risk.MultipleProperties ? true : false}
                      checkHandler={(value: any) => {
                        validateCplModCheckBox(i, 'MultipleProperties', value);
                      }}
                      disabled={isUwrDisabled || isDisabled}
                    />
                    {risk.MultipleProperties && (
                      <Grid item sm={12}>
                        <Grid item sm={12} style={{ paddingTop: '12px', paddingBottom: '12px' }}>
                          <Typography variant="body2">
                            Where the Real Estate Transaction to be referred to in the letter is comprised of more than
                            one property.
                          </Typography>
                        </Grid>
                        {coveredParties.length > 1 && (
                          <UnderwritingCoveredParties
                            index={i}
                            coveredParties={coveredParties}
                            cplModCoveredParties={risk.MultipleProperties.CoveredParties}
                            cplModCheckBox="MultipleProperties"
                            handleCoveredParties={handleCoveredParties}
                            isUwrDisabled={isUwrDisabled}
                            isDisabled={isDisabled}
                          />
                        )}
                        <Grid item sm={12} style={{ paddingTop: '20px', paddingBottom: '0px' }}>
                          <Typography variant="body2">
                            I have a PDF property listing to attach to the request
                          </Typography>
                        </Grid>
                        <Grid item sm={12} className={classes.linePadding}>
                          <Divider />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </AccordionContent>
        </Grid>
      </Grid>
    ));
  };

  return (
    <>
      <Container>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <AgentNetDivider
              title={'Risks'}
              typoVariant="h2"
              primaryButtonName="Add New Risk"
              primaryButtonDisable={isUwrDisabled || (uwr?.Risks?.length ?? 0) >= 5 || isDisabled}
              onClickPrimary={() => handleNewRisk(false)}
              buttonName={'Close All'}
              buttonName2={'Expand All'}
              onClick={() => {
                handleRiskExpand(false);
              }}
              onClick2={() => {
                handleRiskExpand(true);
              }}
              primaryButtonQaAttr="AddNewRiskTop"
              buttonNameQaAttr="RisksCloseAll"
              buttonName2QaAttr="RisksExpandAll"
              disablePaddingX
            />
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          {renderRisks()}
        </Grid>
        <AgentNetButton
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => handleNewRisk(false)}
          disabled={isUwrDisabled || (uwr?.Risks?.length ?? 0) >= 5 || isDisabled}
          data-testid="AddNewRiskBottom"
        >
          Add New Risk
        </AgentNetButton>
      </Container>
      <>
        <AgentNetConfirmationDialog
          qaAttrPrefix="ConfirmationRiskDelete"
          onConfirm={deleteRisk}
          open={isdeleteRisk}
          onDismissAction={cancelDeleteRisk}
          dialogTitle="Delete Risk"
          dialogBtnContent="Yes, Delete"
          dialogText="You are about to delete this risk from your underwriting request. Are you sure you want to proceed?"
        />
      </>
    </>
  );
};

export default UnderwritingRisks;
