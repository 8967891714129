import { gql } from '@apollo/client';
import performQuery from 'features/help/queries/contentfulClient';
import { Topic } from '../types';

export type GetTopicCollectionArgs = {
  iconWidth: number;
  iconHeight: number;
};

const BROWSE_BY_TOPIC_QUERY = gql`
  query BrowseByTopicCollectionQuery($preview: Boolean) {
    ansKbTopicCollection(limit: 16, order: sortOrder_ASC, preview: $preview) {
      items {
        sys {
          id
          spaceId
        }
        topicName
        slug
        previewDescription
        externalUrl
        topicIcon {
          url(transform: { height: 56, width: 56 })
        }
      }
    }
  }
`;

export default async function useBrowseByTopicQuery() {
  const response = await performQuery(BROWSE_BY_TOPIC_QUERY, {});

  if (response.hasError) {
    return {
      data: null,
      error: true,
    };
  }

  return {
    data: response.res?.data?.ansKbTopicCollection?.items as Topic[] | null,
    error: false,
  };
}
