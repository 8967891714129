import { useAuth } from '@agentnet/auth';
import { pxToRem } from '@fluentsms/agentnet-web-components';
import { Grid, IconButton, Popper, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import Autocomplete, { AutocompleteCloseReason } from '@material-ui/lab/Autocomplete';
import { getFileInfo } from 'api/file/file-api';
import { fileInfoType } from 'api/file/interfaces/get-file';
import { deleteStandaloneEndorsements, getEndorsements, postStandaloneEndorsements } from 'api/jacket-api';
import { FileDataContext, FileDataContextInterface } from 'hooks/FileDataContext';
import useAsync from 'hooks/useAsync';
import React, { useContext, useEffect, useState } from 'react';
import AccordionContent from 'ui-kit/components/accordion/AccordionContent';
import { AgentNetDivider } from 'ui-kit/components/dividers/AgentNetDivider2';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import { Notification } from 'ui-kit/components/notification/Notification';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import { AgentNetTextInput, CurrencyField } from 'ui-kit/inputs';
import { uniqueArray } from 'utilities/utilities';
import { StandaloneSchema } from 'utilities/validation/schemas/jacket-schema';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import './StandaloneEndorsement.scss';
import StandaloneEndorsementActions from './StandaloneEndorsementActions';
import { Endorsement, StandaloneEndorsement, StandaloneEndorsementFormDataType } from './types';

const randomID = () => (Math.random() + 1).toString(36).substring(7);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mb2: {
      marginBottom: theme.spacing(2),
    },
    popper: {
      backgroundColor: '#FFF',
      border: '1px solid #c4c4c4',
      borderRadius: '6px',
      zIndex: 15,
    },
    popperContent: {
      width: 400,
      boxShadow:
        '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
    },
    popperOption: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    popperPaper: {
      margin: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    popperDisablePortal: {
      position: 'relative',
      width: '100% !important',
    },
    inputBaseWrap: {
      padding: '16px 12px 12px',
      borderBottom: '1px solid #c4c4c4',
    },
    inputBase: {
      width: '100%',
      '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: '1px solid #C4C4C4',
        fontSize: 14,
        '&:focus': {
          boxShadow:
            '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
          borderColor: '#c4c4c4',
        },
      },
    },
    deleteRow: {
      padding: 0,
      marginLeft: theme.spacing(1),
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    miscEndoAsterisk: {
      color: theme.palette.error.dark,
      fontSize: pxToRem(14),
      fontWeight: 600,
    },
  }),
);

interface StandaloneEndorsementProps {
  saEndorsements?: StandaloneEndorsement[] | null;
  fileId?: string;
  state?: string;
  underwriter?: string;
  setStandaloneFlag?: any;
  handleDelete?: () => void;
  filestatus?: string;
}

const StandaloneEndorsementFC: React.FC<StandaloneEndorsementProps> = ({
  saEndorsements,
  fileId,
  state,
  underwriter,
  setStandaloneFlag,
  handleDelete,
  filestatus,
}: StandaloneEndorsementProps) => {
  const { addGlobalMsg } = useGlobalMessages();
  const classes = useStyles();
  const { setFileData }: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const [expandSaEndorsement, setExpandSaEndorsement] = useState(true);
  const [standaloneEndorsements, setStandaloneEndorsements] = useState<StandaloneEndorsement[]>(saEndorsements ?? []);
  const [initialSaEndorsements, setInitialSaEndorsements] = useState<StandaloneEndorsement[]>(saEndorsements ?? []);
  const [endorsements, setEndorsements] = useState<Endorsement[]>([]);
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const [shouldShowValidation, setShouldShowValidation] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isFileClosed] = useState(filestatus == 'Closed' ? true : false);
  const handleClose = (e: any, reason: AutocompleteCloseReason) => {
    if (reason === 'toggleInput') {
      return;
    }
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const updateSaEndorsement = (
    id: string | null,
    rflId: number | null,
    name: string | null,
    amount: number | null,
    reason: string,
    type: string,
    starsNumber: string | null = '',
  ) => {
    if (type === 'MISC') {
      switch (reason) {
        case 'update-name': {
          const updatedEndorsements = standaloneEndorsements?.map((x) => {
            if (x.randomId === id && x.id === rflId) {
              x.endorsementName = name ?? '';
            }
            return x;
          });
          setStandaloneEndorsements(updatedEndorsements ?? []);
          break;
        }
        case 'update-amount': {
          const updatedEndorsements = standaloneEndorsements?.map((x) => {
            if (x.randomId === id && x.id === rflId) {
              x.calculatedAmount = amount;
            }
            return x;
          });
          setStandaloneEndorsements(updatedEndorsements ?? []);
          break;
        }
        case 'remove-misc': {
          let updatedEndorsements = null;
          if (rflId) {
            updatedEndorsements = standaloneEndorsements?.filter((x) => x.id !== rflId);
          } else {
            updatedEndorsements = standaloneEndorsements?.filter((x) => x.randomId !== id);
          }
          setStandaloneEndorsements(updatedEndorsements ?? []);
          break;
        }
      }
    } else {
      if (reason === 'remove') {
        let updatedEndorsements = null;
        if (rflId) {
          updatedEndorsements = standaloneEndorsements?.filter((x) => x.id !== rflId);
        } else {
          updatedEndorsements = standaloneEndorsements?.filter((x) => x.randomId !== id);
        }
        setStandaloneEndorsements(updatedEndorsements ?? []);
      } else {
        const newEndorsement: StandaloneEndorsement = {
          id: 0,
          endorsementType: 'Endorsement',
          endorsementName: name ?? '',
          calculatedAmount: null,
          endorsementFormNumber: starsNumber ?? '',
          randomId: randomID(),
          sequenceNumber: null,
          createdBy: null,
        };
        const updatedEndorsements: StandaloneEndorsement[] =
          name === 'Miscellaneous Endorsement' ? addMiscEndorsement() : [...standaloneEndorsements, newEndorsement];
        setStandaloneEndorsements(updatedEndorsements);
      }
    }
  };

  const addMiscEndorsement = (): StandaloneEndorsement[] => {
    const newMiscEndorsement: StandaloneEndorsement = {
      id: 0,
      endorsementType: 'Miscellaneous',
      endorsementName: '',
      calculatedAmount: null,
      endorsementFormNumber: 'MISC.END.',
      randomId: randomID(),
      sequenceNumber: null,
      createdBy: null,
    };
    return [...standaloneEndorsements, newMiscEndorsement];
  };

  const saveSaEndorsements = () => {
    setShouldShowValidation(true);
    if (validationErrors?.length == 0) {
      executePostSaEndorsements().then();
    }
  };

  const cancelSaEndorsements = () => {
    if (initialSaEndorsements.length > 0) {
      setStandaloneEndorsements(initialSaEndorsements);
    } else {
      setStandaloneFlag(false);
    }
  };

  const handleSaEndorsementExpandClose = (value: boolean) => {
    setExpandSaEndorsement(value);
  };

  const { getAccessToken } = useAuth();

  /* Handle GET File DATA and its values  */
  const getFile = async (): Promise<fileInfoType> => {
    const token = await getAccessToken();
    return getFileInfo(fileId ?? '', token);
  };

  const {
    execute: executeGetFile,
    status,
    value: fileApiData,
    errors: getFileError,
  } = useAsync<fileInfoType>(getFile, false);

  const fetchEndorsements = async (): Promise<Endorsement[]> => {
    const token = await getAccessToken();
    return getEndorsements(state ?? '', underwriter ?? '', token);
  };

  const deleteSaEndorsements = async (): Promise<boolean> => {
    const token = await getAccessToken();
    return deleteStandaloneEndorsements(fileId ?? '', token);
  };

  const postSaEndorsements = async (): Promise<StandaloneEndorsement[]> => {
    const token = await getAccessToken();
    const endorsements = [...standaloneEndorsements];
    const data = endorsements.map((x) => {
      if (x.endorsementType === 'Miscellaneous') {
        x.endorsementFormNumber = null;
      }
      return x;
    });
    const payload: StandaloneEndorsementFormDataType = {
      fileId: fileId,
      standaloneEndorsementRequest: data,
    };
    return postStandaloneEndorsements(payload, token);
  };

  const {
    execute: executePostSaEndorsements,
    value: postSaEndorsementsValue,
    errors: saveSaEndorsementError,
    status: saveSaEndorsementsStatus,
  } = useAsync<StandaloneEndorsement[]>(postSaEndorsements, false);

  const { execute: executeGetEndorsements, value: getEndorsementsValue } = useAsync<Endorsement[]>(
    fetchEndorsements,
    false,
  );

  const {
    execute: executeDeleteSaEndorsements,
    value: deleteSaEndorsementsValue,
    status: deleteSaEndorsementsStatus,
  } = useAsync<boolean>(deleteSaEndorsements, false);

  const removeStandaloneEndorsements = () => {
    executeDeleteSaEndorsements().then();
  };

  const actionStatus =
    saveSaEndorsementsStatus === 'pending' || deleteSaEndorsementsStatus === 'pending' ? 'pending' : 'idle';

  useEffect(() => {
    if (!getEndorsementsValue) executeGetEndorsements().then();
  }, []);

  useEffect(() => {
    if (deleteSaEndorsementsValue) {
      setStandaloneEndorsements([]);
      setInitialSaEndorsements([]);
      handleDelete && handleDelete();
    }
  }, [deleteSaEndorsementsValue]);

  useEffect(() => {
    if (getEndorsementsValue) {
      const miscEndorsement: Endorsement = {
        endorsementName: 'Miscellaneous Endorsement',
        endorsementFormNumber: 'Miscellaneous Endorsement',
        policyCategories: [],
        policyCoverages: [],
      };
      const endorsements = [...getEndorsementsValue, miscEndorsement];
      setEndorsements(endorsements);
      setShouldShowValidation(false);
    }
  }, [getEndorsementsValue]);

  useEffect(() => {
    if (postSaEndorsementsValue) {
      setStandaloneEndorsements(postSaEndorsementsValue);
      setInitialSaEndorsements(postSaEndorsementsValue);
      executeGetFile().then();
    }
  }, [postSaEndorsementsValue]);

  useEffect(() => {
    if (saveSaEndorsementError || getFileError) {
      uniqueArray([...(saveSaEndorsementError ?? []), ...(getFileError ?? [])])?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [saveSaEndorsementError, getFileError]);

  useEffect(() => {
    if (fileApiData) {
      setFileData?.(fileApiData);
    }
  }, [fileApiData]);

  useEffect(() => {
    if (standaloneEndorsements) {
      const miscEndorsement = standaloneEndorsements.filter((x) => x.endorsementType === 'Miscellaneous');
      if (miscEndorsement.length > 0) {
        validateStandaloneErrors(miscEndorsement);
      }
    }
  }, [standaloneEndorsements]);

  const validateStandaloneErrors = async (standaloneEndorsements: StandaloneEndorsement[]) => {
    const errs: FieldValidationError[] = (await doValidate(standaloneEndorsements, StandaloneSchema)) || [];
    setValidationErrors(errs);
  };

  return (
    <>
      <LoadingSpinner status={actionStatus} variant="linear" />
      <AgentNetDivider
        title={standaloneEndorsements.length ? 'Standalone Endorsements' : 'Create Standalone Endorsements'}
        typoVariant="h1"
        primaryButtonQaAttr="StandaloneEndorsementAddNewJacket"
        buttonName={'Close All'}
        buttonNameQaAttr="StandaloneEndorsementCloseAll"
        buttonName2={'Expand All'}
        buttonName2QaAttr="StandaloneEndorsementExpandAll"
        onClick={() => handleSaEndorsementExpandClose(false)}
        onClick2={() => handleSaEndorsementExpandClose(true)}
      />
      <div className="jacketsWrap">
        {initialSaEndorsements.length > 0 && (
          <Notification
            inline
            title="File contains Standalone Endorsements"
            severity="warning"
            msg="To add a policy, please delete standalone endorsements."
          />
        )}
        <AccordionContent
          accordianQAAttribute="StandaloneEndorsementAccordionContent"
          icon={'document'}
          type={'cpl'}
          title={'Standalone Endorsements'}
          subtitle={[`${standaloneEndorsements.length} Endorsements Selected`]}
          key={'saEndorsement'}
          id={'saEndorsement'}
          expanded={expandSaEndorsement}
          status={standaloneEndorsements.length ? 'Open' : 'Draft'}
          className="mb2"
          {...(initialSaEndorsements.length > 0 &&
            standaloneEndorsements.length > 0 && {
              onDelete: () => removeStandaloneEndorsements(),
            })}
          deleteQAAttribute="StandaloneEndorsementDelete"
          hideStatus
          isDeleteDisabled={isFileClosed}
        >
          <>
            <div className="componentTitle">
              <div className="componentTitle">
                <AgentNetDivider
                  typoVariant="h3"
                  title={'Endorsements'}
                  primaryButtonName={'Add Endorsements'}
                  primaryButtonQaAttr="StandaloneEndorsementAddEndorsements"
                  onClickPrimary={(e: any) => setAnchorEl(e.currentTarget)}
                  primaryButtonDisable={isFileClosed}
                  disablePadding
                />
                <Popper className={classes.popper} placement="bottom-end" anchorEl={anchorEl} open={open}>
                  <div className={classes.popperContent}>
                    <Autocomplete
                      options={endorsements}
                      getOptionLabel={(option) => option.endorsementName}
                      open
                      onClose={handleClose}
                      multiple
                      classes={{
                        option: classes.popperOption,
                        popperDisablePortal: classes.popperDisablePortal,
                        paper: classes.popperPaper,
                      }}
                      value={endorsements}
                      onChange={(e, v, r, d) => {
                        updateSaEndorsement(
                          null,
                          null,
                          d?.option.endorsementName ?? '',
                          null,
                          'add',
                          'Endorsement',
                          d?.option.endorsementFormNumber,
                        );
                      }}
                      disablePortal
                      renderTags={() => null}
                      noOptionsText="No labels"
                      disableCloseOnSelect
                      renderInput={(params) => (
                        <div className={classes.inputBaseWrap}>
                          <TextField
                            variant="outlined"
                            label="Search Endorsements"
                            ref={params.InputProps.ref}
                            inputProps={params.inputProps}
                            autoFocus
                            className={classes.inputBase}
                            InputLabelProps={{ shrink: true }}
                            data-testid="StandaloneSearchEndorsements"
                          />
                        </div>
                      )}
                    />
                  </div>
                </Popper>
              </div>
              {standaloneEndorsements && standaloneEndorsements.length > 0 ? (
                <div className="endorsementsTable">
                  <Grid container className="endorsementsTableHeader">
                    <Grid item sm={3}>
                      Endorsement
                    </Grid>
                    <Grid item sm={9}>
                      Description
                    </Grid>
                  </Grid>
                  <Grid container className="endorsementsTableList">
                    {standaloneEndorsements.map((e: StandaloneEndorsement, i) => (
                      <Grid container key={i} className="tableRow">
                        <Grid item sm={3} className="tableCell">
                          <Typography variant="body2" data-testid={'StandaloneEndorsementType' + [i + 1]}>
                            {e.endorsementType && e.endorsementType === 'Miscellaneous' ? (
                              <>
                                MISC. END<span className={classes.miscEndoAsterisk}>*</span>
                              </>
                            ) : (
                              e.endorsementFormNumber
                            )}
                          </Typography>
                        </Grid>
                        <Grid item sm={9} className="tableCell">
                          {e.endorsementType && e.endorsementType === 'Miscellaneous' ? (
                            <Grid container spacing={1}>
                              <Grid item xs={10} data-testid={'StandaloneMISCENDOName' + [i + 1]}>
                                <AgentNetTextInput
                                  variant="outlined"
                                  value={e.endorsementName}
                                  name={'endorsementName' + [e.randomId]}
                                  onChange={(el) => {
                                    updateSaEndorsement(e.randomId, e.id, el.target.value, null, 'update-name', 'MISC');
                                  }}
                                  required={true}
                                  errs={validationErrors}
                                  showValidation={shouldShowValidation}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={2} data-testid={'StandaloneMISCENDOPremium' + [i + 1]}>
                                <CurrencyField
                                  variant="outlined"
                                  value={e.calculatedAmount}
                                  fullWidth
                                  max={99999999}
                                  allowNegative={false}
                                  onChange={(el) => {
                                    updateSaEndorsement(
                                      e.randomId,
                                      e.id,
                                      null,
                                      Number(el.target.value),
                                      'update-amount',
                                      'MISC',
                                    );
                                  }}
                                />
                              </Grid>
                            </Grid>
                          ) : (
                            <Typography variant="body2" data-testid={'StandaloneEndorsementName' + [i + 1]}>
                              {e.endorsementName}
                            </Typography>
                          )}
                          <IconButton
                            className={classes.deleteRow}
                            data-testid={'RemoveStandaloneEndoIcon' + [i + 1]}
                            disabled={isFileClosed}
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                            aria-label="delete endorsement"
                            onClick={() => {
                              updateSaEndorsement(
                                e.randomId,
                                e.id,
                                null,
                                null,
                                e.endorsementType == 'Endorsement' ? 'remove' : 'remove-misc',
                                e.endorsementType == 'Endorsement' ? 'Endorsement' : 'MISC',
                              );
                            }}
                          >
                            <Close color="disabled" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              ) : (
                ''
              )}
            </div>
          </>
        </AccordionContent>
        <StandaloneEndorsementActions
          hasSavedEndorsements={initialSaEndorsements.length > 0}
          saveStandaloneEndorsement={() => saveSaEndorsements()}
          cancelStandaloneEndorsement={() => cancelSaEndorsements()}
          isSaveDisabled={actionStatus === 'pending' || isFileClosed}
        />
      </div>
    </>
  );
};

export default StandaloneEndorsementFC;
