export enum ServiceOrderActiveStatus {
  searching = 'Searching',
  examining = 'Examining',
  qualityReview = 'Quality Review',
  actionRequired = 'Action Required',
}

export enum ServiceOrderCompleteStatus {
  orderComplete = 'Order Complete',
}

export const ServiceOrderDashboardSubtitle =
  'Track and monitor your service orders with real-time status updates. View estimated completion times and use the messaging feature to communicate directly with First American Title Production about your orders.';
export const YTDDashboardSubtitle =
  'View and filter your completed orders year-to-date. Export your data as needed. Click the message icon to contact production.';
export const MTDDashboardSubtitle =
  'View and filter your completed orders month-to-date. Export your data as needed. Click the message icon to contact production.';
