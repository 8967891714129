import { useAuth } from '@agentnet/auth';
import { DataTable, pxToRem } from '@fluentsms/agentnet-web-components';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ColDef } from 'ag-grid-enterprise';
import { getFirmDetails, getFirmList } from 'api/onBehalfOf/api';
import { RegexNumeric } from 'features/constants';
import SearchField from 'features/files/files-summary/search-form/SearchField';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import PageHeader from 'ui-kit/components/headers/PageHeader';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import NoResults from 'ui-kit/components/noResults/NoResults';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import RadioGroup, { RadioProps } from 'ui-kit/components/radios/RadioGroup';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';
import FileStatus from 'ui-kit/icons/FileStatus';
import { searchFirmSchema } from 'utilities/validation/schemas/on-behalf-schema';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: pxToRem(114),
    },
    contentWrap: {
      padding: theme.spacing(0, 3, 3),
      paddingBottom: 0,
    },
    mb3: {
      marginBottom: theme.spacing(3),
    },
    spaceElements: {
      marginBottom: theme.spacing(2),
    },
    gridContainer: {
      height: 'calc(100vh - 364px) !important',
      width: '100%',
      fontFamily: theme.typography.fontFamily,
    },
    searchWrap: {
      display: 'flex',
      marginBottom: theme.spacing(2),
    },
    contentClass: {
      margin: '0 auto auto',
    },
    searchButton: {
      height: '30px',
    },
    selectedRows: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      fontSize: pxToRem(14),
      padding: theme.spacing(2, 0),
    },
  }),
);

const OnBehalfOFSearch = () => {
  const { getAccessToken } = useAuth();
  const history = useHistory();
  const { addGlobalMsg } = useGlobalMessages();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { setUserFirm, profile } = profileCtx;
  if (profile?.roleId === 2 || profile?.roleId === 13) {
    history.push('/');
    return null;
  }
  const classes = useStyles();
  const firmFilters: RadioProps[] = [
    { label: 'Firm Name', value: 'name', dataTestId: 'FirmSelectFirmName' },
    { label: 'Account No.', value: 'account', dataTestId: 'FirmSelectAccountNo' },
  ];
  const [firmFilter, setFirmFilter] = useState<RadioProps>(firmFilters[0]);
  const [searchText, setSearchText] = useState('');
  const [selectedFirm, setSelectedFirm] = useState('');
  const [rowData, setRowData] = useState<any>([]);
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const [showValidationError, setShowValidationError] = useState<boolean>(false);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errMsgs, setErrMsgs] = useState([]);
  const [gridApi, setGridApi] = useState<any>(null);

  const statusCellRenderer = (params: GridRenderCellParams): React.ReactElement => {
    const { value } = params;
    const statusDotClassName = params.value === 'Active' ? 'green-dot' : 'blue-dot',
      statusClassName = params.value === 'Active' ? 'open-status' : 'closed-status';
    return FileStatus({
      value: value,
      statusDotClassName: statusDotClassName,
      statusClassName: statusClassName,
    });
  };

  const columnDefs: ColDef[] = useMemo(
    () => [
      { headerName: 'Firm ID', field: 'firmId', filter: 'agTextColumnFilter', tooltipField: 'firmId' },
      {
        headerName: 'Title & Escrow',
        field: 'isTitleEscrow',
        cellStyle: { display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center' },
        cellRenderer: (params: any) => {
          return params?.data?.isTitleEscrow ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircleIcon style={{ color: 'green' }} />
            </div>
          ) : (
            ''
          );
        },
        tooltipField: 'isTitleEscrow',
        maxWidth: 150,
      },
      { headerName: 'Firm Name', field: 'name', filter: 'agTextColumnFilter', flex: 1.5, tooltipField: 'name' },
      { headerName: 'Licensed States', field: 'states', filter: 'agTextColumnFilter', tooltipField: 'states' },
      {
        headerName: 'Status',
        field: 'status',
        cellRenderer: statusCellRenderer,
        tooltipField: 'status',
      },
      {
        headerName: 'Action',
        field: '',
        floatingFilter: false,
        sortable: false,
        cellRenderer: (params: any) => {
          return (
            <AgentNetButton
              data-testid={`FirmSelectLink${params?.node?.id}`}
              onClick={() => {
                setSelectedFirm(params?.data?.firmId);
              }}
              color="primary"
              variant="text"
              size="small"
            >
              Select
            </AgentNetButton>
          );
        },
        cellStyle: { display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center' },
        tooltipValueGetter: () => 'Select Firm',
        pinned: 'right',
        maxWidth: 150,
      },
    ],
    [],
  );

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      floatingFilter: true,
      resizable: true,
      minWidth: 100,
      flex: 1,
      editable: false,
      suppressMenu: true,
    }),
    [],
  );

  const getFirms = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await getFirmList(token, firmFilter?.value, searchText);
  };
  const {
    execute: executeFirmList,
    status: executeFirmListStatus,
    value: firmListResults,
    errors: firmListErrors,
    errorDetails: listErrorDetails,
  } = useAsync<any>(getFirms, false);

  const getFirmDetail = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await getFirmDetails(token, selectedFirm);
  };
  const {
    execute: executeFirmDetails,
    status: executeFirmDetailsStatus,
    value: firmDetailsResults,
    errors: firmDetailsErrors,
    errorDetails: firmErrorDetails,
  } = useAsync<any>(getFirmDetail, false);

  useEffect(() => {
    setRowData(firmListResults);
  }, [firmListResults]);

  useEffect(() => {
    if (selectedFirm) executeFirmDetails().then();
  }, [selectedFirm]);

  useEffect(() => {
    if (firmDetailsResults) {
      window.localStorage.setItem('userFirm', JSON.stringify(firmDetailsResults));
      setUserFirm && setUserFirm(firmDetailsResults);
      history.push('/files');
    }
  }, [firmDetailsResults]);

  useEffect(() => {
    const isError = executeFirmListStatus === 'error' || executeFirmDetailsStatus === 'error';

    setDisplayError(listErrorDetails?.errorCode !== 400 && firmErrorDetails?.statusCode !== 400 && isError);
  }, [executeFirmListStatus, executeFirmDetailsStatus, firmErrorDetails?.errorCode, listErrorDetails?.errorCode]);

  useEffect(() => {
    if (displayError) {
      const errorMessages = [...(firmDetailsErrors ?? []), ...(firmListErrors ?? [])];
      setErrMsgs(errorMessages);
    }
  }, [displayError]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [errMsgs]);

  useEffect(() => {
    doValidate({ searchText }, searchFirmSchema(firmFilter.value === 'account')).then(
      (errs: FieldValidationError[]) => {
        getFilteredErrors(errs, ['searchText']);
      },
    );
  }, [searchText]);

  const getFilteredErrors = (errs: FieldValidationError[], fields: string[]) => {
    if (!validationErrors) {
      setValidationErrors(errs);
    } else if (errs) {
      setValidationErrors((prevErrs: any) => {
        return [...prevErrs.filter((err: { field: string }) => !fields.includes(err.field)), ...errs];
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      setValidationErrors((prevErrs: any) => {
        return prevErrs.filter((err: { field: string }) => {
          return !(fields.includes(err.field) && !errs);
        });
      });
    }
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const getDisplayedRowCount = () => {
    if (gridApi) {
      return gridApi.getDisplayedRowCount();
    }
    return 0;
  };

  const onFilterChanged = () => {
    if (gridApi?.getDisplayedRowCount() === 0) {
      gridApi?.showNoRowsOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  };

  const handleSearch = () => {
    if (validationErrors?.some((err) => err.field === 'searchText')) {
      setShowValidationError(true);
    } else {
      executeFirmList().then();
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (firmFilter.value === 'account') {
      const trimmedValue = value.trim();
      if (RegexNumeric.test(trimmedValue) && trimmedValue.length <= 10) {
        setSearchText(trimmedValue);
      }
    } else {
      setSearchText(value);
    }
  };

  const searchActionStatus =
    executeFirmListStatus === 'pending' || executeFirmDetailsStatus === 'pending' ? 'pending' : 'idle';

  return (
    <ContentContainer>
      <LoadingSpinner status={searchActionStatus} variant="linear" />
      <div className={classes.contentWrap}>
        <PageHeader title="Select a Firm" disableMargin="x" />

        <RadioGroup
          className={classes.spaceElements}
          selected={firmFilter}
          options={firmFilters}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFirmFilter(firmFilters?.find((option) => option.value === e.target.value) ?? firmFilter);
            setSearchText('');
            setValidationErrors([]);
            setShowValidationError(false);
          }}
          compact
        />
        <div className={(classes.spaceElements, classes.searchWrap)}>
          <SearchField
            onChange={handleOnChange}
            value={searchText}
            onSearch={handleSearch}
            name="searchText"
            errs={validationErrors}
            executeOnSearchOnChange={false}
            displayError={showValidationError}
            qaAttribute="FirmSelectSearchInput"
            showCLoseIcon={true}
            onClose={() => setSearchText('')}
            compact
          />
          <AgentNetButton
            onClick={handleSearch}
            color="primary"
            variant="contained"
            data-testid="FirmSelectSearch"
            size="small"
            className={classes.searchButton}
          >
            Search
          </AgentNetButton>
        </div>

        {rowData && rowData.length > 0 ? (
          <div className={`ag-theme-alpine ${classes.gridContainer}`}>
            <DataTable
              onGridReady={onGridReady}
              onFilterChanged={onFilterChanged}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowSelection={'multiple'}
              gridOptions={{ suppressRowClickSelection: true }}
              animateRows={true}
            />
          </div>
        ) : (
          <NoResults showButton={false} type="results" titleText="No Firms Found" pText="Try adjusting your search" />
        )}
        {rowData?.length ? (
          <div className={classes.selectedRows}>
            <span>
              Showing {getDisplayedRowCount()} of {rowData?.length} firms
            </span>
          </div>
        ) : null}
      </div>
    </ContentContainer>
  );
};

export default OnBehalfOFSearch;
