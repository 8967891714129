import {
  //palette,
  shape,
  breakpoints as sharedBreakpoints,
  overrides as sharedOverrides,
  props as sharedProps,
  spacing as sharedSpacing,
  typography as sharedTypography,
} from '@fluentsms/agentnet-web-components';
import { createMuiTheme } from '@material-ui/core';
import overrides from './overrides';
import palette from './palette/palette';

//TODO: Fix palette conflicts
const combinedOverrides = {
  ...overrides,
  ...sharedOverrides.MuiCssBaseline,
  MuiButton: {
    ...sharedOverrides.MuiButton,
  },
  MuiCardActionArea: {
    ...sharedOverrides.MuiCardActionArea,
  },
  MuiIconButton: {
    ...sharedOverrides.MuiIconButton,
  },
  MuiLink: {
    ...sharedOverrides.MuiLink,
  },
  MuiList: {
    ...sharedOverrides.MuiList,
  },
  MuiListItem: {
    ...sharedOverrides.MuiListItem,
  },
  MuiListItemAvatar: {
    ...sharedOverrides.MuiListItemAvatar,
  },
  MuiListItemIcon: {
    ...sharedOverrides.MuiListItemIcon,
  },
  MuiListItemText: {
    ...sharedOverrides.MuiListItemText,
  },
  MuiListSubheader: {
    ...sharedOverrides.MuiListSubheader,
  },
  MuiSvgIcon: {
    ...sharedOverrides.MuiSvgIcon,
  },
  MuiTable: {
    ...sharedOverrides.MuiTable,
  },
  MuiTableRow: {
    ...sharedOverrides.MuiTableRow,
  },
  MuiTableCell: {
    ...sharedOverrides.MuiTableCell,
  },
  MuiTypography: {
    ...sharedOverrides.MuiTypography,
  },
};

export const theme = createMuiTheme({
  breakpoints: sharedBreakpoints,
  overrides: combinedOverrides,
  props: sharedProps,
  palette,
  spacing: sharedSpacing,
  typography: sharedTypography,
  shape,
});
