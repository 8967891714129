import { useAuth } from '@agentnet/auth';
import { pxToRem } from '@fluentsms/agentnet-web-components';
import { FormControlLabel, Grid, IconButton, Paper, Switch, Tab, Tabs, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ReportProblemOutlined } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { getFileInfoProps } from 'api/file/file-api';
import { getEndorsements, getRateTypes } from 'api/jacket-api';
import { addRemoveQueue, calculateReports, getBulkRateTypes, ReportFiles, setAutoReport } from 'api/reports/api';
import { policyFieldsWithNameInLabel } from 'features/jackets/constants';
import { Endorsement, PolicyField, PolicyFieldOption, RateTypes } from 'features/jackets/types';
import { orderStatuses } from 'features/Remittance/reportsListConfig';
import { BulkRateTypePayload, BulkRateTypeResponse, Policy, SingleRateTypePayload } from 'features/Remittance/types';
import { FileDataContext, FileDataContextInterface } from 'hooks/FileDataContext';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import { useNavPermissions } from 'hooks/useNavPermissions';
import * as _ from 'lodash';
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import FormDrawerComponent from 'ui-kit/components/drawer/FormDrawerComponent';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import ReportedFeesTable from 'ui-kit/form/ReportedFeesTable';
import NoProducts from 'ui-kit/icons/NoProducts';
import NoRatesIcon from 'ui-kit/icons/NoRates';
import { AgentNetCheckBox } from 'ui-kit/inputs';
import { calculateTotals, dollarize, formatDate, removeEmpty } from 'utilities/utilities';
import { CalcCriteriaPolicySchema, FileLevelSettings, isValidDate } from 'utilities/validation/schemas/jacket-schema';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import CalcCriteriaTab from './CalcCriteriaTab';

interface ReportsAndPayDrawerProps {
  openDrawer: boolean;
  setOpenDrawer: (isOpen: boolean) => void;
  drawerData: any;
  isFromFile?: boolean;
  setDrawerData: any;
  getUpdatedData?: any;
  setFileTab?: any;
  rateAndFeeExecute: any;
  hasViewPayRight?: boolean;
  onReport?: () => void;
  disableFields?: boolean;
  loadFileData?: (fileProps: getFileInfoProps, callback?: () => void) => void;
}
type ApiStatus = 'idle' | 'pending' | 'success' | 'error';
interface IDrawerData {
  showBackDropOnDrawer?: boolean;
  rateAndFeeStatus?: ApiStatus;
  calcCriteriaStatus?: ApiStatus;
  payAndInvoiceStatus?: ApiStatus;
  noRateAndFeeExists?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabWrap: {
      borderBottom: `1px solid #e5e5e5`,
      width: '105%',
      margin: '-46px 0 0 -24px',
    },
    tabText: {
      textTransform: 'none',
      fontSize: pxToRem(14),
    },
    switch: {
      marginTop: '8px',
      marginBottom: '8px',
      marginLeft: '8px',
      justifyContent: 'left',
      '& span': {
        fontWeight: 'bold',
      },
    },
    standAlone: {
      marginTop: '12px',
    },
    tabsRoot: {
      marginLeft: theme.spacing(3),
      '& .MuiTab-root': {
        padding: theme.spacing(2),
        '&.Mui-selected .MuiTypography-root': {
          fontWeight: 600,
          color: theme.palette.text.primary,
        },
      },
    },
    noProducts: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      height: '300px',
      gap: '24px',
      flexDirection: 'column',
      width: '100%',
      marginTop: '16px',
    },
    text: {
      fontWeight: 500,
      fontSize: '16px',
    },
    subtext: {
      fontWeight: 400,
      fontSize: '14px',
      color: '#666',
      lineHeight: '20px',
      marginTop: '8px',
    },
    actions: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      gap: '16px',
    },
    priceOverridenMsg: {
      fontStyle: 'italic',
    },
    remittedProductMessage: {
      margin: '8px 10px 8px 8px',
    },
    notesHeading: {
      fontWeight: 'bold',
      margin: '8px 10px 16px 8px',
      width: '100%',
    },
    notesContainer: {
      marginBottom: '28px',
    },
    notesMsg: {
      margin: '8px 10px 0 8px',
    },
    alertContainer: {
      marginBottom: '62px',
    },
    alertMsg: {
      width: '-webkit-fill-available !important',
      maxWidth: 'unset !important',
      position: 'absolute',
      bottom: '49px',
      left: 0,
      padding: '6px 24px',
      zIndex: 3,
      borderTop: '1px solid #c4c4c4',
      borderRadius: 0,
    },
    bold: {
      fontWeight: 500,
    },
    autoReport: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: theme.spacing(2, 0),
    },
  }),
);

const ADMIN_ROLE_ID = 3;
const OBO_ROLE_ID = 5;

const ReportsAndPayDrawer = ({
  openDrawer,
  setOpenDrawer,
  drawerData,
  setDrawerData,
  getUpdatedData,
  setFileTab,
  isFromFile = false,
  rateAndFeeExecute,
  onReport,
  hasViewPayRight = false,
  disableFields = false,
  loadFileData,
}: ReportsAndPayDrawerProps): JSX.Element => {
  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const { fileData } = fileDataCtx;
  const history = useHistory();
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(drawerData.tab);
  const [fileName, setfileName] = useState(drawerData.name);
  const [isDiscardError, setDiscardError] = useState(false);
  const [isFormValid, setFormValid] = useState(false);
  const [isUnqueue, setUnqueue] = useState(false);
  const [reportDetails, setReport] = useState({
    isReport: false,
    fromReport: false,
  });
  const [showRemittedProduct, setShowRemittedProduct] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [endorsementsList, setEndorsementsList] = useState<Endorsement[]>([]);
  const [isCalcCriteriaDataUpdated, setCalcCriteriaDataUpdated] = useState(false);
  const [isCalcCriteriaDiscard, setCalcCriteriaDiscard] = useState(false);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [updateData, setUpdateData] = useState<boolean>(false);
  const [errMsgs, setErrMsgs] = useState([]);
  const [rateAndFeeData, setRateAndFeeData] = useState(_.cloneDeep(drawerData?.rateAndFeeData ?? []));
  const [calcCriteriaData, setCalcCriteriaData] = useState(_.cloneDeep(drawerData?.calcCriteriaData ?? []));
  const additionalQuestionRef = useRef<any>({});

  // for validation of calc criteria tab
  const [validationError, setValidationError] = useState<FieldValidationError[]>([]);
  const [fileLevelValidationError, setFileLevelValidationError] = useState<FieldValidationError[]>([]);

  const [showCalcCriteriaValidation, setCalcCriteriaValidation] = useState(false);

  const { getAccessToken } = useAuth();
  const { addGlobalMsg } = useGlobalMessages();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { profile, userFirm } = profileCtx;
  const hideReportsAndPay = useMemo(() => {
    const payRights = profile?.activityRights?.find(
      (rights) => rights.ActivityRightId === 61 || rights.ActivityRightId === 83,
    );
    const remitRights = profile?.activityRights?.find((rights) => rights.ActivityRightId === 33);
    return isFromFile && !remitRights ? !payRights || !remitRights : !payRights && remitRights;
  }, [profile?.activityRights, isFromFile]);

  const reportSelectedFiles = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    const payload: { files: string[] } = { files: [drawerData.fileId] };
    return await ReportFiles(payload, token, 'report');
  };
  const {
    execute: executeReportData,
    status: executeReportDataStatus,
    value: reportResults,
    errors: reportErrors,
  } = useAsync<any>(reportSelectedFiles, false);

  const updateRateTypesAndStatCode = (
    policyDetails: PolicyField[],
    policyFields: PolicyField,
    rateTypes: RateTypes[],
  ) => {
    if (policyFields.name === 'RateType') {
      policyFields.options = rateTypes.map((rateTypeOption: RateTypes) => {
        return {
          text: rateTypeOption.rateTypeName,
          value: String(rateTypeOption.rateTypeId),
        };
      });

      const ifValueExists = policyFields.options.find(
        (currentOption: PolicyFieldOption) => currentOption.value === policyFields.value,
      );
      const isDefaultExist = policyFields.options.find(
        (currentOption: PolicyFieldOption) => currentOption.text === 'Basic',
      );
      if (!ifValueExists) {
        policyFields.value = isDefaultExist ? isDefaultExist.value : '';
      }
    }

    if (policyFields.name === 'StatCode') {
      const rateType = policyDetails.find((x: PolicyField) => x.name === 'RateType');
      policyFields.value = filterStatCode(policyFields, rateType).newValue;
    }
  };

  const fetchRateTypes = async (payload: SingleRateTypePayload, fields: PolicyField[]) => {
    try {
      setDrawerData((prev: IDrawerData) => ({ ...prev, showBackDropOnDrawer: true }));
      const token = await getAccessToken();
      const { formNumber, stateCode, policyEffectiveDate, isExtendedCoverage, countyName, accountNumber } = payload;
      if (formNumber != '') {
        const result = await getRateTypes(
          formNumber,
          stateCode,
          policyEffectiveDate,
          isExtendedCoverage,
          countyName,
          accountNumber,
          token,
        );

        fields.forEach((item: PolicyField) => {
          updateRateTypesAndStatCode(fields, item, result);
        });
      }
    } finally {
      setDrawerData((prev: IDrawerData) => ({ ...prev, showBackDropOnDrawer: false }));
    }
  };

  const fetchBulkRateTypes = async (payload: BulkRateTypePayload, policy: Policy[]) => {
    try {
      setDrawerData((prev: IDrawerData) => ({ ...prev, showBackDropOnDrawer: true }));
      const token = await getAccessToken();
      const result = await getBulkRateTypes(token, payload);

      result.forEach((item: BulkRateTypeResponse) => {
        policy.forEach((policyDetails: Policy) => {
          if (policyDetails.formNumber === String(item.formNumber)) {
            policyDetails.fields.forEach((policyFields: PolicyField) => {
              updateRateTypesAndStatCode(policyDetails.fields, policyFields, item.rateTypes);

              if (policyFields.name === 'IsExtendedCoverage') {
                policyFields.isChecked = item.isExtendedCoverage;
                policyFields.value = String(item.isExtendedCoverage);
              }
            });
          }
        });
      });
    } finally {
      setDrawerData((prev: IDrawerData) => ({ ...prev, showBackDropOnDrawer: false }));
    }
  };

  const saveRateAndFees = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    const payload = { fileId: drawerData.fileId, rateFeeLineItems: rateAndFeeData?.rateFeeLineItems };
    return await calculateReports(token, 'rates-fees', payload);
  };

  const {
    execute: executeCalculate,
    status: executeCalculateStatus,
    value: calculateResults,
    errors: calculateErrors,
  } = useAsync<any>(saveRateAndFees, false);

  const saveCalcCriteria = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await calculateReports(token, 'calc-criteria', calcCriteriaData);
  };

  const {
    execute: executeCalcCriteria,
    status: executeCalcCriteriaStatus,
    value: calcCriteriaResults,
    errors: calcCriteriaErrors,
  } = useAsync<any>(saveCalcCriteria, false);

  const toggleAutoReport = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    const payload: { files: string[]; onHold: boolean } = {
      files: [drawerData?.fileId],
      onHold: !rateAndFeeData?.isAutoReportOnHold,
    };
    return await setAutoReport(token, payload);
  };

  const {
    execute: executeAutoReportToggle,
    status: executeAutoReportToggleStatus,
    value: autoReportToggleResults,
    errors: autoReportToggleErrors,
  } = useAsync<any>(toggleAutoReport, false);

  useEffect(() => {
    if (calculateResults) {
      setRateAndFeeData(calculateResults);
      setDrawerData((prev: any) => ({
        ...prev,
        rateAndFeeData: {
          propertyState: calculateResults?.propertyState ?? prev?.rateAndFeeData?.propertyState,
          ...calculateResults,
        },
      }));
      if (isDiscardError) {
        setTabValue(1);
      }
    }
  }, [calculateResults]);

  useEffect(() => {
    if (executeAutoReportToggleStatus === 'error') {
      const prevSwitchValue = !rateAndFeeData?.isAutoReportOnHold;
      setRateAndFeeData((prev: any) => ({ ...prev, isAutoReportOnHold: prevSwitchValue }));
    }
    if (autoReportToggleResults && executeAutoReportToggleStatus === 'success') {
      setUpdateData(true);
    }
  }, [executeAutoReportToggleStatus]);

  const isPricingValid = useMemo(() => {
    const hasPriceValid = rateAndFeeData?.isPricingValid || calcCriteriaData?.isPricingValid;
    return hasPriceValid || (rateAndFeeData?.hasRemittedProduct && hasPriceValid);
  }, [rateAndFeeData?.isPricingValid, rateAndFeeData?.hasRemittedProduct, calcCriteriaData?.isPricingValid]);

  const isRemitValid = useMemo(
    () => rateAndFeeData?.isRemitValid || calcCriteriaData?.isRemitValid,
    [rateAndFeeData?.isRemitValid, calcCriteriaData?.isRemitValid],
  );

  const getAdditionalQuestionDetails = (oldData: any, newData: any) => {
    let oldQuestionList = [];
    let newQuestionList = [];

    if (oldData?.jacketRates?.length > 0) {
      oldQuestionList = oldData.jacketRates.map((items: any) => items.questions || []).flat();
      newQuestionList = newData.jacketRates.map((items: any) => items.questions || []).flat();
    }

    if (oldData?.standAloneEndorsementRates?.endorsements) {
      newQuestionList = [...newQuestionList, ...(newData?.standAloneEndorsementRates?.questions ?? [])];
      oldQuestionList = [...oldQuestionList, ...(oldData?.standAloneEndorsementRates?.questions ?? [])];
    }

    if (oldData?.cplRates?.length > 0) {
      oldQuestionList = [...oldQuestionList, ...oldData.cplRates.map((items: any) => items.questions || []).flat()];
      newQuestionList = [...newQuestionList, ...newData.cplRates.map((items: any) => items.questions || []).flat()];
    }

    const oldQuestionIds = oldQuestionList.map((question: any) => question.randomId);
    const newQuestionIds = newQuestionList.map((question: any) => question.randomId);

    const isBothEqual = _.isEqual(oldQuestionIds, newQuestionIds);
    const differenceBtwBoth = _.difference(newQuestionIds, oldQuestionIds);

    return {
      isBothEqual,
      elementIdToScroll: differenceBtwBoth[0],
    };
  };

  const handleScroll = (currentElement: any) => {
    if (currentElement) {
      const headerHeight = 70;
      const rootElement = document.querySelector('[data-testid="drawer-content"]');
      currentElement.scrollIntoView();
      if (rootElement) rootElement.scrollBy({ top: -headerHeight, behavior: 'smooth' });
      additionalQuestionRef.current = {};
      addSnackbarMessage({
        message: `Calculation requires Additional questions to be answered`,
        type: 'error',
      });
    }
  };

  useEffect(() => {
    if (calcCriteriaResults && executeCalcCriteriaStatus === 'success') {
      const { isBothEqual, elementIdToScroll } = getAdditionalQuestionDetails(calcCriteriaData, calcCriteriaResults);
      setCalcCriteriaData(_.cloneDeep(calcCriteriaResults));
      setDrawerData((prev: any) => ({
        ...prev,
        calcCriteriaData: _.cloneDeep(calcCriteriaResults),
      }));

      if (isBothEqual || !calcCriteriaResults.hasUnansweredQuestions) {
        rateAndFeeExecute().then(() => {
          setTabValue(0);
          setUpdateData(true);
        });
      } else {
        const renderTimeout = setTimeout(() => {
          handleScroll(additionalQuestionRef?.current?.[elementIdToScroll as string]);
          clearTimeout(renderTimeout);
        }, 100);
      }
    }
  }, [calcCriteriaResults, executeCalcCriteriaStatus, calcCriteriaResults]);

  const addInQueue = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    const payload: { files: string[] } = { files: [drawerData.fileId] };
    return await addRemoveQueue(token, payload, 'add');
  };
  const removeFromQueue = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    const payload: { files: string[] } = { files: [drawerData.fileId] };
    return await addRemoveQueue(token, payload, 'remove');
  };

  const { addSnackbarMessage } = useSnackBars();

  const fetchEndorsements = async (): Promise<Endorsement[]> => {
    const token = await getAccessToken();
    return getEndorsements(
      drawerData?.calcCriteriaData?.propertyState ?? '',
      drawerData?.calcCriteriaData?.underwriterCode ?? '',
      token,
    );
  };

  const { execute: executeGetEndorsements, value: getEndorsementsValue } = useAsync<Endorsement[]>(
    fetchEndorsements,
    false,
  );

  const {
    execute: executeAddQueue,
    status: executeAddQueueStatus,
    value: queueResult,
    errors: queueErrors,
  } = useAsync<any>(addInQueue, false);

  const {
    execute: executeUnqueue,
    status: executeUnqueueStatus,
    value: unqueueResult,
    errors: unqueueErrors,
  } = useAsync<any>(removeFromQueue, false);

  const closeDrawer = () => {
    if (updateData) {
      getUpdatedData?.();
      setUpdateData(false);
    }
    setUnqueue(false);
    setOpenDrawer(false);
    setDiscardError(false);
    setCalcCriteriaDiscard(false);
    setShowRemittedProduct(false);
    setShowValidation(false);
    setCalcCriteriaValidation(false);
    setValidationError([]);
    setDrawerData({});
    setEndorsementsList([]);
    setRateAndFeeData([]);
    setCalcCriteriaData([]);
    setReport({
      isReport: false,
      fromReport: false,
    });

    if (isFromFile) {
      history.replace(window.location.pathname);
      window.location.pathname.includes('file-info') && loadFileData?.({ fileId: drawerData.fileId });
    }
  };

  const initializeCalcCriteriaData = () => {
    const initialData = _.cloneDeep(drawerData.calcCriteriaData);
    let isDataManuallyUpdated = false;
    if (initialData?.standAloneEndorsementRates?.endorsements) {
      initialData.standAloneEndorsementRates.endorsements.forEach((endorsement: any) => {
        if (endorsement?.fields?.[0] && endorsement?.fields?.[0]?.options?.length === 1) {
          isDataManuallyUpdated = true;
          endorsement.fields[0].value = endorsement.fields[0].options[0].value;
        }
      });
    }

    if (initialData?.jacketRates) {
      initialData.jacketRates.forEach((jacketRate: any) => {
        (jacketRate?.endorsements ?? []).forEach((endorsement: any) => {
          if (endorsement?.fields?.[0] && endorsement?.fields?.[0]?.options?.length === 1) {
            isDataManuallyUpdated = true;
            endorsement.fields[0].value = endorsement.fields[0].options[0].value;
          }
        });
      });
    }

    if (isDataManuallyUpdated) {
      console.warn('Data is updated manually, unsaved changes.');
    }

    setCalcCriteriaData(_.cloneDeep(initialData));
  };

  const handleValueReset = () => {
    if (tabValue === 0) {
      setDiscardError(false);
      setTabValue && setTabValue(1);
      setRateAndFeeData(_.cloneDeep(drawerData.rateAndFeeData));
    } else {
      setCalcCriteriaDiscard(false);
      setFormValid(true);
      setTabValue && setTabValue(0);
      initializeCalcCriteriaData();
    }
  };

  const handleCancel = () => {
    if (isUnqueue) {
      setUnqueue(false);
    } else {
      closeDrawer();
    }
  };

  const randomId = () => (Math.random() + 1).toString(36).substring(7);

  const updateEndorsemnt = (
    endorsements: any,
    newEndorsement: any,
    id: string | null,
    name: string | null,
    amount: number | null,
    reason: string,
  ) => {
    const currentEndorsements = _.cloneDeep(endorsements);
    let updatedEndorsements;
    switch (reason) {
      case 'remove':
      case 'remove-misc': {
        updatedEndorsements = currentEndorsements?.filter((obj: any) => obj.randomId !== id);
        break;
      }
      case 'update-name': {
        updatedEndorsements = currentEndorsements?.map((obj: any) => {
          if (obj.randomId === id) {
            obj.endorsementName = name ?? '';
          }
          return obj;
        });
        break;
      }
      case 'update-amount': {
        updatedEndorsements = currentEndorsements?.map((obj: any) => {
          if (obj.randomId === id) {
            obj.calculatedAmount = amount ?? 0;
          }
          return obj;
        });
        break;
      }
      case 'update-statcode': {
        updatedEndorsements = currentEndorsements?.map((obj: any) => {
          if (obj.randomId === id) {
            obj.fields[0].value = name ?? '';
          }
          return obj;
        });
        break;
      }
      case 'add': {
        updatedEndorsements = [...currentEndorsements, newEndorsement];
        break;
      }
    }
    return updatedEndorsements;
  };

  const validateErrors = async (data: any, index: number) => {
    let errs: FieldValidationError[] = (await doValidate(data, CalcCriteriaPolicySchema)) || [];
    errs = errs.map((x) => {
      if (policyFieldsWithNameInLabel.includes(x.field)) {
        x.message = `${data.policyFields.find((y: any) => y.name === x.field)?.label} ${x.message.split(' ').pop()}`;
      }
      return x;
    });
    setValidationError((prev: any) => {
      const item = [...prev];
      item[index] = errs;
      return item;
    });
  };

  const updateJacketEndorsement = (
    jacketId: string | null,
    id: string | null,
    rflId: number | null,
    name: string | null,
    amount: number | null,
    reason: string,
    type: string,
    starsNumber: string | null = '',
    index: number,
  ) => {
    const oldData = _.cloneDeep(calcCriteriaData);
    const currentEndorsements =
      type === 'incorporatedEndorsements'
        ? oldData.jacketRates[index].incorporatedEndorsements
        : oldData.jacketRates[index].endorsements;

    const isMiscellaneous = reason === 'add' && name === 'Miscellaneous Endorsement';
    const newEndorsement = {
      endorsementFormNumber: isMiscellaneous ? 'MISC. END.' : starsNumber ?? '',
      randomId: randomId(),
      isIncorporated: false,
      endorsementName: isMiscellaneous ? '' : name ?? '',
      calculatedAmount: null,
      endorsementId: null,
      group: null,
      isChecked: null,
      endorsementInputFields: null,
      pricingDetails: null,
      fields: [
        {
          name: 'StatCode',
          label: 'Stat Code',
          value: '',
          dataType: 'select',
          group: '',
          order: 4,
          section: 0,
          isRequired: false,
          isChecked: false,
          isDisabled: false,
          options: [],
        },
      ],
    };

    const updatedEndorsement = updateEndorsemnt(currentEndorsements, newEndorsement, id, name, amount, reason);

    if (type === 'incorporatedEndorsements') {
      oldData.jacketRates[index].incorporatedEndorsements = updatedEndorsement;
    } else {
      oldData.jacketRates[index].endorsements = updatedEndorsement;
    }

    setCalcCriteriaData(oldData);
  };

  const updateSaEndorsement = (
    jacketId: string | null,
    id: string | null,
    rflId: number | null,
    name: string | null,
    amount: number | null,
    reason: string,
    type: string,
    starsNumber: string | null = '',
  ) => {
    const oldData = _.cloneDeep(calcCriteriaData);
    const currentEndorsements = oldData.standAloneEndorsementRates.endorsements;

    const isMiscellaneous = reason === 'add' && name === 'Miscellaneous Endorsement';
    const newEndorsement = {
      id: 0,
      endorsementType: isMiscellaneous ? 'Miscellaneous' : 'Endorsement',
      endorsementName: isMiscellaneous ? '' : name ?? '',
      calculatedAmount: null,
      endorsementFormNumber: isMiscellaneous ? 'MISC. END.' : starsNumber ?? '',
      randomId: randomId(),
      sequenceNumber: null,
      createdBy: null,
      fields: [
        {
          name: 'StatCode',
          label: 'Stat Code',
          value: '',
          dataType: 'select',
          group: '',
          order: 4,
          section: 0,
          isRequired: false,
          isChecked: false,
          isDisabled: false,
          options: [],
        },
      ],
    };

    oldData.standAloneEndorsementRates.endorsements = updateEndorsemnt(
      currentEndorsements,
      newEndorsement,
      id,
      name,
      amount,
      reason,
    );
    setCalcCriteriaData(oldData);
  };

  useEffect(() => {
    const isError =
      executeUnqueueStatus === 'error' ||
      executeAddQueueStatus === 'error' ||
      executeReportDataStatus === 'error' ||
      executeCalculateStatus === 'error' ||
      executeAutoReportToggleStatus === 'error';
    setDisplayError(isError);
  }, [
    executeUnqueueStatus,
    executeAddQueueStatus,
    executeReportDataStatus,
    executeCalculateStatus,
    executeAutoReportToggleStatus,
  ]);

  useEffect(() => {
    if (displayError) {
      const errorMessages = [
        ...(unqueueErrors ?? []),
        ...(reportErrors ?? []),
        ...(queueErrors ?? []),
        ...(calculateErrors ?? []),
        ...(autoReportToggleErrors ?? []),
      ];
      setErrMsgs(errorMessages);
    }
  }, [displayError]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [errMsgs]);

  useEffect(() => {
    if (executeReportDataStatus === 'success') {
      closeDrawer();
      if (reportDetails.fromReport) {
        addSnackbarMessage({
          message: `File Reported: ${drawerData.name}`,
          type: 'success',
        });
        if (hasViewPayRight) {
          history.push('/accounting/remittance/pay');
        } else {
          onReport?.();
          getUpdatedData?.();
        }
      } else if (reportDetails.isReport) {
        history.push({
          pathname: `/accounting/remittance/pay/invoice-summary`,
          state: {
            localFirmID: drawerData?.eventData?.firmId,
            selectedRows: [
              {
                fileOrderIds: reportResults?.fileOrderIds,
                ...drawerData.eventData,
                underwriter: drawerData?.calcCriteriaData?.underwriterCode ?? '',
                getPaymentData: true,
              },
            ],
          },
        });
      }
    }
  }, [executeReportDataStatus]);

  useEffect(() => {
    if (executeCalculateStatus === 'success') {
      addSnackbarMessage({
        message: `Save Successful`,
        type: 'success',
      });
      if (!isFromFile) {
        setUpdateData(true);
      }
    }
  }, [executeCalculateStatus]);

  useEffect(() => {
    if (queueResult) {
      getUpdatedData && getUpdatedData();
      closeDrawer();
      addSnackbarMessage({
        message: `File was queued`,
        type: 'success',
      });
    }
  }, [queueResult]);

  useEffect(() => {
    if (unqueueResult) {
      getUpdatedData && getUpdatedData();
      closeDrawer();
      addSnackbarMessage({
        message: `File was removed from queue`,
        type: 'success',
      });
    }
  }, [unqueueResult]);

  useEffect(() => {
    if (drawerData.tab !== undefined) {
      setTabValue(drawerData.tab);
    }
  }, [drawerData.tab]);

  useEffect(() => {
    if (drawerData.name !== undefined) {
      setfileName(drawerData.name);
    }
  }, [drawerData.name]);

  useEffect(() => {
    if (drawerData.rateAndFeeData !== undefined) {
      setRateAndFeeData(_.cloneDeep(drawerData.rateAndFeeData));
    }
  }, [drawerData.rateAndFeeData]);

  useEffect(() => {
    if (drawerData.calcCriteriaData !== undefined) {
      initializeCalcCriteriaData();
      // if (drawerData?.calcCriteriaData?.underwriterCode && drawerData?.calcCriteriaData?.propertyState)
      //   executeGetEndorsements().then();
    }
  }, [drawerData.calcCriteriaData]);

  useEffect(() => {
    if (getEndorsementsValue) {
      setEndorsementsList([
        ...getEndorsementsValue,
        {
          endorsementName: 'Miscellaneous Endorsement',
          endorsementFormNumber: 'Miscellaneous Endorsement',
          policyCategories: [],
          policyCoverages: [],
        },
      ]);
    }
  }, [getEndorsementsValue]);

  useEffect(() => {
    if (drawerData.calcCriteriaData != undefined && calcCriteriaData !== undefined) {
      setCalcCriteriaDiscard(false);
      const originalData = _.cloneDeep(drawerData.calcCriteriaData);
      const newData = _.cloneDeep(calcCriteriaData);
      const cleanOriginalData = removeEmpty(originalData);
      const cleanNewData = removeEmpty(newData);
      const isDataSame = _.isEqual(cleanOriginalData, cleanNewData);
      setCalcCriteriaDataUpdated(!isDataSame);
    }
  }, [drawerData.calcCriteriaData, calcCriteriaData]);

  const onChangeProduct = (
    val: any,
    rateFeeLineItemId: string,
    field: string,
    childId?: string,
    nestedChildId?: string,
  ) => {
    if (childId) {
      if (!nestedChildId) {
        const newObj = rateAndFeeData.rateFeeLineItems.map((obj: any) => {
          if (obj.rateFeeLineItemId === rateFeeLineItemId && obj.additionalFees) {
            const target = obj.additionalFees.find((e: any) => e.rateFeeLineItemId === childId);
            const source = {
              ...target,
              [field]: val,
            };
            if (field === 'grossReported') {
              source.isGrossOverridden = !(target.grossCalculated === source.grossReported);
            }
            if (field === 'netReported') {
              source.isNetOverridden = !(target.netCalculated === source.netReported);
            }
            source.isError = source.netReported > source.grossReported;

            const newProducts = obj.additionalFees.map((item: any) =>
              item.rateFeeLineItemId === childId ? source : item,
            );
            return {
              ...obj,
              additionalFees: newProducts,
            };
          }
          return obj;
        });
        setRateAndFeeData({ ...rateAndFeeData, rateFeeLineItems: [...newObj] });
      }
      if (nestedChildId) {
        const newObj = rateAndFeeData.rateFeeLineItems.map((parent: any) => {
          if (parent.rateFeeLineItemId === rateFeeLineItemId && parent.additionalFees) {
            const child = parent.additionalFees.find(
              (e: any) => e.rateFeeLineItemId === childId && e.additionalFees && e.additionalFees.length,
            );
            const grandChildren = child.additionalFees.map((ch: any) => {
              if (ch.rateFeeLineItemId === nestedChildId) {
                if (field === 'grossReported') {
                  ch.isGrossOverridden = !(ch.grossCalculated === val);
                  ch.isError = ch.netReported > val;
                }
                if (field === 'netReported') {
                  ch.isNetOverridden = !(ch.netCalculated === val);
                  ch.isError = val > ch.grossReported;
                }
                return {
                  ...ch,
                  [field]: val,
                };
              }
              return ch;
            });
            const newChild = {
              ...child,
              additionalFees: grandChildren,
            };
            const newChildren = parent.additionalFees.map((item: any) =>
              item.rateFeeLineItemId === childId ? newChild : item,
            );
            return {
              ...parent,
              additionalFees: newChildren,
            };
          }
          return parent;
        });
        setRateAndFeeData({ ...rateAndFeeData, rateFeeLineItems: [...newObj] });
      }
    } else {
      const newObj = rateAndFeeData.rateFeeLineItems.map((obj: any) => {
        if (obj.rateFeeLineItemId === rateFeeLineItemId) {
          if (field === 'grossReported') {
            obj.isGrossOverridden = !(obj.grossCalculated === val);
            obj.isError = obj.netReported > val;
          }
          if (field === 'netReported') {
            obj.isNetOverridden = !(obj.netCalculated === val);
            obj.isError = val > obj.grossReported;
          }
          return {
            ...obj,
            [field]: val,
          };
        }
        return obj;
      });
      setRateAndFeeData({ ...rateAndFeeData, rateFeeLineItems: [...newObj] });
    }
  };

  const onClear = (rateFeeLineItemId: any, field: string, childId?: any, nestedChildId?: string) => {
    if (childId) {
      if (!nestedChildId) {
        const newObj = rateAndFeeData.rateFeeLineItems.map((obj: any) => {
          if (obj.rateFeeLineItemId === rateFeeLineItemId && obj.additionalFees) {
            const target = obj.additionalFees.find((e: any) => e.rateFeeLineItemId === childId);
            const source = {
              ...target,
            };
            if (field === 'grossReported') {
              source.isGrossOverridden = false;
              source.grossReported = target.grossCalculated;
            }
            if (field === 'netReported') {
              source.isNetOverridden = false;
              source.netReported = target.netCalculated;
            }
            const newProducts = obj.additionalFees.map((item: any) =>
              item.rateFeeLineItemId === childId ? source : item,
            );
            return {
              ...obj,
              additionalFees: newProducts,
            };
          }
          return obj;
        });
        setRateAndFeeData({ ...rateAndFeeData, rateFeeLineItems: [...newObj] });
      }
      if (nestedChildId) {
        const newObj = rateAndFeeData.rateFeeLineItems.map((parent: any) => {
          if (parent.rateFeeLineItemId === rateFeeLineItemId && parent.additionalFees) {
            const child = parent.additionalFees.find(
              (e: any) => e.rateFeeLineItemId === childId && e.additionalFees && e.additionalFees.length,
            );
            const grandChildren = child.additionalFees.map((ch: any) => {
              if (ch.rateFeeLineItemId === nestedChildId) {
                if (field === 'grossReported') {
                  ch.isGrossOverridden = false;
                  ch.grossReported = ch.grossCalculated;
                }
                if (field === 'netReported') {
                  ch.isNetOverridden = true;
                  ch.netReported = ch.netCalculated;
                }
              }
              return ch;
            });
            const newChild = {
              ...child,
              additionalFees: grandChildren,
            };
            const newChildren = parent.additionalFees.map((item: any) =>
              item.rateFeeLineItemId === childId ? newChild : item,
            );
            return {
              ...parent,
              additionalFees: newChildren,
            };
          }
          return parent;
        });
        setRateAndFeeData({ ...rateAndFeeData, rateFeeLineItems: [...newObj] });
      }
    } else {
      const newObj = rateAndFeeData.rateFeeLineItems.map((obj: any) => {
        if (obj.rateFeeLineItemId === rateFeeLineItemId) {
          if (field === 'grossReported') {
            obj.isGrossOverridden = false;
            obj.grossReported = obj.grossCalculated;
          }
          if (field === 'netReported') {
            obj.isNetOverridden = false;
            obj.netReported = obj.netCalculated;
          }
          return {
            ...obj,
          };
        }
        return obj;
      });
      setRateAndFeeData({ ...rateAndFeeData, rateFeeLineItems: [...newObj] });
    }
  };

  const omitKeysRecursively: any = (obj: any, keys: any) => {
    return _.isObject(obj) ? _.mapValues(_.omit(obj, keys), (value) => omitKeysRecursively(value, keys)) : obj;
  };

  const hasOverrideFees = (data: any) => {
    const keysToIgnore = ['isGrossOverridden', 'isNetOverridden', 'isError', 'isAutoReportOnHold'];
    const oldData = omitKeysRecursively(_.cloneDeep(drawerData.rateAndFeeData), keysToIgnore);
    const newData = omitKeysRecursively(_.cloneDeep(data), keysToIgnore);
    return !_.isEqual(oldData, newData);
  };

  const hasNetError = (subfees: any) => {
    if (rateAndFeeData?.propertyState === 'GA' && ![ADMIN_ROLE_ID, OBO_ROLE_ID].includes(profile?.roleId ?? 0)) {
      return false;
    }

    for (const subfee of subfees) {
      if (subfee.isError) {
        return true;
      }

      if (subfee.additionalFees && subfee.additionalFees.length > 0) {
        if (hasNetError(subfee.additionalFees)) {
          return true;
        }
      }
    }
    return false;
  };

  const isPriceOverriden = useMemo(
    () => hasOverrideFees(rateAndFeeData ?? []),
    [rateAndFeeData, drawerData.rateAndFeeData],
  );
  const grossTotal = useMemo(() => calculateTotals(rateAndFeeData?.rateFeeLineItems ?? [], false), [rateAndFeeData]);

  const showCalculateButton = useMemo(() => {
    if (!isPricingValid || (isPricingValid && !isRemitValid)) {
      return isPricingValid;
    }
    return hasOverrideFees(rateAndFeeData ?? []);
  }, [rateAndFeeData, drawerData?.rateAndFeeData, isPriceOverriden, isPricingValid, isRemitValid]);

  useEffect(() => {
    setDiscardError(false);
    setUnqueue(false);
    setReport({
      isReport: false,
      fromReport: false,
    });
  }, [rateAndFeeData]);

  const handleQueue = () => {
    executeAddQueue().then();
  };

  const handleUnqueueConfirmation = () => {
    setUnqueue(true);
    setDiscardError(false);
  };

  const handleReport = () => {
    setReport({
      isReport: false,
      fromReport: true,
    });
  };

  const isMoreInfoRequired = useMemo(() => isPricingValid && !isRemitValid, [isPricingValid, isRemitValid]);
  const isOnlyCPLExist = calcCriteriaData?.cplRates?.length > 0 && calcCriteriaData?.jacketRates?.length === 0;
  const showProblemIcon = !isPricingValid || isMoreInfoRequired || (!isFormValid && showCalcCriteriaValidation);

  const toShowActionItem =
    tabValue === 0 &&
    isPricingValid &&
    !isUnqueue &&
    !(reportDetails.isReport || reportDetails.fromReport) &&
    !isDiscardError &&
    !showCalculateButton &&
    !showProblemIcon;

  const hideActionItems = isFromFile
    ? toShowActionItem && profile?.activityRights?.find((rights) => rights.ActivityRightId === 33)
    : toShowActionItem;

  const toShowQueueUnqueue = drawerData?.rateAndFeeData?.isRemitValid ?? false;

  const actionItems: ReactNode = (
    <>
      {!disableFields && hideActionItems && drawerData.resource !== 'order-management' && (
        <>
          <AgentNetButton
            color="primary"
            data-testid="RatesAndFeesReport"
            onClick={handleReport}
            variant="outlined"
            id="report-action"
            size="small"
          >
            Report
          </AgentNetButton>
          {toShowQueueUnqueue && drawerData.resource !== 'order-management' && (
            <>
              {tabValue === 0 &&
                drawerData.rateAndFeeData.isMyQueue === false &&
                drawerData.rateAndFeeData.isFirmQueue === false && (
                  <AgentNetButton
                    onClick={handleQueue}
                    data-testid="RatesAndFeesQueue"
                    color="primary"
                    variant="outlined"
                    id="queue-action"
                    size="small"
                  >
                    Queue
                  </AgentNetButton>
                )}
              {tabValue === 0 && (drawerData.rateAndFeeData.isMyQueue || drawerData.rateAndFeeData.isFirmQueue) && (
                <AgentNetButton
                  onClick={handleUnqueueConfirmation}
                  data-testid="RatesAndFeesRemoveQueue"
                  color="primary"
                  variant="outlined"
                  id="unqueue-action"
                  size="small"
                >
                  Remove from Queue
                </AgentNetButton>
              )}
            </>
          )}
        </>
      )}
    </>
  );

  const primaryActionLabel = React.useMemo(() => {
    if (tabValue === 1) {
      return 'Save & Calculate';
    }
    if (!showCalculateButton) {
      if (isUnqueue) return 'Yes, Remove';
      if (reportDetails.isReport || reportDetails.fromReport) return 'Yes, Report';
    }
    if (showCalculateButton || isDiscardError) {
      return 'Calculate';
    }
    return 'Report & Pay';
  }, [reportDetails.isReport, reportDetails.fromReport, isUnqueue, isDiscardError, showCalculateButton, tabValue]);

  const renderMessage = useCallback(
    (msg: string, severity: 'warning' | 'error' = 'warning', action?: React.ReactNode) => (
      <>
        <div className={classes.alertContainer}></div>
        <Alert className={classes.alertMsg} {...(action && { action })} severity={severity}>
          {msg}
        </Alert>
      </>
    ),
    [],
  );

  const goToJacket = () => {
    closeDrawer();
    setFileTab?.(5);
    history.push(`/files/${drawerData.fileId}/jackets`);
  };

  const warningMessages = () => {
    if (rateAndFeeData?.hasPendingProduct) {
      const action: React.ReactNode = (
        <IconButton aria-label="close" color="inherit" size="small" onClick={goToJacket}>
          <Typography className={classes.bold}>Create Jacket</Typography>
        </IconButton>
      );

      return renderMessage(
        'File contains pending products and cannot be reported.  Please resolve.',
        'warning',
        action,
      );
    }

    if (isMoreInfoRequired && !isOnlyCPLExist) {
      const action: React.ReactNode = (
        <IconButton aria-label="close" color="inherit" size="small" onClick={goToCalcCriteria}>
          <Typography className={classes.bold}>Enter Required Info</Typography>
        </IconButton>
      );

      return renderMessage(
        'Rates calculated successfully, but additional information is required for reporting.',
        'warning',
        action,
      );
    }

    if (isMoreInfoRequired && isOnlyCPLExist) {
      const action: React.ReactNode = (
        <IconButton aria-label="close" color="inherit" size="small" onClick={goToJacket}>
          <Typography className={classes.bold}>Add New Jacket</Typography>
        </IconButton>
      );

      return renderMessage('At least one remittable product is required to price a file.', 'warning', action);
    }

    if (primaryActionLabel === 'Calculate' && showValidation && hasNetError(rateAndFeeData?.rateFeeLineItems ?? [])) {
      return renderMessage('Net Values cannot exceed gross, please update before calculating.', 'error');
    }

    if (reportDetails.isReport || reportDetails.fromReport) {
      if (drawerData.rateAndFeeData?.propertyState === 'NY')
        return renderMessage(
          `I, ${profile?.firstName} ${profile?.lastName}, of ${userFirm?.name} hereby certify that the Title Premium(s) were properly calculated and found to be accurate. I further certify that only permitted, proper fees and other charges have been made. I further certify that I have provided clear notice to the insured and/or borrower, as required, that the lender or other party may require the performance of additional services which are not necessary services in connection with the issuance of the title policy, and the issuance of the title policy is not dependent upon the performance of such additional services.`,
        );
      return renderMessage(`Reporting 1 file for ${dollarize(grossTotal.net)}. Do you want to continue?`);
    }

    if (isUnqueue) {
      return renderMessage('Removing file from Queue. Do you want to continue?');
    }

    if (isDiscardError) {
      const action: React.ReactNode = (
        <IconButton
          aria-label="close"
          color="inherit"
          data-testid="RatesAndFeesDiscardChanges"
          size="small"
          onClick={handleValueReset}
        >
          <Typography className={classes.bold}>Discard Changes</Typography>
        </IconButton>
      );

      return renderMessage('You have unsaved changes on this page.', 'warning', action);
    }

    if (isPriceOverriden) {
      return renderMessage('Amounts have changed, please recalculate to continue.');
    }
    if (drawerData.eventData?.isReopened) {
      return renderMessage(
        'Resubmitted files will not be eligible for payment until an invoice is generated, to ensure proper credits are applied.',
        'warning',
      );
    }
  };

  const filterStatCode = (statCode: any, rateType: any) => {
    let selectedOptions = [];
    const options = statCode?.options;
    if (rateType.value !== '') {
      selectedOptions = options?.filter((x: any) => x.rateTypeValue === rateType.value);
      if (selectedOptions.length == 0) {
        selectedOptions = options;
      }
    }
    const filteredOption = selectedOptions?.map((x: any, i: any) => {
      return { name: x.text, value: x.value };
    });
    let newValue = '';
    if (filteredOption?.length === 1) {
      newValue = filteredOption[0].value;
    } else {
      newValue = filteredOption.filter((x: any) => x.value === statCode.value)?.[0]?.value ?? '';
    }
    return { newValue, filteredOption };
  };

  const updateCalcCriteriaData = async (
    type: string,
    key: string,
    val: any,
    index?: any,
    subIndex?: any,
    isCheckBox = false,
  ) => {
    const obj = _.cloneDeep(calcCriteriaData);

    if (type === 'updateFileLevelSettings') {
      obj.fields = obj.fields.map((item: any) => {
        if (item.name === key) {
          return { ...item, value: val };
        }
        return item;
      });

      // logic for 'RateEffectiveDate' change
      if (key === 'RateEffectiveDate') {
        const payload: BulkRateTypePayload = {
          accountNumber: obj.accountNumber,
          state: obj.propertyState,
          county: obj.propertyCounty,
          effectiveDate: formatDate(val),
          jackets: obj.jacketRates.map((item: Policy) => {
            return {
              formNumber: item.formNumber,
              isExtendedCoverage:
                item.fields.find((x: PolicyField) => x.name === 'IsExtendedCoverage')?.isChecked ?? false,
            };
          }),
        };
        if (payload.effectiveDate) {
          await fetchBulkRateTypes(payload, obj.jacketRates);
        }
      }
    }

    if (type === 'updatePolicySettings' && obj?.jacketRates?.[index]?.fields) {
      obj.jacketRates[index].fields = obj.jacketRates[index].fields.map((item: any, fieldIndex: any) => {
        if (item.name === key && fieldIndex === subIndex) {
          return { ...item, value: val, ...(isCheckBox ? { isChecked: val === true, value: String(val) } : {}) };
        }
        return item;
      });

      if (key === 'IsExtendedCoverage') {
        obj.jacketRates[index].fields.forEach((item: any) => {
          if (item.name === 'RateType') {
            const isNewOptionExist =
              item.options.findIndex((rate: any) => rate.isExtendedCoverage === val && rate.value === item.value) !==
              -1;
            item.value = isNewOptionExist ? item.value : '';
          }
        });
      }
      if (key === 'RateType') {
        obj.jacketRates[index].fields.forEach((item: any) => {
          if (item.name === 'StatCode') {
            const rateType = obj.jacketRates[index].fields.filter((x: any) => x.name === 'RateType')[0];
            item.value = filterStatCode(item, rateType).newValue;
          }
        });
      }

      if (key === 'PolicyDate') {
        const ifRateEffectiveDateExists = obj.fields.filter((x: PolicyField) => x.name === 'RateEffectiveDate')[0]
          .value;
        if (!ifRateEffectiveDateExists) {
          const payload: SingleRateTypePayload = {
            formNumber: obj.jacketRates[index].formNumber,
            stateCode: obj.propertyState,
            policyEffectiveDate: formatDate(val),
            isExtendedCoverage:
              obj.jacketRates[index].fields.find((x: PolicyField) => x.name === 'IsExtendedCoverage')?.isChecked ??
              false,
            countyName: obj.propertyCounty,
            accountNumber: obj.accountNumber,
          };
          if (payload.policyEffectiveDate) {
            await fetchRateTypes(payload, obj.jacketRates[index].fields);
          }
        }
      }
    }

    if (type === 'updateCPLSettings' && obj?.cplRates?.[index]?.fields?.[0]) {
      obj.cplRates[index].fields[0].value = val;
    }

    if (type === 'updateQuestions' && obj?.[key]?.[index]?.questions?.[subIndex]) {
      obj[key][index].questions[subIndex].value = val;
    }
    if (type === 'updateStandAloneQuestions' && obj?.[key]?.questions?.[index]) {
      obj[key].questions[index].value = val;
    }
    setCalcCriteriaData(obj);
  };

  const validateFileLevelSetting = async (field: any) => {
    const errs: FieldValidationError[] = (await doValidate(field, FileLevelSettings)) || [];
    setFileLevelValidationError(errs);
  };

  useEffect(() => {
    if (calcCriteriaData?.jacketRates) {
      calcCriteriaData.jacketRates.forEach((policy: any, index: number) => {
        const updatedPolicy = { ...policy };
        const rateType = updatedPolicy.fields.filter((item: any) => item.name === 'RateType')[0];
        const currentOption = rateType.options.filter((item: any) => item.value === rateType.value)[0];
        const validateExtraFields = currentOption?.text?.toLowerCase()?.includes('reissue') ?? false;

        if (!validateExtraFields) {
          const fieldsToBeIgnore = ['PriorLiabilityAmount', 'PriorPolicyDate'];
          updatedPolicy.fields = updatedPolicy.fields.filter((item: any) => !fieldsToBeIgnore.includes(item.name));
        }
        validateErrors(updatedPolicy, index);
      });
    }
  }, [calcCriteriaData.jacketRates]);

  useEffect(() => {
    if (calcCriteriaData.fields) {
      const fields: any = {};
      calcCriteriaData.fields.forEach((field: any) => {
        fields[field.name] = {
          name: field.name,
          label: field.label,
          dataType: field.dataType,
          value: field.value,
          options: field.options ?? [],
          isRequired: field.isRequired ?? false,
        };

        if (field.name === 'RateEffectiveDate') {
          const policyDate: string[] = [];
          calcCriteriaData.jacketRates.forEach((jacket: any) => {
            const currentPolicyDate = jacket.fields.filter((field: any) => field.name === 'PolicyDate')[0];
            if (currentPolicyDate) {
              policyDate[policyDate.length] = currentPolicyDate.value;
            }
          });

          const ifAnyDateInvalid = policyDate.some(
            (currentDate: any) => !currentDate || !isValidDate(new Date(currentDate)),
          );

          if (policyDate.length > 0 && !ifAnyDateInvalid) {
            fields[field.name].isRequired = Array.from(new Set(policyDate)).length !== 1;
          }
        }
      });
      validateFileLevelSetting(fields);
    }
  }, [calcCriteriaData.jacketRates, calcCriteriaData.fields]);

  const renderCalculateCriteriaMessage = () => {
    if (isCalcCriteriaDataUpdated && isCalcCriteriaDiscard) {
      const action: React.ReactNode = (
        <IconButton
          aria-label="close"
          color="inherit"
          data-testid="RatesAndFeesDiscardChanges"
          size="small"
          onClick={handleValueReset}
        >
          <Typography className={classes.bold}>Discard Changes</Typography>
        </IconButton>
      );

      return renderMessage('You have unsaved changes on this page.', 'warning', action);
    }

    if (!isFormValid && showCalcCriteriaValidation) {
      return renderMessage('Missing fields above are required to calculate and report.', 'error');
    }
  };

  const handleDrawerTabChange = (_e: any, v: any) => {
    if (drawerData.noRateAndFeeExists) {
      setTabValue && setTabValue(v);
      return;
    }
    if (v === 1) {
      if (tabValue === 0 && isPriceOverriden) {
        setDiscardError(true);
        setUnqueue(false);
        setReport({
          isReport: false,
          fromReport: false,
        });
      } else {
        setDiscardError(false);
        setTabValue && setTabValue(v);
      }
    } else {
      if (tabValue === 1 && isCalcCriteriaDataUpdated) {
        setCalcCriteriaDiscard(true);
      } else {
        setCalcCriteriaDiscard(false);
        setTabValue && setTabValue(v);
      }
    }
  };

  const primaryQaAttribute = React.useMemo(() => {
    if (tabValue === 1) {
      return 'CalcCriteriaCalculate';
    }
    if (isUnqueue && !showCalculateButton) {
      return 'RatesAndFeesYesRemove';
    }
    if (!showCalculateButton) {
      if (isUnqueue) return 'RatesAndFeesYesRemove';
      if (reportDetails.isReport) return 'RatesAndFeesYesReport';
      if (reportDetails.fromReport) return 'ReportsYesReport';
    }
    if (showCalculateButton || isDiscardError) {
      return 'RatesAndFeesCalculate';
    }
    return 'RatesAndFeesReport&Pay';
  }, [isUnqueue, isDiscardError, showCalculateButton, tabValue]);

  const calculateRateAndFees = () => {
    if (hasNetError(rateAndFeeData?.rateFeeLineItems ?? [])) {
      setShowValidation(true);
      setUnqueue(false);
      setReport({
        isReport: false,
        fromReport: false,
      });
      setDiscardError(false);
    } else {
      executeCalculate().then();
    }
  };

  const handlePrimaryAction = () => {
    if (tabValue === 1) {
      setCalcCriteriaValidation(true);
      if (isFormValid) {
        executeCalcCriteria().then();
      }
    } else if (primaryActionLabel === 'Yes, Report') {
      executeReportData().then();
    } else if (primaryActionLabel === 'Calculate') {
      calculateRateAndFees();
    } else if (isUnqueue) {
      executeUnqueue().then();
    } else if (primaryActionLabel === 'Report & Pay') {
      setReport({
        isReport: true,
        fromReport: false,
      });
    } else {
      closeDrawer();
    }
  };

  const goToStandAlone = () => {
    setTabValue(1);
    setDrawerData((prev: any) => ({
      ...prev,
      noRateAndFeeExists: false,
      calcCriteriaData: {
        ...prev.calcCriteriaData,
        standAloneEndorsementRates: {
          endorsements: [],
          fields: [],
          questions: [],
        },
      },
    }));
    setCalcCriteriaData((prev: any) => ({
      ...prev,
      standAloneEndorsementRates: {
        endorsements: [],
        fields: [],
        questions: [],
      },
    }));
  };

  const goToCpl = () => {
    closeDrawer();
    setFileTab?.(2);
    history.push(`/files/${drawerData.fileId}/cpl`);
  };

  const goToCalcCriteria = () => {
    handleDrawerTabChange(null, 1);
  };
  const showLoader =
    (tabValue === 1 && executeCalcCriteriaStatus === 'pending') ||
    (tabValue === 0 &&
      ((executeReportDataStatus === 'pending' && primaryActionLabel === 'Yes, Report') ||
        (executeCalculateStatus === 'pending' && primaryActionLabel === 'Calculate')));
  const hideRateAndFeeTable = !isPricingValid || (!isFormValid && showCalcCriteriaValidation);
  const isPricingNotValid = tabValue === 0 && hideRateAndFeeTable;

  const handleToggle = () => {
    const newSwitchValue = !rateAndFeeData?.isAutoReportOnHold;
    setRateAndFeeData((prev: any) => ({ ...prev, isAutoReportOnHold: newSwitchValue }));
    executeAutoReportToggle().then();
  };

  useEffect(() => {
    const toggleButtonStatus =
      rateAndFeeData?.hasRemittedProduct &&
      rateAndFeeData?.remittedLineItems?.length > 0 &&
      rateAndFeeData?.rateFeeLineItems?.length === 0;
    setShowRemittedProduct(toggleButtonStatus);
  }, [rateAndFeeData?.hasRemittedProduct, tabValue]);

  const showRemittedProductToggle = rateAndFeeData?.hasRemittedProduct && rateAndFeeData?.remittedLineItems?.length > 0;

  const rateAndFeesNode: ReactNode = (
    <>
      {drawerData.rateAndFeeStatus === 'loading' && (
        <div style={{ marginTop: 8 }}>
          <LoadingSpinner variant="circle" status={'pending'} />
        </div>
      )}
      {drawerData.rateAndFeeStatus === 'success' && (
        <>
          {hideRateAndFeeTable ? (
            <div className={classes.noProducts}>
              <NoRatesIcon />
              <Grid>
                <Typography className={classes.text}>
                  Rates cannot be calculated. Please add required information.
                </Typography>
              </Grid>
              <Grid>
                <AgentNetButton
                  data-testid="RatesAndFeesAddCalcCriteria"
                  onClick={goToCalcCriteria}
                  color="primary"
                  variant="contained"
                  size="small"
                >
                  Add Calculation Criteria
                </AgentNetButton>
              </Grid>
            </div>
          ) : (
            <>
              <div className={classes.autoReport}>
                <Typography
                  className={classes.priceOverridenMsg}
                  style={{ visibility: isPriceOverriden ? 'visible' : 'hidden' }}
                  variant="body2"
                  color="textSecondary"
                >
                  User entered amounts are highlighted
                </Typography>

                {rateAndFeeData?.isAutoReportingEnabled ? (
                  <AgentNetCheckBox
                    label="Exclude from Auto-Reporting"
                    value={rateAndFeeData?.isAutoReportOnHold}
                    checkHandler={handleToggle}
                    disabled={
                      fileData?.fileStatus !== orderStatuses['open'] || executeAutoReportToggleStatus === 'pending'
                    }
                  />
                ) : null}
              </div>
              <ReportedFeesTable
                onChangeProduct={onChangeProduct}
                onClear={onClear}
                products={rateAndFeeData?.rateFeeLineItems ?? []}
                displayNet={rateAndFeeData?.displayNet ?? true}
                showValidation={showValidation}
                qaPrefix="RateAndFees"
                disableFields={disableFields}
              />
              {rateAndFeeData?.notes && (
                <div {...(showRemittedProductToggle && { className: classes.notesContainer })}>
                  <Typography className={classes.notesHeading}>Notes</Typography>
                  {rateAndFeeData?.notes.map((notes: string, index: any) => (
                    <Typography key={index} className={classes.notesMsg}>
                      {notes}
                    </Typography>
                  ))}
                </div>
              )}

              {showRemittedProductToggle && (
                <>
                  <div>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showRemittedProduct ?? false}
                          onChange={() => {
                            setShowRemittedProduct((prev) => !prev);
                          }}
                          name="Reported Products"
                          size="small"
                        />
                      }
                      label="Reported Products"
                      labelPlacement="start"
                      value={showRemittedProduct ?? false}
                      className={classes.switch}
                    />
                    {showRemittedProduct && (
                      <Typography className={classes.remittedProductMessage}>
                        Refer to the table below for a complete list of all previously reported products in this file.
                      </Typography>
                    )}
                  </div>
                  {showRemittedProduct && (
                    <ReportedFeesTable
                      onChangeProduct={onChangeProduct}
                      products={rateAndFeeData?.remittedLineItems ?? []}
                      displayNet={rateAndFeeData?.displayNet ?? true}
                      showValidation={false}
                      qaPrefix="RateAndFeesRemittedProduct"
                      disableFields={true}
                      hasRemittedProduct={true}
                    />
                  )}
                </>
              )}
              {!disableFields && warningMessages()}
            </>
          )}
        </>
      )}
    </>
  );
  const calculatedNode: ReactNode = (
    <>
      {drawerData.calcCriteriaStatus === 'loading' && (
        <div style={{ marginTop: 8 }}>
          <LoadingSpinner variant="circle" status={'pending'} />
        </div>
      )}
      {drawerData.calcCriteriaStatus === 'success' && (
        <>
          <CalcCriteriaTab
            updateJacketEndorsement={updateJacketEndorsement}
            endorsementsList={endorsementsList}
            calcCriteriaData={calcCriteriaData}
            updateCalcCriteriaData={updateCalcCriteriaData}
            updateSaEndorsement={updateSaEndorsement}
            filterStatCode={filterStatCode}
            additionalQuestionRef={additionalQuestionRef}
            shouldShowValidation={showCalcCriteriaValidation}
            validationErrors={validationError}
            setFormValid={setFormValid}
            fileLevelValidationError={fileLevelValidationError}
            disableFields={disableFields}
            savedJacketData={drawerData?.calcCriteriaData?.jacketRates ?? []}
          />
          {!disableFields && renderCalculateCriteriaMessage()}
        </>
      )}
    </>
  );

  const { hasPermission } = useNavPermissions();

  return (
    <FormDrawerComponent
      title={`File No: ${fileName}`}
      open={openDrawer}
      width={960}
      disableEnforceFocus
      primaryActionProps={{
        disabled:
          ((drawerData.eventData?.isReopened || grossTotal.net === 0) && primaryActionLabel === 'Report & Pay') ||
          drawerData.noRateAndFeeExists ||
          showLoader,
        loading: showLoader,
        'data-testid': primaryQaAttribute,
      }}
      dismissActionProps={{
        'data-testid': 'RatesAndFeesCancel',
      }}
      crossQAPrefix="RatesAndFees"
      primaryActionLabel={primaryActionLabel}
      primaryActionTootip={
        primaryActionLabel === 'Report & Pay' && grossTotal.net === 0
          ? 'You can Report $0.00 but you cannot pay $0.00.'
          : ''
      }
      isPaddingRightZero
      hidePrimaryAction={
        (tabValue === 1 && drawerData.eventData?.isReopenedByProduct && !isCalcCriteriaDataUpdated) ||
        (drawerData.calcCriteriaStatus === 'loading' && tabValue === 1) ||
        (tabValue === 0 && isMoreInfoRequired) ||
        disableFields ||
        isPricingNotValid ||
        drawerData.noRateAndFeeExists ||
        (hideReportsAndPay && primaryActionLabel === 'Report & Pay') ||
        (drawerData.resource === 'order-management' && primaryActionLabel === 'Report & Pay')
      }
      onPrimaryAction={handlePrimaryAction}
      onDismissAction={handleCancel}
      actionItems={actionItems}
      showBackDropOnDrawer={drawerData.showBackDropOnDrawer ?? false}
    >
      {
        <>
          <Paper square elevation={0} className={classes.tabWrap}>
            <Tabs
              value={parseInt((tabValue || 0) as unknown as string)}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleDrawerTabChange}
              className={classes.tabsRoot}
            >
              <Tab
                label={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" className={classes.tabText}>
                      Rates & Fees
                    </Typography>
                  </div>
                }
                data-testid="RatesAndFeesTab"
              />
              <Tab
                data-testid="CalcCriteriaTab"
                label={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" className={classes.tabText}>
                      Calc. Criteria
                    </Typography>
                    {showProblemIcon && <ReportProblemOutlined style={{ marginLeft: 5 }} />}
                  </div>
                }
              />
            </Tabs>
          </Paper>

          {drawerData.noRateAndFeeExists ? (
            drawerData.calcCriteriaStatus === 'loading' || drawerData.rateAndFeeStatus === 'loading' ? (
              <div style={{ marginTop: 8 }}>
                <LoadingSpinner variant="circle" status={'pending'} />
              </div>
            ) : (
              <div className={classes.noProducts}>
                <NoProducts />
                {disableFields ? (
                  <Grid>
                    <Typography className={classes.text}>No Products Found</Typography>
                  </Grid>
                ) : (
                  <>
                    <Grid>
                      <Typography className={classes.text}>No Products Found</Typography>
                      <Typography className={classes.subtext}>
                        At least one remittable product is required to price a file.
                      </Typography>
                    </Grid>
                    <Grid>
                      <Grid className={classes.actions}>
                        <AgentNetButton
                          onClick={goToCpl}
                          data-testid="RatesAndFeesAddNewCPL"
                          color="primary"
                          variant="contained"
                          size="small"
                        >
                          Add New CPL
                        </AgentNetButton>
                        {hasPermission.jackets && (
                          <AgentNetButton
                            onClick={goToJacket}
                            data-testid="RatesAndFeesAddNewJacket"
                            color="primary"
                            variant="contained"
                            size="small"
                          >
                            Add New Jacket
                          </AgentNetButton>
                        )}
                      </Grid>
                      <AgentNetButton
                        onClick={goToStandAlone}
                        className={classes.standAlone}
                        data-testid="RatesAndFeesAddSAEndo"
                        variant="text"
                        size="small"
                      >
                        Add Standalone Endorsement
                      </AgentNetButton>
                    </Grid>
                  </>
                )}
              </div>
            )
          ) : tabValue === 0 ? (
            rateAndFeesNode
          ) : (
            calculatedNode
          )}
        </>
      }
    </FormDrawerComponent>
  );
};

export default ReportsAndPayDrawer;
