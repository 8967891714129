import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  AutorenewRounded,
  CloudUploadOutlined,
  DeleteOutline,
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@material-ui/icons';
import { useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';

interface SignatureUploaderProps {
  acceptTypes?: Record<string, string[]> | undefined;
  maxSize: number;
  onImageUpload: (imageData: string) => void;
}

const SignatureUploader = ({ acceptTypes = {}, maxSize = 0, onImageUpload }: SignatureUploaderProps): JSX.Element => {
  const [image, setImage] = useState<File | null>(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const editorRef = useRef<any>();
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      dropzone: {
        width: '674px',
        border: '1px dashed #c4c4c4',
        borderRadius: '4px',
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        transition: '.3s all',
        '&:hover': {
          backgroundColor: theme.palette.background.default,
        },
      },
      sigContent: {
        border: '1px solid #D9E0E5',
        padding: 20,
      },
      blueLink: {
        color: theme.palette.primary.main,
        padding: '0 4px',
      },
      mb0: {
        marginBottom: 0,
      },
      mr2: {
        marginRight: theme.spacing(2),
        marginBottom: '-6px',
      },
      mb1: {
        width: '336px',
        height: '24px',
        gap: '24px',
        opacity: '0',
      },
    }),
  );

  const classes = useStyles();

  const handleDrop = (acceptedFiles: File[]) => {
    if (maxSize > 0) {
      if (maxSize > 0 && acceptedFiles.length > 0 && acceptedFiles[0].size <= (maxSize ?? 70) * 1024 * 1024) {
        setImage(acceptedFiles[0]);
        updateImage();
      }
    } else if (maxSize == 0) {
      setImage(acceptedFiles[0]);
      updateImage();
    }
  };

  const handleRemoveImage = () => {
    setImage(null);
    setScale(1);
    setRotate(0);
  };

  const handleResetImage = () => {
    setScale(1);
    setRotate(0);
    updateImage();
  };

  const handleZoomIn = () => {
    setScale(scale + 0.1);
    updateImage();
  };

  const handleZoomOut = () => {
    setScale(scale - 0.1);
    updateImage();
  };

  const handleRotateLeft = () => {
    setRotate(rotate - 10);
    updateImage();
  };

  const handleRotateRight = () => {
    setRotate(rotate + 10);
    updateImage();
  };

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result && typeof reader.result === 'string') {
          const img = new Image();
          img.src = reader.result;
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const padding = 70; // Add padding to the image
            canvas.width = img.width + padding * 2;
            canvas.height = img.height + padding * 2;
            const ctx = canvas.getContext('2d');
            if (ctx) {
              ctx.fillStyle = '#fff'; // Optional: Set background color if needed
              ctx.fillRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(img, padding, padding);
              const dataUrl = canvas.toDataURL();
              onImageUpload(dataUrl);
            }
          };
        }
      };
      reader.readAsDataURL(image);
    }
  }, [image]);
  const updateImage = () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      const padding = 70; // Add padding to the image
      const newCanvas = document.createElement('canvas');
      newCanvas.width = canvas.width + padding * 2;
      newCanvas.height = canvas.height + padding * 2;
      const ctx = newCanvas.getContext('2d');
      if (ctx) {
        ctx.fillStyle = '#fff'; // Optional: Set background color if needed
        ctx.fillRect(0, 0, newCanvas.width, newCanvas.height);
        ctx.drawImage(canvas, padding, padding);
        const dataUrl = newCanvas.toDataURL();
        onImageUpload(dataUrl);
      }
    }
  };
  useEffect(() => {
    if (rotate || scale) {
      updateImage();
    }
  }, [scale, rotate]);

  return (
    <Grid>
      <div>
        <Grid>
          {image ? (
            <Grid className={classes.sigContent}>
              <AvatarEditor
                image={image}
                ref={editorRef}
                width={680}
                height={185}
                border={0}
                scale={scale}
                rotate={rotate}
              />
            </Grid>
          ) : (
            ''
          )}
          {!image ? (
            <Grid>
              <Dropzone onDrop={handleDrop} maxFiles={1} accept={acceptTypes}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className={classes.dropzone}>
                    <input {...getInputProps()} />
                    {/* <p>Drag & drop an image here, or click to select one (max {maxSize}MB)</p> */}

                    <Typography variant="body2" color="textPrimary" className={classes.mb0}>
                      <CloudUploadOutlined htmlColor="#666666" className={classes.mr2} />
                      Drag &amp; Drop or <span className={classes.blueLink}>Click to Upload</span> a File
                    </Typography>
                    <Typography variant="caption" color="textSecondary" style={{ paddingTop: '6px' }}>
                      Accepted File Type: {Object.values(acceptTypes).flat().join(', ').toUpperCase()}
                    </Typography>
                    {maxSize > 0 ? (
                      <Typography variant="caption" color="textSecondary">
                        Maximum upload file size: {maxSize} MB
                      </Typography>
                    ) : (
                      <div></div>
                    )}
                  </div>
                )}
              </Dropzone>
            </Grid>
          ) : (
            ''
          )}
        </Grid>
        {image && (
          <div style={{ marginTop: '20px', marginLeft: '20px' }}>
            <Grid container spacing={2}>
              <Grid container spacing={1} justify="space-between">
                <Grid item>
                  <AgentNetButton
                    variant="text"
                    startIcon={<DeleteOutline />}
                    onClick={handleRemoveImage}
                    color="primary"
                    size="small"
                  >
                    Remove Image
                  </AgentNetButton>
                  <AgentNetButton
                    variant="text"
                    startIcon={<AutorenewRounded />}
                    onClick={handleResetImage}
                    color="primary"
                    size="small"
                  >
                    Reset Adjustments
                  </AgentNetButton>
                </Grid>
                <Grid item>
                  <AgentNetButton
                    variant="text"
                    startIcon={<RotateLeftOutlined />}
                    onClick={handleRotateLeft}
                    style={{ marginRight: '-20px' }}
                    color="primary"
                    size="small"
                  />
                  <AgentNetButton
                    variant="text"
                    startIcon={<RotateRightOutlined />}
                    onClick={handleRotateRight}
                    style={{ marginRight: '-20px' }}
                    color="primary"
                    size="small"
                  />
                  <AgentNetButton
                    variant="text"
                    startIcon={<ZoomInOutlined />}
                    onClick={handleZoomIn}
                    style={{ marginLeft: '20px' }}
                    color="primary"
                    size="small"
                  />
                  <AgentNetButton
                    variant="text"
                    startIcon={<ZoomOutOutlined />}
                    onClick={handleZoomOut}
                    style={{ marginLeft: '-20px' }}
                    color="primary"
                    size="small"
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </Grid>
  );
};

export default SignatureUploader;
