import { pxToRem } from '@fluentsms/agentnet-web-components';
import { makeStyles } from '@material-ui/core/styles';
import './usersList.scss';

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: '#fff',
    color: ' #423d3d',
    fontSize: pxToRem(13),
    padding: '5px',
    whiteSpace: 'nowrap',
    border: '1px solid lightgrey',
  },
});

type LoginUserNameRendererProps = {
  loginUserId?: string;
  handleEditClick: () => void;
};

const LoginUserNameRenderer = ({ loginUserId, handleEditClick }: LoginUserNameRendererProps) => {
  const classes = useStyles(); // Declare the 'classes' variable
  return (
    <div data-testid={loginUserId} onClick={handleEditClick} className="loginUserId">
      {loginUserId}
    </div>
  );
};

export default LoginUserNameRenderer;
