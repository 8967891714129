import { amber, green, grey, indigo, lightGreen, pink, purple, red, yellow } from '@material-ui/core/colors';

export function* getMemberNames(enumType: any): IterableIterator<string> {
  for (const enumMember in enumType) {
    if (Number.isNaN(Number(enumMember))) {
      yield enumMember;
    }
  }
}

export function getKeyByValue(enumType: any, value: any): string | null {
  for (const enumMember in enumType) {
    if (Number.isNaN(Number(enumMember)) && value == enumType[enumMember]) {
      return enumMember;
    }
  }
  return null;
}

function getColorFromHex(hexColor: string): number {
  return parseInt(hexColor.replace('#', '0x'));
}

export enum ActivityRights {
  Admin = 138,
  View = 139,
  BookOrder = 140,
}

export enum FileStatus {
  New = 2920,
  Hold = 2921,
  'In Process' = 2922,
  'Review/Research' = 2923,
  'Pending Action' = 2924,
  'Pending Agent Response' = 2925,
  'Pending Escrow Response' = 2926,
  Reconciled = 2927,
  Closed = 2928,
}

export enum FileStatusColor {
  New = getColorFromHex(lightGreen[600]),
  Hold = getColorFromHex(red[600]),
  'In Process' = getColorFromHex(green[600]),
  'Review/Research' = getColorFromHex(yellow[600]),
  'Pending Action' = getColorFromHex(amber[600]),
  'Pending Agent Response' = getColorFromHex(purple[600]),
  'Pending Escrow Response' = getColorFromHex(pink[600]),
  Reconciled = getColorFromHex(indigo[600]),
  Closed = getColorFromHex(grey[600]),
}

export const defaultFscTeam = {
  id: 3001,
  team: 'IFSC',
  role: 'FSC',
};

export enum TeamAssignment {
  IFSC = 3001,
  PFSC = 3002,
  CRS = 3003,
  //CIU = 3004,
}

// handling numeric enum iterating
export function getTeamAssignmentIds(): number[] {
  const teamAssignmentIds: number[] = [];
  for (const value of Object.values(TeamAssignment)) {
    if (typeof value === 'number') {
      teamAssignmentIds.push(value);
    }
  }
  return teamAssignmentIds;
}

export enum ReasonCode {
  'QA' = 2970,
  'Missing Inventory' = 2971,
  'Inventory Issue' = 2972,
  'Missing File Info' = 2973,
  'Account Management (FKA OAM)' = 2974,
  'NCS' = 2975,
  'Multi-issue' = 2976,
  'KY Tax' = 2977,
  'Co-Insurance' = 2978,
}

export function getColorsByStatus(): { [status: string]: string } {
  const colors: { [status: string]: string } = {};
  const fileStatusColors = getMemberNames(FileStatusColor);
  let fileStatusColor = fileStatusColors.next();
  while (!fileStatusColor.done) {
    const colorCode = FileStatusColor[fileStatusColor.value as keyof typeof FileStatusColor];
    colors[fileStatusColor.value] = '#' + colorCode.toString(16).padStart(6, '0');
    fileStatusColor = fileStatusColors.next();
  }
  return colors;
}
