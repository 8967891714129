import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Address,
  categorizeOrderInsightRisks,
  getParentSeverity,
  PropertyOrderInsightsProps,
  RiskCategory,
  toFullAddress,
} from 'api/file/interfaces/get-file-order-insights';
import clsx from 'clsx';
import React from 'react';
import OrderInsightRisks from './OrderInsightRisks';
import OrderInsightsRiskIcon from './OrderInsightsRiskIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      display: 'flex',
      flex: '1',
      paddingLeft: theme.spacing(1),
    },
    brdBtm: {
      borderBottom: '1px solid #E9E9E9',
      padding: theme.spacing(2, 0),
      pageBreakInside: 'avoid',
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    labelCol: {
      minWidth: '25%',
      maxWidth: '25%',
      marginRight: theme.spacing(3),
    },
    flexGrow: {
      flex: '1',
    },
    ml1: {
      margin: theme.spacing(0, 0, 0, 1),
    },
    mb1: {
      marginBottom: theme.spacing(1),
    },
    minWidth: {
      marginRight: theme.spacing(1),
      minWidth: '100px',
      display: 'inline-block',
      fontWeight: 500,
    },
    danger: {
      background: '#FDECEA',
      color: '#B3150A',
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
      '& a': {
        color: '#B3150A',
      },
    },
    warning: {
      background: '#FDF4E7',
      color: '#C77700',
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
      '& a': {
        color: '#C77700',
      },
    },
    flexDisplay: {
      display: 'flex',
    },
    success: {
      background: '#EFF7EE',
      color: '#3B873E',
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    flex8: {
      fontSize: '9pt',
      display: 'flex',
    },
    flex14: {
      fontSize: '14px',
      display: 'flex',
    },
    f8: {
      fontSize: '9pt',
    },
    f14: {
      fontSize: '14px',
    },
  }),
);

const OwnersMailingAddress: React.FC<PropertyOrderInsightsProps> = ({ property, isPdf }) => {
  const { alertRisks, warningRisks, unknownRisks, errorRisks } = categorizeOrderInsightRisks(
    property.risks,
    RiskCategory.OwnerAddr,
  );
  const classes = useStyles();
  const recordedAddress = () => {
    const address = new Address();
    address.address1 = property.ownerInformation?.streetAddress;
    address.city = property.ownerInformation?.city;
    address.stateOrProvince = property.ownerInformation?.state;
    address.postalCode = property.ownerInformation?.zip9;
    return toFullAddress(address);
  };

  return (
    <div className={classes.brdBtm}>
      <div className={classes.flex}>
        <div className={classes.labelCol}>
          <span className={classes.flexCenter}>
            <OrderInsightsRiskIcon
              isPdf={isPdf}
              riskSeverity={getParentSeverity(property.risks, RiskCategory.OwnerAddr)}
            />
            <Typography
              variant="h4"
              color="textSecondary"
              className={clsx(classes.ml1, isPdf ? classes.f8 : classes.f14)}
            >
              Owner&apos;s Mailing Address:
            </Typography>
          </span>
        </div>
        <div className={classes.flexGrow}>
          <Typography variant="body1" className={(classes.mb1, isPdf ? classes.flex8 : classes.flex14)}>
            <strong className={classes.minWidth}>On File:</strong>
            <span>
              {property.onFileOwnerAddressWithPartyName &&
                property.onFileOwnerAddressWithPartyName.map((x, index) => (
                  <span key={index} style={{ display: 'block' }}>
                    {x}
                  </span>
                ))}
            </span>
          </Typography>
          <Typography variant="body1" className={(classes.mb1, isPdf ? classes.f8 : classes.f14)}>
            <strong className={classes.minWidth}>Recorded:</strong>
            {recordedAddress()}
          </Typography>
          <OrderInsightRisks
            isPdf={isPdf}
            alerts={alertRisks}
            warnings={warningRisks}
            unknowns={unknownRisks}
            errors={errorRisks}
          />
        </div>
      </div>
    </div>
  );
};

export default OwnersMailingAddress;
