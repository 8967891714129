import { useAuth } from '@agentnet/auth';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Footer from 'core/footer/footer';
import IdlePrompter from 'core/IdlePrompter';
import ProductFruitsFullStory from 'core/layout-core/core-content/ProductFruitsFullStory';
import LeftNav from 'core/navbar/LeftNav';
import {
  AccountingLinks,
  AdminLinks,
  DashboardLinks,
  FilesLinks,
  OrderManagementInfoPanelLinks,
  OrderManagementLinks,
  ReportsLinks,
  ServicesLinks,
} from 'core/navbar/LeftNavLinks';
import NavFileInfo from 'features/files/FileSnapshotLeftNav';
import HelpLayout from 'features/help/components/Main/HelpLayout/HelpLayout';
import { ContentfulErrorProvider } from 'features/help/hooks/ContentfulErrorContext';
import DefaultOrderManagementPanel from 'features/orderManagement/DefaultOrderManagementPanel';
import OrderInfoPanel from 'features/orderManagement/OrderInfoPanel';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import { useNavPermissions } from 'hooks/useNavPermissions';
import { useViewState } from 'hooks/ViewStateContext';
import { DefaultNewFile } from 'models/file-model';
import { useContext, useEffect, useState } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import GlobalHeader from '../header/header';
import { NavLinkType } from '../navbar/NavLinkType';
import { RouteType } from './Router';

// A wrapper for <Route> that handles "sub"-routes by passing them as
// `routes` prop to the component it renders.

const RouteWithSubRoutes = (route: RouteType & { isNested?: boolean }): JSX.Element => {
  const auth = useAuth();
  const useStyles = makeStyles(() =>
    createStyles({
      routeRoot: {
        display: 'flex',
        flex: `1 0 auto`,
        backgroundColor: !route.isNested ? '#FAFAFA' : 'transparent',
        width: '100%',
        height: 'auto',
      },
      loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Full viewport height
        width: '100vw', // Full viewport width
        position: 'fixed', // Optional: Use if you want the loader to be fixed on the screen
      },
    }),
  );
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [refreshKey, setRefreshKey] = useState('0');

  useEffect(() => {
    if (!route.isPublic && !auth.isAuthenticated()) {
      auth.login().then();
    }
  }, []);
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};

  const { userFirm, profileStatus } = profileCtx;

  const { viewState, setViewState, menuCollapsed, setMenuCollapse, navState, setNavState } = useViewState();
  const {
    hasPermission,
    prismUserProfile,
    fileData,
    setFileData,
    setRatesAndFeeDrawerClicked,
    isFormDirty,
    setOpenRatesAndFeeDrawer,
    openRatesAndFeeDrawer,
    setOpenSaveModal,
  } = useNavPermissions();

  // TODO: Fix the router. This is a hack because the router is not working appropriately.
  // Helper function to merge nav states and remove non-existing links
  const mergeNavStates = (existingNavState: NavLinkType[], newNavState: NavLinkType[]) => {
    const mergedState = existingNavState.filter((existingLink) =>
      newNavState.some((newLink) => newLink.path === existingLink.path),
    );

    newNavState.forEach((newLink) => {
      const existingLinkIndex = mergedState.findIndex((link) => link.path === newLink.path);
      if (existingLinkIndex !== -1) {
        // Only update the expanded property if necessary
        mergedState[existingLinkIndex] = {
          ...mergedState[existingLinkIndex],
          expanded: mergedState[existingLinkIndex].expanded,
        };
      } else {
        mergedState.push(newLink);
      }
    });

    return mergedState;
  };

  // TODO: Unwind all of this once the router is fixed.
  useEffect(() => {
    if (!route.path.includes('/files')) {
      window.sessionStorage.removeItem('fileId');
      window.sessionStorage.removeItem('properties');
      if (fileData?.fileId) setFileData?.(DefaultNewFile);
    }
    const pathname = route.location.pathname;
    let newNavState: NavLinkType[] = [];

    // Logic to determine the nav state based on the pathname
    switch (true) {
      case pathname.startsWith('/order-management'):
        if (route.location.state) {
          newNavState = OrderManagementInfoPanelLinks;
        } else {
          newNavState = OrderManagementLinks;
        }
        break;
      case pathname.startsWith('/files'):
        newNavState = FilesLinks;
        break;
      case pathname.startsWith('/accounting') || pathname.startsWith('/invoices') || pathname.startsWith('/statements'):
        newNavState = AccountingLinks;
        break;
      case pathname.startsWith('/reporting'):
        newNavState = ReportsLinks;
        break;
      case pathname.startsWith('/services'):
        newNavState = ServicesLinks;
        break;
      case pathname.startsWith('/dashboards'):
        newNavState = DashboardLinks;
        break;
      case pathname.startsWith('/admin'):
        newNavState = AdminLinks;
        break;
      default:
        newNavState = FilesLinks;
    }
    setNavState(mergeNavStates(navState, newNavState));
  }, [route.path, route.location.state]);

  useEffect(() => {
    const currentState = location?.state as { refreshComponent?: boolean };
    if (currentState?.refreshComponent) {
      setRefreshKey(`${Math.random()}${userFirm?.firmId}`);
      window.history.replaceState({}, '');
    } else {
      setRefreshKey(`${userFirm?.firmId}`);
    }
  }, [location?.state, userFirm?.firmId]);

  const onViewSwitchAdmin = () => {
    setViewState('admin/user');
    history.push('/admin/user/profile');
  };

  const onViewSwitchPortal = () => {
    setViewState('portal');
    history.push('/files');
  };

  return (
    <>
      {(auth.isAuthenticated() || route.isPublic) && (
        <>
          <ProductFruitsFullStory />
          <IdlePrompter />
          <Route
            path={route.path}
            render={(props) => {
              // pass the sub-routes down to keep nesting4
              if (route.layout === 'AuthLayout') {
                return (
                  <>
                    <div className={clsx(classes.routeRoot, 'routeRoot')}>
                      <GlobalHeader
                        onViewSwitch={onViewSwitchAdmin}
                        onPortalSwitch={onViewSwitchPortal}
                        currentView={viewState}
                        hasPermission={hasPermission}
                        hideNav={!hasPermission.hasFirmId}
                      />
                      {route.location.pathname !== '/files' &&
                        route.location.pathname !== '/searchFirms' &&
                        (hasPermission.hasFirmId || hasPermission.orderManagement) && (
                          <LeftNav
                            menuCollapsed={menuCollapsed}
                            setMenuCollapse={setMenuCollapse}
                            navState={navState}
                            setNavState={setNavState}
                            pathParams={route}
                            hasPermission={hasPermission}
                            userFirm={userFirm}
                            prismUserProfile={prismUserProfile}
                            fileData={fileData}
                            openRatesAndFeeDrawer={openRatesAndFeeDrawer}
                            setRatesAndFeeDrawerClicked={setRatesAndFeeDrawerClicked}
                            setOpenRatesAndFeeDrawer={setOpenRatesAndFeeDrawer}
                            isFormDirty={isFormDirty}
                            setOpenSaveModal={setOpenSaveModal}
                            {...props}
                          >
                            {route.location.pathname.startsWith('/files') && <NavFileInfo />}
                            {hasPermission.orderManagement &&
                              (route.location.pathname == '/order-management' ||
                                route.location.pathname == '/order-management/closed-order-details') &&
                              (route.location.state == null ? <DefaultOrderManagementPanel /> : <OrderInfoPanel />)}
                          </LeftNav>
                        )}

                      {!userFirm?.firmId && profileStatus !== 'success' ? (
                        <div className={classes.loader}>
                          <LoadingSpinner variant="circle" status={profileStatus} />
                        </div>
                      ) : (
                        <route.component key={refreshKey} {...props} routes={route.routes} isNested={route.isNested} />
                      )}
                    </div>
                  </>
                );
              } else if (route.layout === 'PublicLayout') {
                return (
                  <>
                    <GlobalHeader currentView={'public'} hideNav={true} />
                    <div className={clsx(classes.routeRoot, 'routeRoot')}>
                      <route.component {...props} routes={route.routes} isNested={route.isNested} />
                    </div>
                    <Footer />
                  </>
                );
              } else if (route.layout === 'HelpLayout') {
                return (
                  <ContentfulErrorProvider>
                    <HelpLayout>
                      <div className={clsx(classes.routeRoot, 'routeRoot')}>
                        <route.component {...props} routes={route.routes} isNested={route.isNested} />
                      </div>
                    </HelpLayout>
                  </ContentfulErrorProvider>
                );
              } else {
                return (
                  <div className={clsx(classes.routeRoot, 'routeRoot')}>
                    <route.component {...props} routes={route.routes} isNested={route.isNested} />
                  </div>
                );
              }
            }}
          />
        </>
      )}
    </>
  );
};
export default RouteWithSubRoutes;
