import { pxToRem } from '@fluentsms/agentnet-web-components';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import moment from 'moment';
import { formatDate } from './invoicesListConfig';

type InvoiceDateRendererProps = {
  value?: string;
  tab?: number;
};

const useStyles = makeStyles({
  invoiceDateIcon: {
    marginLeft: 10,
    display: 'flex',
  },
  tooltip: {
    backgroundColor: '#fff',
    color: ' #423d3d',
    fontSize: pxToRem(13),
    padding: '5px',
    whiteSpace: 'nowrap',
    border: '1px solid lightgrey',
  },
});

const InvoiceDateRenderer = ({ value, tab }: InvoiceDateRendererProps) => {
  const invoiceDt = moment(value);
  const currDt = moment(new Date());
  const dayDiff = moment.duration(currDt.diff(invoiceDt)).asDays();
  const classes = useStyles(); // Declare the 'classes' variable

  return (
    <>
      {dayDiff > 90 && tab == 0 ? (
        <div className="error-status d-flex-center">
          <Tooltip title={formatDate(value ?? '')} classes={{ tooltip: classes.tooltip }}>
            <span>{formatDate(value ?? '')}</span>
          </Tooltip>
          <span className={classes.invoiceDateIcon}>
            <Tooltip title="3 months old or older invoice." classes={{ tooltip: classes.tooltip }} placement="right">
              <ErrorOutlineIcon />
            </Tooltip>
          </span>
        </div>
      ) : tab == 0 ? (
        <Tooltip title={formatDate(value ?? '')} classes={{ tooltip: classes.tooltip }} placement="right">
          <div>{formatDate(value ?? '')}</div>
        </Tooltip>
      ) : (
        <div>{formatDate(value ?? '')}</div>
      )}
    </>
  );
};

export default InvoiceDateRenderer;
