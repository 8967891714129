import { useAuth } from '@agentnet/auth';
import { Grid, TextField } from '@material-ui/core';
import { format as formatDate } from 'date-fns';
import { useEffect, useState } from 'react';

import FormDrawerComponent from 'ui-kit/components/drawer/FormDrawerComponent';
import { AgentNetTextInput, CurrencyField } from 'ui-kit/inputs';
import AutocompleteSearch from 'ui-kit/inputs/AutocompleteSearch/AutocompleteSearch';
import DateFieldString from 'ui-kit/inputs/DateField/DateFieldString';

import { orderManagementTypeSchema } from 'utilities/validation/schemas/order-management-schema';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';

import { getLockboxNumberApi, updateOrderApi } from 'api/orderManagement/order-management-api';
import useAsync from 'hooks/useAsync';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import './OrderManagement.scss';
import { LockboxNumberType, OrderRequestType, OrderType } from './types';

interface OrderEditContainerProps {
  openDrawer: boolean;
  setOpenDrawer: (isOpen: boolean) => void;
  orderData: OrderType;
}

const OrderEditContainer = ({ openDrawer, setOpenDrawer, orderData }: OrderEditContainerProps): JSX.Element => {
  const [lockboxNumberList, setLockboxNumberList] = useState<Array<LockboxNumberType>>(() => []);
  const [orderDate, setOrderDate] = useState<string | null>();
  const [lockboxNumber, setLockboxNumber] = useState<LockboxNumberType | null>();
  const [referenceNumber, setReferenceNumber] = useState<string>();
  const [checkCount, setCheckCount] = useState<number>();
  const [reportedAmount, setReportedAmount] = useState<number>();
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const { addGlobalMsg } = useGlobalMessages();
  const { getAccessToken } = useAuth();

  const history = useHistory();
  const showValidation = true;

  const formatValidDate = (inputDate: string) => {
    if (inputDate) {
      const date = moment(inputDate);
      return formatDate(date.toDate(), 'MM/dd/yyyy');
    }
    return null;
  };

  const handleCancelEdit = () => {
    setOpenDrawer(false);
  };

  const getLockboxNumber = async (): Promise<Array<LockboxNumberType>> => {
    const token = await getAccessToken();
    const response = await getLockboxNumberApi(token);
    return response.result;
  };

  const getFilteredErrors = (errs: FieldValidationError[], fields: string[]) => {
    if (!validationErrors) {
      setValidationErrors(errs);
    } else if (errs) {
      setValidationErrors((prevErrs: FieldValidationError[]) => {
        return [...prevErrs.filter((err: { field: string }) => !fields.includes(err.field)), ...errs];
      });
    } else {
      setValidationErrors((prevErrs: FieldValidationError[]) => {
        return prevErrs.filter((err: { field: string }) => {
          return !(fields.includes(err.field) && !errs);
        });
      });
    }
  };

  const clearError = (field: string) => {
    if (validationErrors?.some((err) => err.field === field)) {
      setValidationErrors((prevErrs: FieldValidationError[]) => {
        return prevErrs.filter((err: { field: string }) => err.field !== field);
      });
    }
  };

  const updateOrder = async (createOrderRequest: OrderRequestType): Promise<OrderType> => {
    const token = await getAccessToken();
    return await updateOrderApi(createOrderRequest, token);
  };

  const {
    execute: executeUpdateOrder,
    status: executeUpdateOrderStatus,
    value: updatedOrder,
    errors: updatedOrderErrors,
  } = useAsync<OrderType>(updateOrder, false);

  useEffect(() => {
    const fetchLockboxNumbers = async () => {
      const result = await getLockboxNumber();
      setLockboxNumberList(result);
    };
    fetchLockboxNumbers();
  }, []);

  useEffect(() => {
    if (!openDrawer) {
      setOrderDate(formatValidDate(orderData.orderDate));
      setLockboxNumber(orderData.lockboxNumber ?? null);
      setReferenceNumber(orderData.referenceNumber ?? '');
      setCheckCount(orderData.checkCount ?? 0);
      setReportedAmount(orderData.reportedAmount ?? 0);
    }
  });

  useEffect(() => {
    if (executeUpdateOrderStatus === 'success') {
      setOpenDrawer(false);
      const orderResponse = updatedOrder as OrderType;
      orderResponse.firm = {
        firmId: orderData.firm?.firmId || 0,
        firmName: orderData.firm?.firmName || '',
        isActive: orderData.firm?.isActive || false,
      };
      orderResponse.lockboxNumber = {
        name: lockboxNumber?.name ?? '',
        value: lockboxNumber?.value ?? 0,
      };
      history.push('/order-management', { order: orderResponse });
    }

    if (executeUpdateOrderStatus === 'error') {
      updatedOrderErrors?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [executeUpdateOrderStatus]);

  return (
    <FormDrawerComponent
      title={'Edit Order Info'}
      open={openDrawer}
      primaryActionProps={{
        loading: executeUpdateOrderStatus === 'pending',
        disabled: executeUpdateOrderStatus === 'pending',
      }}
      dismissActionProps={{}}
      primaryActionLabel="Save"
      onPrimaryAction={() => {
        doValidate(
          {
            orderDate,
            referenceNumber,
            checkCount,
            firmSearchInput: orderData.firm?.firmName || '',
            lockboxId: lockboxNumber?.value,
          },
          orderManagementTypeSchema,
        ).then((errs: FieldValidationError[]) => {
          getFilteredErrors(errs, ['orderDate', 'referenceNumber', 'checkCount', 'lockboxId']);
          if (!errs) {
            // Set user input to order object
            const updateOrderRequest: OrderType = {
              orderId: orderData.orderId,
              firmId: orderData.firmId || 0,
              orderDate: orderDate || '',
              lockboxId: lockboxNumber?.value,
              referenceNumber: referenceNumber,
              checkCount: checkCount,
              reportedAmount: reportedAmount,
            };
            executeUpdateOrder(updateOrderRequest);
          }
        });
      }}
      onDismissAction={handleCancelEdit}
      testId="scrollable-form-drawer"
      width={600}
    >
      <Grid container spacing={3} className="edit-order-form-container">
        <Grid item xs={12} className="firm-search-form">
          <TextField label="Firm Name:" disabled={true} value={orderData?.firm?.firmName || '-'} fullWidth={true} />
        </Grid>
        <Grid item sm={6}>
          <DateFieldString
            label="Order Date"
            id="order-date"
            required={true}
            data-testid={'OrderManagement-EditOrder-OrderDate'}
            maxDate="12/31/2100"
            value={orderDate}
            error={validationErrors?.some((err) => err.field === 'orderDate') && showValidation}
            errs={validationErrors}
            name="orderDate"
            onChange={(dateVal) => {
              setOrderDate(dateVal || null);
              clearError('orderDate');
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteSearch
            label="Lockbox/Ticket Number"
            name="lockboxNumber"
            required={true}
            data-testid={'OrderManagement-EditOrder-LockboxNumber'}
            hideSearchIcon
            disableFilterOptions={true}
            options={lockboxNumberList}
            noMatchesLabel=""
            value={lockboxNumber?.value}
            error={validationErrors?.some((err) => err.field === 'lockboxId') && showValidation}
            errs={validationErrors}
            helperText={
              validationErrors?.some((err) => err.field === 'lockboxId') && showValidation && 'Lockbox is required.'
            }
            onChange={(event) => {
              const selectedLockbox = lockboxNumberList.find((lockbox) => lockbox.value === event);
              if (selectedLockbox) {
                setLockboxNumber(selectedLockbox);
                clearError('lockboxId');
              } else {
                setLockboxNumber(null);
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <AgentNetTextInput
            variant="outlined"
            fullWidth
            label="Batch/Ticket Reference Number"
            name="referenceNumber"
            id="referenceNumber"
            data-testid={'OrderManagement-EditOrder-ReferenceNumber'}
            errs={validationErrors}
            value={referenceNumber}
            onChange={(event) => {
              setReferenceNumber(event.target.value);
              clearError('referenceNumber');
            }}
            error={validationErrors?.some((err) => err.field === 'referenceNumber') && showValidation}
            helperText={
              validationErrors?.some((err) => err.field === 'referenceNumber') &&
              showValidation &&
              'Invalid Reference Number'
            }
          />
        </Grid>
        <Grid item xs={6}>
          <AgentNetTextInput
            type="number"
            InputProps={{
              inputProps: { min: 0 },
            }}
            variant="outlined"
            fullWidth
            label="Number of Checks"
            name="checkCount"
            id="checkCount"
            data-testid={'OrderManagement-EditOrder-NumberOfChecks'}
            errs={validationErrors}
            value={checkCount}
            onChange={(event) => {
              setCheckCount(Number(event.target.value));
              clearError('checkCount');
            }}
            error={validationErrors?.some((err) => err.field === 'checkCount') && showValidation}
            showValidation={showValidation}
          />
        </Grid>
        <Grid item sm={6}>
          <CurrencyField
            variant="outlined"
            fullWidth
            label={'Reported Total'}
            id="reportedTotal"
            defaultValue={0}
            name={'reportedTotal'}
            errs={validationErrors}
            max={100000000000}
            allowNegative={false}
            data-testid={'OrderManagement-EditOrder-ReportedTotal'}
            value={reportedAmount}
            onChange={(event) => setReportedAmount(Number(event.target.value))}
          />
        </Grid>
      </Grid>
    </FormDrawerComponent>
  );
};

export default OrderEditContainer;
