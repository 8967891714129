import { pxToRem } from '@fluentsms/agentnet-web-components';
import { FormControlLabel, Switch, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import RadioGroup from 'ui-kit/components/radios/RadioGroup';
import { AgentNetTextInput } from 'ui-kit/inputs';
import { paymentSchema } from 'utilities/validation/schemas/payment-schema';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import useSnackBars from '../notification/useSnackbars';
import { paymentOptions, underwriter_acNum, underwriter_code } from './Config';

const useStyles = makeStyles((theme) => ({
  paymentComponent: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    width: '312px',
    [theme.breakpoints.up('lg')]: {
      width: '342px',
    },
  },
  header: {
    padding: theme.spacing(2, 3),
  },
  amountDue: {
    fontSize: pxToRem(23),
    fontWeight: 500,
  },
  amountDueLabel: {
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(3),
    borderTop: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
  },
  wireContent: {},
  checkContent: {},
  ePayContent: {},
  ePayConfirmation: {
    fontSize: pxToRem(14),
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'left',
    marginBottom: '20px',
  },
  mb20: {
    marginBottom: pxToRem(20),
  },
  disclaimer: {
    display: 'block',
    marginBottom: pxToRem(20),
  },
  ePayAutocomplete: {
    height: 'auto !important',
    marginBottom: pxToRem(20),
  },
}));

export interface PaymentComponentProps {
  paymentMethod: string | undefined;
  wireNumber: string | undefined;
  checkNumber: string | undefined;
  email: string | undefined;
  memo: string | undefined;
  ePayId: string | undefined | null;
  ePayType: string | undefined;
  ePayName: string | undefined;
  externalPaymentOptions: string | undefined;
  isPremiumInvoice: boolean;
}
const PaymentComponent = ({
  underwriter,
  amountDue = 0,
  isAmountPaid = false,
  ePayAccounts = [],
  wireNumber,
  checkNumber,
  epayConfirmationNum,
  email,
  memo,
  onSubmit,
  disabled,
  showValidation = false,
  setShowValidation,
  paymentType = 'ePay',
  disableRadioOptions,
  onChange,
  externalPaymentOptions,
  setOpenPaymentDrawer,
  isPremiumInvoice,
  showLoader = false,
  defaultEpayAccount = null,
  totalNoOfFiles = 0,
}: any) => {
  const { addSnackbarMessage } = useSnackBars();
  const addPaymentLink = '';
  const classes = useStyles();
  const dollarize = (str: number) => '$' + str.toLocaleString('en-US', { minimumFractionDigits: 2 });
  const [paymentMethod, setPaymentMethod] = useState(paymentOptions?.find((option) => option.value === paymentType));
  const [wireInstructions, setWireInstructions] = useState(false);
  const [showToasterError, setShowToasterError] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);

  useEffect(() => {
    if (showValidation) {
      setShowToasterError(true);
    }
  }, [showValidation]);

  const [paymentDetails, setPaymentDetails] = useState<PaymentComponentProps>({
    paymentMethod: paymentMethod?.value,
    wireNumber: wireNumber || undefined,
    checkNumber: checkNumber || undefined,
    email: email || undefined,
    memo: memo || undefined,
    ePayId: undefined,
    ePayType: undefined,
    ePayName: undefined,
    externalPaymentOptions: externalPaymentOptions,
    isPremiumInvoice: isPremiumInvoice,
  });

  const [selectedEpayAccount, setSelectedEpayAccount] = React.useState(defaultEpayAccount);

  const submitPressed = (e: any) => {
    e.preventDefault();
    onSubmit && onSubmit(validationErrors?.length);
  };

  useEffect(() => {
    let newEpayAccount: any;

    if (ePayAccounts?.length === 1) {
      newEpayAccount = ePayAccounts[0];
    } else if (defaultEpayAccount?.value) {
      newEpayAccount = ePayAccounts?.find((acc: any) => acc?.value === defaultEpayAccount?.value);
    }

    if (newEpayAccount) {
      setSelectedEpayAccount(newEpayAccount);
      setPaymentDetails((prevDetails) => ({
        ...prevDetails,
        ePayId: newEpayAccount?.value,
        ePayName: newEpayAccount?.name,
        ePayType: newEpayAccount?.type,
      }));
    }
  }, [defaultEpayAccount?.value, ePayAccounts]);

  useEffect(() => {
    setShowValidation && setShowValidation(false);
  }, [paymentMethod?.value]);

  useEffect(() => {
    onChange && onChange(paymentDetails);
    doValidate(paymentDetails, paymentSchema(paymentMethod?.value)).then((errs: FieldValidationError[]) => {
      setValidationErrors(errs);
    });
  }, [paymentDetails, paymentMethod?.value]);

  useEffect(() => {
    if (showToasterError) {
      addSnackbarMessage({
        message: `Payment Failed`,
        type: 'error',
        onClose: () => {
          setShowToasterError(false);
        },
      });
    }
  }, [showToasterError]);

  return (
    <>
      <div className={`${classes.paymentComponent} pf-paymentComponent`}>
        <div className={classes.header}>
          <Typography variant="h6" className={classes.amountDueLabel} color="textSecondary">
            Amount {isAmountPaid ? 'Paid' : 'Due'}
          </Typography>
          <Typography variant="h3" className={classes.amountDue}>
            {dollarize(amountDue)}
          </Typography>
        </div>
        <div className={classes.content}>
          {epayConfirmationNum ? (
            <div className={classes.ePayConfirmation}> Confirmation Number: {epayConfirmationNum} </div>
          ) : null}
          <RadioGroup
            label="Payment Method"
            options={externalPaymentOptions ? externalPaymentOptions : paymentOptions}
            selected={paymentMethod}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              paymentOptions && setPaymentMethod(paymentOptions?.find((option) => option.value === e.target.value));
              const isEpay = e.target.value === 'ePay';
              const isSingleEpayAccount = isEpay && ePayAccounts?.length === 1;
              setPaymentDetails((prevDetails) => {
                const singleAccount = isSingleEpayAccount ? ePayAccounts[0] : {};
                return {
                  ...prevDetails,
                  paymentMethod: e.target.value,
                  wireNumber: '',
                  checkNumber: '',
                  memo: '',
                  ePayId: isSingleEpayAccount ? singleAccount.value : undefined,
                  ePayName: isSingleEpayAccount ? singleAccount.name : undefined,
                  ePayType: isSingleEpayAccount ? singleAccount.type : undefined,
                };
              });

              if (isSingleEpayAccount) {
                setSelectedEpayAccount(ePayAccounts[0]);
              } else {
                setSelectedEpayAccount(null);
              }
            }}
            compact
            className={classes.mb20}
            disabled={disabled || showLoader || disableRadioOptions}
          />

          {paymentMethod && paymentMethod.value === 'Wire' && (
            <div className={classes.wireContent}>
              <form onSubmit={submitPressed} noValidate>
                <Typography variant="body2" color="textSecondary" className={classes.disclaimer}>
                  Click on Submit to send the Payment Transmittal Sheet to remittance@firstam.com. This will also update
                  the generated invoice(s)/order(s) as &quot;Wire in Process&quot; on the Pay Tab.
                </Typography>

                <FormControlLabel
                  data-testid="PaySumWireShowInstructions"
                  control={
                    <Switch
                      checked={wireInstructions}
                      onChange={() => setWireInstructions(!wireInstructions)}
                      name="wire_instructions"
                      value={wireInstructions}
                      size="small"
                    />
                  }
                  label="Show Wire Instructions"
                  value={wireInstructions}
                  className={classes.mb20}
                />
                {wireInstructions && (
                  <div className={classes.mb20} data-testid="PaySumWireInstructions">
                    <Typography variant="h3">{underwriter_code[underwriter] || underwriter}</Typography>
                    <Typography variant="body2">
                      JPMorgan Chase
                      <br />
                      ABA/Routing Number: 021000021
                      <br />
                      A/C Number: {underwriter_acNum[underwriter]}
                      <br />
                      Account Name: First American Title
                      <br />
                      Address: 4 First American Way, Santa Ana, CA 92707
                    </Typography>
                  </div>
                )}
                <div className={classes.mb20}>
                  <AgentNetTextInput
                    data-testid="PaySumWireNumber"
                    variant="outlined"
                    label="Wire Confirmation Number"
                    name="wireNumber"
                    value={paymentDetails.wireNumber}
                    onChange={(e) => {
                      setPaymentDetails({ ...paymentDetails, wireNumber: e.target.value });
                    }}
                    fullWidth
                    required
                    disabled={disabled}
                    // error={errors}
                    showValidation={showValidation}
                    errs={validationErrors}
                  />
                </div>

                <div className={classes.mb20}>
                  <AgentNetTextInput
                    data-testid="PaySumWireEmail"
                    variant="outlined"
                    label="Send Copy To"
                    name="email"
                    value={paymentDetails.email}
                    onChange={(e) => {
                      setPaymentDetails({ ...paymentDetails, email: e.target.value });
                    }}
                    fullWidth
                    helperText="To add multiple email addresses, please separate email addresses with a semi-colon."
                    disabled={disabled}
                    showValidation={showValidation}
                    errs={validationErrors}
                  />
                </div>

                <div className={classes.mb20}>
                  <AgentNetTextInput
                    data-testid="PaySumWireInstructions"
                    variant="outlined"
                    label="Special Instructions/Comments"
                    name="memo"
                    value={paymentDetails.memo}
                    onChange={(e) => {
                      setPaymentDetails({ ...paymentDetails, memo: e.target.value });
                    }}
                    multiline
                    helperText="Add notes here to print on the Payment Transmittal Sheet."
                    disabled={disabled}
                    showValidation={showValidation}
                    errs={validationErrors}
                  />
                </div>

                <AgentNetButton
                  fullWidth
                  loading={showLoader}
                  variant="contained"
                  color="primary"
                  size="small"
                  type="submit"
                  disabled={disabled || showLoader}
                  data-testid="PaySumSubmit"
                >
                  Submit
                </AgentNetButton>
              </form>
            </div>
          )}

          {paymentMethod && paymentMethod.value === 'Check' && (
            <div className={classes.checkContent}>
              <form onSubmit={submitPressed}>
                <Typography variant="caption" color="textSecondary" className={classes.disclaimer}>
                  Click on Submit to remit {totalNoOfFiles} file(s) and update the generated invoice(s)/order(s) as
                  &quot;Manual Pay in Process&quot; on the Pay Tab.
                </Typography>

                <div className={classes.mb20}>
                  <AgentNetTextInput
                    data-testid="PaySumCheckNumber"
                    variant="outlined"
                    label="Check Number"
                    name="checkNumber"
                    value={paymentDetails.checkNumber}
                    onChange={(e) => {
                      setPaymentDetails({ ...paymentDetails, checkNumber: e.target.value });
                    }}
                    fullWidth
                    disabled={disabled}
                    // error={errors}
                    showValidation={showValidation}
                    errs={validationErrors}
                  />
                </div>

                <div className={classes.mb20}>
                  <AgentNetTextInput
                    data-testid="PaySumCheckEmail"
                    variant="outlined"
                    label="Send Copy To"
                    name="email"
                    value={paymentDetails.email}
                    onChange={(e) => {
                      setPaymentDetails({ ...paymentDetails, email: e.target.value });
                    }}
                    fullWidth
                    helperText="To add multiple email addresses, please separate email addresses with a semi-colon."
                    disabled={disabled}
                    showValidation={showValidation}
                    errs={validationErrors}
                  />
                </div>

                <div className={classes.mb20}>
                  <AgentNetTextInput
                    data-testid="PaySumCheckInstructions"
                    variant="outlined"
                    label="Special Instructions/Comments"
                    name="memo"
                    value={paymentDetails.memo}
                    onChange={(e) => {
                      setPaymentDetails({ ...paymentDetails, memo: e.target.value });
                    }}
                    multiline
                    helperText="Add notes here to print on the Payment Transmittal Sheet."
                    disabled={disabled}
                    showValidation={showValidation}
                    errs={validationErrors}
                  />
                </div>

                <AgentNetButton
                  fullWidth
                  loading={showLoader}
                  variant="contained"
                  color="primary"
                  size="small"
                  type="submit"
                  disabled={disabled || showLoader}
                  data-testid="PaySumSubmit"
                >
                  Submit
                </AgentNetButton>
              </form>
            </div>
          )}

          {paymentMethod && paymentMethod.value === 'ePay' && (
            <div className={classes.ePayContent}>
              {ePayAccounts && ePayAccounts.length ? (
                <form onSubmit={submitPressed} noValidate>
                  {isPremiumInvoice && (
                    <Typography variant="caption" color="textSecondary" className={classes.disclaimer}>
                      There is a premium attached to your order. You can only pay for premium orders using ACH transfer.
                    </Typography>
                  )}
                  <Autocomplete
                    className={classes.ePayAutocomplete}
                    options={ePayAccounts}
                    getOptionLabel={(option: { name: string; value: string }) => option.name}
                    renderInput={(params) => (
                      <AgentNetTextInput
                        {...params}
                        data-testid={`PaySumePayOption`}
                        name="ePayId"
                        showValidation={showValidation}
                        errs={validationErrors}
                        label="Payment Name"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        required
                      />
                    )}
                    renderOption={(option: { name: string; value: string }, state: any) => {
                      const index = ePayAccounts?.indexOf(option);
                      return <li data-testid={`PaySumePayOption${index}`}>{option.name}</li>;
                    }}
                    value={selectedEpayAccount}
                    onChange={(event: any, value: any) => {
                      setPaymentDetails((prevDetails) => ({
                        ...prevDetails,
                        ePayId: value?.value,
                        ePayName: value?.name,
                        ePayType: value?.type,
                      }));
                      setSelectedEpayAccount(value || null);
                    }}
                    disabled={disabled}
                  />
                  <div className={classes.mb20}>
                    <AgentNetTextInput
                      data-testid="PaySumePayEmail"
                      variant="outlined"
                      label="Send Copy To"
                      name="email"
                      value={paymentDetails.email}
                      onChange={(e) => {
                        setPaymentDetails({ ...paymentDetails, email: e.target.value });
                      }}
                      fullWidth
                      helperText="To add multiple email addresses, please separate email addresses with a semi-colon."
                      disabled={disabled}
                      showValidation={showValidation}
                      errs={validationErrors}
                    />
                  </div>
                  {!epayConfirmationNum ? (
                    <Typography variant="caption" color="textSecondary" className={classes.disclaimer}>
                      <span>Don’t see your account?</span>&nbsp;
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenPaymentDrawer(true);
                        }}
                        to={addPaymentLink}
                        data-testid="PaySumNoePay"
                      >
                        Please click here to add.
                      </Link>
                    </Typography>
                  ) : null}
                  <AgentNetButton
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="small"
                    onSubmit={submitPressed}
                    type="submit"
                    loading={showLoader}
                    disabled={disabled || showLoader}
                    data-testid="PaySumSubmit"
                  >
                    Pay
                  </AgentNetButton>
                </form>
              ) : (
                <>
                  <Typography variant="caption" color="textSecondary" className={classes.disclaimer}>
                    <span>You do not currently have a payment method setup.</span>&nbsp;
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenPaymentDrawer(true);
                      }}
                      to={addPaymentLink}
                      data-testid="PaySumNoePay"
                    >
                      Please click here to add one.
                    </Link>
                  </Typography>
                  <AgentNetButton fullWidth variant="contained" size="large" data-testid="PaySumSubmit" disabled>
                    Pay
                  </AgentNetButton>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(PaymentComponent);
