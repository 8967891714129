import { pxToRem } from '@fluentsms/agentnet-web-components';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { contactUsSendMessage } from 'api/contactUs/contactUs-api';
import { contactUsReasons } from 'features/constants';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { AgentNetDropdownSelector, AgentNetTextInput } from 'ui-kit/inputs';
import { contactUsSchema } from 'utilities/validation/schemas/contact-us-schema';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import AgentNetButton from '../button/AgentNetButton';
import useSnackBars from '../notification/useSnackbars';

interface ContactUsDialogProps {
  onClose: (value: boolean) => void;
  open: boolean;
}

const useStyles = makeStyles((theme) => ({
  footer: {
    margin: pxToRem(64, 0, 0),
    display: 'flex',
    width: 'calc(100% - 64px)',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10.5px 32px',
    borderTop: '1px solid #C4C4C4',
  },
  footerText: {
    textAlign: 'right',
  },
  footerTos: {
    fontSize: pxToRem(14),
    '& span': {
      padding: '0 10px',
      color: theme.palette.text.secondary,
      transform: `translateY(1px)`,
      display: 'inline-block',
    },
  },
  mb1: {
    marginBottom: theme.spacing(1),
  },
  dialogWrap: {
    minWidth: 600,
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  dialogClose: {
    padding: 0,
  },
  dialogContent: {
    padding: pxToRem(24),
  },
  logoWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    '& svg': {
      width: '231px',
      height: 'auto',
    },
  },
  menuIconBtn: {
    marginLeft: theme.spacing(1),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    '&:hover, &:visited': {
      color: theme.palette.primary.dark,
    },
  },
  mb3: {
    marginBottom: theme.spacing(3),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
}));

const ContactUsDialog = ({ onClose, open }: ContactUsDialogProps) => {
  const { addSnackbarMessage } = useSnackBars();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm, profile } = profileCtx;
  const [contactUsObj, setContactUsObj] = useState<{ contactUsReason: string; contactUsMessage: string }>({
    contactUsReason: '',
    contactUsMessage: '',
  });
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const [showToasterSuccess, setShowToasterSuccess] = useState<boolean>(false);
  const [showToasterError, setShowToasterError] = useState<boolean>(false);
  const classes = useStyles();
  const handleSendMessage = async () => {
    setIsSubmitted(true);
    if (!validationErrors || validationErrors.length === 0) {
      setIsSendingMessage(true);
      let name = profile?.firstName ? profile?.firstName + ' ' : '';
      name += profile?.lastName ? profile?.lastName : '';

      const requestObj = {
        fullName: name,
        firm: userFirm ? userFirm.name : '',
        email: profile?.emailAddress ? profile?.emailAddress : '',
        phone: profile?.phoneNumber ? profile?.phoneNumber : '',
        reason: contactUsObj.contactUsReason,
        message: contactUsObj.contactUsMessage,
      };
      if (await contactUsSendMessage(requestObj)) {
        handleCloseDialog();
        setShowToasterSuccess(true);
      } else {
        setShowToasterError(true);
      }
      setIsSendingMessage(false);
    }
  };

  useEffect(() => {
    doValidate(contactUsObj, contactUsSchema(true)).then((errs: FieldValidationError[]) => {
      setValidationErrors(errs);
    });
  }, [contactUsObj]);
  useEffect(() => {
    if (showToasterSuccess) {
      addSnackbarMessage({
        message: `Message Sent`,
        type: 'success',
        onClose: () => {
          setShowToasterSuccess(false);
        },
      });
    }
  }, [showToasterSuccess]);
  useEffect(() => {
    if (showToasterError) {
      addSnackbarMessage({
        message: `Send Message Failed`,
        type: 'error',
        onClose: () => {
          setShowToasterError(false);
        },
      });
    }
  }, [showToasterError]);
  const handleCloseDialog = () => {
    setIsSubmitted(false);
    setContactUsObj({ contactUsReason: '', contactUsMessage: '' });
    onClose(true);
  };
  return (
    <Dialog
      onClose={handleCloseDialog}
      aria-labelledby="contact-us"
      open={open}
      maxWidth={'xs'}
      PaperProps={{ className: classes.dialogWrap }}
    >
      <DialogTitle>
        <div className={classes.dialogTitle}>
          <Typography variant="h3" color="textPrimary">
            Contact Us
          </Typography>
          <IconButton className={classes.dialogClose} onClick={handleCloseDialog}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <AgentNetDropdownSelector
          name="contactUsReason"
          required
          showValidation={isSubmitted}
          label="What can we help you with?"
          options={contactUsReasons}
          value={contactUsObj.contactUsReason}
          id="contactUsReason"
          menuOption={(val: string) => setContactUsObj({ ...contactUsObj, contactUsReason: val })}
          qaAttribute="ContactUsReason"
          dropdowntype="outlined"
          errs={validationErrors}
        />
        <br></br>
        <AgentNetTextInput
          multiline
          fullWidth
          variant="outlined"
          label="Message"
          name="contactUsMessage"
          id="contactUsMessage"
          required
          data-testid={'ContactUsMessage'}
          value={contactUsObj.contactUsMessage}
          showValidation={isSubmitted}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setContactUsObj({ ...contactUsObj, contactUsMessage: event.target.value })
          }
          errs={validationErrors}
        />
      </DialogContent>
      <DialogActions className="modal-btn-container ghost-white flex-sb p-16-24">
        <AgentNetButton
          variant="outlined"
          color="primary"
          onClick={handleCloseDialog}
          data-testid="ContactUsCancel"
          aria-label="cancel-delete"
        >
          Cancel
        </AgentNetButton>
        <AgentNetButton
          color="primary"
          variant="contained"
          autoFocus
          aria-label="confirm-delete"
          data-testid={'ContactUsSubmit'}
          onClick={handleSendMessage}
          loading={isSendingMessage}
          disabled={isSendingMessage}
        >
          Send Message
        </AgentNetButton>
      </DialogActions>
    </Dialog>
  );
};

export default ContactUsDialog;
