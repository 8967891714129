import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { KBLinks } from 'features/constants';
import moment from 'moment';
import FileStatus from 'ui-kit/icons/FileStatus';

export const pay = 'Pay';
export const review = 'Review';
export const report = 'Report';

export const advancedSearchFilters = [
  { name: 'Order Number', value: 'orderNumber' },
  { name: 'Invoice Number', value: 'invoiceNumber' },
  { name: 'File Number', value: 'fileNumber' },
  { name: 'Policy Number', value: 'policyNumber' },
  { name: 'Date Range', value: 'dateRange' },
];

export const cplFilters = [
  { name: 'All Files', value: 'allFiles' },
  { name: 'CPL-Only Files', value: 'cplOnlyFiles' },
  { name: 'Exclude CPL-Only Files', value: 'excludeCplOnlyFiles' },
];

export const cplFilterswithAutoReport = [
  { name: 'All Files', value: 'allFiles' },
  { name: 'CPL-Only Files', value: 'cplOnlyFiles' },
  { name: 'Exclude CPL-Only Files', value: 'excludeCplOnlyFiles' },
  { name: 'Excluded from Auto-Reporting', value: 'excludedFromAutoReporting' },
];

export const queueFilters = [
  { name: 'Eligible Files', value: 'eligibleFiles' },
  { name: 'My Queue', value: 'myQueueOnlyFiles' },
  { name: 'Firm Queue', value: 'firmQueueOnlyFiles' },
];

export const reportQueueButtonAttributes: any = {
  eligibleFiles: 'ReportEligibleFilesFilter',
  myQueueOnlyFiles: 'ReportMyQueueFilter',
  firmQueueOnlyFiles: 'ReportFirmQueueFilter',
};

export const tabSubtitle = {
  Review: `Files in 'Open' status containing errors which prevent reporting eligibility. Product and pricing edits can be made by selecting the in-line calculator icon.`,
  Report: `Files in 'Open' status that meet eligible requirements for reporting to First American. Select individual or multiple files to Queue, Report, Report & Pay or Export. Product and pricing edits can be made using in-line calculator icon.`,
  Pay: 'Orders and Invoices that are ready for payment. Select individual or multiple orders to pay using your preferred payment method: electronic payment (ePay), wire transfer, or manual payment (check). Select the Magnifying Glass icon to view file level details.',
};

export const tabKBLinks = {
  Review: KBLinks.reportPayReview,
  Report: KBLinks.reportPayReport,
  Pay: KBLinks.reportPayPay,
};

export const advancedSearchViewText =
  'You are currently viewing search results from an advanced search. To return to the default Pay data, please clear your search.';
export const autoReportingInfoText = `Order was generated by Auto-Reporting. To change Auto-Reporting settings, select 'Manage Auto-Reporting' from the Administration Menu.`;
export const reopenedFileInfoText = `Orders containing reopened files will not be eligible for payment for 24 hours to ensure proper credits are applied.`;
export const restrictReportingText =
  'You cannot pay for files across multiple underwriters or firms at the same time. Please go back and ensure you have selected files from only one underwriter and firm before clicking Report & Pay.\n\nTo avoid this in future, please filter your list by underwriter before selecting items for payment.';
export const restrictLimitReportingText =
  'You cannot report & pay for more than 50 files at one time and they must all be the same underwriters and firms. Please go back and ensure you have selected no more than 50 files from only one underwriter and firm before clicking Report & Pay.\n\nTo avoid this in future, please filter your list by underwriter before selecting items for payment.';
export const searchFilesReportedText = 'Files reported outside of AgentNet will not be included in the search results.';
export const reviewFilesErrorCodes = [
  'All',
  'Pricing',
  'Compliance Date',
  'Future Dated Policy',
  'Stat Code(s)',
  'Pending Product',
];

export const noFilesText = {
  review: 'Great news, all files complete with no missing information.',
  report: 'Please check the Review tab for files with potential errors.',
  pay: 'Please ensure that you have reported your eligible files.',
};

export const ErrorRenderer = (params: GridRenderCellParams): React.ReactElement => {
  const { value } = params;
  const statusDotClassName = 'dot',
    statusClassName = 'error-status ',
    valueStyle = 't-u';
  return FileStatus({
    value: value,
    statusDotClassName: statusDotClassName,
    statusClassName: statusClassName,
    valueStyle: valueStyle,
  });
};
const getStartOfDay = (date: Date) => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

export const filterParams = {
  comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
    const cellDate = getStartOfDay(cellValue);
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate.getTime() < filterLocalDateAtMidnight.getTime()) {
      return -1;
    }
    if (cellDate.getTime() > filterLocalDateAtMidnight.getTime()) {
      return 1;
    }
    return 0;
  },
  browserDatePicker: true,
  dateFormat: 'yyyy-mm-dd',
  inRangeInclusive: true,
};

export const formatDate = (date: string) => {
  if (!date) return '';
  return moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY');
};

export const numberValueFormatter = function (params: any, nonAgValue = false) {
  const value = nonAgValue ? params?.toFixed(2) : params?.value?.toFixed(2);
  return '$' + value?.replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const orderStatuses = {
  open: 'Open',
  manualPay: 'Manual Pay in Process',
  ePay: 'ePay in Process',
  closed: 'Closed',
  wirePay: 'Wire in Process',
  inProcess: 'In Process',
};
