/* eslint-disable @typescript-eslint/no-explicit-any */
import { ArrowDropDown } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { AgentNetTextInput, AgentTextInputProps } from '../TextField';

export type searchType = {
  id?: number | string | undefined | null;
  value: any;
  name: any;
  type?: ('Most Recent' | 'Available Policies') | string | undefined | null;
};

export type PolicyTypeAheadProps = AgentTextInputProps & {
  label?: string;
  value?: string | number;
  onChange?: (value: any) => void;
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
  options?: Array<any> | null | undefined;
  disableIcon?: boolean;
  recentLabel?: string;
  disabled?: boolean;
  dataTestId?: string;
};

export function PolicyTypeAhead({
  label = 'Policy Type',
  value: valueProp = '',
  onBlur = () => undefined,
  onChange = () => undefined,
  id = '',
  options,
  disableIcon,
  recentLabel = 'Most Recent',
  errs,
  name,
  showValidation,
  disabled,
  dataTestId,
  ...props
}: PolicyTypeAheadProps): JSX.Element {
  const optionsById = (val: any) => options?.find((ele) => ele.value === val);
  const [value, setValue] = useState<any | null>(null);

  /* Display Validation Errors */
  const [errors, setErrors] = useState(false);
  const [displayErrors, setDisplayError] = useState(showValidation);
  /* Will need this code for future purposes, will keep this out */
  // const displayErrorOnCallback = (callback: () => void) => {
  //   callback();
  // };

  useEffect(() => {
    const hasError = errs?.find((err) => err.field === name);
    hasError ? setErrors(true) : setErrors(false);
  }, [errs]);

  useEffect(() => {
    setDisplayError(showValidation);
  }, [showValidation]);

  useEffect(() => {
    if (value !== valueProp) {
      const val = optionsById(valueProp);
      setValue(val);
    }
  }, [valueProp, options]);

  const getOptions = () => {
    let arr = [];

    if (options !== null && options !== undefined) {
      arr = options.map((e) => {
        const isRecent = e.name.startsWith('*');
        return {
          ...e,
          name: isRecent ? e.name.substring(1) : e.name,
          type: isRecent ? 'Most Recent' : 'Available Policies',
        };
      });

      return arr;
    }
  };

  const [optionState, setOptionState] = useState<any>(getOptions());

  const formatOption = (e: string) => {
    const isRecent = e.startsWith('*');
    return isRecent ? e.substring(1) : e;
  };

  const formatId = (e: string) => {
    const clean = e.replace(/[^a-zA-Z0-9s]/g, '');
    const formatted = clean.replace(/\s+/g, '-').toLowerCase();
    return formatted;
  };

  useEffect(() => {
    setOptionState(getOptions());
  }, [options]);

  return (
    <Autocomplete
      autoComplete
      autoHighlight
      clearOnEscape
      onBlur={onBlur}
      options={optionState}
      getOptionLabel={(option: searchType) => option.name.replace(/^\*/, '')}
      getOptionSelected={(selected, option) => selected.value === option?.value}
      value={value}
      onChange={(_, value) => {
        onChange(value?.value || '');
        setValue(value);
      }}
      renderInput={(param) => {
        return (
          <AgentNetTextInput
            variant="outlined"
            label={label}
            errs={errs}
            showValidation={showValidation}
            onChange={(value) => {
              onChange(value);
            }}
            {...param}
            {...props}
            inputProps={{
              ...param.inputProps,
              'aria-label': 'policy-type-input-select',
              autoComplete: 'new-password',
              form: { Autocomplete: 'off' },
            }}
            InputLabelProps={{ shrink: true }}
            error={errors && displayErrors}
            title={value && value.name ? value.name.toString() : label}
          />
        );
      }}
      renderOption={(optionProps) => (
        <div
          {...optionProps}
          className="policyTypeAheadOption"
          id={formatId(optionProps.name)}
          data-component={formatId(optionProps.name)}
        >
          {formatOption(optionProps.name)}
        </div>
      )}
      id={id}
      data-testid={dataTestId}
      popupIcon={<ArrowDropDown />}
      className="autocomplete-search"
      forcePopupIcon={disableIcon || disabled ? false : true}
      disabled={disabled}
      groupBy={(option) => (option.type ? option.type : '')}
    />
  );
}

export default PolicyTypeAhead;
