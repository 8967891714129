export function LockboxIcon() {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 0H2C1.25 0 .5.675.5 1.5v2.258c0 .54.323 1.005.75 1.267V13.5c0 .825.825 1.5 1.5 1.5h10.5c.675 0 1.5-.675 1.5-1.5V5.025c.428-.263.75-.727.75-1.268V1.5C15.5.675 14.75 0 14 0m-.75 13.5H2.75V5.25h10.5zM14 3.75H2V1.5h12z"
        fill="#0075C3"
      />
      <path d="M10.25 7.5h-4.5V9h4.5z" fill="#0075C3" />
    </svg>
  );
}

export function CalendarIcon() {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 1.75h-.75V.25h-1.5v1.5h-7.5V.25h-1.5v1.5H2c-.825 0-1.5.675-1.5 1.5v12c0 .825.675 1.5 1.5 1.5h12c.825 0 1.5-.675 1.5-1.5v-12c0-.825-.675-1.5-1.5-1.5m0 13.5H2V7h12zm0-9.75H2V3.25h12z"
        fill="#0075C3"
      />
    </svg>
  );
}
