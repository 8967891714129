import { useAuth } from '@agentnet/auth';
import { pxToRem } from '@fluentsms/agentnet-web-components';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import { AgentNetTextInput } from 'ui-kit/inputs';
import { deleteEmailNotification } from '../../../api/admin/user-profile-api';
import AgentNetButton from '../button/AgentNetButton';

interface emailType {
  ServiceOrderEmailNotificationId: number;
  ServiceOrderEmailAddress: string;
}

interface SearchOrderEmailsTable {
  emails?: emailType[];
  disabled?: boolean;
  onDelete?: (index: any) => void;
  onChange?: (items: any) => void;
  dialogText?: string;
  dialogTitle?: string;
  qaAttrPrefix?: string;
  qaBtnprefix?: string;
  isDuplicate: (index: any) => void;
  onValidate: (index: any) => void;
  addnewEmaildisable?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emailTable: {
      width: '99%',
      padding: '0 0 24px',
    },
    emailTableHeader: {
      backgroundColor: '#F2F5F6',
      borderBottom: '1px solid #D9E0E5',
      fontWeight: 600,
      fontSize: pxToRem(14),
    },
    tableHeaderCell: {
      padding: pxToRem(12),
      paddingLeft: pxToRem(8),
    },
    emailTableList: {},
    tableRow: {
      borderBottom: `1px solid #E0E0E0`,
    },
    tableCell: {
      padding: pxToRem(12),
      display: 'flex',
      alignItems: 'center',
      paddingRight: pxToRem(8),
      paddingLeft: pxToRem(7),
    },
    deleteRow: {
      padding: 0,
      marginLeft: theme.spacing(1),
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    my1: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    mb1: {
      marginBottom: theme.spacing(1),
    },
    deleteCell: {
      justifyContent: 'space-between',
    },
    ctaBtn: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(3),
      width: '100%',
    },
    validationMsg: {
      width: '100%',
      fontSize: pxToRem(14),
    },
    validationMsg1: {
      width: '100%',
      fontSize: pxToRem(14),
      color: 'red',
    },
  }),
);

const SearchOrderEmailsTable = ({
  disabled,
  emails,
  onChange,
  onDelete,
  dialogText,
  dialogTitle,
  qaAttrPrefix,
  qaBtnprefix,
  isDuplicate,
  onValidate,
  addnewEmaildisable,
}: SearchOrderEmailsTable) => {
  const classes = useStyles();

  const { getAccessToken, account, signOut } = useAuth();
  const [items, setItems] = useState<emailType[]>([]);
  const [item, setItem] = useState<string[]>([]);
  const [dialog, setDialog] = React.useState<DialogInterface>({ show: false, id: undefined });
  const newEmailDetails: emailType = { ServiceOrderEmailNotificationId: 0, ServiceOrderEmailAddress: '' };
  const [isduplicate, setIsDuplicate] = useState<boolean>(false);
  const [showmessage, setShowmessage] = useState<boolean>(false);
  const [emailFormatValidation, setEmailFormatValidation] = useState<string>('');
  const [validateEmailResult, setvalidateEmailResult] = useState<boolean>();

  useEffect(() => {
    if (emails && emails.length > 0) {
      setItems(emails);
    }
  }, [emails]);

  useEffect(() => {
    onValidate(validateEmailResult);
  }, [validateEmailResult]);

  useEffect(() => {
    isDuplicate(isduplicate);
  }, [isduplicate]);

  useEffect(() => {
    onChange && onChange(items);
  }, [items]);

  const addNewEmailField = () => {
    setItems([...items, newEmailDetails]);
  };

  const handleEmailChange = (val: string, i: number) => {
    const newEmails = [...items];
    setEmailFormatValidation(val);
    setIsDuplicate(newEmails.some((item) => item.ServiceOrderEmailAddress === val));
    newEmails[i].ServiceOrderEmailAddress = val;
    setItems(newEmails);
    onChange && onChange(items);
  };

  const handleDelete = (i: number) => {
    const newEmails = items.filter((_, index) => index !== i);
    setvalidateEmailResult(true);
    setItems(newEmails);
    //set the value as false when delete happens
    setIsDuplicate(false);
    const deletedEmail = items[i];
    onDelete && onDelete(i);
    DeleteServiceOrderEmailNotification(deletedEmail?.ServiceOrderEmailNotificationId);
    setDialog({ show: false, id: undefined });
  };

  const DeleteServiceOrderEmailNotification = async (id: number) => {
    const token = await getAccessToken();
    const responsedata = await deleteEmailNotification(id, token);
  };
  interface DialogInterface {
    show: boolean;
    id: any;
  }

  const modalConfirmation = (e: number) => {
    setDialog({ show: true, id: e });
  };

  const validateEmail = (item: string): boolean => {
    const regex = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
    if (item !== '') return regex.test(item);
    else return true;
  };
  //function executes when grid focus is out
  const handleBlur = () => {
    setShowmessage(true);
    setvalidateEmailResult(validateEmail(emailFormatValidation));
  };

  return (
    <>
      {dialog.id >= 0 && (
        <AgentNetConfirmationDialog
          onConfirm={() => handleDelete(dialog.id)}
          onDismissAction={() => setDialog({ show: false, id: undefined })}
          open={dialog.show}
          dialogTitle={dialogTitle}
          dialogBtnContent="Yes, Continue"
          dialogText={dialogText}
          qaAttrPrefix={qaAttrPrefix}
        />
      )}
      <Grid item sm={8}>
        <Typography variant="body2">
          {items.length == 0 && (
            <div className={classes.validationMsg}>There are no Service Order Email Notifications.</div>
          )}
        </Typography>
        <Typography variant="body2">
          {isduplicate &&
            items.length !== 0 &&
            ((addnewEmaildisable = true),
            (
              <div className={classes.validationMsg1}>
                Email is already set up. Please enter a unique email address.
              </div>
            ))}
        </Typography>
        <Typography variant="body2">
          {!validateEmailResult && items.length > 0 && showmessage
            ? ((addnewEmaildisable = true),
              (<div className={classes.validationMsg1}>Please enter a valid Email address</div>))
            : null}
        </Typography>
      </Grid>
      <Grid item sm={4}>
        <Typography>
          <div className={classes.ctaBtn}>
            <AgentNetButton
              variant="contained"
              color="primary"
              data-testid={qaBtnprefix}
              size="small"
              onClick={() => addNewEmailField()}
              disabled={addnewEmaildisable}
            >
              Add New Email
            </AgentNetButton>
          </div>
        </Typography>
      </Grid>
      {!(items.length == 0) ? (
        <div className={clsx(classes.emailTable)}>
          <Grid container className={classes.emailTableHeader}>
            <Grid item sm={12} className={classes.tableHeaderCell}>
              Email Address
            </Grid>
          </Grid>

          <Grid container className={classes.emailTableList}>
            {items.map((e: any, i) => (
              <Grid container key={i} className={clsx(classes.tableRow)}>
                <Grid item sm={11} className={clsx(classes.tableCell)}>
                  <AgentNetTextInput
                    fullWidth
                    variant="outlined"
                    value={e.ServiceOrderEmailAddress}
                    data-testid={qaBtnprefix + 'Field'}
                    onChange={(e) => handleEmailChange(e.target.value, i)}
                    onBlur={handleBlur}
                    name="ServiceorderNotificationEmail"
                  />
                </Grid>
                <Grid item sm={1} className={clsx(classes.tableCell, classes.deleteCell)}>
                  {onDelete && (
                    <IconButton
                      className={classes.deleteRow}
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      aria-label="delete document"
                      data-testid={qaBtnprefix + 'Close' + i}
                      onClick={() => modalConfirmation(i)}
                      disabled={disabled}
                    >
                      <Close color="disabled" />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </div>
      ) : null}
    </>
  );
};

export default SearchOrderEmailsTable;
