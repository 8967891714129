import * as yup from 'yup';

export const REQUIRED_FIELD_MESSAGE_TEXT = 'required';

export const requiredFieldMessage = (fieldName: string): string => `${fieldName} ${REQUIRED_FIELD_MESSAGE_TEXT}`;
export const maxLengthFieldMessage = (fieldName?: string, maxLength?: number): string =>
  `${fieldName} Max length is ${maxLength}`;

export const orderManagementTypeSchema = yup.object().shape({
  orderDate: yup
    .date()
    .required()
    .min('1889-01-01')
    .max('2100-12-31')
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError('Invalid Date'),
  firmSearchInput: yup.string().required(),
  referenceNumber: yup.string().matches(/^[a-zA-Z0-9.-]*$/, 'Invalid Reference Number'),
  checkCount: yup.number().min(0),
  lockboxId: yup.number().required(),
});
