import { Autocomplete } from '@agentnet/components';
import { pxToRem } from '@fluentsms/agentnet-web-components';
import { createStyles, IconButton, InputAdornment, makeStyles, Popper, TextField, Theme } from '@material-ui/core';
import { Clear, HelpOutlineOutlined, NotificationsOutlined, SwapHoriz } from '@material-ui/icons';
import { Firm, Profile } from 'api/profile/types';
import { Dispatch, MouseEvent, Ref, SetStateAction, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: {
      backgroundColor: '#FFF',
      borderRadius: '6px',
      zIndex: 20,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      boxShadow: `0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)`,
      width: '300px',
    },
    popperContent: {
      width: 300,
    },
    popperOption: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: '6px 16px',
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    popperPaper: {
      margin: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    popperDisablePortal: {
      position: 'relative',
      width: '100% !important',
    },
    popperlistbox: {
      padding: '0 12px 16px',
    },
    inputBaseWrap: {
      padding: '16px 12px 12px',
    },
    inputBase: {
      width: '100%',
      '& input': {
        backgroundColor: theme.palette.common.white,
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontSize: 14,
      },
    },
    iconBtn: {
      '&:hover': {
        background: 'transparent',
      },
    },
    firmName: {
      fontSize: pxToRem(16),
      fontWeight: 500,
    },
    firmSwitch: {
      margin: theme.spacing(0, 1),
    },
  }),
);

type FirmSwitcherProps = {
  firm: {
    name: string;
    value: string;
  };
  firmAnchorEl: any;
  isFirmMenuOpen: boolean;
  pfNotifications: number | undefined;
  popperRef: Ref<HTMLDivElement>;
  profile: Profile | null | undefined;
  setFirm: Dispatch<
    SetStateAction<{
      name: string;
      value: string;
    }>
  >;
  setFirmAnchorEl: any;
  userFirm: Firm | null | undefined;
  handleFirmChange: (newValue: string | undefined, logout?: boolean, firmDetailsResults?: any) => void;
  handleMenuClose: () => void;
};

export function FirmSwitcher({
  firm,
  firmAnchorEl,
  isFirmMenuOpen,
  pfNotifications,
  popperRef,
  profile,
  setFirm,
  setFirmAnchorEl,
  userFirm,
  handleFirmChange,
  handleMenuClose,
}: FirmSwitcherProps) {
  const classes = useStyles();
  const history = useHistory();
  const company = userFirm ? userFirm.name : '';

  const firmOptions = useMemo(
    () =>
      profile?.firms
        ?.map((firmOption: Firm) => {
          return {
            name:
              firmOption.isDefault && profile?.firms?.length && profile?.firms?.length > 1
                ? `${firmOption.name} (Default)`
                : `${firmOption.name}${firmOption.status === 'Inactive' ? ' (Inactive)' : ''}`,
            value: firmOption.firmId,
          };
        })
        .sort((firstFirm, secondFirm) => firstFirm.name.localeCompare(secondFirm.name)) ?? [],
    [profile?.firms],
  );

  return (
    <>
      <div className="custom-pf" id="notificationIcon">
        <NotificationsOutlined />
        {pfNotifications && pfNotifications > 0 ? <span>{pfNotifications}</span> : null}
      </div>
      <IconButton
        disableFocusRipple
        disableRipple
        disableTouchRipple
        onClick={() => window.open('https://agentnet.firstam.com/help', '_blank')}
        className={classes.iconBtn}
        aria-label="Help"
      >
        <HelpOutlineOutlined htmlColor="#fff" />
      </IconButton>

      {userFirm?.firmId && (
        <IconButton
          aria-label="Switch Firms"
          color="inherit"
          disableFocusRipple
          disableRipple
          disableTouchRipple
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            if (profile?.roleId === 2 || profile?.roleId === 3 || profile?.roleId === 5 || profile?.roleId === 13) {
              history.push('/searchFirms');
            }
            if (profile?.roleId === 14) {
              history.push('/order-management');
            }
            if (isFirmMenuOpen || !(profile?.firms?.length && profile?.firms?.length > 1)) {
              setFirmAnchorEl(null);
            } else {
              setFirmAnchorEl(e.currentTarget);
              setFirm({ name: userFirm?.name, value: userFirm?.firmId });
            }
          }}
          className={classes.iconBtn}
        >
          <span className={classes.firmName} data-testid="HeaderFirmName">
            {company}
          </span>
          {((profile?.firms?.length && profile?.firms?.length > 1) ||
            profile?.roleId === 2 ||
            profile?.roleId === 3 ||
            profile?.roleId === 5 ||
            profile?.roleId === 13) && <SwapHoriz data-testid="HeaderFirmArrows" className={classes.firmSwitch} />}
        </IconButton>
      )}

      <Popper
        className={classes.popper}
        placement="bottom-end"
        anchorEl={firmAnchorEl}
        open={isFirmMenuOpen}
        ref={popperRef}
      >
        <div className={classes.popperContent}>
          <Autocomplete
            options={firmOptions}
            getOptionLabel={(option) => option.name}
            open
            data-testid="HeaderFirmNameSearch"
            onClose={handleMenuClose}
            classes={{
              option: classes.popperOption,
              popperDisablePortal: classes.popperDisablePortal,
              paper: classes.popperPaper,
              listbox: classes.popperlistbox,
            }}
            onChange={(event, newValue) => {
              handleFirmChange(newValue?.value);
            }}
            disableCloseOnSelect
            disablePortal
            renderTags={() => null}
            noOptionsText="No labels"
            value={firm}
            renderInput={(params) => (
              <div className={classes.inputBaseWrap}>
                <TextField
                  variant="outlined"
                  label="Select Firm"
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  className={classes.inputBase}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="select firm"
                          onClick={() => {
                            setFirm({ name: '', value: '' });
                          }}
                          edge="end"
                        >
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
          />
        </div>
      </Popper>
    </>
  );
}
