import { Redirect, useLocation } from 'react-router-dom';
import OrderManagementContent from './OrderManagementContent';

export function RedirectToAllOrdersContent() {
  const location = useLocation();
  if (!location.state) {
    return <Redirect to="/order-management/all-orders" />;
  }
  return <OrderManagementContent />;
}
