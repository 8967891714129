import { FormatDateUtils } from '@agentnet/utils';
import { pxToRem } from '@fluentsms/agentnet-web-components';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { formatNameTag } from 'utilities/utilities';
import { NotesType } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      gap: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      paddingRight: theme.spacing(2),
    },
    conversation: {
      backgroundColor: theme.palette.brand.feather,
      borderRadius: 4,
      color: theme.palette.text.primary,
      fontSize: pxToRem(14),
      padding: theme.spacing(1),
      maxWidth: '75%',
      width: 'max-content',
    },
    icon: {
      alignItems: 'center',
      backgroundColor: theme.palette.primary.light,
      borderRadius: 32,
      color: theme.palette.primary.main,
      display: 'flex',
      flex: '0 0 32px',
      fontWeight: 500,
      height: 32,
      justifyContent: 'center',
      marginTop: 23,
      width: 32,
    },
    system: {
      alignItems: 'flex-end',
    },
    systemUser: {
      backgroundColor: theme.palette.brand.feather50,
    },
    time: {
      marginBottom: theme.spacing(0.5),
    },
    userName: {
      marginRight: theme.spacing(1),
    },
  }),
);

type NoteAttachmentItemProps = {
  conversation: NotesType;
  profileUserId: number | undefined;
};

export function NoteAttachmentItem({ conversation, profileUserId }: NoteAttachmentItemProps) {
  const classes = useStyles();
  const formattedTime = FormatDateUtils.MonthDateYearTime(conversation.createdDate || '');
  const isUser =
    conversation.createdByUserId && profileUserId && conversation.createdByUserId !== profileUserId ? true : false;

  const nameTag = (): string => {
    return formatNameTag(conversation.userName || '');
  };

  return (
    <div className={classes.root}>
      {isUser && <div className={classes.icon}>{nameTag()}</div>}
      <div className={clsx(classes.content, { [classes.system]: !isUser })}>
        <Typography className={classes.time} variant="body2">
          {isUser && <strong className={classes.userName}>{conversation.userName}</strong>}
          <Typography color="textSecondary" component="span" variant="body2">
            {formattedTime}
          </Typography>
        </Typography>

        <div className={clsx(classes.conversation, { [classes.systemUser]: !isUser })}>{conversation.notes}</div>
      </div>
    </div>
  );
}
