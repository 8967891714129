import {
  Backdrop,
  Box,
  CircularProgress,
  Drawer,
  DrawerProps,
  fade,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { ReactNode } from 'react';
import AgentNetButton, { ICustomButtonProps } from '../../components/button/AgentNetButton';
import DrawerActions from './FormDrawerActions';
import DrawerContent from './FormDrawerContent';
import DrawerTitle from './FormDrawerTitle';

export type FormDrawerProps = DrawerProps & {
  children?: ReactNode;
  header?: string;
  width?: number;
  fullWidth?: boolean;
  onDismissAction?: () => void;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
  primaryActionLabel?: string;
  secondaryActionLabel?: string;
  primaryActionTootip?: string;
  primaryActionProps?: ICustomButtonProps;
  dismissActionProps?: ICustomButtonProps;
  secondaryActionProps?: ICustomButtonProps;
  testId?: string;
  loading?: boolean;
  disabled?: boolean;
  actionItems?: any;
  showDrawerActions?: boolean;
  disableEnforceFocus?: boolean;
  hidePrimaryAction?: boolean;
  showBackDropOnDrawer?: boolean;
};

const FormDrawerBase = ({
  header,
  children,
  width = 720,
  onPrimaryAction = () => undefined,
  onDismissAction = () => undefined,
  onSecondaryAction = () => undefined,
  primaryActionLabel,
  secondaryActionLabel,
  primaryActionTootip = '',
  dismissActionProps,
  primaryActionProps,
  secondaryActionProps,
  testId = 'form-drawer' || null,
  loading = false,
  disabled,
  actionItems,
  showDrawerActions = true,
  disableEnforceFocus = false,
  hidePrimaryAction = false,
  fullWidth,
  showBackDropOnDrawer = false,
  ...props
}: FormDrawerProps): JSX.Element => {
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: fullWidth ? '100%' : width,
      maxWidth: 1920,
    },
    buttonsContainer: {
      [theme.breakpoints.only('xs')]: {
        minHeight: 'auto',
        flexDirection: 'column',
        display: 'flex',
        paddingBottom: 0,
      },
      [theme.breakpoints.up('sm')]: {
        '& > :first-child': {
          marginLeft: theme.spacing(2),
        },
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        width: '100%',
      },
    },
    buttonReverse: {
      display: 'flex !important',
      flexDirection: 'row-reverse',
      '& button': {
        marginLeft: '10px !important',
      },
    },
    secondaryButton: {
      display: 'flex',
      alignSelf: 'center',
      [theme.breakpoints.up('sm')]: {
        flex: 1,
      },
    },
    disabledStyle: {
      '&:disabled': {
        color: '#9e9e9e !important',
        border: '1px solid #9e9e9e !important',
      },
    },
    boxStyles: {
      width: fullWidth ? '100%' : width,
      maxWidth: 1920,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 2, // Keep backdrop above drawer
      backgroundColor: fade(theme.palette.common.black, 0.3),
      position: 'absolute',
    },
  }));
  const classes = useStyles();
  return (
    <>
      <Drawer anchor="right" {...props} disableEnforceFocus title="" PaperProps={{ className: classes.boxStyles }}>
        <Box className={classes.root} data-testid={testId}>
          <Backdrop className={classes.backdrop} open={showBackDropOnDrawer}>
            <CircularProgress color="primary" />
          </Backdrop>
          <DrawerTitle title={header} />
          <DrawerContent>{children}</DrawerContent>
          {showDrawerActions && (
            <DrawerActions loading={loading}>
              {secondaryActionLabel && (
                <div className={classes.secondaryButton}>
                  <AgentNetButton
                    id="secondary-action"
                    variant="outlined"
                    size="small"
                    danger
                    className={classes.disabledStyle}
                    onClick={onSecondaryAction}
                    {...secondaryActionProps}
                  >
                    {secondaryActionLabel}
                  </AgentNetButton>
                </div>
              )}
              <div className={classes.buttonsContainer}>
                <div className={classes.buttonReverse}>
                  {!hidePrimaryAction && (
                    <Tooltip title={primaryActionTootip} arrow placement="top">
                      <span style={{ display: 'inline-block' }}>
                        <AgentNetButton
                          onClick={onPrimaryAction}
                          className={disabled ? 'disabled-btn' : ''}
                          disabled={disabled}
                          id="primary-action"
                          color="primary"
                          size="small"
                          variant="contained"
                          {...primaryActionProps}
                        >
                          {primaryActionLabel}
                        </AgentNetButton>
                      </span>
                    </Tooltip>
                  )}
                  {actionItems}
                </div>
                <div>
                  <AgentNetButton
                    onClick={onDismissAction}
                    id="dismiss-action"
                    color="primary"
                    size="small"
                    variant="outlined"
                    {...dismissActionProps}
                  >
                    Cancel
                  </AgentNetButton>
                </div>
              </div>
            </DrawerActions>
          )}
        </Box>
      </Drawer>
    </>
  );
};
export default FormDrawerBase;
