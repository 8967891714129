import { useAuth } from '@agentnet/auth';
import { HashUtils } from '@agentnet/utils';
import { AgentNetButton } from '@fluentsms/agentnet-web-components';
import { createStyles, makeStyles, Menu, MenuItem, Theme, Typography } from '@material-ui/core';
import { ExitToAppOutlined, HelpOutlineOutlined, InfoOutlined, MessageOutlined } from '@material-ui/icons';
import { clearUserCache } from 'api/file/file-api';
import { getAnteUrl } from 'api/host-by-env';
import { Firm, Profile } from 'api/profile/types';
import clsx from 'clsx';
import { FileDataContext, FileDataContextInterface } from 'hooks/FileDataContext';
import { Dispatch, SetStateAction, useContext } from 'react';
import LogoutOutlined from 'ui-kit/icons/LogoutOutlined';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountMenuWrap: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      boxShadow: `0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)`,
    },
    accountMenuList: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    accountMenu: {
      width: '300px',
    },
    accountMenuDetails: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    disableHover: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    divider: {
      borderBottom: '1px solid #c4c4c4',
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
    },
    inputBase: {
      width: '100%',
      '& input': {
        backgroundColor: theme.palette.common.white,
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontSize: 14,
      },
    },
    menuIconBtn: {
      marginLeft: theme.spacing(1),
    },
  }),
);

type AccountMenuProps = {
  anchorEl: HTMLElement | null;
  email: string;
  isStaffAdmin: boolean;
  name: string;
  profile: Profile | null | undefined;
  userFirm: Firm | null | undefined;
  handleMenuClose: () => void;
  onViewSwitch?: () => void;
  setAboutModal: Dispatch<SetStateAction<boolean>>;
  setContactUsModal: Dispatch<SetStateAction<boolean>>;
};

export function AccountMenu({
  anchorEl,
  email,
  isStaffAdmin,
  name,
  profile,
  userFirm,
  handleMenuClose,
  onViewSwitch,
  setAboutModal,
  setContactUsModal,
}: AccountMenuProps) {
  const classes = useStyles();
  const { setOpenSaveModal, isFormDirty, setLogoutAttempted }: FileDataContextInterface =
    useContext(FileDataContext) ?? {};
  const { signOut, getAccessToken } = useAuth();
  const isMenuOpen = Boolean(anchorEl);

  const handleHelpClick = () => {
    window.open('/help', '_blank');
    handleMenuClose();
  };

  const handleSwitchToClassicClick = () => {
    let anteUrl = `${getAnteUrl()}?t=AN2`;

    if (userFirm?.firmId) {
      const encodedFirmId = HashUtils.encode(parseInt(userFirm.firmId));
      anteUrl += `&f=${encodedFirmId}`;
    }

    window.open(anteUrl, '_blank');
    handleMenuClose();
  };

  const handleLogout = async () => {
    // If the Edit File form has unsaved changes, open the modal
    if (isFormDirty) {
      setOpenSaveModal && setOpenSaveModal(true);
      setLogoutAttempted && setLogoutAttempted(true);
      handleMenuClose();
    }
    // otherwise, just do the action
    else {
      if (profile?.login) {
        try {
          const token = await getAccessToken();
          await clearUserCache(profile.login, token);
          sessionStorage.clear();
          localStorage.clear();
        } catch (error) {
          console.error('Error clearing user cache:', error);
        }
      }
      await signOut();
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      getContentAnchorEl={null}
      id={'account-menu'}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      PaperProps={{ className: classes.accountMenuWrap }}
      MenuListProps={{ className: classes.accountMenuList }}
    >
      <div className={classes.accountMenu}>
        <MenuItem className={classes.accountMenuDetails} disableRipple>
          <Typography variant="h3">{name ? name : ''}</Typography>
          <Typography variant="caption" color="textSecondary">
            {email}
          </Typography>
        </MenuItem>
        {!isStaffAdmin && (
          <>
            <MenuItem className={clsx(classes.disableHover, classes.divider)}>
              <AgentNetButton
                variant="outlined"
                color="primary"
                disableFocusRipple
                disableRipple
                data-testid="HeaderManageProfile"
                onClick={onViewSwitch}
                size="small"
              >
                {'Manage User Profile'}
              </AgentNetButton>
            </MenuItem>
            <MenuItem
              onClick={() => setContactUsModal(true)}
              className={classes.disableHover}
              data-testid="HeaderContactUs"
            >
              <MessageOutlined color="primary" />
              <Typography variant="body1" className={classes.menuIconBtn}>
                Contact Us
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleHelpClick} className={classes.disableHover} data-testid="HeaderHelp">
              <HelpOutlineOutlined color="primary" />
              <Typography variant="body1" className={classes.menuIconBtn}>
                Help
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => setAboutModal(true)} className={classes.disableHover} data-testid="HeaderAboutApp">
              <InfoOutlined color="primary" />
              <Typography variant="body1" className={classes.menuIconBtn}>
                About App
              </Typography>
            </MenuItem>
          </>
        )}
        <MenuItem
          onClick={handleSwitchToClassicClick}
          className={classes.disableHover}
          data-testid="HeaderSwitchToClassic"
        >
          <ExitToAppOutlined color="primary" />
          <Typography variant="body1" className={classes.menuIconBtn}>
            Switch to Classic
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleLogout} className={classes.disableHover} disableRipple data-testid="HeaderSignOut">
          <LogoutOutlined />
          <Typography variant="body1" className={classes.menuIconBtn}>
            Sign out
          </Typography>
        </MenuItem>
      </div>
    </Menu>
  );
}
