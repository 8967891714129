import { useAuth } from '@agentnet/auth';
import {
  DrawerComponent,
  DrawerContent,
  DrawerExit,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@fluentsms/agentnet-web-components';
import { Button, createStyles, IconButton, makeStyles, Tab, Tabs, Theme } from '@material-ui/core';
import { SendOutlined } from '@material-ui/icons';
import { getNotesApi, uploadNotesAndDocumentsApi } from 'api/orderManagement/order-management-api';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import { ChangeEvent, FormEvent, useContext, useEffect, useRef, useState } from 'react';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import { NoMessages } from 'ui-kit/components/NoMessages';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import { ConversationTabPanel } from './ConversationTabPanel';
import { NoteAttachmentItem } from './NoteAttachmentItem';
import {
  GetNotesActionLog,
  NotesType,
  UploadNotesAndDocumentsRequestType,
  UploadNotesAndDocumentsResponseType,
} from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .ag-center-cols-viewport': {
        height: 220,
      },
    },
    inputContainer: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 4,
      display: 'flex',
    },
    inputMessage: {
      background: 'none',
      border: 0,
      padding: theme.spacing(0, 2),
      width: '100%',
    },
    inputSubmit: {
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderRadius: 0,
      color: theme.palette.actionSecondary.active,
      padding: theme.spacing(0.8, 1),
    },
    tabContent: {
      height: 'calc(100% - 64px)',
      overflow: 'hidden',
      overflowY: 'auto',
    },
    loadingClass: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      minHeight: 'calc(100vh - 400px)',
    },
  }),
);

interface ConversationDrawerProps {
  conversationPayload: GetNotesActionLog | undefined;
  conversationFileNumber: string;
  onClose: () => void;
  isDashboardMessagePanelOpen: boolean;
}

export function ConversationDrawer({
  conversationPayload,
  conversationFileNumber,
  onClose,
  isDashboardMessagePanelOpen,
}: ConversationDrawerProps) {
  const classes = useStyles();
  const { getAccessToken } = useAuth();
  const [tabValue, setTabValue] = useState<number>(0);
  const messageRef = useRef<HTMLInputElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [conversationsState, setConversationsState] = useState<Array<NotesType>>();
  const { addGlobalMsg } = useGlobalMessages();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { profile } = profileCtx;

  const profileUserId = profile?.userId;
  const orderId = conversationPayload?.orderId;
  const fileId = conversationPayload?.fileId;

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getNotesData = async (orderId: number, fileId: string): Promise<Array<NotesType>> => {
    const token = await getAccessToken();
    const response = await getNotesApi(orderId, fileId, token);
    return response;
  };

  const {
    execute: executeGetNotes,
    status: executeGetNotesStatus,
    value: executeGetNotesResults,
    errors: executeGetNotesErrors,
  } = useAsync<Array<NotesType>>(getNotesData, false);

  useEffect(() => {
    if (conversationPayload && isDashboardMessagePanelOpen) {
      executeGetNotes(orderId, fileId);
    }
  }, [conversationPayload, isDashboardMessagePanelOpen]);

  useEffect(() => {
    if (executeGetNotesStatus === 'success' && executeGetNotesResults) {
      setConversationsState(executeGetNotesResults);
    }
  }, [executeGetNotesStatus, executeGetNotesResults]);

  useEffect(() => {
    if (executeGetNotesErrors && executeGetNotesErrors.length > 0) {
      executeGetNotesErrors.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [executeGetNotesErrors]);

  useEffect(() => {
    scrollToBottom();
  }, [conversationsState]);

  const postNotesAndDocumentsData = async ({
    orderId,
    fileId,
    notes,
    uploadFileName,
    uploadFileString,
  }: UploadNotesAndDocumentsRequestType): Promise<UploadNotesAndDocumentsResponseType> => {
    const token = await getAccessToken();
    const response = await uploadNotesAndDocumentsApi(
      {
        orderId,
        fileId,
        notes,
        uploadFileName,
        uploadFileString,
      },
      token,
    );
    return response;
  };

  const onTabChange = (e: ChangeEvent<unknown>, newValue: number): void => {
    setTabValue(newValue);
  };

  const onFormSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (messageRef.current && messageRef.current.value.length > 0) {
      setIsFormSubmitting(true);

      if (orderId && fileId && conversationsState) {
        try {
          const payload: UploadNotesAndDocumentsRequestType = {
            orderId,
            fileId,
            notes: messageRef.current.value,
            uploadFileName: '', // Assuming no file upload for now
            uploadFileString: '', // Assuming no file upload for now
          };

          const response = await postNotesAndDocumentsData(payload);

          if (response) {
            executeGetNotes(orderId, fileId);
            if (executeGetNotesResults) {
              setConversationsState(executeGetNotesResults);
            }
          }

          messageRef.current.value = '';
          setIsFormSubmitting(false);
        } catch (e) {
          if (e instanceof Error) {
            addGlobalMsg({
              message: e.message,
              type: 'error',
            });
          }

          setIsFormSubmitting(false);
        }
      }
    }
  };

  const renderLoading = () => (
    <div className={classes.loadingClass}>
      <LoadingSpinner status="pending" variant="circle" />
    </div>
  );

  const renderConversations = () => (
    <>
      {conversationsState?.length ? (
        conversationsState.map((note, index) => (
          <NoteAttachmentItem conversation={note} profileUserId={profileUserId} key={index} />
        ))
      ) : (
        <NoMessages />
      )}
      <div ref={messagesEndRef} />
    </>
  );

  return (
    <>
      <DrawerComponent isDrawerOpen={conversationPayload ? true : false}>
        <DrawerHeader
          content={<DrawerTitle title={`File No: ${conversationFileNumber}`} />}
          contentRight={
            <DrawerExit
              onClick={() => {
                onClose();
              }}
            />
          }
          divider
          subHeader={
            <Tabs aria-label="Conversation logs" indicatorColor="primary" value={tabValue} onChange={onTabChange}>
              <Tab aria-controls="conversation-panel" id="conversation-tab" label="Conversation" />
            </Tabs>
          }
        />
        <DrawerContent>
          <ConversationTabPanel
            aria-labelledby="conversation-tab"
            id="conversation-panel"
            index={0}
            value={tabValue}
            style={{ height: '100%' }}
          >
            <div className={classes.tabContent}>
              {!executeGetNotesResults ? renderLoading() : conversationsState ? renderConversations() : null}
            </div>
            <form onSubmit={onFormSubmit}>
              <div className={classes.inputContainer}>
                <input
                  className={classes.inputMessage}
                  disabled={isFormSubmitting}
                  placeholder="Type a message..."
                  ref={messageRef}
                />
                <IconButton
                  className={classes.inputSubmit}
                  disabled={isFormSubmitting}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  type="submit"
                >
                  <SendOutlined />
                </IconButton>
              </div>
            </form>
          </ConversationTabPanel>
        </DrawerContent>
        <DrawerFooter>
          <Button color="primary" variant="contained" onClick={onClose}>
            Done
          </Button>
        </DrawerFooter>
      </DrawerComponent>
    </>
  );
}
