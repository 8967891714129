import { useAuth } from '@agentnet/auth';
import { pxToRem } from '@fluentsms/agentnet-web-components';
import { createStyles, Grid, ListItemText, makeStyles, MenuItem, Theme, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { EditNewUser, getOfficeDetails, postAddNewUser, searchFirmByDetails } from 'api/manageUsers/manage-users-api';
import { getUser, setUser } from 'api/orderManagement/order-management-api';
import clsx from 'clsx';
import { defaultFscTeam, getTeamAssignmentIds, TeamAssignment } from 'features/orderManagement/enum';
import { UserType } from 'features/orderManagement/types';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import { useNavPermissions } from 'hooks/useNavPermissions';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BottomAppBar from 'ui-kit/components/bottomAppBar/BottomAppBar';
import PageHeader from 'ui-kit/components/headers/PageHeader';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import { AgentNetCheckBox, AgentNetDropdownSelector, AgentNetTextInput } from 'ui-kit/inputs';
import { AddUserSchema, EditUserSchema, EditUserSchemaForIAM } from 'utilities/validation/schemas/manage-users-schema';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import AutocompleteChecklist from '../../../ui-kit/inputs/AutocompleteFirms/AutocompleteFirms'; // Import the AutocompleteChecklist component
import PhoneField from '../../../ui-kit/inputs/PhoneField/PhoneField';
import { AddUser } from './type';
import UserBanner from './userBanner';

interface AddNewUserProps {
  activityRights: any;
  userId?: number;
  userDetail: any;
  reLoadEdit?: (userId: number) => void;
}

const AddNewUser = ({ activityRights, userId = 0, userDetail, reLoadEdit }: AddNewUserProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      fieldSection: {
        display: 'flex',
        paddingBottom: theme.spacing(8),
      },
      fieldSectionLeft: {
        padding: theme.spacing(3),
      },
      fieldSectionColumn: {
        marginRight: theme.spacing(200),
        padding: theme.spacing(3),
      },
      fieldSectionRight: {
        padding: theme.spacing(3),
        marginTop: 0,
      },
      addFirm: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'right',
        lineHeight: 'normal',
        transition: 'none',
      },
      notes: {
        size: 'large',
        multiline: true,
      },
      tableRow: {
        borderBottom: `0px solid #E0E0E0`,
      },
      tableCell: {
        display: 'flex',
        alignItems: 'left',
      },

      textBox: {
        padding: pxToRem(12),
        marginLeft: '50px',
      },
      lable: {
        fontSize: '11px',
        padding: pxToRem(10),
      },

      errorlabel: {
        marginTop: '10px',
        marginRight: '110px',
        marginBottom: theme.spacing(1),
        whitespace: 'nowrap',
      },
      gridContainer: {
        height: 'calc(100vh - 200px) !important',
        width: '100%',
        fontFamily: theme.typography.fontFamily,
        marginLeft: theme.spacing(2.5),
      },
      rowHover: {
        '&:hover': {
          backgroundColor: 'transparent !important',
        },
      },
      textFormat: {
        color: 'grey',
        fontSize: '14px',
      },
    }),
  );

  type CheckedItems = {
    [key: string]: boolean;
  };

  interface OptionType {
    id?: string | number;
    name: string;
    default?: boolean;
    officeName?: string;
    isTitleEscrow?: boolean;
    status?: string;
    firmIdArray?: any;
  }

  const history = useHistory();
  const useProfile: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm, profile } = useProfile;
  const { hasPermission } = useNavPermissions();
  const [addUser, setAddUser] = useState<AddUser>({
    FirstName: '',
    LastName: '',
    EmailAddress: '',
    PhoneNumber: '',
    Role: '',
    IsHqUser: false,
    Notes: '',
    Status: 'Not Activated',
    AssociatedFirmId: userFirm?.firmId ?? '',
    AssociatedOfficeId: userFirm?.offices?.find((office) => office.isDefault === true)?.officeId ?? 0,
    AdditionalRightName: [],
    LoginExpirationDays: 0,
    AdditionalFirmAccessIds: [],
  });
  const classes = useStyles();
  const adminRoles = [
    { name: 'System Administrator', value: 'System Administrator' },
    { name: 'Staff Administrator', value: 'Staff Administrator' },
    { name: 'On Behalf Of', value: 'On Behalf Of' },
    { name: 'User', value: 'User' },
    { name: 'FSC', value: 'FSC' },
  ];

  const adminAccessRoles = ['System Administrator', 'Staff Administrator', 'On Behalf Of', 'User', 'FSC'];

  const roles = [
    { name: 'Agent', value: 'Agent' },
    { name: 'Title & Escrow User', value: 'Title & Escrow User' },
    { name: 'Title Only User', value: 'Title Only User' },
    { name: 'Escrow Only User', value: 'Escrow Only User' },
  ];

  const AgentRole = [{ name: 'Agent', value: 'Agent' }];

  const titleEscrowRoles = [
    { name: 'Title & Escrow User', value: 'Title & Escrow User' },
    { name: 'Title Only User', value: 'Title Only User' },
    { name: 'Escrow Only User', value: 'Escrow Only User' },
  ];

  const fscTeams = getTeamAssignmentIds().map((value) => {
    return { name: TeamAssignment[value], value: value };
  });

  const firmSearchInput = {
    firmName: null,
    status: null,
    officeAddress: null,
    officeName: null,
  };
  const status = [
    { name: 'Not Activated', value: 'Not Activated' },
    { name: 'Active', value: 'Active' },
    { name: 'Disabled', value: 'Disabled' },
    { name: 'Inactive', value: 'Inactive' },
  ];

  const statusInactive = [
    { name: 'Active', value: 'Active' },
    { name: 'Disabled', value: 'Disabled' },
    { name: 'Inactive', value: 'Inactive' },
  ];

  const statusActiveDisabled = [
    { name: 'Active', value: 'Active' },
    { name: 'Disabled', value: 'Disabled' },
  ];

  const [roleValue, setRoleValue] = useState<string>('');
  const [officeName, setOfficeName] = useState<any>(userFirm?.firmId);
  const { addSnackbarMessage } = useSnackBars();
  const [allActivityRights, setAllActivilityRights] = React.useState<any>(null);
  const [defaultRights, setDefaultRights] = React.useState<any>(null);
  const [additionalRights, setAdditionalRights] = React.useState<any>(null);
  const [checkedItems, setCheckedItems] = React.useState<CheckedItems>({
    // 'View Agent Dashboard Premium Revenue': true,
  });
  const [daysToExpire, setDaysToExpire] = React.useState<string>('90');
  const [expirationDate, setExpirationDate] = React.useState<string>(); // State for the calculated expiration date
  const [error, setError] = React.useState<string>(''); // State for error message
  const hqUser = 'HQ User';
  const [firmSearchModel, setFirmSearchModel] = useState<any>(firmSearchInput);
  const { getAccessToken } = useAuth();
  const [showFirm, setShowFirm] = useState<boolean>(true);

  const [firmNameArray, setfirmNameArray] = useState<any>(
    useProfile?.profile?.roleId === 3
      ? []
      : userFirm && Object.keys(userFirm).length === 0
      ? []
      : Array.isArray(userFirm?.name)
      ? userFirm?.name
      : [{ name: userFirm?.name, value: userFirm?.firmId }],
  );
  const [firmTitleValue, setFirmTitle] = useState<any>(
    firmNameArray && firmNameArray.length > 0 ? firmNameArray[0].value : null,
  );
  const [shouldShowValidation, setShouldShowValidation] = useState(false);
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const [updateUserValidationErrors, setUpdateUserValidationErrors] = useState<FieldValidationError[]>([]);
  const [officeNameArray, setOfficeNameArray] = useState<any>(
    firmNameArray.length === 0
      ? []
      : Array.isArray(userFirm?.offices)
      ? userFirm?.offices
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((office) => ({
            name: office.name,
            value: office.officeId,
          }))
      : [],
  );
  const [OfficeValue, setOfficeValue] = useState<number>(
    officeNameArray && officeNameArray.length > 0 ? officeNameArray[0]?.value : '',
  );
  const [firmId, setFirmId] = useState<any>(useProfile?.profile?.roleId === 3 ? null : userFirm?.firmId);
  const [defaultFirmId, setDefaultFirmId] = useState<any>(userDetail?.userInfo?.DefaultFirmId);
  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>(
    firmNameArray && firmNameArray.length > 0 && useProfile?.profile?.roleId !== 3
      ? [
          {
            name: firmNameArray[0].name,
            id: firmNameArray[0].value,
            isTitleEscrow: useProfile?.profile?.firms?.filter(
              (firm: any) => firm?.firmId === firmNameArray[0].value,
            )?.[0]?.isTitleEscrow,
          },
        ]
      : [],
  );
  const mappedFirms =
    userDetail?.MultipleFirms.map((firm: any) => ({
      value: firm.FirmID,
      name: firm.FirmName,
    })) || [];
  const uniqueMappedFirms = Array.from(new Set(mappedFirms.map((firm: any) => firm.value))).map((value) =>
    mappedFirms.find((firm: any) => firm.value === value),
  );
  const [defaultFirmOptions, setDefaultFirmOptions] = useState<any>(
    Array.isArray(uniqueMappedFirms) ? uniqueMappedFirms : [],
  );

  const [selectedOffice, setSelectedOffice] = useState<number>(userDetail?.userInfo?.FirmLocationId);
  const [firstName, setFirstName] = useState<string>(userDetail?.userInfo?.FirstName);
  const [lastName, setLastName] = useState<string>(userDetail?.userInfo?.LastName);
  const [email, setEmail] = useState<string>(userDetail?.userInfo?.EmailAddress);
  const [phoneNumber, setPhoneNumber] = useState<string>(userDetail?.userInfo?.PhoneNumber);
  const [isSave, setIsSave] = useState(false);

  const getLast10Digits = (phoneNumber: string): string => {
    return phoneNumber?.slice(-10);
  };

  const [actualPhoneNumber, setActualPhoneNumber] = useState<string>(
    getLast10Digits(userDetail?.userInfo?.PhoneNumber),
  );

  const [statusValue, setStatusValue] = useState<string>(
    userDetail?.userInfo?.StatusType === 'NotActivated' ? 'Not Activated' : userDetail?.userInfo?.StatusType,
  );
  const [notes, setNotes] = useState<string>(userDetail?.userInfo?.Notes);
  const [isHqUser, setIsHqUser] = useState<boolean>(userDetail?.userInfo?.IsHQUser);
  const [LoginExpirationDays, setLoginExpirationDays] = useState<number>(
    userDetail?.userInfo?.LoginExpirationDays === 0 ? daysToExpire : userDetail?.userInfo?.LoginExpirationDays,
  );
  const assignedFirms = userDetail?.MultipleFirms.map((firm: any) => ({ firmId: firm.FirmID }));
  const [multipleFirms, setMultipleFirms] = useState<any>(assignedFirms);
  const [multipleActivityRights, setMultipleActivityRights] = useState<any>(
    userDetail?.AdocAssignedActivityRights.map((activity: any) => activity.ActivityRightName),
  );
  const [loginUserId, setLoginUserId] = useState<string>(userDetail?.userInfo?.LoginUserId);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [addUserId, setAddUserId] = useState<number>();

  const [defaultFirm, setDefaultFirm] = useState<any>(
    userDetail?.MultipleFirms?.filter((firm: any) => firm?.IsDefaultFirm === true),
  );
  const [userRoleOptions, setuserRoleOptions] = useState<any>(
    userId && userId > 0 && (useProfile.profile?.roleId === 13 || hasPermission?.orderManagement)
      ? adminRoles
      : userId &&
        userId > 0 &&
        useProfile.profile?.roleId === 3 &&
        adminAccessRoles.includes(userDetail?.userInfo?.RoleName)
      ? adminRoles
      : userId && userId > 0
      ? defaultFirm[0]?.IsTitleEsrowFirm === true
        ? titleEscrowRoles
        : AgentRole
      : userId === 0 && useProfile?.profile?.roleId === 3
      ? roles
      : userFirm?.isTitleEscrow === true
      ? titleEscrowRoles
      : AgentRole,
  );
  const [defaultRoleValue, setDefaultRoleValue] = useState<any>(
    userId === 0 && useProfile?.profile?.roleTypeId === 100 && userFirm?.isTitleEscrow === true
      ? ''
      : userId === 0 && userRoleOptions.length === 1
      ? 'Agent'
      : '',
  );
  const [role, setRole] = useState<string>(
    userRoleOptions.find((option: any) => option.name === userDetail?.userInfo?.RoleName)
      ? userDetail?.userInfo?.RoleName
      : '',
  );
  const [fscTeamValue, setFscTeamValue] = useState<number>(0);
  const [fscUser, setFscUser] = useState<UserType>();
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [hqName, setHqName] = useState(userDetail?.userInfo?.DefaultFirmName);

  const addNewUser = (field: string, value: any) => {
    const newUser = { ...addUser, [field]: value } as AddUser;
    setAddUser(newUser);
  };

  useEffect(() => {
    if (userId && userId > 0) {
      setSelectedOptions((prevOptions) => {
        const defaultUpdatedOptions = prevOptions.filter((option) => option?.id != defaultFirmId);
        const defaultfirmTitleOption = prevOptions.find((option) => option?.id === defaultFirmId);
        if (defaultfirmTitleOption) {
          defaultUpdatedOptions.unshift(defaultfirmTitleOption);
        }
        return defaultUpdatedOptions;
      });
    } else {
      setSelectedOptions((prevOptions) => {
        const updatedOptions = prevOptions.filter((option) => option.id !== firmTitleValue);
        const firmTitleOption = prevOptions.find((option) => option.id === firmTitleValue);
        if (firmTitleOption) {
          updatedOptions.unshift(firmTitleOption);
        }
        return updatedOptions;
      });
    }
  }, [firmTitleValue, defaultFirmId]);

  const handleChange = (newValue: OptionType[]) => {
    setSelectedOptions((prevOptions) => {
      const existingIds = new Set(prevOptions?.map((option) => option.id));
      const filteredNewValue = newValue.filter((option) => !existingIds.has(option.id));

      if (useProfile?.profile?.roleId === 3) {
        return prevOptions ? [...prevOptions, ...filteredNewValue] : filteredNewValue;
      } else {
        const mergedOptions = filteredNewValue.filter((option) => option.status !== 'Inactive');
        return prevOptions ? [...prevOptions, ...mergedOptions] : mergedOptions;
      }
    });
    const firmNameList = newValue.map((option) => ({ name: option.name, value: option.id }));
    setfirmNameArray(firmNameList);
    if (userId && userId > 0) {
      setDefaultFirmOptions((prevOptions: any) => {
        const existingIds = new Set(prevOptions.map((option: any) => option.value));
        const filteredFirmNameList = firmNameList?.filter((option: any) => !existingIds.has(option.value));
        return [...prevOptions, ...filteredFirmNameList];
      });
    }
    if (!userId && userId <= 0 && !firmTitleValue) {
      setFirmId(newValue[0]?.id);
      setOfficeValue(officeNameArray && officeNameArray.length > 0 ? officeNameArray[0]?.value : null);
      setSelectedOffice(officeNameArray && officeNameArray.length > 0 ? officeNameArray[0]?.value : null);
      setFirmTitle(firmNameArray && firmNameArray.length > 0 ? firmNameArray[0]?.value : null);
      if (selectedOptions[0]?.isTitleEscrow == false) {
        setuserRoleOptions(AgentRole);
        setDefaultRoleValue('Agent');
        setRole('Agent');
        addNewUser('Role', 'Agent');
      } else if (!selectedOptions[0] && newValue[0]?.isTitleEscrow == false) {
        setuserRoleOptions(AgentRole);
        setDefaultRoleValue('Agent');
        setRole('Agent');
        addNewUser('Role', 'Agent');
      } else {
        setuserRoleOptions(titleEscrowRoles);
        setDefaultRoleValue('');
        setRole('');
        addNewUser('Role', '');
      }
    }
  };

  useEffect(() => {
    if (!userId && userId <= 0 && !firmTitleValue) {
      setFirmTitle(firmNameArray && firmNameArray.length > 0 ? firmNameArray[0]?.value : null);
    }
  }, [firmNameArray]);

  const handleDelete = (option: OptionType) => {
    const newOptions = selectedOptions.filter((opt) => opt.name !== option.name);
    setSelectedOptions(newOptions);
    const updatedFirmNameArray = newOptions.map((opt) => ({
      name: opt.name,
      value: opt.id,
    }));
    setDefaultFirmOptions(updatedFirmNameArray);
  };

  const validateErrors = async (addUser: AddUser) => {
    if (addUser.AssociatedFirmId === null) {
      addUser['AssociatedFirmId'] = '';
    }
    if (getFirmsValue) {
      if (userId && userId > 0) {
        addUser['Role'] = role;
      } else {
        addUser['Role'] = defaultRoleValue;
      }
    }
    const errs: FieldValidationError[] = (await doValidate(addUser, AddUserSchema)) || [];
    await errs.forEach((error) => {
      if (error?.field === 'AssociatedOfficeId' && !addUser.AssociatedOfficeId) {
        error.message = 'Office Name Required';
      }
      if (error?.field === 'Role') {
        error.message = 'Role Required';
      }
    });
    await setValidationErrors(errs);
  };
  const validateUpdateUserErrors = async () => {
    const formattedPhoneNumber = formatPhoneNumber(actualPhoneNumber);

    const updateRequest = {
      UserId: userId > 0 ? userId : addUserId,
      FirstName: firstName,
      LastName: lastName,
      MiddleName: userDetail?.userInfo?.MiddleName,
      EmailAddress: email,
      PhoneNumber: formattedPhoneNumber,
      Role: role,
      UserStatus: statusValue,
      Notes: notes,
      IsHqUser: isHqUser,
      AssociatedFirmId: defaultFirmId,
      AssociatedOfficeId: selectedOffice,
      AdditionalFirmAccessIds: multipleFirms,
      LoginExpirationDays: LoginExpirationDays,
      LoginUserId: loginUserId,
      AdditionalRightName: multipleActivityRights,
    };
    let errs: FieldValidationError[];
    if (useProfile?.profile?.roleId === 13) {
      errs = (await doValidate(updateRequest, EditUserSchemaForIAM)) || [];
    } else {
      errs = (await doValidate(updateRequest, EditUserSchema)) || [];
    }

    const updatedErrs = await errs.map((err) => {
      if (err.field == 'AssociatedOfficeId') {
        err.field = 'AssociatedOfficeId';
      }
      if (err.field == 'UserStatus') {
        err.field = 'status';
      }
      if (err.field == 'AssociatedFirmId') {
        err.field = 'AssociatedFirmId';
      }
      if (err.field == 'UserStatus') {
        err.field = 'status';
      }
      if (err.field == 'Role') {
        err.message = 'Role Required';
      }
      if (
        err.field == 'EmailAddress' &&
        err.message === 'Email address cannot contain the First American domain' &&
        useProfile?.profile?.roleId === 13
      ) {
        err.message = '';
      }
      if (
        err.field == 'EmailAddress' &&
        err.message === 'Email address cannot contain the First American domain' &&
        useProfile?.profile?.roleId != 13 &&
        statusValue != 'Not Activated'
      ) {
        err.message = '';
      } else {
        return err;
      }
      return err;
    });
    const errors = updatedErrs.filter((err) => err.message !== '');

    setUpdateUserValidationErrors(errors);
  };

  useEffect(() => {
    validateUpdateUserErrors();
  }, [firstName, lastName, email, phoneNumber, role, statusValue, selectedOffice, defaultFirmId]);

  useEffect(() => {
    if (userId && userId > 0) {
      const array: Array<number> = [];
      array.push(userDetail?.userInfo?.UserId);
      setDaysToExpire(userDetail?.userInfo?.LoginExpirationDays?.toString());
      setDefaultFirm(userDetail?.userInfo?.DefaultFirmName);
      const defaultOfficeName =
        userId && userId > 0 && userFirm?.offices
          ? userFirm?.offices?.find((office) => office.officeId === userDetail?.userInfo?.FirmLocationId)?.name ?? ''
          : addUser.AssociatedOfficeId ?? officeName;
      setOfficeName(defaultOfficeName);
      if (useProfile?.profile?.roleId === 3 && adminAccessRoles.includes(userDetail?.userInfo?.RoleName)) {
        setShowFirm(false);
      }

      if (useProfile?.profile?.roleId === 13) {
        setShowFirm(false);
      }

      if (userDetail?.MultipleFirms.length > 0) {
        userDetail?.MultipleFirms.map((firm: any) => {
          setSelectedOptions((prevState: any) => [
            ...prevState,
            {
              id: firm.FirmID,
              name: firm.FirmName,
              default: firm.IsDefaultFirm,
              isTitleEscrow: firm.IsTitleEsrowFirm,
              officeName: firm.OfficeName,
            },
          ]);
        });
      }

      const AdditionalRightName: Array<string> = Array.from(userDetail?.AdocAssignedActivityRights).map(
        (activity: any) => activity.ActivityRightName,
      );

      const initialCheckedItems: CheckedItems = AdditionalRightName?.reduce((acc, right) => {
        acc[right] = true;
        return acc;
      }, {} as CheckedItems);

      setCheckedItems(initialCheckedItems);
    }
  }, [userId && userId > 0]);

  useEffect(() => {
    if (selectedOptions?.length > 0) {
      const additionalFirmsId: number[] = selectedOptions?.map((firm: any) => {
        return firm.id;
      });

      setMultipleFirms(additionalFirmsId);
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (userId && userId === 0) {
      if (addUser) validateErrors(addUser);
    }
  }, [addUser]);

  useEffect(() => {
    if (addUser) {
      validateErrors(addUser);
    }
  }, [addUser]);

  useEffect(() => {
    if (checkedItems != null) {
      let checkedItemKeys = Object.keys(checkedItems).filter((key) => checkedItems[key]);
      checkedItemKeys = checkedItemKeys.filter((key) => key !== 'Select All');
      addUser.AdditionalRightName = checkedItemKeys;
      setMultipleActivityRights(checkedItemKeys);
    }
  }, [checkedItems]);

  useEffect(() => {
    const firmIdArray = firmNameArray.map((option: any) => option.value);
    addUser.AdditionalFirmAccessIds = firmIdArray;
  }, [firmNameArray]);

  useEffect(() => {
    addUser.AssociatedOfficeId = OfficeValue;
  }, [OfficeValue]);

  useEffect(() => {
    addUser.AssociatedFirmId = firmId;
  }, [firmId]);

  const addUserRequest = async (): Promise<any> => {
    const token = await getAccessToken();
    const response = await postAddNewUser(addUser, token);
    return response;
  };

  const {
    execute: executeAddUser,
    value: addUserResponseValue,
    status: addUserResponseStatus,
    errors: addUserResponseError,
  } = useAsync<any>(addUserRequest, false);

  const editUserRequest = async (): Promise<boolean> => {
    const formattedPhoneNumber = formatPhoneNumber(actualPhoneNumber);
    const updateRequest = {
      UserId: userId > 0 ? userId : addUserId,
      FirstName: firstName,
      LastName: lastName,
      MiddleName: userDetail?.userInfo?.MiddleName,
      EmailAddress: email,
      PhoneNumber: formattedPhoneNumber,
      Role: role,
      UserStatus: statusValue === 'Not Activated' ? 'NotActivated' : statusValue,
      Notes: notes,
      IsHqUser: isHqUser,
      AssociatedFirmId: defaultFirmId,
      AssociatedOfficeId: selectedOffice,
      AdditionalFirmAccessIds: multipleFirms,
      LoginExpirationDays: LoginExpirationDays,
      LoginUserId: loginUserId,
      AdditionalRightName: multipleActivityRights,
    };
    const token = await getAccessToken();
    const response = await EditNewUser(updateRequest, token);
    return response;
  };

  const setFscTeamRequest = async (): Promise<UserType> => {
    const user: UserType = {
      userId: userId,
      fscRoleId: fscTeamValue,
      fscRole: TeamAssignment[fscTeamValue],
      batchLimit: fscUser?.batchLimit ?? 0,
      isActive: fscUser?.isActive ?? false,
    };
    const token = await getAccessToken();
    const response = await setUser(user, token);
    return response;
  };

  const { execute: executeUpdateUser, status: editUserResponseStatus } = useAsync<boolean>(editUserRequest, false);
  const { execute: executeSetFscTeam, status: executeSetFscTeamStatus } = useAsync<UserType>(setFscTeamRequest, false);

  const getOfficeList = async (firmId: any) => {
    const token = await getAccessToken();
    const response = await getOfficeDetails(token, firmId);
    const officeNames = response.Offices.map((office: any) => ({
      name: office.Name,
      value: office.OfficeId,
    }));
    setOfficeNameArray(officeNames);
    if (userId && userId > 0 && response?.Offices) {
      const defaultOffice = response?.Offices?.find(
        (office: any) => office.OfficeId === userDetail?.userInfo?.FirmLocationId,
      );
      if (defaultOffice !== undefined) {
        setSelectedOffice(officeNameArray[0].value);
      }
    }
    setOfficeValue(officeNames[0]?.value);
    setSelectedOffice(officeNames[0]?.value);
    if (firmId === firmNameArray[0]?.value) {
      setFirmTitle(firmNameArray && firmNameArray.length > 0 ? firmNameArray[0]?.value : null);
    }
    const officeDefault = officeNames?.find((office: any) => office.name === selectedOptions[0]?.officeName);
    const office = officeNames?.find((office: any) => office.name === selectedOptions[0]?.officeName);
    if (selectedOptions?.length === 0) {
      if (selectedOptions[0]?.isTitleEscrow) {
        addNewUser('AssociatedOfficeId', officeDefault?.value);
        addNewUser('Role', 'Title & Escrow User');
      } else {
        addNewUser('AssociatedOfficeId', officeDefault?.value);
        addNewUser('Role', 'Agent');
      }
    } else if (selectedOptions[0]?.isTitleEscrow) {
      addNewUser('AssociatedOfficeId', office?.value);
      addNewUser('Role', 'Title & Escrow User');
    } else {
      addNewUser('AssociatedOfficeId', office?.value);
      addNewUser('Role', 'Agent');
    }
    return response;
  };

  const { execute: executegetOfficeList } = useAsync<boolean>(getOfficeList, false);

  useEffect(() => {
    if (firmId && !(userId && userId > 0)) {
      executegetOfficeList(firmId).then();
      setOfficeValue(officeNameArray && officeNameArray.length > 0 ? officeNameArray[0]?.value : null);
      setSelectedOffice(officeNameArray && officeNameArray.length > 0 ? officeNameArray[0]?.value : null);
    }
  }, [firmId]);

  const fetchFirms = async (): Promise<any[]> => {
    const token = await getAccessToken();
    userId && userId > 0
      ? (firmSearchModel['firmName'] = defaultFirmOptions.find((firm: any) => firm.value === defaultFirmId).name)
      : (firmSearchModel['firmName'] = firmNameArray.find((firm: any) => firm.value === firmTitleValue).name);
    const response = await searchFirmByDetails(token, firmSearchModel);
    if (!userId && userId <= 0 && !firmTitleValue && useProfile?.profile?.roleId === 3) {
      setHqName('');
    } else {
      if (response && userId === 0) {
        setHqName(response[0]?.hqName);
      } else {
        setHqName(response[0]?.hqName);
      }
    }
    validateErrors(addUser);
    firmSearchModel['firmName'] = '';
    return firmSearchModel;
  };

  const getFscUser = async (): Promise<UserType> => {
    try {
      const token = await getAccessToken();
      const response = await getUser(userId, token);
      if (!getTeamAssignmentIds().includes(response.fscRoleId)) {
        throw new Error('Invalid FSC Role');
      }
      setFscUser(response);
      setFscTeamValue(response?.fscRoleId);
      return response;
    } catch (error) {
      // default user to display if user does not have FSC role
      const defaultUser: UserType = {
        userId: userId,
        fscRoleId: defaultFscTeam.id,
        fscRole: defaultFscTeam.team,
        batchLimit: 0,
        isActive: userDetail?.userInfo?.StatusType === 'Active',
      };
      setFscUser(defaultUser);
      setFscTeamValue(defaultUser.fscRoleId);
      return defaultUser;
    }
  };

  const { execute: executeGetFirms, value: getFirmsValue } = useAsync<any[]>(fetchFirms, false);
  const { execute: executeFscUser } = useAsync<UserType>(getFscUser, false);

  useEffect(() => {
    executeGetFirms();
  }, [defaultFirmId, firmTitleValue]);

  useEffect(() => {
    executeGetFirms();
    hasPermission?.orderManagementAdmin && executeFscUser();
  }, []);

  useEffect(() => {
    if (defaultFirmId) {
      executegetOfficeList(defaultFirmId);
      setSelectedOptions((prevOptions) => {
        const defaultUpdatedOptionsEdit = prevOptions.filter((option) => option.id !== defaultFirmId);
        const defaultfirmTitleOptionEdit = prevOptions.find((option) => option.id === defaultFirmId);
        if (defaultfirmTitleOptionEdit) {
          defaultUpdatedOptionsEdit.unshift(defaultfirmTitleOptionEdit);
        }
        return defaultUpdatedOptionsEdit;
      });
    }
  }, [defaultFirmId]);

  const handleSave = () => {
    setShouldShowValidation(true);
    if (validationErrors?.length == 0) {
      setIsSave(true);
      executeAddUser().then();
    } else {
      setIsSave(false);
      addSnackbarMessage({
        message: 'Add User Failed',
        type: 'error',
      });
    }
  };

  const formatPhoneNumber = (phoneNumber: string): string => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  useEffect(() => {
    if (role && role != '') {
      RenderAtiviTyRights(role);
    }
  }, [role]);

  useEffect(() => {
    if (editUserResponseStatus === 'success') {
      setNotes('');
      addSnackbarMessage({
        message: 'Update Successful',
        type: 'success',
      });
    }
  }, [editUserResponseStatus]);

  const handleUpdate = () => {
    if (hasPermission?.orderManagement) {
      executeSetFscTeam().then(() => {
        history.push('/admin/users/manage', { userId: userId });
      });
    } else {
      setShouldShowValidation(true);
      validateUpdateUserErrors();
      if (updateUserValidationErrors?.length === 0) {
        executeUpdateUser();
      } else {
        addSnackbarMessage({
          message: 'Update User Failed',
          type: 'error',
        });
      }
    }
  };

  const selectAll = 'Select All';
  const disableFeature: boolean =
    (useProfile?.profile?.roleId === 3 || useProfile?.profile?.roleId === 14) &&
    userId &&
    userId > 0 &&
    adminAccessRoles.includes(role)
      ? true
      : false;

  const HasUserAndIsAdminAccessRoleExceptOrderManagementAdminActivityRight: boolean =
    userId && userId > 0 && adminAccessRoles.includes(role) && !hasPermission?.orderManagementAdmin ? true : false;

  useEffect(() => {
    const checkedItems = multipleActivityRights?.reduce((acc: any, right: any) => {
      acc[right] = true;
      return acc;
    }, {});
    setCheckedItems(checkedItems);
    if (activityRights) {
      setAllActivilityRights(activityRights);
      if (userId && userId > 0) {
        const agentRights = activityRights.filter((right: any) => right.Role === userDetail?.userInfo?.RoleName);
        const deafultsRights = agentRights[0]?.DefaultRights?.sort((a: any, b: any) => a.localeCompare(b));
        if (deafultsRights) {
          setDefaultRights(deafultsRights);
          splitListIntoColumns(deafultsRights);
        }
        const additionalRights = agentRights[0]?.AdditionalRights?.sort((a: any, b: any) => a.localeCompare(b));
        if (additionalRights) {
          setAdditionalRights(additionalRights);
          splitListIntoColumns(additionalRights);
        }
      } else {
        const agentRights = activityRights.filter((right: any) => right.Role === 'Agent');
        const deafultsRights = agentRights[0]?.DefaultRights?.sort((a: any, b: any) => a.localeCompare(b));
        if (deafultsRights) {
          setDefaultRights(deafultsRights);
          splitListIntoColumns(deafultsRights);
        }
        const additionalRights = agentRights[0]?.AdditionalRights?.sort((a: any, b: any) => a.localeCompare(b));
        if (additionalRights) {
          setAdditionalRights(additionalRights);
          splitListIntoColumns(additionalRights);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (addUserResponseValue) {
      addSnackbarMessage({
        message: `Account activation was sent to ${addUserResponseValue.EmailAddress}.`,
        type: 'success',
      });
      const formattedPhoneNumber = formatPhoneNumber(addUserResponseValue.PhoneNumber);
      setAddUserId(addUserResponseValue.UserId);
      setIsSaved(true);
      setIsSave(false);
      setAddUserId(addUserResponseValue.UserId);
      setEmail(addUserResponseValue.EmailAddress);
      setPhoneNumber(formattedPhoneNumber);
      setRole(addUserResponseValue.RoleType);
      setStatusValue(addUser.Status);
      setLoginUserId(addUserResponseValue.LoginUserId);
      setNotes(addUser.Notes);
      setIsHqUser(addUserResponseValue.IsHqUser);
      setSelectedOffice(addUserResponseValue.DefaultOfficeId);
      setDefaultFirmId(addUserResponseValue.DefaultFirmId);
      setLoginExpirationDays(addUserResponseValue.LoginExpirationDays);
      setMultipleFirms(addUser.AdditionalFirmAccessIds);
      setMultipleActivityRights(addUser.AdditionalRightName);
    }
  }, [addUserResponseValue]);

  useEffect(() => {
    if (addUserResponseStatus == 'success') {
      setIsSaved(true);
    }
  }, [addUserResponseStatus]);

  useEffect(() => {
    if (addUserResponseError.length > 0) {
      addSnackbarMessage({
        message: 'Add User Failed',
        type: 'error',
      });
      setIsSave(false);
    }
  }, [addUserResponseError]);

  const splitListIntoColumns = (list: any) => {
    const part1: any = [];
    const part2: any = [];

    list?.forEach((item: any, index: any) => {
      if (index % 2 === 0) {
        part1.push(item);
      } else {
        part2.push(item);
      }
    });

    return [part1, part2];
  };

  const [leftColumnDefault, rightColumnDefault] = splitListIntoColumns(defaultRights);
  const [leftColumnAdditional, rightColumnAdditional] = splitListIntoColumns(additionalRights);

  const RenderAtiviTyRights = (role: string) => {
    if (userId === 0) {
      setCheckedItems({});
    }
    const agentRights = allActivityRights?.filter((right: any) => right.Role === role);
    if (agentRights?.length > 0) {
      const deafultsRights = agentRights[0]?.DefaultRights?.sort((a: any, b: any) => a.localeCompare(b));
      if (deafultsRights) {
        setDefaultRights(deafultsRights);
      }
      const additionalRights = agentRights[0]?.AdditionalRights?.sort((a: any, b: any) => a.localeCompare(b));
      if (additionalRights) {
        setAdditionalRights(additionalRights);
      }
    }
    setDaysToExpire('90');
  };

  useEffect(() => {
    // Set the checkbox to be checked by default if the value is "View Agent Dashboard Premium Revenue"
    if (userId === 0) {
      setCheckedItems((prevState: any) => ({
        ...prevState,
        'View Agent Dashboard Premium Revenue': true,
      }));
    }
  }, [roleValue]);

  useEffect(() => {
    if (checkedItems && userId && userId > 0) {
      const selectedRights = Object.keys(checkedItems).filter((key) => checkedItems[key]);

      const removeSelectAll = selectedRights.filter((right: string) => right !== selectAll);
      setMultipleActivityRights(removeSelectAll);
    }
  }, [checkedItems]);

  const handleCheckBoxClick = (name: string) => {
    const daysToAdd = daysToExpire;
    const currentDateTimePst = moment().tz('America/Los_Angeles');
    const futureDateTimePst = currentDateTimePst.add(daysToAdd, 'days');
    const formattedDate = futureDateTimePst.format('MM/DD/YYYY');

    const daysNumber = parseInt(daysToExpire, 10);
    if (daysNumber || daysNumber === 0 || daysNumber >= 90) {
      setDaysToExpire('90');
      addNewUser('LoginExpirationDays', 90);
      setError('');
    }

    setExpirationDate(formattedDate);
    if (name === selectAll) {
      const newCheckedItems = { ...checkedItems };
      const allChecked = !selectAllChecked;
      leftColumnAdditional?.forEach((right: any) => {
        newCheckedItems[right] = allChecked;
      });
      rightColumnAdditional?.forEach((right: any) => {
        newCheckedItems[right] = allChecked;
      });
      setCheckedItems(newCheckedItems);
    } else {
      setCheckedItems((prevState: any) => ({
        ...prevState,
        [name]: !prevState[name],
      }));
    }
  };

  useEffect(() => {
    const allChecked =
      leftColumnAdditional?.every((right: any) => checkedItems[right]) &&
      rightColumnAdditional?.every((right: any) => checkedItems[right]);
    setSelectAllChecked(allChecked);
  }, [checkedItems, leftColumnAdditional, rightColumnAdditional]);

  const handleDaysToExpireChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    setDaysToExpire(event.target.value);
    setLoginExpirationDays(value);
    if (value > 0 && value <= 90) {
      setError(''); // Clear error if value is valid
      const daysToAdd = value;
      const currentDateTimePst = moment().tz('America/Los_Angeles');
      const futureDateTimePst = currentDateTimePst.add(daysToAdd, 'days');
      const formattedDate = futureDateTimePst.format('MM/DD/YYYY');
      setExpirationDate(formattedDate);
    } else if (value <= 0) {
      setError('Days to expire must be greater than 0');
    } else if (value > 90) {
      setError('Days to expire must be less than or equal to 90');
    } else {
      setExpirationDate('');
      setError('Days to expire Required');
    }
  };

  const renderActivityRights = () => (
    <>
      <Grid item sm={4} className={classes.fieldSectionLeft}>
        <Typography variant="h2">Activity Rights</Typography>
      </Grid>
      <Grid container spacing={2} sm={8} className={classes.fieldSectionRight}>
        <Grid item sm={12}>
          <h3 className={clsx(classes.textFormat)}>DEFAULT RIGHTS</h3>
          <Grid container sm={12}>
            <Grid item sm={6}>
              {leftColumnDefault?.map((right: any, i: any) => (
                <Grid item sm={12} container key={i} className={clsx(classes.tableCell)}>
                  <MenuItem key={right} value={right}>
                    <AgentNetCheckBox disabled value={true} size="medium" />
                    <ListItemText primary={right} />
                  </MenuItem>
                </Grid>
              ))}
            </Grid>
            <Grid item sm={6}>
              {rightColumnDefault?.map((right: any, i: any) => (
                <Grid container key={i + rightColumnDefault?.length} className={clsx(classes.tableRow)}>
                  <Grid item sm={12} className={clsx(classes.tableCell)}>
                    <MenuItem key={right} value={right}>
                      <AgentNetCheckBox disabled value={true} size="medium" />
                      <ListItemText primary={right} />
                    </MenuItem>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <h3 className={clsx(classes.textFormat)}>Additional Rights</h3>
          <table>
            <tbody>
              {leftColumnAdditional && leftColumnAdditional.length > 0 && (
                <tr>
                  <td colSpan={2}>
                    <MenuItem key={selectAll} value={selectAll}>
                      <AgentNetCheckBox
                        disabled={disableFeature}
                        value={selectAllChecked}
                        size="medium"
                        checkHandler={() => handleCheckBoxClick(selectAll)}
                      />
                      <ListItemText primary={selectAll} />
                    </MenuItem>
                  </td>
                </tr>
              )}

              {leftColumnAdditional?.map((right: any, i: any) => (
                <tr key={i}>
                  <td style={{ width: `400px` }}>
                    <MenuItem key={right} value={right}>
                      <AgentNetCheckBox
                        disabled={disableFeature}
                        value={
                          userId && userId > 0
                            ? multipleActivityRights?.some((rightName: string) => rightName === right) ?? false
                            : !!checkedItems[right]
                        }
                        size="medium"
                        checkHandler={() => handleCheckBoxClick(right)}
                      />
                      <ListItemText primary={right} />
                    </MenuItem>
                  </td>
                  <td>
                    {rightColumnAdditional[i] && (
                      <MenuItem key={rightColumnAdditional[i]} value={rightColumnAdditional[i]}>
                        <AgentNetCheckBox
                          disabled={disableFeature}
                          value={
                            userId && userId > 0
                              ? multipleActivityRights?.some(
                                  (rightName: string) => rightName === rightColumnAdditional[i],
                                ) ?? false
                              : !!checkedItems[rightColumnAdditional[i]]
                          }
                          size="medium"
                          checkHandler={() => handleCheckBoxClick(rightColumnAdditional[i])}
                        />
                        <ListItemText primary={rightColumnAdditional[i]} />
                      </MenuItem>
                    )}
                    {rightColumnAdditional[i] === 'STARS Status Override' && checkedItems[rightColumnAdditional[i]] && (
                      <>
                        <tr>
                          <td>
                            <tr style={{ marginRight: '1000px' }}>
                              <AgentNetTextInput
                                fullWidth
                                variant="outlined"
                                label={'Days to Expire'}
                                name="starsStatusOverride"
                                value={daysToExpire}
                                required
                                data-testid="UserProfilePhoneEdit"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  const value = event.target.value;
                                  if (value === '' || (Number(value) <= 99 && /^[0-9]*$/.test(value))) {
                                    handleDaysToExpireChange(event);
                                    addNewUser('LoginExpirationDays', value);
                                  }
                                }}
                                inputProps={{
                                  pattern: '[0-9]*',
                                  type: 'text',
                                  max: 99,
                                  onKeyPress: (event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  },
                                }}
                                style={{ width: '110px', marginLeft: '50px' }} // Set the width of the input field
                              />
                            </tr>
                            <tr>
                              {error && (
                                <Typography
                                  color="error"
                                  variant="body1"
                                  style={{ width: '110px', marginLeft: '50px' }}
                                >
                                  {error}
                                </Typography>
                              )}
                            </tr>
                          </td>
                          <td className={clsx(classes.lable)}>
                            <tr>Expiration Date</tr>
                            <tr>{expirationDate ? expirationDate : ''}</tr>
                          </td>
                        </tr>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Grid>
      </Grid>
    </>
  );

  const actionStatus = editUserResponseStatus === 'pending' || addUserResponseStatus === 'pending' ? 'pending' : 'idle';

  return (
    <>
      <LoadingSpinner status={actionStatus} variant="linear" />
      {isSaved || (userId && userId > 0) ? (
        <>
          <UserBanner
            firstName={firstName}
            lastName={lastName}
            middleName={userDetail?.userInfo?.MiddleName}
            userId={userDetail?.userInfo?.loginUserId == undefined ? loginUserId.toString() : ''}
            id={userId && userId > 0 ? userId : addUserResponseValue.UserId}
            email={userId && userId > 0 ? email : addUser.EmailAddress}
            roleType={
              userDetail?.userInfo?.RoleTypeCdId > 0
                ? userDetail.userInfo.RoleTypeCdId
                : addUserResponseValue.RoleTypeId
            }
            userStatus={
              userDetail?.userInfo?.StatusType === 'NotActivated'
                ? 'Not Activated'
                : userDetail?.userInfo?.StatusType || addUser.Status
            }
          />
          {/* <AgentNetDivider typoVariant="h1" title={`Edit User:` + '  ' + firstName + lastName} />
          <AgentNetDivider typoVariant="h4" title={`UserId:` + '  ' + userDetail?.userInfo?.LoginUserId} /> */}
        </>
      ) : (
        <PageHeader
          title="Add User"
          subtitle="Enter new user details and configure their access permissions and activity rights."
        />
      )}
      <Grid container className={classes.fieldSection}>
        <Grid item sm={4} className={classes.fieldSectionLeft}>
          <Typography variant="h2">General</Typography>
        </Grid>
        <Grid container spacing={2} sm={8} className={classes.fieldSectionRight}>
          <Grid item sm={6}>
            <AgentNetTextInput
              fullWidth
              variant="outlined"
              label={'First Name'}
              name="FirstName"
              data-testid="firstName"
              value={userId && userId > 0 ? firstName : addUser.FirstName}
              onChange={(event: any) => {
                addNewUser('FirstName', event.target.value);
                setFirstName(event.target.value);
              }}
              required
              errs={userId && userId > 0 ? updateUserValidationErrors : validationErrors}
              showValidation={shouldShowValidation}
              disabled={
                userId &&
                userId > 0 &&
                useProfile?.profile?.roleId === 3 &&
                userDetail?.userInfo?.RoleTypeName === 'Staff'
                  ? true
                  : userId && userId > 0 && statusValue !== 'Not Activated'
                  ? true
                  : false
              }
            />
          </Grid>
          <Grid item sm={6}>
            <AgentNetTextInput
              fullWidth
              variant="outlined"
              label={'Last Name'}
              name="LastName"
              data-testid="lastName"
              value={userId && userId > 0 ? lastName : addUser.LastName}
              onChange={(event: any) => {
                addNewUser('LastName', event.target.value);
                setLastName(event.target.value);
              }}
              required
              errs={userId && userId > 0 ? updateUserValidationErrors : validationErrors}
              showValidation={shouldShowValidation}
              disabled={
                userId &&
                userId > 0 &&
                useProfile?.profile?.roleId === 3 &&
                userDetail?.userInfo?.RoleTypeName === 'Staff'
                  ? true
                  : userId && userId > 0 && statusValue !== 'Not Activated'
                  ? true
                  : false
              }
            />
          </Grid>
          <Grid item sm={6}>
            {userId && userId === 0 ? (
              <PhoneField
                fullWidth
                variant="outlined"
                label={'Phone'}
                name="PhoneNumber"
                value={userId && userId > 0 ? actualPhoneNumber : addUser.PhoneNumber}
                required
                data-testid="phoneNumber"
                onChange={(event: any) => {
                  addNewUser('PhoneNumber', event.target.value);
                  setActualPhoneNumber(event.target.value);
                  setPhoneNumber(event.target.value);
                }}
                errs={userId && userId > 0 ? updateUserValidationErrors : validationErrors}
                showValidation={shouldShowValidation}
                disabled={
                  userId &&
                  userId > 0 &&
                  useProfile?.profile?.roleId === 3 &&
                  userDetail?.userInfo?.RoleTypeName === 'Staff'
                    ? true
                    : userId && userId > 0 && statusValue !== 'Not Activated'
                    ? true
                    : false
                }
              />
            ) : userId && userId > 0 && userDetail?.userInfo?.StatusType !== 'NotActivated' ? (
              <AgentNetTextInput
                fullWidth
                variant="outlined"
                label={'Phone'}
                name="PhoneNumber"
                value={userId && userId > 0 ? userDetail?.userInfo?.PhoneNumber : addUser.PhoneNumber}
                required
                data-testid="phoneNumber"
                onChange={(event: any) => {
                  addNewUser('PhoneNumber', event.target.value);
                  setActualPhoneNumber(event.target.value);
                  setPhoneNumber(event.target.value);
                }}
                errs={userId && userId > 0 ? updateUserValidationErrors : validationErrors}
                showValidation={shouldShowValidation}
                disabled={
                  userId &&
                  userId > 0 &&
                  useProfile?.profile?.roleId === 3 &&
                  userDetail?.userInfo?.RoleTypeName === 'Staff'
                    ? true
                    : userId && userId > 0 && statusValue !== 'Not Activated'
                    ? true
                    : false
                }
              />
            ) : (
              <PhoneField
                fullWidth
                variant="outlined"
                label={'Phone'}
                name="PhoneNumber"
                value={userId && userId > 0 ? actualPhoneNumber : addUser.PhoneNumber}
                required
                data-testid="phoneNumber"
                onChange={(event: any) => {
                  addNewUser('PhoneNumber', event.target.value);
                  setActualPhoneNumber(event.target.value);
                  setPhoneNumber(event.target.value);
                }}
                errs={userId && userId > 0 ? updateUserValidationErrors : validationErrors}
                showValidation={shouldShowValidation}
                disabled={
                  userId &&
                  userId > 0 &&
                  useProfile?.profile?.roleId === 3 &&
                  userDetail?.userInfo?.RoleTypeName === 'Staff'
                    ? true
                    : userId && userId > 0 && statusValue !== 'Not Activated'
                    ? true
                    : false
                }
              />
            )}
          </Grid>
          <Grid item sm={6}>
            <AgentNetTextInput
              fullWidth
              variant="outlined"
              label={'Email'}
              name="EmailAddress"
              value={userId && userId > 0 ? email : addUser.EmailAddress}
              required
              onChange={(event: any) => {
                addNewUser('EmailAddress', event.target.value);
                setEmail(event.target.value);
              }}
              errs={userId && userId > 0 ? updateUserValidationErrors : validationErrors}
              showValidation={shouldShowValidation}
              disabled={
                userId &&
                userId > 0 &&
                useProfile?.profile?.roleId === 3 &&
                userDetail?.userInfo?.RoleTypeName === 'Staff'
                  ? true
                  : userId && userId > 0 && statusValue !== 'Not Activated'
                  ? true
                  : false
              }
            />
          </Grid>
          <Grid item sm={6}>
            <AgentNetDropdownSelector
              fullWidth
              disabled={disableFeature}
              variant="outlined"
              label={'Role'}
              name="Role"
              value={userId && userId > 0 ? role : defaultRoleValue}
              required
              data-testid="role"
              options={userRoleOptions}
              menuOption={(value: string) => {
                addNewUser('Role', value);
                setRoleValue(value);
                RenderAtiviTyRights(value);
                setRole(value);
                setDefaultRoleValue(value);
              }}
              errs={userId && userId > 0 ? updateUserValidationErrors : validationErrors}
              showValidation={shouldShowValidation}
            />
          </Grid>
          <Grid item sm={6}>
            <AgentNetDropdownSelector
              fullWidth
              variant="outlined"
              label={'Status'}
              name="status"
              value={
                userId && userId > 0
                  ? statusValue === 'Not Activated'
                    ? 'Not Activated'
                    : statusValue === 'InActive'
                    ? 'Inactive'
                    : statusValue
                  : addUser.Status ?? 'Not Activated'
              }
              disabled={disableFeature ? true : userId && userId > 0 && statusValue !== 'Not Activated' ? false : true}
              defaultValue={status[0]?.value}
              data-testid="status"
              options={
                userId && userId > 0 && (statusValue === 'Active' || statusValue === 'Disabled')
                  ? statusActiveDisabled
                  : userId && userId > 0 && statusValue === 'InActive'
                  ? statusInactive
                  : status
              }
              menuOption={(value: string) => {
                addNewUser('Status', value);
                setStatusValue(value);
              }}
              errs={userId && userId > 0 ? updateUserValidationErrors : validationErrors}
              showValidation={shouldShowValidation}
            />
          </Grid>
          {hasPermission?.orderManagementAdmin && (
            <Grid item sm={6}>
              <AgentNetDropdownSelector
                fullWidth
                variant="outlined"
                label={'FSC Team'}
                name="FscRole"
                value={fscTeamValue}
                required
                data-testid="fsc-role"
                options={fscTeams}
                menuOption={(value: number) => {
                  setFscTeamValue(value);
                }}
              />
            </Grid>
          )}
        </Grid>
        {useProfile?.profile?.roleId !== 3 && renderActivityRights()}
        {showFirm && !hasPermission?.orderManagementAdmin && (
          <>
            <Grid item sm={4} className={classes.fieldSectionLeft}>
              <Typography variant="h2">Defaults</Typography>
              {useProfile?.profile?.firms?.length === 0 && (
                <>
                  <Typography variant="body2" color="textSecondary">
                    Add a firm below to set defaults.
                  </Typography>
                </>
              )}
            </Grid>
            <Grid container spacing={2} sm={8} className={classes.fieldSectionRight}>
              {(useProfile?.profile?.roleTypeId === 100 &&
                profile?.isHqUser &&
                !useProfile?.profile?.isMultiFirmAccess) ||
              useProfile?.profile?.roleId === 3 ? (
                <>
                  <Grid
                    item
                    sm={6}
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <Tooltip title={hqName}>
                      <Typography noWrap style={{ cursor: 'default' }}>
                        {hqName}
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item sm={6} style={{ display: 'flex', flexDirection: 'row', padding: 0, margin: 0 }}>
                    <MenuItem key={hqUser} value={hqUser}>
                      <AgentNetCheckBox
                        style={{ display: 'flex', flexDirection: 'row', padding: 0, margin: 0 }}
                        value={userId && userId > 0 ? isHqUser ?? false : addUser?.IsHqUser ?? false}
                        size="medium"
                        checkHandler={(val: any) => {
                          setIsHqUser(val);
                          addNewUser('IsHqUser', val);
                        }}
                      />
                      <ListItemText primary={hqUser} />
                    </MenuItem>
                  </Grid>
                </>
              ) : (
                useProfile?.profile?.roleTypeId === 100 &&
                userDetail?.userInfo?.IsHQUser && (
                  <>
                    <Grid item sm={6}>
                      <Typography>{hqName}</Typography>
                    </Grid>
                    <Grid item sm={6} style={{ display: 'flex', flexDirection: 'row', padding: 0, margin: 0 }}>
                      <MenuItem key={hqUser} value={hqUser}>
                        <ListItemText primary={hqUser} />
                      </MenuItem>
                    </Grid>
                  </>
                )
              )}
              <Grid item sm={6}>
                <AgentNetDropdownSelector
                  fullWidth
                  variant="outlined"
                  label={'Firm'}
                  name="AssociatedFirmId"
                  value={userId && userId > 0 ? defaultFirmId : firmTitleValue}
                  required
                  disabled={
                    userId && userId > 0
                      ? isHqUser
                        ? true
                        : useProfile.profile?.roleId === 3
                        ? false
                        : useProfile.profile?.roleId != 3
                        ? true
                        : false
                      : addUser.IsHqUser
                      ? true
                      : false
                  }
                  data-testid="firmName"
                  options={userId && userId > 0 ? defaultFirmOptions : firmNameArray}
                  menuOption={(value: number) => {
                    addNewUser('FirmName', value);
                    setFirmTitle(value);
                    addNewUser('AssociatedFirmId', value);
                    setOfficeName(value);
                    setDefaultFirmId(value);
                    setFirmId(value);
                    const hqOptionName: any = selectedOptions.find((option) => option.id === value)?.name;
                    const isEcrowFirm = selectedOptions.find((option) => option.id === value);
                    if (isEcrowFirm?.isTitleEscrow === true) {
                      setDefaultRoleValue('');
                      setRole('');
                      setuserRoleOptions(titleEscrowRoles);
                      addNewUser('Role', '');
                    } else {
                      setDefaultRoleValue('Agent');
                      setRole('Agent');
                      setuserRoleOptions(AgentRole);
                      addNewUser('Role', 'Agent');
                    }
                  }}
                  errs={userId && userId > 0 ? updateUserValidationErrors : validationErrors}
                  showValidation={shouldShowValidation}
                />
              </Grid>

              <Grid item sm={6}>
                <AgentNetDropdownSelector
                  fullWidth
                  variant="outlined"
                  label={'Office'}
                  name="AssociatedOfficeId"
                  value={userId && userId > 0 ? selectedOffice : OfficeValue}
                  required
                  disabled={useProfile?.profile?.roleId === 13 ? true : false}
                  menuOption={(value: number) => {
                    addNewUser('AssociatedOfficeId', value);
                    setSelectedOffice(value);
                    setOfficeValue(value);
                  }}
                  options={officeNameArray}
                  errs={userId && userId > 0 ? updateUserValidationErrors : validationErrors}
                  showValidation={shouldShowValidation}
                />
              </Grid>
            </Grid>
            {useProfile?.profile?.roleId != 13 && (
              <>
                <Grid item sm={4} className={classes.fieldSectionLeft}>
                  <Typography variant="h2">Firm Access</Typography>
                </Grid>
                <Grid container spacing={2} sm={8} className={classes.fieldSectionRight}>
                  <AutocompleteChecklist
                    loggedUserRoleId={useProfile?.profile?.roleTypeId}
                    options={[
                      { id: 1, name: 'Option 1' },
                      { id: 2, name: 'Option 2' },
                      { id: 3, name: 'Option 3' },
                    ]}
                    label="Firm"
                    onChange={handleChange}
                    onDelete={handleDelete}
                    value={selectedOptions}
                    dialogText="Are you sure you want to delete this option?"
                    dialogTitle="Delete Confirmation"
                    qaAttrPrefix="autocomplete-checklist"
                    qaBtnprefix="autocomplete-checklist-btn"
                  />
                </Grid>
              </>
            )}
          </>
        )}
        {useProfile?.profile?.roleId === 3 && renderActivityRights()}
        {!hasPermission?.orderManagementAdmin && (
          <Grid item sm={4} className={classes.fieldSectionLeft}>
            <Typography variant="h2">Notes</Typography>
          </Grid>
        )}
        {!hasPermission?.orderManagementAdmin && (
          <Grid container spacing={2} sm={8} className={classes.fieldSectionRight}>
            <Grid item sm={12}>
              <AgentNetTextInput
                disabled={disableFeature}
                className={classes.notes}
                variant="outlined"
                label="Notes"
                name="notes"
                value={userId && userId > 0 ? notes : addUser.Notes}
                multiline
                fullWidth
                required={false}
                onChange={(event: any) => {
                  addNewUser('Notes', event.target.value);
                  setNotes(event.target.value);
                }}
                errs={validationErrors}
                showValidation={shouldShowValidation}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <BottomAppBar
        primaryActionLabel="Save"
        cancelDataQa={'UserProfileCancel'}
        cancelLabel="Cancel"
        primaryActionDataQa={'UserProfileSave'}
        primaryButtonLoader={executeSetFscTeamStatus === 'pending'}
        onPrimaryClick={isSaved || (userId && userId > 0) ? handleUpdate : handleSave}
        disablePrimary={
          userId && userId > 0 && useProfile?.profile?.roleId === 13 && adminAccessRoles.includes(role)
            ? false
            : HasUserAndIsAdminAccessRoleExceptOrderManagementAdminActivityRight
            ? true
            : executeSetFscTeamStatus === 'pending'
            ? true
            : isSave
        }
        onCancel={() => {
          if (isSaved) {
            userId = addUserResponseValue.UserId;
          }
          if (userId && userId > 0) {
            if (userId && reLoadEdit) {
              reLoadEdit(userId);
            }
          } else {
            window.location.reload();
          }
        }}
        showFullWidth={useProfile?.profile?.roleId == 13 || hasPermission?.orderManagementAdmin}
      />
    </>
  );
};

export default AddNewUser;
