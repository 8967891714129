import { grey } from '@material-ui/core/colors';
import createPalette from '@material-ui/core/styles/createPalette';
import { CSSProperties } from 'react';

type PaletteColors = {
  main: CSSProperties['color'];
  dark: CSSProperties['color'];
  light: CSSProperties['color'];
  contrastText: CSSProperties['color'];
};

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    brand: {
      charcoal: CSSProperties['color'];
      feather: CSSProperties['color'];
      feather25: CSSProperties['color'];
      feather50: CSSProperties['color'];
      foundation: CSSProperties['color'];
      gold: CSSProperties['color'];
      legacy: CSSProperties['color'];
      lilRed: CSSProperties['color'];
      sky: CSSProperties['color'];
      sky25: CSSProperties['color'];
      sky50: CSSProperties['color'];
      vivid: CSSProperties['color'];
      vividDark: CSSProperties['color'];
      welcome: CSSProperties['color'];
      finance: PaletteColors;
      knowledge: PaletteColors;
      marketing: PaletteColors;
    };
    document: {
      default: CSSProperties['color'];
      docFile: CSSProperties['color'];
      email: CSSProperties['color'];
      excel: CSSProperties['color'];
      folder: CSSProperties['color'];
      html: CSSProperties['color'];
      image: CSSProperties['color'];
      pdf: CSSProperties['color'];
      powerpoint: CSSProperties['color'];
      template: CSSProperties['color'];
      video: CSSProperties['color'];
      word: CSSProperties['color'];
    };
    status: {
      canceled: CSSProperties['color'];
      closed: CSSProperties['color'];
      closedBackground: CSSProperties['color'];
      draft: CSSProperties['color'];
      error: CSSProperties['color'];
      info: CSSProperties['color'];
      open: CSSProperties['color'];
      pending: CSSProperties['color'];
      void: CSSProperties['color'];
      voidBackground: CSSProperties['color'];
    };
    actionSecondary: {
      active: CSSProperties['color'];
      hover: CSSProperties['color'];
      hoverOpacity: number;
      selected: CSSProperties['color'];
      disabled: CSSProperties['color'];
      disabledBackground: CSSProperties['color'];
      focus: CSSProperties['color'];
    };
    statesPrimary: {
      hover: CSSProperties['color'];
      selected: CSSProperties['color'];
      focus: CSSProperties['color'];
      focusVisible: CSSProperties['color'];
      outlinedBorder: CSSProperties['color'];
      hoverBorder: CSSProperties['color'];
      disabledBorder: CSSProperties['color'];
      disabledBackground: CSSProperties['color'];
    };
    statesSecondary: {
      hover: CSSProperties['color'];
      selected: CSSProperties['color'];
      focus: CSSProperties['color'];
      focusVisible: CSSProperties['color'];
      outlinedBorder: CSSProperties['color'];
      hoverBorder: CSSProperties['color'];
      disabledBorder: CSSProperties['color'];
      disabledBackground: CSSProperties['color'];
    };
    accordion: {
      document: CSSProperties['color'];
      property: CSSProperties['color'];
      buyer: CSSProperties['color'];
      seller: CSSProperties['color'];
      lender: CSSProperties['color'];
      editBackground: CSSProperties['color'];
      activeBackground: CSSProperties['color'];
    };
    highlightIcon: {
      main: CSSProperties['color'];
      highlight: CSSProperties['color'];
    };
    agGrid: {
      disabledBackground: CSSProperties['color'];
      overrideBackground: CSSProperties['color'];
      overrideBackgroundDisabled: CSSProperties['color'];
      overrideBorder: CSSProperties['color'];
      overrideBorderHover: CSSProperties['color'];
    };
  }
  interface PaletteOptions {
    brand?: Palette['brand'];
    document?: Palette['document'];
    status?: Palette['status'];
    actionSecondary?: Palette['actionSecondary'];
    statesPrimary?: Palette['statesPrimary'];
    statesSecondary?: Palette['statesSecondary'];
    accordion?: Palette['accordion'];
    highlightIcon?: Palette['highlightIcon'];
    agGrid?: Palette['agGrid'];
  }
}

const palette = createPalette({
  action: {
    active: '#0075C3',
    hover: '#0075C30a',
    hoverOpacity: 0.12,
    selected: '#0075C314',
    disabled: '#0075C361',
    disabledBackground: '#0075C31F',
    focus: '#0075C31f',
  },
  background: {
    default: '#FFFFFF',
    paper: '#FFFFFF',
  },
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  divider: '#CFD8DC', // blueGray/100
  primary: {
    main: '#0075C3',
    dark: '#00497A',
    light: '#D0E3F5',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#65788F',
    dark: '#3D4D5F',
    light: '#D5DCE2',
    contrastText: '#FFFFFF',
  },
  error: {
    main: '#AC1209', // red/600
    dark: '#9A0C05', // red/800
    light: '#BE382F', // red/400
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#FB8C00', // orange/600
    dark: '#EF6C00', // orange/800
    light: '#FFA726', // orange/400
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#039BE5', // lightBlue/600
    dark: '#0277BD', // lightBlue/800
    light: '#29B6F6 ', // lightBlue/400
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#43A047', // green/600
    dark: '#2E7D32', // green/800
    light: '#66BB6A', // green/400
    contrastText: '#FFFFFF',
  },
  text: {
    primary: grey[900],
    secondary: grey[700],
    disabled: grey[500],
  },
  brand: {
    foundation: '#141D3A',
    welcome: '#0075C3',
    charcoal: '#2F2F2F',
    vivid: '#4EADE1',
    vividDark: '#1688C5',
    feather: '#F4F4F4',
    feather25: '#FAFAFA',
    feather50: '#F9F9F9',
    gold: '#EBBD5F',
    legacy: '#013A6F',
    lilRed: '#E63E1F',
    sky: '#DCEFF9',
    sky25: '#F6FBFD',
    sky50: '#EDF7FC',
    finance: {
      main: '#af9e69',
      light: '#dbd4bd',
      dark: '#ffffff',
      contrastText: '#ffffff',
    },
    knowledge: {
      main: '#ebbd5f',
      light: '#fbf0db',
      dark: '#da9a1b',
      contrastText: '#ffffff',
    },
    marketing: {
      main: '#e17e26',
      light: '#f8dfc9',
      dark: '#b46118',
      contrastText: '#ffffff',
    },
  },
  document: {
    default: '#d0e3f5',
    docFile: '#0075c3',
    email: '#009688',
    excel: '#33691e',
    folder: '#1c9489',
    html: '#2196f3',
    image: '#0288d1',
    pdf: '#b71c1c',
    powerpoint: '#d84315',
    template: '#00497a',
    video: '#827717',
    word: '#01579b',
  },
  actionSecondary: {
    active: '#65788F', // secondary/main
    disabled: '#65788f61',
    disabledBackground: '#65788f1f',
    focus: '#65788f1f',
    hover: '#65788f0a',
    hoverOpacity: 0.04,
    selected: '#65788f14',
  },
  status: {
    canceled: '#ac1209',
    closed: '#013a6f',
    closedBackground: '#edf7fc',
    draft: '#bdbdbd',
    error: '#ac1209',
    info: '#039be5',
    open: '#43a047',
    pending: '#fb8c00',
    void: '#4f4178',
    voidBackground: '#dcd9e4',
  },
  statesPrimary: {
    disabledBackground: '#0075C30f',
    disabledBorder: '#0075C34d',
    focus: '#0075C31f',
    focusVisible: '#0075C34d',
    hover: '#0075C30a',
    hoverBorder: '#0075C3b3',
    outlinedBorder: '#0075C380',
    selected: '#0075C314',
  },
  statesSecondary: {
    disabledBackground: '#65788f0f',
    disabledBorder: '#65788f4d',
    focus: '#65788f1f',
    focusVisible: '#65788f4d',
    hover: '#65788f0a',
    hoverBorder: '#65788fb3',
    outlinedBorder: '#65788f80',
    selected: '#65788f14',
  },
  accordion: {
    document: '#00497a', //primary/dark
    property: '#004d40', //teal/900
    buyer: '#311b92', //deepPurple/900
    seller: '#bf360c', //deepOrange/900
    lender: '#880e4f', //pink/900
    editBackground: '#f8dfc9', //brand.knowledge.light
    activeBackground: '#F6FBFD', //brand/sky25
  },
  highlightIcon: {
    main: '#013A6F',
    highlight: '#80BAE1',
  },
  agGrid: {
    disabledBackground: '#FAFAFA', //feather25
    overrideBackground: '#FFF3E0', //orange50
    overrideBackgroundDisabled: '#F8BC000F', //warning
    overrideBorder: '#F8BC00',
    overrideBorderHover: '#F8BC00B3',
  },
});

export default palette;
