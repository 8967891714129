export type environment =
  | 'LOCAL'
  | 'DEV'
  | 'DEV1'
  | 'DEV2'
  | 'TEST'
  | 'QA'
  | 'QA1'
  | 'QA2'
  | 'CERT'
  | 'UAT'
  | 'IP'
  | 'SUPPORT'
  | 'STAGE'
  | 'TRAINING'
  | 'PRIOR'
  | 'PROD';

export const LOCAL = 'LOCAL';
export const DEV = 'DEV';
export const DEV1 = 'DEV1';
export const DEV2 = 'DEV2';
export const TEST = 'TEST';
export const QA = 'QA';
export const QA1 = 'QA1';
export const QA2 = 'QA2';
export const CERT = 'CERT';
export const UAT = 'UAT';
export const IP = 'IP';
export const SUPPORT = 'SUPPORT';
export const STAGE = 'STAGE';
export const TRAINING = 'TRAINING';
export const PRIOR = 'PRIOR';
export const PROD = 'PROD';

export const environments: {
  [url: string]: environment;
} = {
  'localhost:3000': LOCAL,
  'dev.agentnetportal.firstam.net': DEV,
  'env1.dev.agentnetportal.firstam.net': DEV1,
  'env2.dev.agentnetportal.firstam.net': DEV2,
  'test.agentnetportal.firstam.net': TEST,
  'qa.agentnetportal.firstam.net': QA,
  'env1.qa.agentnetportal.firstam.net': QA1,
  'env2.qa.agentnetportal.firstam.net': QA2,
  'cert.agentnetportal.firstam.net': CERT,
  'uat.agentnetportal.firstam.net': UAT,
  'ip.agentnetportal.firstam.com': IP,
  'support.agentnetportal.firstam.net': SUPPORT,
  'stage.agentnetportal.firstam.net': STAGE,
  'training.agentnetportal.firstam.net': TRAINING,
  'prior.agentnetportal.firstam.net': PRIOR,
  'agentnet.firstam.com': PROD,
};

/**
 * Returns the current environment name based on the webpage's host.
 *
 * @returns {environment} env - the environment name.
 * @default DEV
 */
const resolveEnv = (): environment => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const apiHostname = window.location.host;

  return environments[apiHostname] || DEV;
};

export default resolveEnv;
