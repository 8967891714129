import { useHistory } from 'react-router-dom';
import NoResults from 'ui-kit/components/noResults/NoResults';

const OrderManagementUnauthorizedAccessContent = () => {
  const history = useHistory();

  return (
    <NoResults
      type="order-management-access-denied"
      titleText="404 - Page Not Found"
      pText="This page may not exist or you don't have permission to access it. Please contact support if you think this is a mistake."
      buttonText="Go To Homepage"
      onClick={() => history.push('/')}
    />
  );
};

export default OrderManagementUnauthorizedAccessContent;
