import React from 'react';

function QuestionCircleWithStars(): React.ReactElement {
  return (
    <svg width="250" height="122" viewBox="0 0 250 122" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M63 95.5h91q.774-.002 1.5-.161.726.159 1.5.16h52a7 7 0 1 0 0-14h-6a7 7 0 1 1 0-14h19a7 7 0 1 0 0-14h-22a7 7 0 1 0 0-14h-64a7 7 0 1 0 0-14H79a7 7 0 1 0 0 14H39a7 7 0 1 0 0 14h25a7 7 0 1 1 0 14H24a7 7 0 1 0 0 14h39a7 7 0 1 0 0 14m163 0a7 7 0 1 0 0-14 7 7 0 0 0 0 14"
        fill="#ECEFF1"
      />
      <path d="M166.5 1.5v6m0 8v6m-10-10h6m8 0h6m-93 93v4m0 8v4m-8-8h4m8 0h4" stroke="#B0BEC5" />
      <path
        d="M154.711 87.626a45.5 45.5 0 0 0 9.023-18.229 46.2 46.2 0 0 0 1.16-13.868 46 46 0 0 0-2.429-11.97 45.8 45.8 0 0 0-5.59-11.044 46.5 46.5 0 0 0-4.841-5.911 46.3 46.3 0 0 0-8.632-7.025 46 46 0 0 0-10.476-4.88 46.23 46.23 0 0 0-25.024-.887 45.56 45.56 0 0 0-20.914 11.543 45.7 45.7 0 0 0-8.637 11.443 45.7 45.7 0 0 0-4.514 12.79 46.2 46.2 0 0 0-.558 13.83 45.98 45.98 0 0 0 12.687 26.978 46.1 46.1 0 0 0 15.638 10.639 46.2 46.2 0 0 0 21.861 3.265 45.7 45.7 0 0 0 17.896-5.51"
        fill="#fff"
      />
      <path
        d="M154.711 87.626a45.5 45.5 0 0 0 9.023-18.229 46.2 46.2 0 0 0 1.16-13.868 46 46 0 0 0-2.429-11.97 45.8 45.8 0 0 0-5.59-11.044 46.5 46.5 0 0 0-4.841-5.911 46.3 46.3 0 0 0-8.632-7.025 46 46 0 0 0-10.476-4.88 46.23 46.23 0 0 0-25.024-.887 45.56 45.56 0 0 0-20.914 11.543 45.7 45.7 0 0 0-8.637 11.443 45.7 45.7 0 0 0-4.514 12.79 46.2 46.2 0 0 0-.558 13.83 45.98 45.98 0 0 0 12.687 26.978 46.1 46.1 0 0 0 15.638 10.639 46.2 46.2 0 0 0 21.861 3.265 45.7 45.7 0 0 0 17.896-5.51"
        stroke="#90A4AE"
      />
      <path d="M144.701 96.878c2.906-1.87 5.49-4.203 7.171-6.087Z" fill="#fff" />
      <path d="M144.701 96.878c2.906-1.87 5.49-4.203 7.171-6.087" stroke="#90A4AE" />
      <path
        d="M161 61.117q-.43.008-.86.008c-25.254 0-45.931-19.689-47.664-44.625C92.376 19.67 77 37.153 77 58.243c0 23.338 18.826 42.257 42.048 42.257 22.261 0 40.482-17.386 41.952-39.383"
        fill="#ECEFF1"
      />
      <path
        d="M119.07 85.59c-2.42 0-4.38-1.97-4.38-4.38a4.36 4.36 0 0 1 4.38-4.38c2.46 0 4.38 1.93 4.38 4.38a4.36 4.36 0 0 1-4.38 4.38m0-14.59c-1.84 0-3.29-1.41-3.29-3.21v-4.45c0-1.1.25-1.96 1.59-3.37l7.39-7.23c1.96-1.96 2.8-3.97 2.8-6.72 0-4.73-3.81-8.57-8.49-8.57s-8.4 3.69-8.49 8.39c0 1.89-1.41 3.31-3.29 3.31S104 47.67 104 45.86c.15-8.04 6.77-14.36 15.07-14.36s15.07 6.52 15.07 14.53c0 4.34-1.38 7.43-4.76 10.67l-7.02 6.69v4.33c0 1.88-1.41 3.29-3.29 3.29z"
        fill="#fff"
      />
      <path
        d="M119.07 32.5c7.76 0 14.07 6.07 14.07 13.53 0 4.04-1.29 6.92-4.45 9.94l-6.71 6.4-.62.59v4.76c0 1.31-.98 2.29-2.29 2.29s-2.29-.95-2.29-2.21v-4.45c0-.75.09-1.38 1.29-2.65l7.4-7.24c2.13-2.13 3.08-4.42 3.08-7.42 0-5.28-4.26-9.57-9.49-9.57s-9.38 4.1-9.48 9.4c0 1.31-.98 2.29-2.29 2.29S105 47.15 105 45.91c.14-7.52 6.32-13.4 14.07-13.4m0 45.31c1.9 0 3.38 1.49 3.38 3.38a3.37 3.37 0 0 1-3.38 3.38c-1.86 0-3.38-1.52-3.38-3.38s1.48-3.38 3.38-3.38m0-47.32c-8.89 0-15.91 6.86-16.07 15.37 0 2.42 1.95 4.29 4.29 4.29s4.29-1.87 4.29-4.29c.08-4.13 3.35-7.41 7.49-7.41s7.49 3.43 7.49 7.57c0 2.18-.55 4.06-2.5 6.01l-7.41 7.25c-1.48 1.56-1.87 2.65-1.87 4.06v4.45c0 2.34 1.87 4.21 4.29 4.21s4.29-1.87 4.29-4.29v-3.9l6.71-6.4c3.51-3.35 5.07-6.71 5.07-11.39 0-8.59-7.1-15.53-16.07-15.53m0 45.32c-2.97 0-5.38 2.34-5.38 5.38s2.41 5.38 5.38 5.38 5.38-2.42 5.38-5.38-2.41-5.38-5.38-5.38"
        fill="#90A4AE"
      />
    </svg>
  );
}

export default QuestionCircleWithStars;
