import { useAuth } from '@agentnet/auth';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { HelpOutline } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';
import FormDrawerComponent from 'ui-kit/components/drawer/FormDrawerComponent';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import RadioGroup, { RadioProps } from 'ui-kit/components/radios/RadioGroup';
import { addressBookAddClosingAttorney, addressBookUpdateClosingAttorney } from '../../../api/admin/address-book-api';
import { AddressBookClosingAttorneyContainerProps } from '../../../api/admin/interfaces';
import { ProfileContext, ProfileContextInterface } from '../../../hooks/ProfileContext';
import { AgentNetTextInput } from '../../../ui-kit/inputs';
import StateSelectField from '../../../ui-kit/inputs/StateSelectField/StateSelectField';
import USPostalCodeField from '../../../ui-kit/inputs/USPostalCodeField/USPostalCodeField';
import { addressBookClosingAttorneySchema } from '../../../utilities/validation/schemas/addressBookContact-schema';
import { doValidate, FieldValidationError } from '../../../utilities/validation/validation';

const AddressBookClosingAttorney = ({
  data,
  openDrawer,
  setOpenDrawer,
}: AddressBookClosingAttorneyContainerProps): JSX.Element => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isShowValidation, setIsShowValidation] = useState<boolean>(false);

  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm } = profileCtx;
  const { getAccessToken } = useAuth();

  const { addSnackbarMessage } = useSnackBars();
  const useStyles = makeStyles((theme) => ({
    mb2: {
      marginBottom: theme.spacing(1),
      border: 'none !important',
    },
    flex: {
      display: 'flex',
      gap: '16px',
    },
    threeCol: {
      display: 'flex',
      width: '100%',
      gap: '15px',
      justifyContent: 'space-between',
    },
    required: {
      color: 'red',
    },
    w50: {
      width: '50%',
    },
    fullWidth: {
      width: '100%',
    },
    w25: {
      width: '25%',
    },
    w100: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    tooltipRow: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      padding: theme.spacing(3, 0),
    },
    tooltipIcon: {
      position: 'absolute',
      left: '150px',
      top: '20px',
    },
    mt2: {
      marginTop: theme.spacing(2),
      width: '100%',
    },
    f13: {
      fontSize: '13px',
      marginRight: '10px',
      fontWeight: 'normal',
    },
  }));
  const classes = useStyles();
  const [closingAttorneyObj, setClosingAttorneyObj] = useState<any>({
    firstName: '',
    middleName: '',
    lastName: '',
    name: '',
    dbaName: '',
    title: '',
    address1: '',
    address2: '',
    city: '',
    stateCode: '',
    zipCode: '',
    phoneNumber: '',
    faxNumber: '',
    email: '',
    businessIn: '',
  });

  const validateFields = async () => {
    return await doValidate(closingAttorneyObj, addressBookClosingAttorneySchema).then(
      (errs: FieldValidationError[]) => {
        setValidationErrors(errs);
        if (errs && errs.length > 0) {
          setIsFormValid(false);
          return false;
        } else {
          setIsFormValid(true);
          return true;
        }
      },
    );
  };

  const handleCancelAdd = () => {
    // Resetting all the data
    setValidationErrors([]);
    setIsSubmitted(false);
    setIsShowValidation(false);
    setOpenDrawer(false);
  };

  const radioOptions: RadioProps[] = [
    { label: 'New Jersey (NJ)', value: 'NJ' },
    { label: 'South Carolina (SC)', value: 'SC' },
  ];

  const handleUpdateAddressBookContact = async () => {
    let firmId = '';
    if (userFirm !== null && userFirm?.firmId !== undefined) {
      firmId = userFirm.firmId;
    }
    const token = await getAccessToken();
    setIsShowValidation(true);
    if (!validationErrors || validationErrors.length === 0) {
      const requestObj = {
        ClosingAttorneyId: closingAttorneyObj.id,
        FirstName: closingAttorneyObj.firstName,
        MiddleName: closingAttorneyObj.middleName,
        LastName: closingAttorneyObj.lastName,
        FullName: [closingAttorneyObj.firstName, closingAttorneyObj.middleName, closingAttorneyObj.lastName]
          .filter(Boolean)
          .join(' '),
        DbaName: closingAttorneyObj.dbaName,
        Address1: closingAttorneyObj.address1,
        Address2: closingAttorneyObj.address2,
        City: closingAttorneyObj.city,
        State: closingAttorneyObj.stateCode,
        Zip: closingAttorneyObj.zipCode,
        PhoneNumber: closingAttorneyObj.phoneNumber?.replaceAll('-', ''),
        FaxNumber: closingAttorneyObj.faxNumber?.replaceAll('-', ''),
        EmailAddress: closingAttorneyObj.email,
        LicenseState: closingAttorneyObj.businessIn,
      };
      if (await addressBookUpdateClosingAttorney(requestObj, firmId, token)) {
        setIsSubmitted(true);
        setIsShowValidation(true);
        handleCancelAdd();
        window.location.reload();
      } else {
        addSnackbarMessage({
          message: `Closing Attorney save failed`,
          type: 'error',
        });
      }
    }
  };

  const handleAddAddressBookContact = async () => {
    let firmId = '';
    if (userFirm !== null && userFirm?.firmId !== undefined) {
      firmId = userFirm.firmId;
    }
    const token = await getAccessToken();
    setIsShowValidation(true);
    if (!validationErrors || validationErrors.length === 0) {
      const requestObj = {
        ClosingAttorneyId: null,
        FirstName: closingAttorneyObj.firstName,
        MiddleName: closingAttorneyObj.middleName,
        LastName: closingAttorneyObj.lastName,
        FullName: [closingAttorneyObj.firstName, closingAttorneyObj.middleName, closingAttorneyObj.lastName]
          .filter(Boolean)
          .join(' '),
        DbaName: closingAttorneyObj.dbaName,
        Address1: closingAttorneyObj.address1,
        Address2: closingAttorneyObj.address2,
        City: closingAttorneyObj.city,
        State: closingAttorneyObj.stateCode,
        Zip: closingAttorneyObj.zipCode,
        PhoneNumber: closingAttorneyObj.phoneNumber?.replaceAll('-', ''),
        FaxNumber: closingAttorneyObj.faxNumber?.replaceAll('-', ''),
        EmailAddress: closingAttorneyObj.email,
        LicenseState: closingAttorneyObj.businessIn,
      };
      if (await addressBookAddClosingAttorney(requestObj, firmId, token)) {
        setIsSubmitted(true);
        setIsShowValidation(true);
        handleCancelAdd();
        window.location.reload();
      } else {
        addSnackbarMessage({
          message: `Closing Attorney save failed`,
          type: 'error',
        });
      }
    }
  };

  useEffect(() => {
    if (isShowValidation) {
      validateFields();
    }
  }, [closingAttorneyObj]);

  useEffect(() => {
    if (openDrawer) {
      setClosingAttorneyObj(data);
    }
    setIsShowValidation(false);
  }, [openDrawer]);

  return (
    <>
      <FormDrawerComponent
        title={data.id ? 'Edit Closing Attorney' : 'Add Closing Attorney'}
        open={openDrawer}
        primaryActionProps={{
          loading: isSubmitted,
          disabled: isSubmitted,
          'data-testid': 'AddAddressBookContact',
          size: 'small',
        }}
        dismissActionProps={{
          disabled: isSubmitted,
          'data-testid': 'AddAddressBookContactCancel',
          size: 'small',
        }}
        crossQAPrefix="AddressBookContact"
        primaryActionLabel="Save"
        onPrimaryAction={async () => {
          if (await validateFields()) {
            setIsSubmitted(true);
            if (data.id) {
              handleUpdateAddressBookContact().then();
            } else {
              handleAddAddressBookContact().then();
            }
          } else {
            setIsSubmitted(false);
            setIsShowValidation(true);
          }
        }}
        onDismissAction={handleCancelAdd}
        testId="scrollable-form-drawer"
        width={960}
      >
        <div className={classes.threeCol}>
          <div className={classes.fullWidth}>
            <AgentNetTextInput
              label="First Name"
              fullWidth
              name="firstName"
              id="closingAttorneyFirstName"
              required
              variant="outlined"
              value={closingAttorneyObj.firstName}
              onChange={(event: any) =>
                setClosingAttorneyObj({
                  ...closingAttorneyObj,
                  firstName: event.target.value,
                })
              }
              showValidation={isShowValidation}
              errs={validationErrors}
            ></AgentNetTextInput>
          </div>
          <AgentNetTextInput
            label="Middle Name"
            fullWidth
            name="middleName"
            id="closingAttorneyMiddleName"
            variant="outlined"
            value={closingAttorneyObj.middleName}
            onChange={(event: any) =>
              setClosingAttorneyObj({
                ...closingAttorneyObj,
                middleName: event.target.value,
              })
            }
            showValidation={isShowValidation}
            errs={validationErrors}
          ></AgentNetTextInput>
          <div className={classes.fullWidth}>
            <AgentNetTextInput
              label="Last Name"
              fullWidth
              name="lastName"
              id="closingAttorneyLastName"
              required
              variant="outlined"
              value={closingAttorneyObj.lastName}
              onChange={(event: any) =>
                setClosingAttorneyObj({
                  ...closingAttorneyObj,
                  lastName: event.target.value,
                })
              }
              showValidation={isShowValidation}
              errs={validationErrors}
            ></AgentNetTextInput>
          </div>
        </div>
        <div className={classes.tooltipRow}>
          <RadioGroup
            options={radioOptions}
            required
            disabled={data.id != null}
            onChange={(el: React.ChangeEvent<HTMLInputElement>) => {
              const fieldName = el.target.value;
              if (fieldName === 'NJ') {
                setClosingAttorneyObj({
                  ...closingAttorneyObj,
                  businessIn: 'NJ',
                });
              } else if (fieldName === 'SC') {
                setClosingAttorneyObj({
                  ...closingAttorneyObj,
                  businessIn: 'SC',
                });
              }
            }}
            selected={radioOptions?.find((option) => option.value === closingAttorneyObj.businessIn)}
            name="businessIn"
            label="State Doing Business"
            errs={validationErrors}
            showValidation={isShowValidation}
          ></RadioGroup>
          <Tooltip
            className={classes.tooltipIcon}
            title="Closing Attorneys are used in New Jersey and South Carolina as part of the Closing Protection Letter (CPL) process. When adding a Closing Attorney, select the applicable 'State Doing Business'."
          >
            <HelpOutline color="primary" />
          </Tooltip>
        </div>
        <div
          className={classes.mt2}
          style={
            closingAttorneyObj && closingAttorneyObj.businessIn !== 'NJ'
              ? { visibility: 'hidden' }
              : { visibility: 'visible' }
          }
        >
          <div className={classes.flex}>
            <AgentNetTextInput
              variant="outlined"
              fullWidth
              label="Address 1"
              name="address1"
              id="addContactAddress1"
              className={classes.mb2}
              value={closingAttorneyObj.address1}
              onChange={(event: any) =>
                setClosingAttorneyObj({
                  ...closingAttorneyObj,
                  address1: event.target.value,
                })
              }
              showValidation={isShowValidation}
              errs={validationErrors}
            ></AgentNetTextInput>
            <AgentNetTextInput
              variant="outlined"
              label="Address 2"
              fullWidth
              className={classes.mb2}
              name="address2"
              id="addContactAddress2"
              value={closingAttorneyObj.address2}
              onChange={(event: any) =>
                setClosingAttorneyObj({
                  ...closingAttorneyObj,
                  address2: event.target.value,
                })
              }
              showValidation={isShowValidation}
              errs={validationErrors}
            ></AgentNetTextInput>
          </div>
          <div className={classes.threeCol}>
            <AgentNetTextInput
              variant="outlined"
              label="City"
              name="city"
              className={classes.w50}
              id="addContactCity"
              value={closingAttorneyObj.city}
              onChange={(event: any) =>
                setClosingAttorneyObj({
                  ...closingAttorneyObj,
                  city: event.target.value,
                })
              }
              showValidation={isShowValidation}
              errs={validationErrors}
            ></AgentNetTextInput>
            <span className={classes.w25}>
              <StateSelectField
                variant="outlined"
                name="stateCode"
                label="State"
                value={closingAttorneyObj.stateCode}
                onChange={(e: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  setClosingAttorneyObj({
                    ...closingAttorneyObj,
                    stateCode: e,
                  });
                }}
                showValidation={isShowValidation}
                errs={validationErrors}
              />
            </span>
            <USPostalCodeField
              variant="outlined"
              label="Zip Code"
              fullWidth
              name="zipCode"
              id="addClosingAttorneyZip"
              value={closingAttorneyObj.zipCode}
              onChange={(event: any) =>
                setClosingAttorneyObj({
                  ...closingAttorneyObj,
                  zipCode: event.target.value,
                })
              }
              showValidation={isShowValidation}
              errs={validationErrors}
            />
          </div>
        </div>
      </FormDrawerComponent>
    </>
  );
};

export default AddressBookClosingAttorney;
