import { Box } from '@material-ui/core';
import SearchField from 'features/files/files-summary/search-form/SearchField';
import { useEffect, useState } from 'react';

export const PolicyNumberSearchField = (props: { onSearchPolicy: (searchText: string) => void }) => {
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    const searchPolicyNumberTimeout = setTimeout(() => {
      props.onSearchPolicy(searchValue);
    }, 500);
    return () => clearTimeout(searchPolicyNumberTimeout);
  }, [searchValue]);

  return (
    <Box marginTop={3}>
      <SearchField
        onChange={() => {
          return 0;
        }}
        onSearch={(searchText: string) => {
          setSearchValue(searchText);
        }}
        placeholder="Filter by Policy Number"
        value={searchValue}
      />
    </Box>
  );
};
