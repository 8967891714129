import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import NoBacktitle from 'ui-kit/icons/NoBacktitle';
import NoFilesToReview from 'ui-kit/icons/NoFilesToReview';
import NoPartiesIcon from 'ui-kit/icons/NoParties';
import NoRatesIcon from 'ui-kit/icons/NoRates';
import NoResultsIcon from 'ui-kit/icons/NoResults';
import QuestionCircleWithStars from 'ui-kit/icons/QuestionCircleWithStars';
import AgentNetButton from '../button/AgentNetButton';

interface NoResultsProps {
  type: string;
  onClick?: (p: any) => void;
  titleText?: string;
  pText?: string;
  buttonText?: string;
  disableButton?: boolean;
  className?: string;
  dataTestId?: string;
  showButton?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(1),
      textTransform: 'none',
    },
    mb2: {
      marginBottom: theme.spacing(2),
    },
    mb3: {
      marginBottom: theme.spacing(3),
    },
    pText: {
      maxWidth: '900px',
      color: theme.palette.text.secondary,
    },
    noResults: (props: NoResultsProps) => ({
      textAlign: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingTop: theme.spacing(3),
      width: props.type === 'order-management-access-denied' ? '100%' : 'auto',
    }),
  }),
);

const NoResults = (props: NoResultsProps) => {
  const {
    type,
    onClick,
    buttonText,
    disableButton,
    titleText,
    pText,
    className,
    dataTestId,
    showButton = true,
  } = props;

  const classes = useStyles(props);
  return (
    <div className={clsx(classes.noResults, className && className)}>
      <div className={classes.mb3}>
        {type === 'parties' && <NoPartiesIcon />}
        {type === 'results' && <NoResultsIcon />}
        {type === 'backtitle' && <NoBacktitle />}
        {type === 'files' && <NoFilesToReview />}
        {type === 'proposed-policies' && <NoRatesIcon />}
        {type === 'order-management-access-denied' && <QuestionCircleWithStars />}
      </div>
      <Typography variant="h3" className={classes.title}>
        {titleText ? titleText : 'No Results'}
      </Typography>
      <Typography
        variant="body1"
        className={clsx(classes.mb3, classes.pText)}
        dangerouslySetInnerHTML={{ __html: pText ? pText : 'Would you like to add one now?' }}
      />
      {showButton ? (
        <AgentNetButton
          variant="contained"
          color="primary"
          size="small"
          className={classes.mb2}
          onClick={onClick}
          data-testid={dataTestId}
          disabled={disableButton}
        >
          {buttonText ? buttonText : 'Add New'}
        </AgentNetButton>
      ) : null}
    </div>
  );
};

export default NoResults;
