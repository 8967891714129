import { pxToRem } from '@fluentsms/agentnet-web-components';
import { Grid, IconButton, InputAdornment, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Clear, ReportProblemOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import OverflowTip from 'ui-kit/components/utility/OverflowTip';
import { CurrencyField } from 'ui-kit/inputs';
import { calculateTotals, dollarize, hideFieldsWithStatus } from 'utilities/utilities';

// Base RateFee interface with recursion for two levels
interface RateFee {
  rateFeeTypeCdId: number;
  rateFeeType: string;
  rateFeeLineItemId: number;
  rateFeeDescription: string;
  policyNumber: string;
  isDisabled: boolean;
  grossCalculated: number;
  grossReported: number;
  isGrossOverridden: boolean;
  netCalculated: number;
  netReported: number;
  isNetOverridden: boolean;
  status: string;
  additionalFees?: RateFee[]; // Recursively allows nested RateFees, up to 2 levels
}

// NestedRateFee restricts third-level nesting by disallowing additionalFees
type NestedRateFee = RateFee & { additionalFees?: never };

// This is a union type that combines the two possibilities
type RateFeeWithLimit = RateFee | NestedRateFee;

interface ReportedFeesTableProps {
  products?: RateFeeWithLimit[];
  onChangeProduct?: (
    value: any,
    rateFeeLineItemId: string,
    field: string,
    childId?: string,
    nestedChildId?: string,
  ) => void;
  onClear?: (rateFeeLineItemId: string, field: string, childId?: string, nestedChildId?: string) => void;
  qaPrefix: string;
  showValidation?: boolean;
  displayNet?: boolean;
  disableFields?: boolean;
  hasRemittedProduct?: boolean;
}
interface ProductDescriptionProps {
  data: RateFeeWithLimit;
  displayNet: boolean;
  isChild?: boolean;
  isChildrenOfChild?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alignCenter: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    warningOutlined: { '& path': { fill: theme.palette.warning.main } },
    reportedFeesTable: {
      width: '100%',
    },
    remittedFeesTable: {
      width: '100%',
      margin: '8px 10px 16px 8px',
    },
    reportedFeesTableHeader: {
      backgroundColor: '#F2F5F6',
      borderTop: `2px solid ${theme.palette.divider}`,
      borderBottom: `1px solid ${theme.palette.divider}`,
      fontWeight: 600,
      fontSize: pxToRem(12),
    },
    reportedFeesTableHeaderCell: {
      padding: theme.spacing(1.25),
    },
    tableRow: {
      borderBottom: `1px solid #E0E0E0`,
      minHeight: '56px',
    },
    tableCell: {
      padding: pxToRem(8),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      textWrap: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    tableCellRight: {
      textAlign: 'right',
    },
    indented: {
      paddingLeft: theme.spacing(3),
    },
    indented2: {
      paddingLeft: theme.spacing(5),
    },
    totalsRow: {
      borderTop: `2px solid ${theme.palette.divider}`,
    },
    totalsCell: {
      padding: theme.spacing(1),
    },
    weight500: {
      fontWeight: 500,
    },
    my1: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    mb1: {
      marginBottom: theme.spacing(1),
    },
    isTouched: {
      '& .MuiInputBase-root': {
        backgroundColor: theme.palette.agGrid.overrideBackground,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.agGrid.overrideBorder,
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: 2,
          borderColor: `${theme.palette.agGrid.overrideBorderHover} !important`,
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: `${theme.palette.agGrid.overrideBorderHover} !important`,
        },
      },
    },
    isError: {
      backgroundColor: '#FDECEA !important',
    },
    iconBtn: {
      '&:hover': {
        background: 'transparent',
      },
    },
    policyNumber: {
      color: theme.palette.text.secondary,
    },
  }),
);

const ProductDescription = ({
  data,
  displayNet,
  isChild = false,
  isChildrenOfChild = false,
}: ProductDescriptionProps) => {
  const classes = useStyles();
  const isPending = data.status === 'Pending' && !isChild && !isChildrenOfChild;
  return (
    <Grid
      item
      sm={displayNet ? 6 : 9}
      className={clsx(classes.tableCell, {
        [classes.indented]: isChild,
        [classes.indented2]: isChildrenOfChild,
      })}
    >
      <OverflowTip>
        <Typography
          variant="body2"
          className={clsx({
            [classes.alignCenter]: isPending,
          })}
        >
          {isPending && <ReportProblemOutlined className={classes.warningOutlined} fontSize="small" />}
          {data.rateFeeDescription}
          {data.policyNumber && !isChild && !isChildrenOfChild && (
            <>
              <br />
              <span className={classes.policyNumber}>{`Policy No: ${data.policyNumber}`}</span>
            </>
          )}
        </Typography>
      </OverflowTip>
    </Grid>
  );
};

const InputFields = ({
  data,
  qaPrefix,
  qaSuffix,
  displayNet,
  disableFields,
  showValidation,
  onGrossChangeProduct,
  onNetChangeProduct,
  onGrossClearProduct,
  onNetClearProduct,
  hasRemittedProduct,
}: any) => {
  const classes = useStyles();

  if (!hasRemittedProduct && hideFieldsWithStatus.includes(data.status)) {
    return null;
  }

  return (
    <>
      <Grid item sm={3} className={clsx(classes.tableCell)}>
        <CurrencyField
          variant="outlined"
          value={data.grossReported}
          fullWidth
          max={100000000000}
          allowNegative={false}
          data-testid={`${qaPrefix}Gross${qaSuffix}`}
          onChange={onGrossChangeProduct}
          className={clsx({ [classes.isTouched]: data.isGrossOverridden === true })}
          disabled={disableFields || data.isDisabled}
          alignRight
          //compact
          InputProps={
            data.isGrossOverridden && {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    onClick={onGrossClearProduct}
                    edge="end"
                    className={classes.iconBtn}
                  >
                    <Clear htmlColor="#666666" data-testid={`${qaPrefix}DeleteGross${qaSuffix}`} />
                  </IconButton>
                </InputAdornment>
              ),
            }
          }
        />
      </Grid>
      {displayNet && (
        <Grid item sm={3} className={clsx(classes.tableCell)}>
          <CurrencyField
            variant="outlined"
            value={data.netReported}
            error={data.isError && showValidation}
            fullWidth
            max={100000000000}
            data-testid={`${qaPrefix}Net${qaSuffix}`}
            allowNegative={false}
            onChange={onNetChangeProduct}
            className={clsx({
              [classes.isTouched]: data.isNetOverridden === true,
              [classes.isError]: data.isError && showValidation,
            })}
            disabled={disableFields || data.isDisabled}
            alignRight
            //compact
            InputProps={
              data.isNetOverridden && {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      onClick={onNetClearProduct}
                      edge="end"
                      className={classes.iconBtn}
                    >
                      <Clear htmlColor="#666666" data-testid={`${qaPrefix}DeleteNet${qaSuffix}`} />
                    </IconButton>
                  </InputAdornment>
                ),
              }
            }
          />
        </Grid>
      )}
    </>
  );
};

const ReportedFeesTable = ({
  onChangeProduct,
  products = [],
  onClear,
  qaPrefix,
  showValidation,
  displayNet = true,
  disableFields = true,
  hasRemittedProduct = false,
}: ReportedFeesTableProps) => {
  const classes = useStyles();
  const [grandTotal, setGrandTotal] = useState({
    gross: 0,
    net: 0,
  });
  useEffect(() => {
    setGrandTotal(calculateTotals(products, hasRemittedProduct));
  }, [products, hasRemittedProduct]);

  return (
    <>
      {products && products.length > 0 && (
        <div className={hasRemittedProduct ? classes.remittedFeesTable : classes.reportedFeesTable}>
          <Grid container className={classes.reportedFeesTableHeader}>
            <Grid
              item
              sm={displayNet ? 6 : 9}
              className={classes.reportedFeesTableHeaderCell}
              style={{ paddingLeft: 16 }}
            >
              Product
            </Grid>
            <Grid item sm={3} className={classes.reportedFeesTableHeaderCell}>
              Gross Reported
            </Grid>
            {displayNet && (
              <Grid item sm={3} className={classes.reportedFeesTableHeaderCell}>
                Net Reported
              </Grid>
            )}
          </Grid>
          <Grid container>
            {products.map((e: any, i) => (
              <>
                <Grid container key={e.rateFeeLineItemId} className={classes.tableRow}>
                  <ProductDescription data={e} displayNet={displayNet} />
                  <InputFields
                    data={e}
                    qaPrefix={qaPrefix}
                    qaSuffix={i + 1}
                    displayNet={displayNet}
                    disableFields={disableFields}
                    showValidation={showValidation}
                    hasRemittedProduct={hasRemittedProduct}
                    onGrossChangeProduct={(el: any) => {
                      onChangeProduct && onChangeProduct(el.target.value, e.rateFeeLineItemId, 'grossReported');
                    }}
                    onNetChangeProduct={(el: any) => {
                      onChangeProduct && onChangeProduct(el.target.value, e.rateFeeLineItemId, 'netReported');
                    }}
                    onGrossClearProduct={() => onClear?.(e.rateFeeLineItemId, 'grossReported')}
                    onNetClearProduct={() => onClear?.(e.rateFeeLineItemId, 'netReported')}
                  />
                </Grid>
                {e.additionalFees &&
                  e.additionalFees.map((c: any, j: any) => (
                    <>
                      <Grid container key={c.rateFeeLineItemId} className={classes.tableRow}>
                        <ProductDescription data={c} isChild displayNet={displayNet} />

                        <InputFields
                          data={c}
                          qaPrefix={qaPrefix}
                          qaSuffix={`${i + 1}${j + 1}`}
                          displayNet={displayNet}
                          disableFields={disableFields}
                          showValidation={showValidation}
                          hasRemittedProduct={hasRemittedProduct}
                          onGrossChangeProduct={(el: any) => {
                            onChangeProduct?.(
                              el.target.value,
                              e.rateFeeLineItemId,
                              'grossReported',
                              c.rateFeeLineItemId,
                            );
                          }}
                          onNetChangeProduct={(el: any) => {
                            onChangeProduct?.(el.target.value, e.rateFeeLineItemId, 'netReported', c.rateFeeLineItemId);
                          }}
                          onGrossClearProduct={() =>
                            onClear?.(e.rateFeeLineItemId, 'grossReported', c.rateFeeLineItemId)
                          }
                          onNetClearProduct={() => onClear?.(e.rateFeeLineItemId, 'netReported', c.rateFeeLineItemId)}
                        />
                      </Grid>
                      {c.additionalFees &&
                        c.additionalFees.map((z: any, k: any) => (
                          <Grid container key={z.rateFeeLineItemId} className={classes.tableRow}>
                            <ProductDescription data={z} isChildrenOfChild displayNet={displayNet} />
                            <InputFields
                              data={z}
                              qaPrefix={qaPrefix}
                              qaSuffix={`${i + 1}${j + 1}${k + 1}`}
                              displayNet={displayNet}
                              disableFields={disableFields}
                              showValidation={showValidation}
                              hasRemittedProduct={hasRemittedProduct}
                              onGrossChangeProduct={(el: any) => {
                                onChangeProduct?.(
                                  el.target.value,
                                  e.rateFeeLineItemId,
                                  'grossReported',
                                  c.rateFeeLineItemId,
                                  z.rateFeeLineItemId,
                                );
                              }}
                              onNetChangeProduct={(el: any) => {
                                onChangeProduct?.(
                                  el.target.value,
                                  e.rateFeeLineItemId,
                                  'netReported',
                                  c.rateFeeLineItemId,
                                  z.rateFeeLineItemId,
                                );
                              }}
                              onGrossClearProduct={() =>
                                onClear?.(
                                  e.rateFeeLineItemId,
                                  'grossReported',
                                  c.rateFeeLineItemId,
                                  z.rateFeeLineItemId,
                                )
                              }
                              onNetClearProduct={() =>
                                onClear?.(e.rateFeeLineItemId, 'netReported', c.rateFeeLineItemId, z.rateFeeLineItemId)
                              }
                            />
                          </Grid>
                        ))}
                    </>
                  ))}
              </>
            ))}
            <Grid container className={classes.totalsRow}>
              <Grid item sm={displayNet ? 6 : 9} className={classes.totalsCell}>
                <Typography variant="body2" className={classes.weight500}>
                  Grand Total:
                </Typography>
              </Grid>
              <Grid item sm={3} className={clsx(classes.totalsCell, classes.tableCellRight)}>
                <Typography variant="body2" data-testid={`${qaPrefix}GrossTotal`} className={classes.weight500}>
                  {dollarize(grandTotal.gross)}
                </Typography>
              </Grid>
              {displayNet && (
                <Grid item sm={3} className={clsx(classes.totalsCell, classes.tableCellRight)}>
                  <Typography variant="body2" data-testid={`${qaPrefix}NetTotal`} className={classes.weight500}>
                    {dollarize(grandTotal.net)}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default ReportedFeesTable;
