import { Container, Grid, IconButton, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { AgentNetDivider } from 'ui-kit/components/dividers/AgentNetDivider2';
import { UnderwritingDocument, UnderwritingRequest } from './types';

import { pxToRem } from '@fluentsms/agentnet-web-components';
import { DeleteForeverOutlined } from '@material-ui/icons';
import { FileWithPath } from 'react-dropzone';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import Uploader from 'ui-kit/components/uploader/Uploader';
import { Document, DocumentPDF, DocumentXLS } from 'ui-kit/icons/DocIcons';

interface UnderwritingProps {
  uwr?: UnderwritingRequest;
  updateUnderwriting: any;
  onViewDocument?: any;
  clearDocuments?: boolean;
  isUwrDisabled?: boolean;
  isDisabled?: boolean;
}
const UnderwritingDocumentsFC: React.FC<UnderwritingProps> = ({
  uwr,
  updateUnderwriting,
  onViewDocument,
  clearDocuments,
  isUwrDisabled,
  isDisabled,
}: UnderwritingProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      Table: {
        width: '100%',
        padding: '0 0 24px',
      },
      TableHeader: {
        backgroundColor: '#F2F5F6',
        borderBottom: '1px solid #D9E0E5',
        fontWeight: 600,
        fontSize: pxToRem(14),
      },
      tableHeaderCell: {
        padding: pxToRem(12),
      },
      required: {
        color: 'red',
      },
      actionsTableList: {},
      tableRow: {
        borderBottom: `1px solid #E0E0E0`,
      },
      tableCell: {
        padding: pxToRem(2, 12),
        display: 'flex',
        alignItems: 'center',
      },
      tableCellActions: {
        justifyContent: 'flex-start',
      },
      docButton: {
        minWidth: 0,
        display: 'grid',
      },
      deleteRow: {
        padding: 0,
        marginLeft: theme.spacing(1),
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      my1: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      mb1: {
        marginBottom: theme.spacing(1),
      },
    }),
  );

  const [uploadedFiles, setUploadedFiles] = useState<FileWithPath[]>([]);

  function generateIcon(fileType?: any) {
    if (fileType === 'pdf') {
      return <DocumentPDF />;
    }
    if (fileType === 'xls' || fileType === 'xlsx') {
      return <DocumentXLS />;
    }
    return <Document />;
  }

  const classes = useStyles();

  const handleDeleteSavedDocument = (file: UnderwritingDocument) => {
    const documentList: UnderwritingDocument[] = uwr?.Documents ?? [];

    const documents = documentList.filter((uwrdocument: UnderwritingDocument) => uwrdocument.Id !== file.Id);
    updateUnderwriting('Documents', documents);
  };

  const getFormattedDate = (input: string | Date | undefined, addTimestamp = false) => {
    if (!input) {
      return '';
    }

    const padded = (num: number) => {
      return num < 10 ? '0' + num : '' + num;
    };

    try {
      const date = new Date(input);

      let output = `${padded(date.getMonth() + 1)}/${date.getDate()}/${date.getFullYear()}`;

      if (addTimestamp) {
        const hour = date.getHours() % 12 || 12;
        const period = date.getHours() < 12 ? 'AM' : 'PM';
        output += ` ${padded(hour)}:${padded(date.getMinutes())}:${padded(date.getSeconds())} ${period} PST`;
      }

      return output;
    } catch {
      return '';
    }
  };

  async function getAsByteArray(file: Blob) {
    const val: ArrayBuffer = (await readFile(file)) as ArrayBuffer;
    const base64 = btoa(new Uint8Array(val).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    return base64;
  }

  function readFile(file: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('loadend', (e) => resolve(e?.target?.result));
      reader.addEventListener('error', reject);
      reader.readAsArrayBuffer(file);
    });
  }

  const handleDocuments: any = (uploads: FileWithPath[]) => {
    setUploadedFiles(uploads);
  };

  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      const documentList: UnderwritingDocument[] = uwr?.Documents ?? [];
      const localDocumentList: UnderwritingDocument[] = [];

      const filePathsPromises: any[] = [];
      uploadedFiles.forEach((file) => {
        const document: UnderwritingDocument = {
          Id: 0,
          Name: file.name,
          CreatedDate: new Date(),
          FileType: file.name.split('.').pop() ?? '',
          Type: 'Other',
          Size: '',
          Binary: '',
          IsUserUploaded: true,
        };

        localDocumentList.push(document);
        filePathsPromises.push(getAsByteArray(file));
      });
      Promise.all(filePathsPromises).then((filePaths) => {
        filePaths.forEach((e, i) => {
          localDocumentList[i].Binary = e;
        });
        updateUnderwriting('Documents', [...documentList, ...localDocumentList]);

        if (tableRef.current) {
          tableRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      });
    }
  }, [uploadedFiles]);

  const deleteDocument: any = async (file?: any) => {
    const documentList: UnderwritingDocument[] = uwr?.Documents ?? [];

    const documents = documentList.filter((uwrdocument: UnderwritingDocument) => {
      if (uwrdocument.Name === file.name && uwrdocument.Id === 0) {
        return false;
      }
      return true;
    });
    updateUnderwriting('Documents', documents);
  };

  const renderDocumentsTable = () => {
    return (
      <div className={clsx(classes.Table, 'actionsTableWrapper')}>
        <Grid container className={classes.TableHeader}>
          <Grid item sm={5} className={classes.tableHeaderCell}>
            Document Name
          </Grid>
          <Grid item sm={isDisabled ? 6 : 6} className={classes.tableHeaderCell}>
            Date
          </Grid>
          <Grid item sm={1} className={classes.tableHeaderCell}>
            File
          </Grid>
        </Grid>
        <Grid container className={classes.actionsTableList}>
          {uwr?.Documents?.map(
            (d: UnderwritingDocument, i) =>
              d.Id > 0 && (
                <Grid container key={i} className={classes.tableRow}>
                  <Grid item sm={5} className={classes.tableCell}>
                    <Typography variant="body2">{d.Name}</Typography>
                  </Grid>
                  <Grid item sm={isDisabled ? 6 : 6} className={classes.tableCell}>
                    <Typography variant="body2">{getFormattedDate(d.CreatedDate, true)}</Typography>
                  </Grid>
                  <Grid item sm={1} className={clsx(classes.tableCell, classes.tableCellActions)}>
                    <AgentNetButton
                      variant="text"
                      onClick={(e1) => onViewDocument(d.Id)}
                      data-testid={'UnderwritingDocumentView' + [i + 1]}
                      className={classes.docButton}
                      color="primary"
                      size="small"
                    >
                      {generateIcon(d.FileType)}
                    </AgentNetButton>
                    {!isDisabled && d.IsUserUploaded === true && (
                      <Typography variant="body2">
                        <IconButton
                          className={classes.deleteRow}
                          data-testid={'UnderwritingDocument' + 'RemoveENDOIcon' + [i + 1]}
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          aria-label="delete document"
                          onClick={() => {
                            handleDeleteSavedDocument(d);
                          }}
                          disabled={isUwrDisabled}
                        >
                          <DeleteForeverOutlined color="error" />
                        </IconButton>
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              ),
          )}
        </Grid>
      </div>
    );
  };

  const renderDocumentsUploader = () => {
    return (
      <>
        {!isDisabled && (
          <Grid container>
            <Grid item sm={12}>
              <Uploader
                handleFiles={(files) => handleDocuments(files)}
                onDeleteItem={deleteDocument}
                listTitle="Uploaded Document(s)"
                maxSize={45}
                acceptTypes={{
                  'application/pdf': ['.pdf'],
                  'application/msword': ['.doc'],
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                  'application/x-excel': ['.xls'],
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                  'application/vnd.ms-powerpoint': ['.ppt'],
                  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
                  'application/rtf': ['.rtf'],
                  'application/txt': ['.txt'],
                  'application/msg': ['.msg'],
                  'image/jpg': ['.JPG'],
                  'application/gif': ['.GIF'],
                  'image/tif': ['.tif'],
                  'image/tiff': ['.tiff'],
                  'application/png': ['.png'],
                  'audio/mpeg': ['.mp3'],
                  'video/mp4': ['.mp4'],
                  'audio/x-ms-wma': ['.wma'],
                }}
                data-testid={'UnderwritingDocumentUpload'}
                disabled={isUwrDisabled || isDisabled}
                clearAll={clearDocuments}
                restrictDuplicate={true}
              />
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <AgentNetDivider title={'Supporting Documents'} typoVariant="h2" disableButton={false} disablePaddingX />
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        {renderDocumentsTable()}
      </Grid>
      <Grid container spacing={0} ref={tableRef}>
        {renderDocumentsUploader()}
      </Grid>
    </Container>
  );
};

export default UnderwritingDocumentsFC;
