import { useAuth } from '@agentnet/auth';
import { DataTable, pxToRem } from '@fluentsms/agentnet-web-components';
import { Tooltip, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { ColDef } from 'ag-grid-enterprise';
import { KBLinks } from 'features/constants';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import PageHeader from 'ui-kit/components/headers/PageHeader';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';
import NoResultFoundIcon from 'ui-kit/icons/NoResultFound';
import {
  addressBookDeleteClosingAttorney,
  addressBookDeleteLender,
  addressBookGetClosingAttorneys,
  addressBookGetLenders,
} from '../../api/admin/address-book-api';
import { ClosingAttorneyDTO, LenderResponseDTO } from '../../api/admin/types';
import { ProfileContext, ProfileContextInterface } from '../../hooks/ProfileContext';
import LoadingSpinner from '../../ui-kit/components/LoadingSpinner';
import { AgentNetConfirmationDialog } from '../../ui-kit/components/modal/ConfirmationDialog';
import './AddressBook.scss';
import AddressBookContactForm from './AddressBook/AddressBookAddContactForm';
import { ContactType } from './constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageContainer: {
      position: 'relative',
      width: '720px',
      height: '137px',
      backgroundColor: theme.palette.common.white,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    },
    image: { minHeight: '100%', width: '100%', position: 'absolute', top: 0 },

    imageOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: `${theme.palette.common.black}8C`,
      zIndex: 1,
    },
    imageText: {
      zIndex: 2,
      color: theme.palette.common.white,
      textAlign: 'center',
      maxWidth: '35ch',
    },
    addrContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    mainContainer: {
      background: theme.palette.common.white,
      paddingBottom: theme.spacing(4),
      overflowX: 'hidden',
    },
    textContainer: {
      margin: theme.spacing(3),
    },
    gridContainer: {
      height: 'calc(100vh - 296px) !important',
      width: '100%',
      fontFamily: theme.typography.fontFamily,
    },
    text: { maxWidth: '60ch' },
    table: { marginBottom: pxToRem(15) },
    tableCell: {
      padding: 0,
      borderWidth: 0,
    },
    inputContainer: {
      margin: theme.spacing(5, 1),
    },
    addressTable: {
      maxWidth: '100%',
      flex: '1',
      marginTop: pxToRem(114),
    },
    btnRow: {
      padding: theme.spacing(0),
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '16px',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(3),
    },
    loader: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    noResultsType: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    tooltip: {
      backgroundColor: '#fff',
      color: ' #423d3d',
      fontSize: '13px',
      padding: '5px',
      whiteSpace: 'nowrap',
      border: '1px solid lightgrey',
    },
    iconContainer: {
      display: 'flex',
      width: '25px',
      textAlign: 'center',
    },
    gridWrap: {
      margin: theme.spacing(3),
      marginTop: 0,
    },
  }),
);

const AddressBook = (): JSX.Element => {
  const { getAccessToken } = useAuth();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [rowData, setRowData] = useState<any>([]);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  const gridRef = useRef<any>(null);

  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm } = profileCtx;
  const classes = useStyles();

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>([]);

  const addNewContact = (type: string) => {
    setSelectedRow({ contactType: type, assignTypeCdId: '' });
    setOpenDrawer(true);
  };

  const handleDeleteContact = () => {
    deleteAddressBookContact().then(() => {
      LoadPage().then(() => {
        handleCloseDialog();
      });
    });
  };

  const deleteAddressBookContact = async (): Promise<unknown> => {
    let firmId = '';
    if (userFirm !== null && userFirm?.firmId !== undefined) {
      firmId = userFirm.firmId;
    }
    const token = await getAccessToken();
    if (selectedRow.contactType === ContactType.LENDER.toString()) {
      return addressBookDeleteLender(
        {
          AddressBookEntryId: selectedRow.id,
          IsMyLender: selectedRow.isMyLender,
          FirmId: selectedRow.ownerFirmId,
          LenderAddressBookEntry: {
            Name: selectedRow.locationName,
            LenderAttentionName: selectedRow.attention,
            Address: {
              Address1: selectedRow.Address1,
              Address2: selectedRow.Address2,
              City: selectedRow.City,
              State: selectedRow.stateCode,
              PostalCode: selectedRow.zipCode,
              County: '',
            },
            LenderClauseType: selectedRow.lenderAssignTypeCdId,
            LenderClauseText: selectedRow.lenderAssigns,
            PhoneNumber: selectedRow.phoneNumber,
            FaxNumber: selectedRow.faxNumber,
            EmailAddress: selectedRow.email,
          },
        },
        firmId,
        token,
      );
    } else {
      return addressBookDeleteClosingAttorney(
        {
          ClosingAttorneyId: selectedRow.id,
          FirstName: selectedRow.attorneyFirstName,
          MiddleName: selectedRow.attorneyMiddleName,
          LastName: selectedRow.attorneyLastName,
          FullName: selectedRow.attorneyFullName,
          Address1: selectedRow.address1,
          Address2: selectedRow.address2,
          City: selectedRow.city,
          State: selectedRow.stateCode,
          Zip: selectedRow.zipCode,
          LicenseState: selectedRow.businessIn,
        },
        firmId,
        token,
      );
    }
  };

  const LoadPage = async () => {
    let firmId = '';
    if (userFirm !== null && userFirm?.firmId !== undefined) {
      firmId = userFirm.firmId;
    }

    const token = await getAccessToken();
    const [lenders, attorneys] = await LoadContacts(token, firmId);
    MergeContacts(
      lenders.sort((a, b) => {
        const nameA = a.Name.toUpperCase();
        const nameB = b.Name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }),
      attorneys.sort((a, b) => {
        const nameA = a.FullName.toUpperCase();
        const nameB = b.FullName.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }),
    );
  };

  const LoadContacts = async (token: string, firmId: string): Promise<[LenderResponseDTO[], ClosingAttorneyDTO[]]> => {
    return await Promise.all([LoadLenders(token, firmId), LoadClosingAttorneys(token, firmId)]);
  };

  const LoadLenders = async (token: string, firmId: string): Promise<LenderResponseDTO[]> => {
    return await addressBookGetLenders(token, firmId);
  };

  const LoadClosingAttorneys = async (token: string, firmId: string): Promise<ClosingAttorneyDTO[]> => {
    return await addressBookGetClosingAttorneys(token, firmId);
  };

  const MergeContacts = async (lenders: LenderResponseDTO[], attorneys: ClosingAttorneyDTO[]) => {
    const mergedList: any[] = [];
    lenders.forEach(function (lenderObj) {
      const formattedContactObj = {
        contactType: ContactType.LENDER.toString(),
        id: lenderObj.AddressBookEntryId,
        isMyLender: lenderObj.IsMyLender,
        firstName: '',
        middleName: '',
        lastName: '',
        name: lenderObj.Name,
        dbaName: '',
        title: '',
        address1: lenderObj.Address.Address1,
        address2: lenderObj.Address.Address2,
        city: lenderObj.Address.City,
        stateCode: lenderObj.Address.State,
        zipCode: lenderObj.Address.PostalCode,
        phoneNumber: lenderObj.PhoneNumber,
        faxNumber: '',
        emailAddress: lenderObj.EmailAddress,
        businessIn: '',
        attention: lenderObj.LenderAttentionName,
        assignTypeCdId: lenderObj.LenderClauseType,
        assigns: lenderObj.LenderClauseText,
      };
      mergedList.push(formattedContactObj);
    });

    attorneys.forEach(function (attorneyObj) {
      const formattedContactObj = {
        contactType: ContactType.CLOSING_ATTORNEY.toString(),
        id: attorneyObj.ClosingAttorneyId,
        firstName: attorneyObj.FirstName,
        middleName: attorneyObj.MiddleName,
        lastName: attorneyObj.LastName,
        dbaName: attorneyObj.DbaName,
        title: attorneyObj.Title,
        name: attorneyObj.FullName,
        address1: attorneyObj.Address1,
        address2: attorneyObj.Address2,
        city: attorneyObj.City,
        stateCode: attorneyObj.State,
        zipCode: attorneyObj.Zip,
        phoneNumber: attorneyObj.PhoneNumber,
        faxNumber: attorneyObj.FaxNumber,
        emailAddress: attorneyObj.EmailAddress,
        businessIn: attorneyObj.LicenseState,
        attention: '',
        assignTypeCdId: '',
        assigns: '',
      };
      mergedList.push(formattedContactObj);
    });

    setRowData(mergedList);
    setDataLoaded(true);
  };

  const handleCloseDialog = () => {
    setOpenDeleteModal(false);
  };

  const onSelectionChanged = useCallback(() => {
    const selectedRow = gridRef.current?.api?.getSelectedRows()[0];
    setSelectedRow(selectedRow);
  }, []);

  const phoneNumberRenderer = (params: any) => {
    const phoneNumber = params?.data?.phoneNumber;
    const rawPhoneNumber =
      phoneNumber?.replaceAll('-', '').replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '') ?? '';
    if (!rawPhoneNumber || rawPhoneNumber.length != 10) {
      return <div></div>;
    }
    // Format the phone number with dashes (assuming US format)
    const formattedPhoneNumber = rawPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    return <div>{formattedPhoneNumber}</div>;
  };

  const CustomOverlayLoading = () => {
    return (
      <div className="no-rows">
        <LoadingSpinner status="pending" className="files-container--spinner-root" />
      </div>
    );
  };

  const onFilterChanged = () => {
    const filterModel = gridRef.current?.api.getFilterModel() ?? {};
    if (filterModel.errorCategory) {
      let errorCode = filterModel.errorCategory?.type;
      errorCode = errorCode === 'Stat Code(s)' ? 'Stat' : errorCode;
      errorCode === 'All' ? gridRef.current?.api.setQuickFilter('') : gridRef.current?.api.setQuickFilter(errorCode);
    }

    if (gridRef.current?.api?.getDisplayedRowCount() === 0) {
      gridRef.current?.api.showNoRowsOverlay();
    } else {
      gridRef.current?.api.hideOverlay();
    }
  };

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        filter: 'agTextColumnFilter',
        tooltipField: 'name',
        sortable: true,
        floatingFilter: true,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: 'Type',
        field: 'contactType',
        flex: 1,
        filter: 'agTextColumnFilter',
        tooltipField: 'contactType',
        sortable: true,
        floatingFilter: true,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: 'Address',
        field: 'address1',
        flex: 1,
        filter: 'agTextColumnFilter',
        tooltipField: 'address1',
        sortable: true,
        floatingFilter: true,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: 'Phone Number',
        field: 'phoneNumber',
        flex: 1,
        filter: 'agTextColumnFilter',
        tooltipField: 'phoneNumber',
        sortable: true,
        floatingFilter: true,
        suppressHeaderFilterButton: true,
        cellRenderer: phoneNumberRenderer,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: 'Email',
        field: 'emailAddress',
        flex: 1,
        filter: 'agTextColumnFilter',
        tooltipField: 'emailAddress',
        sortable: true,
        floatingFilter: true,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: '',
        field: 'edit',
        suppressHeaderMenuButton: true,
        cellStyle: { display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center' },
        cellRenderer: (params: any) => {
          return (
            <div className={classes.iconContainer}>
              <Tooltip
                onClick={() => {
                  setSelectedRow(params?.data);
                  setOpenDrawer(true);
                }}
                title="Edit Contact"
                classes={{ tooltip: classes.tooltip }}
              >
                <EditOutlinedIcon fontSize="small" htmlColor="#0074CA" />
              </Tooltip>
            </div>
          );
        },
        maxWidth: 50,
      },
      {
        headerName: '',
        field: 'delete',
        suppressHeaderMenuButton: true,
        cellStyle: { display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center' },
        sortable: false,
        cellRenderer: (params: any) => {
          return (
            <Tooltip
              onClick={() => {
                setSelectedRow(params?.data);
                setOpenDeleteModal(true);
              }}
              title="Delete Contact"
              classes={{ tooltip: classes.tooltip }}
            >
              <DeleteOutlineOutlinedIcon fontSize="small" htmlColor="#0074CA" />
            </Tooltip>
          );
        },
        maxWidth: 50,
      },
    ],
    [],
  );

  useEffect(() => {
    LoadPage();
  }, []);

  useEffect(() => {
    if (openDrawer === false) {
      LoadPage();
    }
  }, [openDrawer]);

  return (
    <ContentContainer fullWidth>
      <PageHeader
        title="Address Book"
        subtitle="Manage your Lenders and Closing Attorneys (NJ & SC only). Added contacts can be selected when creating new files and generating products like the Closing Protection Letter (CPL)."
        contentRight={
          <div className={classes.btnRow}>
            <AgentNetButton
              color="primary"
              size="small"
              className="button"
              variant="contained"
              onClick={() => addNewContact(ContactType.CLOSING_ATTORNEY.toString())}
            >
              Add Closing Attorney
            </AgentNetButton>
            <AgentNetButton
              color="primary"
              size="small"
              className="button"
              variant="contained"
              onClick={() => addNewContact(ContactType.LENDER.toString())}
            >
              Add Lender
            </AgentNetButton>
          </div>
        }
        menuItems={[
          {
            label: 'Knowledge Base',
            link: KBLinks.addressBook,
          },
        ]}
      />
      {!dataLoaded ? (
        <div className={classes.loader}>
          <LoadingSpinner variant="circle" status={'pending'} />
        </div>
      ) : rowData.length === 0 ? (
        <div className="addr-container">
          <div className="no-results-msg">
            <NoResultFoundIcon className="no-contacts" />
            <Typography variant="h1">No Contacts Found</Typography>
            <Typography variant="body1" className="mb-2">
              Would you like to create one now?
            </Typography>
            <AgentNetButton
              color="primary"
              size="small"
              className="button mr-1"
              variant="contained"
              onClick={() => addNewContact(ContactType.CLOSING_ATTORNEY.toString())}
            >
              Add Closing Attorney
            </AgentNetButton>
            <AgentNetButton
              color="primary"
              size="small"
              className="button"
              variant="contained"
              onClick={() => addNewContact(ContactType.LENDER.toString())}
            >
              Add Lender
            </AgentNetButton>
          </div>
        </div>
      ) : (
        <Grid id="AddressBook">
          <div className={classes.gridWrap}>
            <div className="grid-summary">
              <div className={`ag-theme-alpine table-grid ${classes.gridContainer}`}>
                <DataTable
                  ref={gridRef}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  animateRows={true}
                  onSelectionChanged={onSelectionChanged}
                  onFilterChanged={onFilterChanged}
                  components={{
                    customOverlayLoading: CustomOverlayLoading,
                  }}
                  loadingOverlayComponent={'customOverlayLoading'}
                />
              </div>
            </div>
          </div>
        </Grid>
      )}
      <AddressBookContactForm
        {...{ contactType: selectedRow.contactType, data: selectedRow, openDrawer, setOpenDrawer }}
      />
      <div>
        <AgentNetConfirmationDialog
          qaAttrPrefix="ConfirmationDelete"
          //disabled={deleteStatus === 'pending'}
          onConfirm={handleDeleteContact}
          open={openDeleteModal}
          onDismissAction={handleCloseDialog}
          dialogTitle={selectedRow.contactType === 'Lender' ? 'Delete Lender' : 'Delete Closing Attorney'}
          dialogBtnContent="Yes, Delete"
          dialogText={
            selectedRow.contactType === 'Lender'
              ? 'You are about to permanently delete this Lender.  Are you sure you want to proceed?'
              : 'You are about to permanently delete this Closing Attorney.  Are you sure you want to proceed?'
          }
        />
      </div>
    </ContentContainer>
  );
};

export default AddressBook;
