import { useAuth } from '@agentnet/auth';
import { Grid, Theme } from '@agentnet/components';
import {
  CellLink,
  CellStatus,
  Content,
  DashboardMetricCard,
  DataTable,
  PageHeader,
  pxToRem,
} from '@fluentsms/agentnet-web-components';
import { createStyles, makeStyles } from '@material-ui/styles';
import {
  CellClickedEvent,
  ColDef,
  FilterModel,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  RowNode,
  ValueFormatterParams,
} from 'ag-grid-enterprise';
import { getFirmDetails } from 'api/onBehalfOf/api';
import { getFilesInProcessApi } from 'api/orderManagement/order-management-api';
import clsx from 'clsx';
import { getColorsByStatus, TeamAssignment } from 'features/orderManagement/enum';
import { fileListFilterParams, formatDate, numberValueFormatter } from 'features/orderManagement/fileListConfig';
import { FileEditAssignmentType, FileInProcessType, OrderType } from 'features/orderManagement/types';
import CalculatorRenderer from 'features/Remittance/CalculatorRenderer';
import useRatesAndFee from 'features/Remittance/useRatesAndFee';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BottomAppBar from 'ui-kit/components/bottomAppBar/BottomAppBar';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';
import ReportsAndPayDrawer from '../ReportsAndPayDrawer';
import { FileEditAssignmentContainer } from './FileEditAssignmentContainer';

const colorsByStatus = getColorsByStatus();

export function FilesInProcessContent() {
  const { getAccessToken } = useAuth();
  const gridApiRef = useRef<GridApi<FileInProcessType> | null>(null);
  const { addGlobalMsg } = useGlobalMessages();
  const [filesInProcess, setFilesInProcess] = useState<Array<FileInProcessType>>([]);
  const [selectedFiles, setSelectedFiles] = useState<Array<FileInProcessType>>([]);
  const [currentTeam, setCurrentTeam] = useState<number | null>(null);
  const [displayedRowCount, setDisplayedRowCount] = useState<number>(0);
  const [editAssignmentDrawerOpen, setEditAssignmentDrawerOpen] = useState<boolean>(false);
  const [editAssignmentData, setEditAssignmentData] = useState<FileEditAssignmentType[]>([]);

  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { setUserFirm } = profileCtx;

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        marginTop: pxToRem(114),
      },
      selectedRows: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100% !important',
        fontSize: pxToRem(14),
        padding: theme.spacing(3),
        paddingBottom: 12,
      },
      heading: {
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 20,
      },
      contentWrap: {
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100%)',
      },
      contentClass: {
        margin: '0 auto auto',
      },
      tableDefault: {
        height: `calc(100vh - ${filesInProcess?.length ? '400px' : '350px'}) !important`,
        fontFamily: theme.typography.fontFamily,
      },
      paddingX: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
        width: 'auto',
      },
      noRows: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: 220,
        justifyContent: 'center',
      },
      personBackground: {
        backgroundColor: '#696d7033',
        borderRadius: 50,
      },
    }),
  );
  const classes = useStyles();
  const history = useHistory();

  const headerCheckboxRenderer = () => {
    return <input type="checkbox" data-testid={'OrderManagement-FilesInProcess-AllCheckbox'} />;
  };

  const rowCheckboxRenderer = (params: { node: RowNode }) => {
    return <input type="checkbox" data-testid={`OrderManagement-FilesInProcess-Checkbox-${params.node.id}`} />;
  };

  const { drawerData, setDrawerData, openDrawer, setOpenDrawer, handleDrawerOpen, rateAndFeeExecute } = useRatesAndFee(
    profileCtx.userFirm?.firmId ?? '',
    'order-management',
  );

  const onGridReady = (params: GridReadyEvent<FileInProcessType>) => {
    gridApiRef.current = params.api;
  };

  const onFilterChanged = (): void => {
    setDisplayedRowCount(getDisplayedRowCount());
  };

  const getDisplayedRowCount = (): number => {
    return gridApiRef.current?.getDisplayedRowCount() ?? -1;
  };

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridApiRef.current?.getSelectedRows() ?? [];
    setSelectedFiles(selectedRows);
  }, [selectedFiles]);

  const netAmountTotal = selectedFiles?.reduce(
    (accumulator: number, obj: FileInProcessType) => accumulator + obj.reportedAmount,
    0,
  );

  const selectedFilesText = `Selecting ${selectedFiles?.length} file(s) for ${numberValueFormatter(
    netAmountTotal,
    true,
  )}`;

  const orderDateRenderer = (params: { value: string }) => {
    return <div>{formatDate(params.value ?? '')}</div>;
  };

  const defaultColDef: ColDef = {
    flex: 2,
    floatingFilter: true,
    filter: true,
    sortable: true,
    resizable: true,
    editable: false,
    suppressMenu: true,
    filterParams: { closeOnApply: true, suppressAndOrCondition: true },
  };

  const getColumnConfig = (): ColDef[] => {
    return [
      {
        field: '',
        maxWidth: 40,
        filter: false,
        floatingFilter: false,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
        pinned: 'left',
        headerComponent: headerCheckboxRenderer,
        cellRenderer: rowCheckboxRenderer,
        resizable: false,
      },
      {
        colId: 'statusColumn',
        headerName: 'Status',
        field: 'status',
        filter: 'agSetColumnFilter',
        minWidth: 144,
        maxWidth: 256,
        tooltipField: 'status',
        cellRenderer: (params: ICellRendererParams) => {
          const status = params.data?.status ?? '';
          return <CellStatus status={status} colorsByStatus={colorsByStatus} />;
        },
      },
      {
        colId: 'reasonCodeColumn',
        headerName: 'Reason Code',
        field: 'reasonCode',
        minWidth: 144,
        maxWidth: 256,
        filter: 'agSetColumnFilter',
        tooltipField: 'reasonCode',
      },
      {
        colId: 'fileIdColumn',
        headerName: 'File Id',
        field: 'fileId',
        filter: 'agTextColumnFilter',
        tooltipField: 'fileId',
        hide: true,
      },
      {
        colId: 'fileNumberColumn',
        headerName: 'File Number',
        field: 'fileNumber',
        minWidth: 144,
        maxWidth: 384,
        filter: 'agTextColumnFilter',
        tooltipField: 'fileNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        suppressHeaderFilterButton: true,
        cellRenderer: CellLink,
        // temporary open in new tab until we have a proper order management navigation menu
        cellRendererParams: (row: ValueFormatterParams) => ({
          linkTemplate: `/files/${row.data.fileId}/file-info`,
          openNewTab: true,
        }),
        onCellClicked: (event: CellClickedEvent) => {
          setFirmName(event.data.firmId);
        },
      },
      {
        colId: 'batchIdColumn',
        headerName: 'Order Id',
        field: 'batchId',
        floatingFilterComponentParams: { suppressFilterButton: true },
        minWidth: 144,
        maxWidth: 256,
        filter: 'agNumberColumnFilter',
        suppressHeaderFilterButton: true,
        tooltipField: 'batchId',
        cellRenderer: CellLink,
        cellRendererParams: () => ({
          linkTemplate: 'order-management',
        }),
        onCellClicked: (event: CellClickedEvent) => {
          setFirmName(event.data.firmId);
          goToOrderDetails(event.data);
        },
        sort: 'desc',
      },
      {
        colId: 'firmId',
        headerName: 'Firm Id',
        field: 'firmId',
        filter: 'agNumberColumnFilter',
        tooltipField: 'firmId',
        hide: true,
      },
      {
        colId: 'firmNameColumn',
        headerName: 'Firm',
        field: 'firmName',
        minWidth: 256,
        filter: 'agSetColumnFilter',
        tooltipField: 'firm',
      },
      {
        colId: 'lockboxTicketNumberColumn',
        headerName: 'Lockbox/Ticket Number',
        field: 'remitBatchSource',
        minWidth: 256,
        filter: 'agSetColumnFilter',
        tooltipField: 'lockboxTicketNumber',
      },
      {
        colId: 'propertyStateColumn',
        headerName: 'Property State',
        field: 'propertyState',
        minWidth: 144,
        maxWidth: 256,
        filter: 'agSetColumnFilter',
        tooltipField: 'propertyState',
      },
      {
        colId: 'assigneeColumn',
        headerName: 'Assignee',
        field: 'assignedTo',
        minWidth: 144,
        maxWidth: 256,
        filter: 'agTextColumnFilter',
        tooltipField: 'assignedTo',
      },
      {
        colId: 'assignedToGroupColumn',
        headerName: 'Team',
        field: 'assignedToGroup',
        minWidth: 144,
        maxWidth: 256,
        filter: 'agSetColumnFilter',
        tooltipField: 'assignedToGroup',
      },
      {
        colId: 'orderDateColumn',
        headerName: 'Order Date',
        field: 'orderDate',
        minWidth: 160,
        maxWidth: 256,
        filterParams: fileListFilterParams,
        cellRenderer: orderDateRenderer,
        filter: 'agDateColumnFilter',
        tooltipField: 'orderDate',
      },
      {
        colId: 'checkCountColumn',
        headerName: 'Number of Checks',
        field: 'checkCount',
        minWidth: 160,
        maxWidth: 256,
        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: true },
        suppressHeaderFilterButton: true,
        tooltipField: 'checkCount',
      },
      {
        colId: 'reportedAmountColumn',
        headerName: 'Reported Total',
        field: 'reportedAmount',
        minWidth: 144,
        maxWidth: 256,
        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: true },
        suppressHeaderFilterButton: true,
        valueFormatter: numberValueFormatter,
        tooltipValueGetter: numberValueFormatter,
        type: 'rightAligned',
        cellStyle: { 'justify-content': 'flex-end' },
      },
      {
        field: '',
        floatingFilterComponentParams: { suppressFilterButton: true },
        suppressHeaderFilterButton: true,
        floatingFilter: false,
        sortable: false,
        cellRenderer: CalculatorRenderer,
        cellRendererParams: () => ({
          tabName: 'order-management',
          isRemitValid: true,
        }),
        onCellClicked: (event: CellClickedEvent) => {
          handleDrawerOpen(event, 'order-management');
        },
        maxWidth: 40,
        cellStyle: { display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center' },
        tooltipValueGetter: () => 'Open Rates and Fees',
        pinned: 'right',
      },
    ];
  };

  const setFirmName = async (firmId: number) => {
    const token = await getAccessToken();
    const firmDetailsResults = await getFirmDetails(token, String(firmId));
    if (firmDetailsResults) {
      window.localStorage.setItem('userFirm', JSON.stringify(firmDetailsResults));
      setUserFirm && setUserFirm(firmDetailsResults);
    }
  };

  const goToOrderDetails = (row: FileInProcessType) => {
    const myOrder: OrderType = {
      firmId: row.firmId,
      lockboxId: row.remitBatchSourceId,
      orderId: row.batchId,
      orderDate: row.orderDate,
      referenceNumber: row.referenceText,
      checkCount: row.checkCount,
      reportedAmount: row.reportedAmount,
      targetTab: 'orderQueue',
      firm: {
        firmId: row.firmId,
        firmName: row.firmName,
        isActive: true,
      },
      lockboxNumber: {
        name: row.remitBatchSource,
        value: row.remitBatchSourceId,
      },
    };
    history.push('/order-management', { order: myOrder });
  };

  const getFilesInProcess = async (): Promise<Array<FileInProcessType>> => {
    const token = await getAccessToken();
    const response = await getFilesInProcessApi(token);
    return response.result;
  };

  const {
    execute: executeGetFilesInProcess,
    status: executeGetFilesInProcessStatus,
    value: getFilesInProcessValue,
    errors: getFilesInProcessErrors,
  } = useAsync<Array<FileInProcessType>>(getFilesInProcess, false);

  const onTeamFilterClick = useCallback(
    (team: TeamAssignment): void => {
      if (gridApiRef.current) {
        const filterModel: FilterModel = gridApiRef.current.getFilterModel();
        const isTeamFilterActive = !!filterModel['assignedToGroupColumn'];

        const applyTeamFilter = async (team: TeamAssignment | null) => {
          const filter = Object.keys(TeamAssignment).find(
            (key) => TeamAssignment[key as keyof typeof TeamAssignment] === team,
          );
          await gridApiRef.current?.setColumnFilterModel('assignedToGroupColumn', {
            filterType: 'set',
            values: [filter],
          });
          gridApiRef.current?.onFilterChanged();
          setCurrentTeam(team);
        };

        if (isTeamFilterActive && currentTeam === team) {
          applyTeamFilter(null).catch(console.error);
          return;
        }

        applyTeamFilter(team).catch(console.error);
      }
    },
    [currentTeam],
  );

  let ifscCount = 0;
  let pfscCount = 0;
  let crsCount = 0;

  getFilesInProcessValue?.forEach((fileInProcess) => {
    switch (fileInProcess.assignedToGroup) {
      case 'IFSC':
        ifscCount++;
        break;
      case 'PFSC':
        pfscCount++;
        break;
      case 'CRS':
        crsCount++;
        break;
    }
  });

  useEffect(() => {
    executeGetFilesInProcess();
  }, []);

  useEffect(() => {
    if (executeGetFilesInProcessStatus === 'success') {
      getFilesInProcessValue?.forEach((file) => {
        if ((file.orderDate?.length ?? 0) >= 10) {
          file.orderDate = file.orderDate?.substring(0, 10);
          file.orderId = file.batchId;
        }
      });
      setDisplayedRowCount(getFilesInProcessValue?.length ?? 0);
      setFilesInProcess(getFilesInProcessValue ?? []);
    }
    if (executeGetFilesInProcessStatus === 'error') {
      getFilesInProcessErrors?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [executeGetFilesInProcessStatus]);

  if (!getFilesInProcessValue) {
    return (
      <ContentContainer fullWidth>
        <Content>
          <div className={classes.noRows}>
            <LoadingSpinner status="pending" className="files-container--spinner-root" />
          </div>
        </Content>
      </ContentContainer>
    );
  }

  return (
    <ContentContainer fullWidth>
      <Content>
        <div>
          <PageHeader className={classes.heading} title="Files In Process" />
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4}>
              <DashboardMetricCard
                active={currentTeam === TeamAssignment.IFSC}
                dataTestId="ans-service-order-searching"
                heading="IFSC"
                status="Searching"
                value={ifscCount.toString()}
                onClick={() => onTeamFilterClick(TeamAssignment.IFSC)}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <DashboardMetricCard
                active={currentTeam === TeamAssignment.PFSC}
                dataTestId="ans-service-order-examining"
                heading="PFSC"
                status="Quality Review"
                value={pfscCount.toString()}
                onClick={() => onTeamFilterClick(TeamAssignment.PFSC)}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <DashboardMetricCard
                active={currentTeam === TeamAssignment.CRS}
                dataTestId="ans-service-order-quality-review"
                heading="CRS"
                status="Examining"
                value={crsCount.toString()}
                onClick={() => onTeamFilterClick(TeamAssignment.CRS)}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={clsx('ag-theme-alpine', classes.tableDefault, classes.paddingX)}>
                <DataTable
                  rowData={filesInProcess}
                  columnDefs={getColumnConfig()}
                  defaultColDef={defaultColDef}
                  onGridReady={onGridReady}
                  onFilterChanged={onFilterChanged}
                  onSelectionChanged={onSelectionChanged}
                  gridOptions={{ suppressRowClickSelection: true, rowSelection: 'multiple' }}
                  animateRows={true}
                  noRowsOverlayComponent={'customOverlayNoRows'}
                />
              </div>
            </Grid>
          </Grid>
          <div className={clsx(classes.paddingX, classes.selectedRows)}>
            <span>
              Showing {displayedRowCount >= 0 ? displayedRowCount : getFilesInProcessValue.length} of{' '}
              {getFilesInProcessValue.length} {'files.'}
            </span>
            {selectedFiles?.length ? selectedFilesText : null}
          </div>
          {selectedFiles.length > 0 && (
            <BottomAppBar
              primaryActionLabel="Modify Assignment"
              cancelLabel="Clear All"
              onPrimaryClick={() => {
                setEditAssignmentDrawerOpen(true);
                setEditAssignmentData([...selectedFiles]);
              }}
              onCancel={() => gridApiRef?.current?.deselectAll()}
            />
          )}
          {openDrawer && (
            <ReportsAndPayDrawer
              openDrawer={openDrawer}
              setOpenDrawer={setOpenDrawer}
              drawerData={drawerData}
              setDrawerData={setDrawerData}
              rateAndFeeExecute={rateAndFeeExecute}
            />
          )}
          {editAssignmentDrawerOpen && (
            <FileEditAssignmentContainer
              open={editAssignmentDrawerOpen}
              setOpen={setEditAssignmentDrawerOpen}
              data={editAssignmentData}
              showTeams={true}
            ></FileEditAssignmentContainer>
          )}
        </div>
      </Content>
    </ContentContainer>
  );
}
