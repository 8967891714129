import moment from 'moment';
import { handleError } from 'utilities/utilities';
import {
  AccountInfoRequestType,
  AccountInfoType,
  AllOrdersItemType,
  AllOrdersRequestType,
  BookedOrderType,
  BookOrderRequestType,
  FileInProcessType,
  FileType,
  FirmSearchRequestType,
  FirmSearchResponseType,
  LockboxNumberType,
  NotesType,
  OrderFileDetailsType,
  OrderFileQueueResponseType,
  OrderFilesRequestType,
  OrderRequestType,
  OrderType,
  UploadNotesAndDocumentsRequestType,
  UploadNotesAndDocumentsResponseType,
  UserDetailType,
  UserType,
} from '../../features/orderManagement/types';
import { getApiHost } from '../host-by-env';

export async function firmSearchApi(
  payload: FirmSearchRequestType,
  token: string,
): Promise<{ result: FirmSearchResponseType; errorCode?: string | number }> {
  const url = ` ${getApiHost()}order-management/firm-search`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  return await response.json();
}

export async function getLockboxNumberApi(
  token: string,
): Promise<{ result: Array<LockboxNumberType>; errorCode?: string | number }> {
  const url = `${getApiHost()}order-management/lockbox-items`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const result = await response.json();
  for (const item of result.result) {
    item.name = item.display;
    item.value = item.lockboxId;
  }
  return result;
}

export async function getFilesApi(
  firmId: number,
  token: string,
): Promise<{ result: Array<FileType>; errorCode?: string | number }> {
  const url = `${getApiHost()}order-management/files/${firmId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  return await response.json();
}

export async function getAccountInfoApi(payload: AccountInfoRequestType, token: string): Promise<AccountInfoType[]> {
  const url = `${getApiHost()}order-management/account-info`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  return json.result;
}

export async function bookOrderApi(payload: BookOrderRequestType, token: string): Promise<BookedOrderType> {
  const url = `${getApiHost()}order-management/book-order`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function createOrderApi(payload: OrderRequestType, token: string): Promise<OrderType> {
  payload.orderDate = moment(payload.orderDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
  const url = `${getApiHost()}order-management/order`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function updateOrderApi(payload: OrderRequestType, token: string): Promise<OrderType> {
  payload.orderDate = moment(payload.orderDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
  const url = `${getApiHost()}order-management/order`;
  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function enqueueFilesApi(
  orderId: number,
  payload: OrderFilesRequestType,
  token: string,
): Promise<OrderFileQueueResponseType> {
  const url = `${getApiHost()}order-management/order/${orderId}/enqueue-files`;
  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  return await response.json();
}

export async function dequeueFilesApi(
  orderId: number,
  payload: OrderFilesRequestType,
  token: string,
): Promise<OrderFileQueueResponseType> {
  const url = `${getApiHost()}order-management/order/${orderId}/dequeue-files`;
  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  return await response.json();
}

export async function getFilesInProcessApi(
  token: string,
): Promise<{ result: Array<FileInProcessType>; errorCode?: string | number }> {
  const url = `${getApiHost()}order-management/files-in-process`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  return await response.json();
}

export async function getQueuedFileOrderDetailsApi(
  firmId: number,
  orderId: number,
  token: string,
): Promise<{ result: Array<FileType>; errorCode?: string | number }> {
  const url = `${getApiHost()}order-management/files/${firmId}/${orderId}/queued-files`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  return await response.json();
}

export async function getNonQueuedFileOrderDetailsApi(
  firmId: number,
  orderId: number,
  token: string,
): Promise<{ result: Array<FileType>; errorCode?: string | number }> {
  const url = `${getApiHost()}order-management/files/${firmId}/${orderId}/non-queued-files`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  return await response.json();
}

export async function getAllOrdersApi(payload: AllOrdersRequestType, token: string): Promise<Array<AllOrdersItemType>> {
  const query = payload.lockboxId ? `?lockboxId=${payload.lockboxId}` : '';
  const url = `${getApiHost()}order-management/all-orders/${payload.fromOrderDate}/${payload.toOrderDate}${query}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function getOrderFileDetailsApi(orderId: number, token: string): Promise<OrderFileDetailsType> {
  const url = `${getApiHost()}order-management/order/${orderId}/files`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function getNotesApi(orderId: number, fileId: string, token: string): Promise<Array<NotesType>> {
  const url = `${getApiHost()}order-management/notes/${orderId}/${fileId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function uploadNotesAndDocumentsApi(
  payload: UploadNotesAndDocumentsRequestType,
  token: string,
): Promise<UploadNotesAndDocumentsResponseType> {
  const url = `${getApiHost()}order-management/notes`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function getUser(userId: number, token: string): Promise<UserType> {
  const url = `${getApiHost()}order-management/user/${userId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function setUser(payload: UserType, token: string): Promise<UserType> {
  const url = `${getApiHost()}order-management/user`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function getTeamUsers(fscRoleId: number, token: string): Promise<Array<UserDetailType>> {
  const url = `${getApiHost()}order-management/users/${fscRoleId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}
