import { useAuth } from '@agentnet/auth';
import { DataTable } from '@fluentsms/agentnet-web-components';
import { Theme, Tooltip } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { ColDef, GridApi, ICellRendererParams } from 'ag-grid-enterprise';
import { getDocument } from 'api/myReports/report-api';
import clsx from 'clsx';
import { DocumentType, newWindow, WindowFeatures, xlsDocType } from 'features/constants';
import { StatusRenderer } from 'features/files/files-summary/files-list/filesListConfigs';
import useAsync from 'hooks/useAsync';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import { DocumentPDF, DocumentXLS } from 'ui-kit/icons/DocIcons';
import { openDocument } from 'utilities/utilities';
import { ReportDocumentRequest } from './types';

export interface MyReportsListInterface {
  getHistoryResults?: [];
}
const MyReportsList = ({ getHistoryResults }: MyReportsListInterface) => {
  const { getAccessToken } = useAuth();
  const [historyRowData, setHistoryRowData] = useState<any>([]);
  const [displayedRowCount, setDisplayedRowCount] = useState<number | undefined>(undefined);
  const [reportDocumentRequest, setReportDocumentRequest] = useState<ReportDocumentRequest>();
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errMsgs, setErrMsgs] = useState([]);
  const { addGlobalMsg } = useGlobalMessages();
  const [searchFlag, setSearchFlag] = useState(false);
  const gridApiRef = useRef<GridApi | null>(null);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      paddingX: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        width: 'auto',
      },
      tableDefault: {
        height: `calc(100vh - 296px) !important`,
        fontFamily: theme.typography.fontFamily,

        '& .ag-center-cols-viewport': {
          minHeight: 220,
        },
      },
      docButtonPdf: {
        width: '40px',
        height: '40px',
        display: 'flex',
        justifyContent: 'flex-end',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      docButtonXls: {
        width: '40px',
        height: '40px',
        display: 'flex',
        justifyContent: 'flex-start',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      buttonXlsOnly: {
        width: '72px',
        height: '40px',
        display: 'flex',
        justifyContent: 'flex-end',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      buttonPdfOnly: {
        width: '72px',
        height: '40px',
        display: 'flex',
        justifyContent: 'flex-start',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    }),
  );
  const classes = useStyles();

  const getDocumentData = async (): Promise<{ result: any; errorCode?: string | number }> => {
    setSearchFlag(true);
    const token = await getAccessToken();
    return await getDocument(reportDocumentRequest?.reportQueueId ?? 0, token);
  };

  const {
    execute: executeGetDocument,
    status: getDocumentStatus,
    value: getDocumentResults,
    errors: getDocuumentErrors,
  } = useAsync<any>(getDocumentData, false);

  useEffect(() => {
    setDisplayError(getDocumentStatus === 'error');
  }, [getDocumentStatus]);

  useEffect(() => {
    const errorMessages = getDocuumentErrors?.length ? getDocuumentErrors : [];
    setErrMsgs(errorMessages);
  }, [displayError]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
      setSearchFlag(false);
    }
  }, [displayError, errMsgs]);

  useEffect(() => {
    if (getHistoryResults) {
      getHistoryResults?.map((x: any) => {
        x.createdDate = moment(x.createdDate).format('MM/DD/YYYY');
      });
      setHistoryRowData(getHistoryResults);
    }
  }, [getHistoryResults]);

  useEffect(() => {
    if (getDocumentStatus === 'success') {
      if (getDocumentResults?.DocumentData && getDocumentResults?.ReportFormat === 'PDF') {
        openDocument(getDocumentResults?.DocumentData, DocumentType, newWindow, WindowFeatures);
      } else if (getDocumentResults?.DocumentData && getDocumentResults?.ReportFormat === 'XLS') {
        openDocument(getDocumentResults?.DocumentData, xlsDocType, newWindow, WindowFeatures);
      }
      setSearchFlag(false);
    }
  }, [getDocumentStatus]);

  const onViewDocument = (reportQueueId: number) => {
    setReportDocumentRequest({ reportQueueId: reportQueueId });
  };

  useEffect(() => {
    if (reportDocumentRequest) executeGetDocument().then();
  }, [reportDocumentRequest]);

  useEffect(() => {
    if (gridApiRef.current) {
      if (displayedRowCount === 0) {
        gridApiRef.current.showNoRowsOverlay();
      } else {
        gridApiRef.current.hideOverlay();
      }
    }
  }, [displayedRowCount]);

  function generateIcon(fileType?: any) {
    if (fileType === 'PDF') {
      return <DocumentPDF />;
    }
    if (fileType === 'EXCEL') {
      return <DocumentXLS />;
    }
  }

  const historyColumnsConfig: ColDef[] = [
    {
      headerName: 'Status',
      field: 'queueStatus',
      tooltipField: 'queueStatus',
      cellRenderer: StatusRenderer,
      filter: 'agSetColumnFilter',
      suppressHeaderFilterButton: true,
      minWidth: 110,
      maxWidth: 120,
    },
    {
      headerName: 'Report Name',
      field: 'reportName',
      tooltipField: 'reportName',
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      minWidth: 170,
      maxWidth: 180,
    },
    {
      headerName: 'Report Details',
      field: 'reportDescription',
      tooltipField: 'reportDescription',
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      minWidth: 256,
    },
    {
      headerName: 'Created Date',
      field: 'createdDate',
      tooltipField: 'createdDate',
      minWidth: 110,
      maxWidth: 120,
    },
    {
      field: '',
      maxWidth: 80,
      filter: false,
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      tooltipField: 'View Document',
      cellRenderer: (params: ICellRendererParams) => {
        {
          return (
            <>
              {params?.data?.queueStatus == 'Processed' && (
                <>
                  {params?.data?.pdfReportQueueId != null && (
                    <Tooltip title="PDF" arrow>
                      <div>
                        <AgentNetButton
                          variant="text"
                          color="primary"
                          onClick={() => onViewDocument(params?.data?.pdfReportQueueId)}
                          data-testid="MyReportsPdfDocumentView"
                          className={
                            params?.data?.excelReportQueueId != null ? classes.docButtonPdf : classes.buttonPdfOnly
                          }
                        >
                          {generateIcon('PDF')}
                        </AgentNetButton>
                      </div>
                    </Tooltip>
                  )}
                  {params?.data?.excelReportQueueId != null && (
                    <Tooltip title="Excel" arrow>
                      <div>
                        <AgentNetButton
                          variant="text"
                          color="primary"
                          onClick={() => onViewDocument(params?.data?.excelReportQueueId)}
                          data-qa="MyReportsXlsDocumentView"
                          className={
                            params?.data?.pdfReportQueueId != null ? classes.docButtonXls : classes.buttonXlsOnly
                          }
                        >
                          {generateIcon('EXCEL')}
                        </AgentNetButton>
                      </div>
                    </Tooltip>
                  )}
                </>
              )}
            </>
          );
        }
      },
      cellStyle: { display: 'flex', textAlign: 'right', justifyContent: 'center', alignItems: 'center' },
      pinned: 'right',
    },
  ];

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 2,
      floatingFilter: false,
      filter: false,
      sortable: false,
      resizable: true,
      editable: false,
      suppressMenu: true,
      filterParams: { closeOnApply: true, suppressAndOrCondition: true },
    };
  }, []);

  const onFilterChanged = (): void => {
    setDisplayedRowCount(gridApiRef.current?.getDisplayedRowCount());
  };

  const onGridReady = (params: any) => {
    gridApiRef.current = params.api;
  };

  return (
    <>
      <div className="paper-layout contained">
        <LoadingSpinner status={searchFlag ? 'pending' : 'success'} variant="linear" />
      </div>
      <div className={clsx('ag-theme-alpine', classes.tableDefault, classes.paddingX)}>
        <DataTable
          rowData={historyRowData}
          columnDefs={historyColumnsConfig}
          defaultColDef={defaultColDef}
          gridOptions={{ suppressRowClickSelection: true, domLayout: 'autoHeight' }}
          onGridReady={onGridReady}
          onFilterChanged={onFilterChanged}
        />
      </div>
    </>
  );
};
export default MyReportsList;
