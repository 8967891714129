import { ServiceOrderMapper } from 'features/serviceorder/serviceorder.mapper';
import {
  DownloadDocumentRequest,
  DownloadDocumentResponse,
  Order,
  OrderDocument,
  OrderPageData,
  ProductRequest,
  ServiceOrderRequest,
  UpdateOrderData,
} from 'features/serviceorder/type';
import { handleBusinessError, handleError } from 'utilities/utilities';
import { getAgencyApiHost, getApiHost } from './host-by-env';

export async function getProducts(payload: ProductRequest, token: string) {
  const url = `${getApiHost()}serviceOrder/productList/${payload.fileId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  handleBusinessError(json);
  return json.result;
  ``;
}

export async function postServiceOrder(payload: OrderPageData, token: string): Promise<Order[]> {
  const url = `${getAgencyApiHost()}service-order/v2/orders`;
  const orderPageData = ServiceOrderMapper.postServiceOrder(payload);
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(orderPageData),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  handleBusinessError(json);
  return json.Result;
}

export async function putServiceOrder(payload: UpdateOrderData, token: string): Promise<boolean> {
  const url = `${getAgencyApiHost()}service-order/v2/orders`;
  const updateOrderData = ServiceOrderMapper.putServiceOrder(payload);
  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(updateOrderData),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  handleBusinessError(json);
  return json.Result;
}

export async function getOrderDocument(
  orderRequest: ServiceOrderRequest,
  URI: string,
  token: string,
): Promise<OrderDocument> {
  const url = `${getAgencyApiHost()}service-order/v1/orders/document?fileId=${
    orderRequest.fileId
  }&serviceOrderReferenceId=${orderRequest.serviceOrderReferenceId}&DocumentURI=${URI}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  }).catch((error) => {
    console.log(error);
    throw Error(
      `Error occurred while getting Order Document. payload - ${JSON.stringify(
        orderRequest,
      )} , error - : ${JSON.stringify(error)}`,
    );
  });
  const json = await response.json();
  handleError(json);
  handleBusinessError(json);
  return json.Result;
}

export async function postServiceOrderDocumentDownload(
  payload: DownloadDocumentRequest,
  token: string,
): Promise<DownloadDocumentResponse> {
  const url = `${getAgencyApiHost()}service-order/v2/orders/document-download`;
  const downloadDocumentData = ServiceOrderMapper.postServiceOrderDocumentDownload(payload);
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(downloadDocumentData),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  }).catch((error) => {
    console.log(error);
    throw Error(
      `Error occurred while Service Order Document Download. payload - ${JSON.stringify(
        downloadDocumentData,
      )} , error - : ${JSON.stringify(error)}`,
    );
  });
  const json = await response.json();
  handleError(json);
  handleBusinessError(json);
  return json.Result;
}

export async function getServiceOrder(serviceOrderRequest: ServiceOrderRequest, token: string): Promise<Order[]> {
  const url = `${getApiHost()}serviceOrder/getOrders`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(serviceOrderRequest),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  handleBusinessError(json);
  return json.result;
}

export async function getDecryptedFileId(fileId: string, token: string) {
  const url = `${getApiHost()}file/${fileId}/decrypt`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}
