import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { PartyOrderInsightsProps } from 'api/file/interfaces/get-file-order-insights';
import React from 'react';
import ClaimAlert from './components/ClaimAlert';
import Sdn from './components/Sdn';
import Watchlist from './components/Watchlist';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    w100: {
      width: '100%',
    },
  }),
);

const PartyOrderInsights: React.FC<PartyOrderInsightsProps> = ({ party, isPdf }) => {
  const classes = useStyles();

  return (
    <div className={classes.w100}>
      <Sdn party={party} isPdf={isPdf} />
      {party && party.filePartyType !== 'Lender' && (
        <>
          <Watchlist party={party} isPdf={isPdf} />
          <ClaimAlert party={party} isPdf={isPdf} />
        </>
      )}
    </div>
  );
};

export default PartyOrderInsights;
