import { useAuth } from '@agentnet/auth';
import { AgentNetButton, pxToRem } from '@fluentsms/agentnet-web-components';
import { FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  DocumentRequest,
  DocumentResponseItem,
  EventLogRequest,
  ParseAddressRequest,
  SearchRequest,
} from 'api/backtitle/interfaces';
import { CountiesType, StatesCountiesType } from 'api/backtitle/types';
import { getFileInfoProps } from 'api/file/file-api';
import clsx from 'clsx';
import { KBLinks, RegexNumeric } from 'features/constants';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import PageHeader from 'ui-kit/components/headers/PageHeader';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import DocumentPreviewModal, { DocumentPreviewData } from 'ui-kit/components/modal/DocumentPreviewModal';
import NoResults from 'ui-kit/components/noResults/NoResults';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';
import NoBacktitle from 'ui-kit/icons/NoBacktitle';
import { AgentNetTextInput } from 'ui-kit/inputs';
import AutocompleteCountySearch from 'ui-kit/inputs/AutocompleteCountySearch/AutocompleteCountySearch';
import DateField from 'ui-kit/inputs/DateField/DateFieldString';
import { PlaceAddress } from 'ui-kit/inputs/GoogleAutocompleteSearch/Models';
import StateSelectField, { stateType } from 'ui-kit/inputs/StateSelectField';
import USPostalCodeField from 'ui-kit/inputs/USPostalCodeField/USPostalCodeField';
import {
  getDocument,
  getStatesCountiesData,
  logEvent,
  parseAddress,
  performSearch,
} from '../../../api/backtitle/backtitle-api';
import { useViewState } from '../../../hooks/ViewStateContext';
import './BackTitleContent.scss';
import BackTitleList from './BackTitleList';

export type SearchFieldsType = {
  apn: string;
  city: string;
  state: string;
  zip: string;
  county: string | null;
  streetNumber: string;
  streetName: string;
  block: string;
  platBook: string;
  platPage: string;
  fileNumber: string;
  acreage: string;
  lastNameEntity: string;
  district: string;
  policyNumber: string;
  subdivision: string;
  policyDateFrom: string;
  policyDateTo: string;
  briefLegal: string;
  unitNumber: string;
};

const BackTitleContent = (): JSX.Element => {
  const [isFileView, setFileView] = useState(false);
  const [open, setOpen] = useState(false);
  const [documentPreviewData, setDocumentPreviewData] = useState<DocumentPreviewData>();
  const [eventLogRequest, setEventLogRequest] = useState<EventLogRequest>();

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      flexDirection: 'column',
      display: 'flex',
      marginTop: pxToRem(114),
      width: '100%',
    },
    height: {
      height: pxToRem(400),
    },
    pt2: {
      paddingTop: theme.spacing(2),
      marginTop: 0,
    },
    marginFileView: {
      marginTop: 0,
    },
    mb1: {
      marginBottom: theme.spacing(1),
    },
    mb3: {
      marginBottom: theme.spacing(3),
    },
    noResults: {
      textAlign: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingTop: theme.spacing(4),
    },
    backTitleGridContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
  }));

  const defaultFields: SearchFieldsType = {
    apn: '',
    city: '',
    state: '',
    zip: '',
    county: null,
    streetNumber: '',
    streetName: '',
    block: '',
    platBook: '',
    platPage: '',
    fileNumber: '',
    acreage: '',
    lastNameEntity: '',
    district: '',
    policyNumber: '',
    subdivision: '',
    policyDateFrom: '',
    policyDateTo: '',
    briefLegal: '',
    unitNumber: '',
  };

  const { fileId } = useParams<getFileInfoProps>();
  const [backTitleSearchId, setBackTitleSearchId] = useState(0);
  const [isSearching, setSearchFlag] = useState(false);
  const [isFetchingDocument, setFetchingDocumentFlag] = useState(false);
  const classes = useStyles();
  const { getAccessToken } = useAuth();
  const [rowData, setRowData] = useState<DocumentResponseItem[]>([]);
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm } = profileCtx;
  const [backTitleSearch, setBackTitleSearch] = useState(false);
  const [advancedSearch, setAdvancedSearch] = useState(true);
  const [statesCountiesList, setStatesCountiesList] = useState<StatesCountiesType[]>([]);
  const [statesList, setStatesList] = useState<stateType[]>([]);
  const [countiesList, setCountiesList] = useState<CountiesType[]>([]);
  const [showContent, setShowContent] = useState(true);
  const [dialog, setDialog] = useState('');
  const [showError, setShowError] = useState(false);
  const { addSnackbarMessage } = useSnackBars();
  const { addGlobalMsg } = useGlobalMessages();
  const qaAttributePrefix = 'BackTitle';
  const properties = window.sessionStorage.getItem('properties');
  const parsedProperty = properties ? JSON.parse(properties) : null;
  const getPropertyFromSession = parsedProperty ? parsedProperty[0] : null;
  const isFileIdExist = fileId !== null && fileId !== undefined && fileId !== '';
  const propertyData = isFileIdExist ? getPropertyFromSession : null;
  const [fileRestricted, setFileRestricted] = useState(false);
  const { routerParamValue, setRouterParamValue } = useViewState();
  const [searchFields, setSearchFields] = useState(defaultFields);
  const previousSearchFields = React.useRef(searchFields).current;

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setRouterParamValue(fileId || '');
  }, [routerParamValue, setRouterParamValue]);

  const loadData = async () => {
    setSearchFlag(true);
    resetForm();
    if (isFileIdExist) {
      setShowContent(false);
      setBackTitleSearch(true);
      setFileView(true);
      if (propertyData !== null) {
        await streetDetails(
          {
            address1: propertyData?.address1 ?? '',
            zipcode: propertyData?.postalCode ?? '',
            state: propertyData?.state ?? '',
            city: propertyData?.city ?? '',
            county: propertyData?.county ?? '',
          },
          true,
        );
        await search(true);
        await fetchStatesCountiesList();
        setShowContent(true);
        setSearchFlag(false);
      }
    } else {
      await fetchStatesCountiesList();
    }
  };

  const isEqual = (obj1: any, obj2: any): boolean => {
    const obj1Keys = Object.keys(obj1);
    const obj2Keys = Object.keys(obj2);

    if (obj1Keys.length !== obj2Keys.length) {
      return false;
    }

    for (const objKey of obj1Keys) {
      if (obj1[objKey] !== obj2[objKey]) {
        return false;
      }
    }
    return true;
  };

  const handleDownload = async () => {
    if (eventLogRequest) {
      const token = await getAccessToken();
      await logEvent(token, { ...eventLogRequest, eventType: 'Download' });
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (propertyData !== null) {
      setCounties(propertyData?.state ?? '');
      setSearchFields({
        ...searchFields,
        county: propertyData?.county ?? '',
      });
    }
  }, [statesCountiesList]);

  const isStateExist = isFileView && !!searchFields.state;
  const resetForm = () => {
    setSearchFields((prevFields) => ({
      ...defaultFields,
      state: isStateExist ? prevFields.state : '',
      county: isStateExist ? prevFields.county : null,
    }));
    setSearchFlag(false);
    setBackTitleSearch(false);
    if (!isStateExist) setCountiesList([]);
  };

  const enableSearchButton = (): boolean => {
    return (!isFileView && !!searchFields.state) || (isFileView && !!searchFields.state && !!searchFields.county);
  };

  const validateSearchCriteria = (): boolean => {
    if (enableSearchButton()) {
      const backTitleSearchFields = Object.values(searchFields).filter((e) => e && e.trim() !== '');
      const fieldLength = backTitleSearchFields.length;

      if (!isFileView && searchFields.state && fieldLength < 2) {
        addSnackbarMessage({
          message: 'Please enter at least 1 Search criteria value in addition to State.',
          type: 'warning',
        });
        setBackTitleSearch(false);
        return false;
      }

      if (
        searchFields.policyDateTo != null &&
        searchFields.policyDateFrom != null &&
        searchFields.policyDateFrom != '' &&
        searchFields.policyDateTo != ''
      ) {
        if (searchFields.policyDateTo < searchFields.policyDateFrom) {
          addSnackbarMessage({
            message: "The 'To' date must be greater than or equal to the 'From' date ",
            type: 'warning',
          });
          setBackTitleSearch(false);
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  };

  const SearchBackTitle = async () => {
    setSearchFlag(true);
    setShowError(true);
    if (validateSearchCriteria()) {
      await search(false);
      setShowError(false);
    } else {
      setSearchFlag(false);
    }
  };

  const setField = (field: string, value: any) => {
    setSearchFields({
      ...searchFields,
      [field]: value,
    });
  };

  const onKeyDown = async (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !isEqual(previousSearchFields, searchFields)) {
      await SearchBackTitle();
    }
  };

  const keyPressnumber = (e: React.KeyboardEvent) => {
    if (RegexNumeric.test(e.key)) return true;
    e.preventDefault();
    return false;
  };

  const fetchStatesCountiesList = async () => {
    const token = await getAccessToken();
    let firmId = '';
    if (userFirm !== null && userFirm?.firmId !== undefined) {
      firmId = userFirm.firmId;
    }
    const result = await getStatesCountiesData(token, firmId).catch((error) => {
      console.error('Error in fetching states & counties:', error);
      setSearchFlag(false);
    });
    if (result != null) {
      setStatesList([]);
      setStatesCountiesList(result);
      const statesList: stateType[] = [];
      result.forEach((row) => {
        if (statesList.filter((e) => e.name === row.StateCode).length == 0) {
          statesList.push({ abbreviation: row.StateCode, name: row.StateCode });
        }
      });
      setStatesList(statesList);
      setSearchFlag(false);
    }
    setSearchFlag(false);
  };
  const streetDetails = async (place: PlaceAddress, isFileLoad: boolean) => {
    setSearchFlag(true);
    const token = await getAccessToken();
    const payload: ParseAddressRequest = {
      address1: place.address1,
      streetNumber: '',
      streetName: '',
    };
    const result = await parseAddress(token, payload).catch((error) => {
      console.error('Error in parsing Address1:', error);
    });
    if (result != null) {
      if (isFileLoad) {
        setSearchFields({
          ...searchFields,
          zip: '',
          state: place?.state ?? '',
          city: place?.city ?? '',
          county: place?.county ?? '',
          streetName: result.StreetName,
          streetNumber: '',
        });
        if (propertyData) {
          propertyData.address1 = result.StreetName;
          propertyData.postalCode = '';
        }
      } else {
        setSearchFields({
          ...searchFields,
          zip: place?.zipcode ?? '',
          state: place?.state ?? '',
          city: place?.city ?? '',
          county: place?.county ?? '',
          streetName: result.StreetName,
          streetNumber: result.StreetNumber,
        });
      }
    }
    setSearchFlag(false);
  };

  const search = async (isFirstLoad: boolean) => {
    const token = await getAccessToken();
    let firmId = null;
    if (userFirm !== null && userFirm?.firmId !== undefined) {
      firmId = parseInt(userFirm.firmId);
    }

    let streetName = '';
    let streetNumber = '';

    if (advancedSearch) {
      streetName = searchFields.streetName;
      streetNumber = searchFields.streetNumber;
    }

    const payload: SearchRequest = isFirstLoad
      ? {
          ...searchFields,
          firmId: firmId,
          fileId: fileId,
          state: propertyData?.state ?? '',
          city: propertyData?.city ?? '',
          county: propertyData?.county ?? '',
          streetNumber: '',
          streetName: propertyData?.address1 ?? '',
          fileCounty: propertyData?.county ?? '',
        }
      : {
          ...searchFields,
          firmId: firmId,
          fileId: fileId,
          streetName: streetName,
          streetNumber: streetNumber,
          fileCounty: propertyData?.county ?? '',
          zip: searchFields.zip,
        };
    const result = await performSearch(token, payload).catch((error) => {
      console.error('Error performing backtitle search: ', error);
      addGlobalMsg({
        message: 'System Error. Please try again later',
        type: 'error',
      });
    });
    if (result != null) {
      if (result.IsFileRestricted) {
        setFileRestricted(true);
        setToggleSearch(false);
        setRowData([]);
        setSearchFlag(false);
      } else {
        // Sort policy at top and then effective date descending
        result.BackTitleDocuments?.sort((a, b) => {
          const dateA = a.PolicyEffectiveDate ? new Date(a.PolicyEffectiveDate).getTime() : 0;
          const dateB = b.PolicyEffectiveDate ? new Date(b.PolicyEffectiveDate).getTime() : 0;

          if (a.PolicyType === 'PriorPolicy' && b.PolicyType !== 'PriorPolicy') {
            return -1;
          }
          if (a.PolicyType !== 'PriorPolicy' && b.PolicyType === 'PriorPolicy') {
            return 1;
          }

          return dateB - dateA;
        });

        setBackTitleSearchId(result.BackTitleSearchId ?? 0);
        setRowData(result.BackTitleDocuments);
        if (result.BackTitleDocuments.length > 200) {
          addSnackbarMessage({
            message:
              'Your search returned more records than displayed. Please narrow your search criteria and try again if you do not find what you are searching for.',
            type: 'warning',
          });
        }
      }
    } else {
      setRowData([]);
    }
    setBackTitleSearch(true);
    setSearchFlag(false);
  };

  const fetchDocument = async (docType: string, documentDescription: string, docGuid: string) => {
    setFetchingDocumentFlag(true);
    const token = await getAccessToken();
    let firmId = null;
    if (userFirm !== null && userFirm?.firmId !== undefined) {
      firmId = parseInt(userFirm.firmId);
    }
    const payload: DocumentRequest = {
      DocumentRequest: {
        BackTitleSearchId: backTitleSearchId,
        FirmId: firmId,
        DocGuid: docGuid,
        FileId: fileId,
      },
      DocumentType: docType,
    };
    const result = await getDocument(token, payload).catch((error) => {
      console.error('Error getting backtitle document: ', error);
      addGlobalMsg({
        message: 'System Error. Please try again later',
        type: 'error',
      });
    });
    if (result) {
      if (result.Message) {
        addSnackbarMessage({
          message: result.Message,
          type: 'warning',
        });
      } else {
        if (docType == 'Legal Desc') {
          setDialog(result.Content);
        } else {
          setDocumentPreviewData({
            content: result.Content,
            fileName: payload.DocumentRequest.DocGuid,
            documentType: 'Back Title',
            dataTestId: 'back-title-starter',
          });
          setOpen(true);
          const eventLogRequest: EventLogRequest = {
            firmId: firmId ?? 0,
            searchId: backTitleSearchId,
            documentIdentifier: docGuid,
            policyId: documentDescription,
            eventType: 'Preview',
          };
          setEventLogRequest(eventLogRequest);
          await logEvent(token, eventLogRequest);
        }
      }
    }
    setFetchingDocumentFlag(false);
  };

  const setCounties = (value: any) => {
    setCountiesList([]);
    if (value === null || value === undefined || value === '') {
      setSearchFields({
        ...searchFields,
        state: '',
        county: null,
      });
    } else {
      setSearchFields({
        ...searchFields,
        state: value,
        county: null,
      });
      const countiesList: CountiesType[] = [];
      statesCountiesList?.map((row) => {
        if (row.StateCode === value) {
          countiesList.push({ name: row.CountyName, value: row.CountyName });
        }
      });
      setCountiesList(countiesList);
    }
    return;
  };

  const [toggleSearch, setToggleSearch] = useState(true);

  return (
    <ContentContainer mTop={isFileView ? '0' : undefined} fullWidth>
      <div className={showContent ? '' : 'loading-container'}>
        <LoadingSpinner status={showContent ? '' : 'pending'} size={30} />
      </div>
      {showContent && (
        <>
          <div className="backtitle-header">
            <PageHeader
              title="Back Title"
              subtitle="Disclaimer: The starter image in the Back Title Database is provided to you solely in your capacity as a policy issuing agent of First American and is subject to the terms and conditions of the AgentNet End User License Agreement, the terms and conditions of your current First American policy-issuing agency agreement, as well as all relevant First American National and local underwriting communications and guidance."
              menuItems={[
                {
                  label: 'Knowledge Base',
                  link: isFileView ? KBLinks.backtitleFiles : KBLinks.backtitleAdHoc,
                },
              ]}
              contentRight={
                <FormControlLabel
                  control={
                    <Switch
                      checked={toggleSearch}
                      onChange={() => {
                        setToggleSearch(!toggleSearch);
                      }}
                      size="small"
                    />
                  }
                  label="Show Search Fields"
                  labelPlacement="start"
                />
              }
            />
            {toggleSearch && (
              <div className="backtitle-search-container">
                <div className="backtitle-search-content">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                      <AgentNetTextInput
                        variant="outlined"
                        label="Street #"
                        name="streetNumber"
                        data-testid={qaAttributePrefix + 'StreetNumber'}
                        autoFocus={true}
                        onKeyDown={onKeyDown}
                        inputProps={{ maxLength: 10 }}
                        value={searchFields.streetNumber}
                        onChange={(e) => {
                          setField('streetNumber', e.target.value);
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <AgentNetTextInput
                        variant="outlined"
                        label="Street Name"
                        name="streetName"
                        data-testid={qaAttributePrefix + 'StreetName'}
                        onKeyDown={onKeyDown}
                        inputProps={{ maxLength: 45 }}
                        value={searchFields.streetName}
                        onChange={(e) => {
                          setField('streetName', e.target.value);
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <AgentNetTextInput
                        variant="outlined"
                        label="APN"
                        name="apn"
                        data-testid={qaAttributePrefix + 'APN'}
                        onKeyDown={onKeyDown}
                        inputProps={{ maxLength: 25 }}
                        value={searchFields.apn}
                        onChange={(e) => {
                          setField('apn', e.target.value);
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <AgentNetTextInput
                        variant="outlined"
                        label="Last Name/Entity"
                        name="lastNameEntity"
                        data-testid={qaAttributePrefix + 'LastNameEntity'}
                        onKeyDown={onKeyDown}
                        inputProps={{ maxLength: 25 }}
                        value={searchFields.lastNameEntity}
                        onChange={(e) => {
                          setField('lastNameEntity', e.target.value);
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <AgentNetTextInput
                        variant="outlined"
                        label="City"
                        name="city"
                        data-testid={qaAttributePrefix + 'City'}
                        onKeyDown={onKeyDown}
                        inputProps={{ maxLength: 30 }}
                        value={searchFields.city}
                        onChange={(e) => {
                          setField('city', e.target.value);
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <StateSelectField
                        name="state"
                        label="State"
                        data-testid={qaAttributePrefix + 'State'}
                        stateOptions={statesList}
                        value={searchFields.state}
                        onChange={(e: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                          const newValue = typeof e === 'string' ? e : e.target.value;
                          setCounties(newValue);
                        }}
                        required
                        error={
                          (searchFields.state === undefined ||
                            searchFields.state === '' ||
                            searchFields.state === null) &&
                          showError
                        }
                        disabled={isFileView}
                        helperText={
                          (searchFields.state === undefined ||
                            searchFields.state === '' ||
                            searchFields.state === null) &&
                          showError &&
                          'State is a required field'
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <USPostalCodeField
                        variant="outlined"
                        label="Zip Code"
                        name="zip"
                        data-testid={qaAttributePrefix + 'Zip'}
                        onKeyDown={onKeyDown}
                        onKeyPress={keyPressnumber}
                        inputProps={{ maxLength: 10 }}
                        value={searchFields.zip}
                        onChange={(e) => {
                          setField('zip', e.target.value);
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      {isFileView && fileRestricted ? (
                        <AgentNetTextInput
                          variant="outlined"
                          label="County"
                          name="county"
                          data-testid={qaAttributePrefix + 'County'}
                          inputProps={{ maxLength: 50 }}
                          value={searchFields.county}
                          fullWidth
                          disabled={true}
                          required
                        />
                      ) : (
                        <AutocompleteCountySearch
                          label="County"
                          name="county"
                          data-qa={qaAttributePrefix + 'County'}
                          options={countiesList}
                          value={searchFields.county?.toUpperCase()}
                          onChange={(e: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            setField('county', e ? (typeof e === 'string' ? e : e.target.value) : '');
                          }}
                          disabled={countiesList.length === 0 || isFileView}
                          required={isFileView}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <AgentNetTextInput
                        variant="outlined"
                        label="Condo/Subdivision Name"
                        name="subdivision"
                        data-testid={qaAttributePrefix + 'CondoSubDivisionName'}
                        onKeyDown={onKeyDown}
                        inputProps={{ maxLength: 50 }}
                        value={searchFields.subdivision}
                        onChange={(e) => {
                          setField('subdivision', e.target.value);
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <AgentNetTextInput
                        variant="outlined"
                        label="Unit/Lot #"
                        name="unitNumber"
                        data-testid={qaAttributePrefix + 'UnitLotNumber'}
                        onKeyDown={onKeyDown}
                        inputProps={{ maxLength: 50 }}
                        value={searchFields.unitNumber}
                        onChange={(e) => {
                          setField('unitNumber', e.target.value);
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <AgentNetTextInput
                        variant="outlined"
                        label="Block/Square"
                        name="block"
                        data-testid={qaAttributePrefix + 'BlockSquare'}
                        onKeyDown={onKeyDown}
                        inputProps={{ maxLength: 30 }}
                        value={searchFields.block}
                        onChange={(e) => {
                          setField('block', e.target.value);
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <AgentNetTextInput
                        variant="outlined"
                        label="Section/Acreage"
                        name="acreage"
                        data-testid={qaAttributePrefix + 'SectionAcreage'}
                        onKeyDown={onKeyDown}
                        inputProps={{ maxLength: 20 }}
                        value={searchFields.acreage}
                        onChange={(e) => {
                          setField('acreage', e.target.value);
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <AgentNetTextInput
                        variant="outlined"
                        label="District"
                        name="district"
                        data-testid={qaAttributePrefix + 'District'}
                        onKeyDown={onKeyDown}
                        inputProps={{ maxLength: 30 }}
                        value={searchFields.district}
                        onChange={(e) => {
                          setField('district', e.target.value);
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <AgentNetTextInput
                        variant="outlined"
                        label="Plat Book"
                        name="platBook"
                        data-testid={qaAttributePrefix + 'PlatBook'}
                        onKeyDown={onKeyDown}
                        inputProps={{ maxLength: 20 }}
                        value={searchFields.platBook}
                        onChange={(e) => {
                          setField('platBook', e.target.value);
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <AgentNetTextInput
                        variant="outlined"
                        label="Plat Page"
                        name="platPage"
                        data-testid={qaAttributePrefix + 'PlatPage'}
                        onKeyDown={onKeyDown}
                        inputProps={{ maxLength: 10 }}
                        value={searchFields.platPage}
                        onChange={(e) => {
                          setField('platPage', e.target.value);
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <AgentNetTextInput
                        variant="outlined"
                        label="Brief Legal"
                        name="briefLegal"
                        data-testid={qaAttributePrefix + 'BriefLegal'}
                        onKeyDown={onKeyDown}
                        inputProps={{ maxLength: 75 }}
                        value={searchFields.briefLegal}
                        onChange={(e) => {
                          setField('briefLegal', e.target.value);
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <AgentNetTextInput
                        variant="outlined"
                        label="File #"
                        name="fileNumber"
                        data-testid={qaAttributePrefix + 'FileNumber'}
                        onKeyDown={onKeyDown}
                        inputProps={{ maxLength: 50 }}
                        value={searchFields.fileNumber}
                        onChange={(e) => {
                          setField('fileNumber', e.target.value);
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <AgentNetTextInput
                        variant="outlined"
                        label="Policy #"
                        name="policyNumber"
                        data-testid={qaAttributePrefix + 'PolicyNumber'}
                        onKeyDown={onKeyDown}
                        inputProps={{ maxLength: 50 }}
                        value={searchFields.policyNumber}
                        onChange={(e) => {
                          setField('policyNumber', e.target.value);
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <DateField
                        name="policyDateFrom"
                        label="Policy Date - From"
                        qaAttribute={qaAttributePrefix + 'PolicyDateFrom'}
                        disableFuture
                        value={searchFields.policyDateFrom}
                        onChange={(e: any) => {
                          setField('policyDateFrom', e);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <DateField
                        name="policyDateTo"
                        label="Policy Date - To"
                        qaAttribute={qaAttributePrefix + 'PolicyDateTo'}
                        minDate={searchFields.policyDateFrom}
                        disableFuture
                        value={searchFields.policyDateTo}
                        onChange={(e: any) => {
                          setField('policyDateTo', e);
                        }}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <AgentNetButton
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={resetForm}
                        style={{ marginRight: pxToRem(16) }}
                      >
                        Clear All
                      </AgentNetButton>
                      <AgentNetButton
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={SearchBackTitle}
                        disableElevation
                      >
                        Search
                      </AgentNetButton>
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
          </div>

          <div className="backtitle-search-content">
            <LoadingSpinner status={isSearching || isFetchingDocument ? 'pending' : 'success'} variant="linear" />
            <AgentNetConfirmationDialog
              onConfirm={() => {
                navigator.clipboard.writeText(dialog);
                addSnackbarMessage({
                  message: 'Copied to clipboard',
                  type: 'success',
                  duration: 3000,
                });
              }}
              open={dialog !== ''}
              onDismissAction={() => setDialog('')}
              dialogTitle="Legal Description"
              dialogBtnContent="Copy Text"
              dialogText={dialog}
              size="md"
              textArea
            />

            {backTitleSearch && (
              <div className="full-width">
                <div>
                  <div className="backtitle-search-result">
                    {rowData?.length > 0 ? (
                      <BackTitleList rowData={rowData} fetchDocument={fetchDocument} />
                    ) : (
                      <>
                        {isFileView && fileRestricted ? (
                          <>
                            <div className={clsx(classes.noResults)}>
                              <div className={classes.mb3}>{<NoBacktitle />}</div>
                              <Typography variant="h3" className={classes.mb1}>
                                {
                                  'Back Title searches are not available for this county and/or state. Please contact your local First American representative.'
                                }
                              </Typography>
                            </div>
                          </>
                        ) : isFileView && !fileRestricted ? (
                          <>
                            <NoResults
                              showButton={false}
                              type="backtitle"
                              titleText="No Back Title Results Found"
                              pText="We could not find any documents associated with your search."
                              className={classes.pt2}
                            />
                          </>
                        ) : (
                          <NoResults
                            showButton={false}
                            type="backtitle"
                            titleText="No Back Title Results Found"
                            pText="We could not find any documents associated with your search."
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {open && documentPreviewData && (
        <DocumentPreviewModal
          open={open}
          onClose={handleClose}
          data={documentPreviewData}
          onDownload={handleDownload}
          onPrint={handleDownload}
        />
      )}
    </ContentContainer>
  );
};

export default BackTitleContent;
