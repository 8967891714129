/* eslint-disable @typescript-eslint/no-explicit-any */
import { ArrowDropDown } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useMemo, useState } from 'react';
import { AgentNetTextInput, AgentTextInputProps } from '../TextField';

export type searchType = {
  id?: number | string | undefined | null;
  // key will always be string but
  // value can be anything in a option
  // hence using any type
  [key: string]: any;
  name: any;
  type?: ('primary' | 'secondary' | 'none') | string | undefined | null;
};

export type AutocompleteCountySearchProps = AgentTextInputProps & {
  label?: string;
  value?: string | null;
  onChange?: (value: any) => void;
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
  options?: Array<searchType> | null | undefined;
  disabled?: boolean;
  tabIndex?: number;
};

export function AutocompleteCountySearch({
  label = 'AutocompleteCountySearch',
  value: valueProp,
  onBlur = () => undefined,
  onChange = () => undefined,
  id = '',
  options,
  errs,
  name,
  showValidation,
  disabled,
  tabIndex = 0,
  ...props
}: AutocompleteCountySearchProps): JSX.Element {
  const [value, setValue] = useState<any | null>(null);
  const optionsById = useMemo(() => (val: any) => options?.find((ele) => ele.value === val) ?? null, [options]);

  /* Display Validation Errors */
  const [errors, setErrors] = useState(false);
  const [displayErrors, setDisplayError] = useState(showValidation);
  /* Will need this code for future purposes, will keep this out */
  // const displayErrorOnCallback = (callback: () => void) => {
  //   callback();
  // };

  useEffect(() => {
    const hasError = errs?.find((err) => err.field === name);
    hasError ? setErrors(true) : setErrors(false);
  }, [errs]);

  useEffect(() => {
    setDisplayError(showValidation);
  }, [showValidation]);

  useEffect(() => {
    if (value !== valueProp && valueProp !== '') {
      const val = optionsById(valueProp);
      setValue(val);
    }
  }, [valueProp, options]);

  const filterOptions = (options: searchType[], { inputValue }: { inputValue: string }) => {
    const input = inputValue?.toLowerCase();
    if (!input) {
      return options;
    }
    return options
      ?.filter((option: searchType) => option.name.toLowerCase().startsWith(input))
      ?.sort((optionA, optionB) => optionA.name.localeCompare(optionB.name));
  };

  return (
    <Autocomplete
      autoComplete
      autoSelect
      blurOnSelect
      clearOnEscape
      autoHighlight
      filterOptions={filterOptions}
      onBlur={onBlur}
      options={options ?? []}
      getOptionLabel={(option: searchType) => option.name}
      // we should show only id or something unique for the selection
      getOptionSelected={(selected, option) => selected.value === option?.value}
      value={value}
      onChange={(_, value) => {
        onChange(value?.value);
        setValue(value);
      }}
      renderInput={(param) => (
        <AgentNetTextInput
          variant="outlined"
          label={label}
          errs={errs}
          showValidation={showValidation}
          onChange={(value) => {
            onChange(value);
          }}
          {...param}
          {...props}
          onBlur={(e) => {
            const isFound = options?.filter(
              (item: { name: string }) => item.name.toLowerCase().search(e.target.value.toLowerCase()) === 0,
            )[0];
            onChange(isFound ? e.target.value : '');
            if (onBlur) onBlur(e);
          }}
          inputProps={{
            ...param.inputProps,
            'aria-label': 'autocomplete-field-input-select',
            autoComplete: 'new-password',
            title: value ? value.abbreviation : 'State',
            tabIndex: tabIndex,
          }}
          title={value && value.name ? value.name.toString() : label}
          InputLabelProps={{ shrink: true }}
          error={errors && displayErrors}
        />
      )}
      id={id}
      data-testid={id}
      popupIcon={<ArrowDropDown color="inherit" />}
      className="autocomplete-search"
      disabled={disabled}
    />
  );
}
export default AutocompleteCountySearch;
