import { useAuth } from '@agentnet/auth';
import { createStyles, makeStyles, MenuItem, Theme } from '@material-ui/core';
import { enqueueFilesApi } from 'api/orderManagement/order-management-api';
import {
  FileStatus,
  FileStatusColor,
  getKeyByValue,
  getMemberNames,
  ReasonCode,
  TeamAssignment,
} from 'features/orderManagement/enum';
import {
  FileEditAssignmentType,
  OrderFileQueueResponseType,
  OrderFilesRequestType,
} from 'features/orderManagement/types';
import useAsync from 'hooks/useAsync';
import { useEffect, useState } from 'react';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import { AgentNetDropdownSelector } from 'ui-kit/inputs';
import { SelectOption } from 'ui-kit/inputs/AgentNetDropdownSelector';

interface FileEditAssignmentProps {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  data: FileEditAssignmentType[];
  showTeams: boolean;
}

function firstOrDefault<T>(data: T[]): T | null {
  if (data && data.length > 0) {
    return data[0];
  }
  return null;
}

function getFileStatus(data: FileEditAssignmentType[]): FileStatus | null {
  const first = firstOrDefault(data)?.status;
  if (first && data?.every((datum) => datum.status === first)) {
    return FileStatus[first as keyof typeof FileStatus];
  }
  return null;
}

function getFileReasonCode(data: FileEditAssignmentType[]): ReasonCode | null {
  const first = firstOrDefault(data)?.reasonCode;
  if (first && data?.every((datum) => datum.reasonCode === first)) {
    return ReasonCode[first as keyof typeof ReasonCode];
  }
  return null;
}

function getFileTeam(data: FileEditAssignmentType[]): TeamAssignment | null {
  const first = firstOrDefault(data)?.assignedToGroup;
  if (first && data?.every((datum) => datum.assignedToGroup === first)) {
    return TeamAssignment[first as keyof typeof TeamAssignment];
  }
  return null;
}

export const FileEditAssignmentContainer = ({
  open,
  setOpen,
  data,
  showTeams,
}: FileEditAssignmentProps): JSX.Element => {
  const fileStatus = getFileStatus(data);
  const fileReasonCode = getFileReasonCode(data);
  const fileTeam = getFileTeam(data);

  const [status, setStatus] = useState<FileStatus | null>();
  const [reasonCode, setReasonCode] = useState<ReasonCode | null>();
  const [team, setTeam] = useState<TeamAssignment | null>();

  const { getAccessToken } = useAuth();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      routeContainer: {
        width: '100%',
      },
      circle: {
        width: 12,
        height: 12,
        display: 'inline-block',
        marginRight: 4,
        borderRadius: 12,
      },
      separator: {
        paddingBottom: 20,
      },
    }),
  );

  const classes = useStyles();

  const getTeamSelectOptions = (): SelectOption[] => {
    const teamSelectOptions: SelectOption[] = [];
    const teamAssignments = getMemberNames(TeamAssignment);
    let teamAssignment = teamAssignments.next();
    while (!teamAssignment.done) {
      teamSelectOptions.push({
        value: TeamAssignment[teamAssignment.value as keyof typeof TeamAssignment],
        name: teamAssignment.value,
      });
      teamAssignment = teamAssignments.next();
    }
    return teamSelectOptions;
  };

  const getReasonCodeSelectOptions = (): SelectOption[] => {
    const reasonCodesSelectOptions: SelectOption[] = [];
    const reasonCodes = getMemberNames(ReasonCode);
    let reasonCode = reasonCodes.next();
    while (!reasonCode.done) {
      reasonCodesSelectOptions.push({
        value: ReasonCode[reasonCode.value as keyof typeof ReasonCode],
        name: reasonCode.value,
      });
      reasonCode = reasonCodes.next();
    }
    return reasonCodesSelectOptions;
  };

  const getFileStatusMenuItems = (): JSX.Element[] => {
    const fileStatusMenuItems: JSX.Element[] = [];
    const fileStatuses = getMemberNames(FileStatus);
    let fileStatus = fileStatuses.next();
    while (!fileStatus.done) {
      const fileStatusKey = fileStatus.value;
      const fileStatusValue = FileStatus[fileStatusKey as keyof typeof FileStatus];
      const fileStatusColor = FileStatusColor[fileStatusKey as keyof typeof FileStatusColor];
      const menuItem = (
        <MenuItem key={fileStatusKey} value={fileStatusValue}>
          <span
            className={classes.circle}
            style={{ backgroundColor: '#' + fileStatusColor.toString(16).padStart(6, '0') }}
          ></span>
          {fileStatusKey}
        </MenuItem>
      );
      fileStatusMenuItems.push(menuItem);
      fileStatus = fileStatuses.next();
    }
    return fileStatusMenuItems;
  };

  const getStatus = () => status || fileStatus;
  const getTeam = () => team || fileTeam;
  const getReasonCode = () => reasonCode || fileReasonCode;

  const enqueueFiles = async (): Promise<OrderFileQueueResponseType> => {
    const token = await getAccessToken();
    const first = firstOrDefault(data);
    const orderId = first?.orderId ?? 0;
    const payload: OrderFilesRequestType = {
      orderId: orderId,
      fileIds: data.map((datum) => datum.fileId),
      fileStatusId: getStatus() ?? 0,
      reasonCodeId: getReasonCode() ?? 0,
      assignedGroupId: getTeam(),
    };
    return await enqueueFilesApi(orderId, payload, token);
  };

  const {
    execute: executeEnqueueFiles,
    status: enqueueFilesStatus,
    errors: enqueueFilesErrors,
  } = useAsync<OrderFileQueueResponseType>(enqueueFiles, false);

  const onConfirm = () => {
    executeEnqueueFiles();
  };

  const onDismissAction = () => {
    setStatus(null);
    setTeam(null);
    setReasonCode(null);
    setOpen(false);
  };

  useEffect(() => {
    if (enqueueFilesStatus === 'success') {
      if (data) {
        for (const datum of data) {
          datum.assignedToGroup = getKeyByValue(TeamAssignment, getTeam()) ?? '';
          datum.reasonCode = getKeyByValue(ReasonCode, getReasonCode()) ?? '';
          datum.status = getKeyByValue(FileStatus, getStatus()) ?? '';
        }
      }
      onDismissAction();
    } else if (enqueueFilesStatus === 'error') {
      console.error(enqueueFilesErrors);
    }
  }, [enqueueFilesStatus]);

  return (
    <AgentNetConfirmationDialog
      open={open}
      dialogTitle="Modify Assignment"
      dialogTextHTML={
        <>
          {showTeams && (
            <div className={classes.separator}>
              <AgentNetDropdownSelector
                label="Team"
                name="Team"
                required
                value={getTeam() || ''}
                options={getTeamSelectOptions()}
                menuOption={(value: any) => {
                  setTeam(value);
                }}
              />
            </div>
          )}
          <div className={classes.separator}>
            <AgentNetDropdownSelector
              label="Status"
              name="Status"
              value={getStatus() || ''}
              required
              customMenuItems={getFileStatusMenuItems()}
              menuOption={(value: any) => {
                setStatus(value);
              }}
            />
          </div>
          <div className={classes.separator}>
            <AgentNetDropdownSelector
              label="Reason Code"
              name="Reason Code"
              value={getReasonCode() || ''}
              required
              options={getReasonCodeSelectOptions()}
              menuOption={(value: any) => {
                setReasonCode(value);
              }}
            />
          </div>
        </>
      }
      dialogBtnContent="Save"
      secondaryActionBtnContent="Cancel"
      onDismissAction={onDismissAction}
      onConfirm={onConfirm}
      disabled={enqueueFilesStatus === 'pending'}
      disableBackDropClick
    />
  );
};
