import { getAgencyApiHost } from 'api/host-by-env';

export type GetServicerOrders = {
  action?: string | null;
  dateFrom?: string;
  dateTo?: string;
  firmId: number;
  isETA?: boolean;
};

export type GetServiceOrdersResult = {
  BusinessSegment: string;
  ETA: string;
  FileId: number;
  FileNumber: string;
  OrderNumber: string;
  ProductName: string;
  PropertyState: string;
  ServiceOrderReferenceId: number;
  Status: string;
  SubmittedDate: string;
  TransactionType: string;
  DateDelivered: string;
  ActionRequiredTime: ActionRequiredTimeType[];
  ContactName: string;
  PropertyAddress: string;
  IsNewMessageAvailable: boolean;
};

type ActionLogBase = {
  Type: string;
  Message: string;
  CreatedDate: string;
  CreatedByUserName: string;
};
type ActionRequiredTimeType = {
  startdate: string;
  enddate: string;
};

export type Note = ActionLogBase & {
  FileData: string;
};

export type AuditLog = ActionLogBase & {
  Action: string;
};

export type GetActionLogs = {
  FileId: number;
  ServiceOrderReferenceId: number;
};

export type GetActionLogsResult = {
  FileId: number;
  ServiceOrderReferenceId: number;
  Notes: Note[];
  AuditLogs: AuditLog[];
};

export type PostNotes = {
  fileId: number;
  notes: string;
  serviceOrderReferenceId: number;
};

export type UpdateMessageLastViewedDetailsRequest = {
  fileId: number;
  serviceOrderReferenceId: number;
};

export const getServiceOrders = async (
  payload: GetServicerOrders,
  token: string,
): Promise<GetServiceOrdersResult[]> => {
  const url = `${getAgencyApiHost()}service-order/v2/reports`;
  const response = await fetch(url, {
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    method: 'POST',
  });
  const json = await response.json();
  return json.Result;
};

export const getActionLogs = async (payload: GetActionLogs, token: string): Promise<GetActionLogsResult> => {
  const queryParams: string = new URLSearchParams(payload as unknown as Record<string, string>).toString();
  const url = `${getAgencyApiHost()}service-order/v2/orders/action-logs?${queryParams}`;
  const response = await fetch(url, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    method: 'GET',
  });
  const json = await response.json();
  return json.Result;
};

export const postNotes = async (payload: PostNotes, token: string): Promise<boolean> => {
  const url = `${getAgencyApiHost()}service-order/v2/orders`;
  const response = await fetch(url, {
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    method: 'PUT',
  });
  const json = await response.json();
  return json.Result;
};

export const updateMessageLastViewedDetails = async (
  payload: UpdateMessageLastViewedDetailsRequest,
  token: string,
): Promise<boolean> => {
  const url = `${getAgencyApiHost()}service-order/v2/orders/message-info`;
  const response = await fetch(url, {
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    method: 'POST',
  });
  const json = await response.json();
  return json.Result;
};
